import { addSessionType, getSessionsTypesByTherapist } from '../../../services/SessionService';
import { AlertContext } from '../../../contexts/AlertContext';
import React from 'react';
import { SessionType } from '../../../interfaces/Session';
import { useTranslation } from 'react-i18next';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface Error {
	hasError: boolean;
	text: string;
}

interface NewSessionTypeStore {
	error: Error;
	formIsValid: boolean;
	isLoading: boolean;
	sessionType: string;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleClose: () => void;
	handleConfirm: () => void;
}

interface NewSessionTypeModalProps {
	fetchData: () => Promise<void>;
	onChange: () => void;
}

const useNewSessionTypeModal = (props: NewSessionTypeModalProps): NewSessionTypeStore => {
	const { t } = useTranslation();
	const { getTherapistIdParam } = useTherapistSelected();
	const { showDefaultError } = React.useContext(AlertContext);
	const [sessionType, setSessionType] = React.useState<string>('');
	const [sessionsTypes, setSessionsTypes] = React.useState<SessionType[]>([]);
	const [error, setError] = React.useState<Error>({ hasError: false, text: '' });
	const [formIsValid, setFormIsValid] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const dataSessions = await getSessionsTypesByTherapist(therapistIdParam);

			setSessionsTypes(dataSessions);
		} catch (error) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;

		setSessionType(value);
		setFormIsValid(value !== '');
		setError({
			hasError: false,
			text: '',
		});
	};

	const handleClose = (): void => {
		setSessionType('');
		props.onChange();
	};

	const handleConfirm = async (): Promise<void> => {
		try {
			setIsLoading(true);

			const isValidSessionType = !sessionsTypes.some(session => session.name.toLowerCase() == sessionType.toLowerCase());

			if (isValidSessionType) {
				const therapistIdParam = getTherapistIdParam();

				await addSessionType(therapistIdParam, sessionType);
				await props.fetchData();
				setSessionType('');
				handleClose();
			} else {
				setError({
					hasError: true,
					text: t('agendaView:SESSION_TYPE_ALREADY_EXIST'),
				});
			}
		} catch (error) {
			showDefaultError();
		}
		finally {
			setIsLoading(false);
		}
	};

	return {
		error,
		formIsValid,
		isLoading,
		sessionType,
		handleChange,
		handleConfirm,
		handleClose,
	};
};

export default useNewSessionTypeModal;
