import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import React from 'react';
import { useRoutes } from '../../helpers/useRoutes';
import { UserRole } from '../../interfaces/User';

interface AuthRouteProps {
	Component: React.FC<RouteComponentProps>;
	path: string;
	exact?: boolean;
	roleRequired: UserRole;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ Component, path, exact = false, roleRequired }) => {
	const { isAuth, user } = React.useContext(AuthContext);
	const { routes } = useRoutes();

	return (
		<>
			{
				user?.roles.includes(roleRequired) ? (
					<Route
						exact={exact}
						path={path}
						key={path}
						render={(props: RouteComponentProps): JSX.Element =>
							isAuth ? <Component {...props} /> : <Redirect to={`/${routes.SIGNIN}`} />
						}
					/>
				) : (
					<Redirect to={`/${routes.SIGNIN}`} />
				)
			}
		</>
	);
};

export default AuthRoute;
