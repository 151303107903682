import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EventIcon from '@material-ui/icons/Event';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { formatDate } from '../../../../helpers/Fomatting';
import { Note } from '../../../../interfaces/Note';
import React from 'react';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import styles from './Steppers.module.scss';

interface SteppersProps {
	notes: Note[];
	activeStep: number;
	onClick: (index: number) => void;
	showCheckbox?: boolean;
	onChangeCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickRemove?: (id?: number) => void;
}

const Steppers: React.FC<SteppersProps> = ({ notes, activeStep, onClick, showCheckbox, onChangeCheckbox, onClickRemove }) => {
	const stepperClasses = `${styles.steppers} ${showCheckbox && styles.checkbox}`;
	const lineClasses = showCheckbox ? styles.connector : '';

	return (
		<div className={stepperClasses}>
			<Stepper className={styles.container} activeStep={activeStep} orientation="vertical" connector={<StepConnector classes={{ line: lineClasses }} />}>
				{notes.map((note, index) => {
					const labelClasses = `styles.stepLabel ${index === activeStep ? styles.stepLabelActive : styles.stepLabelInactive}`;
					const icon = showCheckbox && (
						<Checkbox
							checked={note.checked}
							onChange={onChangeCheckbox}
							name={(note.id as number).toString()}
							color="secondary"
							size="small"
							checkedIcon={<CheckBoxOutlinedIcon />}
						/>);

					return (
						<Step className={styles.step} key={note.id} onClick={(): void => onClick(index)}>
							<StepLabel
								icon={icon}
							>
								<StepLabel
									className={labelClasses}
									icon={
										note.hadSession ? (
											<EventIcon className={styles.dotIcon} fontSize="small" color={index === activeStep ? 'secondary' : 'inherit'} />
										) : (
											<FiberManualRecordIcon className={styles.dotIcon} fontSize="small" color={index === activeStep ? 'secondary' : 'inherit'} />
										)
									}
								>
									<div className={styles.text}>
										<span>{formatDate(note.title)}</span>
										{
											onClickRemove && index === activeStep && (
												<DeleteOutlinedIcon onClick={(): void => onClickRemove(note.id)} className={styles.trashIcon} fontSize="small" />
											)
										}
									</div>
								</StepLabel>
							</StepLabel>
							<StepContent className={styles.stepContent}>
								{note.description.length > 85 ? `${note.description.slice(0, 85)}...` : note.description}
							</StepContent>
						</Step>
					);
				})}
			</Stepper>
		</div>
	);
};

export default Steppers;
