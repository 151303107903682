import { GlobalReport, ReportContext, ReportUpdateContext} from '../../../../../../../contexts/ReportContext';
import { documentReportsBodies } from '../../../../../../../services/TherapistService';
import { DocumentSharedBody } from '../../../../../../../interfaces/Document';
import React from 'react';
import { useTherapistSelected } from '../../../../../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface PreviewStore {
	isLoading: boolean;
	report: GlobalReport;
	handleChangeTitle: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleEditPreview: (value: string) => void;
}

const usePreview = (): PreviewStore => {
	const { report } = React.useContext(ReportContext);
	const { setReport } = React.useContext(ReportUpdateContext);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { t } = useTranslation();
	const { getTherapistIdParam } = useTherapistSelected();

	const loadPreview = React.useCallback(async (): Promise<void> => {
		let data = '';

		if (Object.keys(report.patient).length) {
			data = data.concat(
				`${t('patientsView:REPORTS_PREVIEW_PATIENT_INFO')}:
				 ${Object.keys(report.patient).map(key => `<p>${key}: ${report.patient[key]}</p>`).join('')}
				`);
			data = data.concat('<p>-----------------------------</p>');
		}

		if (Object.keys(report.therapist).length) {
			data = data.concat(
				`${t('patientsView:REPORTS_PREVIEW_PROFESSIONAL_INFO')}:
				 ${Object.keys(report.therapist).map(key => report.therapist[key] ? `<p>${key}: ${report.therapist[key]}</p>` : '').join('')}
				`);
			data = data.concat('<p>-----------------------------</p>');

		}

		let documentBodies: DocumentSharedBody[] = [];

		if (report.forms.length) {
			const therapistIdParam = getTherapistIdParam();

			documentBodies = await documentReportsBodies(therapistIdParam, report.forms.map(f => f.id));
		}

		report.resourcesOrder.forEach((orderItem) => {
			if (isNaN(+orderItem.id)) {
				report.notes.forEach((note) => {
					data = data.concat(
						`<p>${t('note')}: ${note.title}:</p>
						 <p>${note.description}</p>
						 <p></p>`);
				});
			} else {
				const body = documentBodies.find(x => x.id === +orderItem.id)?.content;

				if (body) {
					data = data.concat(body);
				}
			}

			data = data.concat('<p>-----------------------------</p>');
		});

		setReport({...report, previewValue: data });

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setReport, t]);

	React.useEffect(() => {
		setIsLoading(true);
		loadPreview();
		setIsLoading(false);
	}, [loadPreview]);

	const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setReport({...report, title: event.target.value });
	};

	const handleEditPreview = (value: string): void => {
		setReport({...report, previewValue: value });
	};

	return {
		isLoading,
		report,
		handleEditPreview,
		handleChangeTitle,
	};
};

export default usePreview;
