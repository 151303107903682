import Modal from '../../Modal/Modal';
import React from 'react';
import styles from './DeleteSessionModal.module.scss';
import useDeleteSessionModal from './useDeleteSessionModal';
import { useTranslation } from 'react-i18next';

interface DeleteSessionModalProps {
	open: boolean;
	onChange: () => void;
	onDelete: () => void;
	sessionId: number | undefined;
}

const DeleteSessionModal: React.FC<DeleteSessionModalProps> = ({ open, sessionId, onChange, onDelete }) => {
	const { t } = useTranslation();
	const {
		handleClose,
		handleDelete,
	} = useDeleteSessionModal({ onChange, onDelete });

	return (
		<Modal
			open={open}
			title={t('agendaView:TITLE_DELETE_SESSION_MODAL')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('agendaView:CONFIRM_BUTTON_PREVIEW_MODAL'),
				onClick: (): void => handleDelete(sessionId),
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('agendaView:REJECT_BUTTON_PREVIEW_MODAL'),
				onClick: (): void => handleClose(),
			}}
			showDividers={false}
		>
			<div className={styles.content} >
				{t('agendaView:CONTENT_DELETE_SESSION_MODAL')}
			</div>
		</Modal>
	);
};

export default DeleteSessionModal;
