import { CheckboxQuestion, Section } from '../../../../interfaces/DocumentForm';
import { FormContext, FormUpdateContext } from '../../../../contexts/FormContext';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CheckboxPreviewStore {
	question: CheckboxQuestion;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface TCheckbox {
	[name: string]: boolean;
}

const useCheckboxPreview = (props: QuestionPreviewProps): CheckboxPreviewStore => {
	const { t } = useTranslation();
	const { formModel, formModel: { showErrors }} = React.useContext(FormContext);
	const { handleUpdateQuestion } = React.useContext(FormUpdateContext);
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = formModel.sections[props.sectionIndex].questions[props.questionIndex] as CheckboxQuestion;

	const isAnswerValid = (value: Partial<TCheckbox>, min: number): boolean => {
		if (!question.required) {
			return true;
		}

		const checkedValues = Object.keys(value).filter(index => value[index.toString()]);

		return checkedValues.length >= min;
	};

	React.useEffect(() => {
		const isValid = !question.required || isAnswerValid(question.value, question.min);

		setErrorMessage(t('libraryView:FORMS_FIELD_REQUIRED'));

		handleUpdateQuestion({ ...question, isValid }, formModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, checked } = event.target;
		const {value} = question;

		const itemsChecked = value?.length || 0;
		const isReplacement = Object.keys(value).includes(name);

		if (itemsChecked < question.max || (isReplacement && !checked)) {
			const valueToUpdate = { ...value, [name]: checked };
			const isValid = isAnswerValid(valueToUpdate, question.min);

			setErrorMessage(isValid ? '' : `${t('libraryView:FORMS_MIN_CHECKBOX_VALIDATION')} ${question.min}`);
			handleUpdateQuestion({ ...question, value: valueToUpdate, isValid }, formModel.sections[props.sectionIndex] as Section);
		}
	};

	return {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	};
};

export default useCheckboxPreview;
