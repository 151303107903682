import { getLinkedPatientDocuments, getPreviewDocument } from '../../../../../../../services/TherapistService';
import { ReportContext, ReportUpdateContext } from '../../../../../../../contexts/ReportContext';
import { DocumentShared } from '../../../../../../../interfaces/Document';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTherapistSelected } from '../../../../../../../hooks/useTherapistSelected';

interface DocumentStore {
	isLoading: boolean;
	formsInfo: Array<DocumentShared>;
	activeItem: DocumentShared | undefined;
	selectedItems: Array<number>;
	selectAll: boolean;
	handleClickActiveCard: (id: number) => void;
	handleFormsSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useDocument = (): DocumentStore => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { report, formsInfo } = React.useContext(ReportContext);
	const { handleChangeFormsInfo, addForm } = React.useContext(ReportUpdateContext);
	const [activeItem, setActiveItem] = React.useState<DocumentShared | undefined>();
	const [selectAll, setSelectAll] = React.useState<boolean>(false);
	const [selectedItems, setSelectedItems] = React.useState<Array<number>>([]);
	const { id } = useParams<{ id: string }>();
	const { getTherapistIdParam } = useTherapistSelected();

	const setReportToDocument = async (item: DocumentShared): Promise<DocumentShared> => {
		if (item && !item.reportDocument) {
			const therapistIdParam = getTherapistIdParam();

			item.reportDocument = await getPreviewDocument(therapistIdParam, item.id);
		}

		return item;
	};

	const loadData = async (): Promise<void> => {
		setIsLoading(true);
		const therapistIdParam = getTherapistIdParam();
		const linkedDocuments = await getLinkedPatientDocuments(therapistIdParam, +id);
		const formsData = linkedDocuments.filter(doc => doc.status === 'COMPLETED');
		let [ item ] = formsData;

		handleChangeFormsInfo(formsData);
		item = await setReportToDocument(item);
		setActiveItem(item);
		setIsLoading(false);
	};

	React.useEffect(() => {
		loadData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const itemsChecked = report.forms.map(form => form.id as number);

		setSelectedItems(itemsChecked);
		setSelectAll(report.forms.length === formsInfo.length);
	}, [formsInfo.length, report]);

	const handleClickActiveCard = async(id: number): Promise<void> => {
		let item = formsInfo.find(item => (item.id as number) === id);

		if (item && !item.reportDocument) {
			item = await setReportToDocument(item);
		}

		setActiveItem(item);
	};

	const handleFormsSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if(event.target.name === 'selectAll') {
			setSelectAll(prevState => !prevState);

			formsInfo.forEach(item => {
				addForm((item.id as number).toString(), event.target.checked);
			});
		} else {
			addForm(event.target.name, event.target.checked);
			setSelectAll(report.forms.length === formsInfo.length);
		}
	};

	return {
		isLoading,
		formsInfo,
		activeItem,
		selectedItems,
		selectAll,
		handleClickActiveCard,
		handleFormsSelect,
	};
};

export default useDocument;
