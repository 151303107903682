import logo from '../../logo.svg';
import React from 'react';
import styles from './LoadingSpinner.module.scss';

const LoadingSpinner = (): JSX.Element => {
	return (
		<img src={logo} className={styles.loadingSpinner} alt="logo" />
	);
};

export default LoadingSpinner;
