import { AuthContext, AuthUpdateContext } from '../../../contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import React from 'react';
import { signOut } from '../../../services/AuthService';
import { useRoutes } from '../../../helpers/useRoutes';
import { UserRole } from '../../../interfaces//User';
import { useSessionStorage } from '../../../hooks/useSessionStorage';

interface TopBarStore {
	avatarUrl?: string;
	anchorRef: React.RefObject<HTMLButtonElement>;
	open: boolean;
	handleChangeTherapist: () => void;
	handleClickNotifactionIcon: () => void;
	handleClose: (event: React.MouseEvent<EventTarget>) => void;
	handleLogout: () => Promise<void>;
	handleOpenProfile: (event: React.MouseEvent<EventTarget>) => void;
	handleToggle: () => void;
}

const useTopBar = (): TopBarStore => {
	const history = useHistory();
	const { assistantRoutes, institutionRoutes, patientRoutes, routes: ROUTES, therapistRoutes } = useRoutes();
	const { user } = React.useContext(AuthContext);
	const { setIsAuth } = React.useContext(AuthUpdateContext);
	const { removeItem } = useSessionStorage('token');
	const { getItem, removeItem: removeSelectedTherapistId} = useSessionStorage('selected_therapist_id_by_assistant');
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const location = useLocation();

	const handleToggle = (): void => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<EventTarget>): void => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	const handleOpenProfile = (event: React.MouseEvent<EventTarget>): void => {
		switch(user?.roles[0]) {
			case UserRole.ASSISTANT:
				history.push(`/${assistantRoutes.ASSISTANT}/${assistantRoutes.PROFILE}`);
				break;
			case UserRole.INSTITUTION:
				history.push(`/${institutionRoutes.INSTITUTION}/${institutionRoutes.PROFILE}`);
				break;
			case UserRole.PATIENT:
				history.push(`/${patientRoutes.PATIENT}/${patientRoutes.PROFILE}`);
				break;
			case UserRole.THERAPIST:
				history.push(`/${therapistRoutes.THERAPIST}/${therapistRoutes.PROFILE}`);
				break;
		}

		handleClose(event);
	};

	const handleChangeTherapist = (): void => {
		removeSelectedTherapistId();
		history.push(`/${assistantRoutes.ASSISTANT}/${assistantRoutes.HOME}`);
	};

	const handleLogout = async (): Promise<void> => {
		await signOut();
		removeItem();
		removeSelectedTherapistId();
		setIsAuth(false);
		history.push(`/${ROUTES.SIGNIN}`);
	};

	const handleClickNotifactionIcon = (): void => {
		const therapistSelectedId = getItem();
		const assistantTaskRoute = `/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistSelectedId}/${assistantRoutes.TASKS}`;
		const therapistTaskRoute = `/${therapistRoutes.THERAPIST}/${therapistRoutes.TASKS}`;
		let taskRoute = '';

		if (location.pathname === assistantTaskRoute || location.pathname === therapistTaskRoute) {
			return;
		}

		if (user?.roles[0] === UserRole.ASSISTANT) {
			taskRoute = assistantTaskRoute;
		} else if (user?.roles[0] === UserRole.THERAPIST) {
			taskRoute = therapistTaskRoute;
		}

		if (taskRoute) {
			history.push(taskRoute);
		}
	};

	return {
		anchorRef,
		avatarUrl: user?.avatarUrl,
		open,
		handleChangeTherapist,
		handleClickNotifactionIcon,
		handleClose,
		handleLogout,
		handleOpenProfile,
		handleToggle,
	};
};

export default useTopBar;
