import React from 'react';

interface DurationSelectorStore {
	isPersonalized: boolean;
	disabledButtons: string[];
	handleAddDuration: (event: React.MouseEvent, duration: string, name: string) => void;
	setIsPersonalized: (value: boolean) => void;
	setDisabledButtons: (value: string[]) => void;
}

const useDurationSelector = (
	multipleValues: boolean,
	handleCheckChange: (event: React.MouseEvent, value: string | string[], name: string) => void
): DurationSelectorStore => {
	const [isPersonalized, setIsPersonalized] = React.useState<boolean>(false);
	const [disabledButtons, setDisabledButtons] = React.useState<string[]>([]);

	const handleAddDuration = (event: React.MouseEvent, duration: string, name: string): void => {
		if (disabledButtons.includes(duration)) {
			const value = disabledButtons.filter(button => button !== duration);

			setDisabledButtons(value);
			handleCheckChange(event, value, name);
		} else {
			if (multipleValues) {
				const value = [...disabledButtons, duration];

				setDisabledButtons(value);
				handleCheckChange(event, value, name);
			} else {
				setDisabledButtons([duration]);
				handleCheckChange(event, duration, name);
			}
		}
	};

	return {
		isPersonalized,
		disabledButtons,
		handleAddDuration,
		setIsPersonalized,
		setDisabledButtons,
	};
};

export default useDurationSelector;
