import { CardsTable, PrivateTopBar, ResourcePreview } from '../../../components';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListPatientsModal from '../../../components/Modals/ListPatientsModal/ListPatientsModal';
import React from 'react';
import { ResourceCard } from '../../../interfaces/Resource';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import ShareIcon from '@material-ui/icons/Share';
import styles from './ResourceLibrary.module.scss';
import TextField from '@material-ui/core/TextField';
import useResourceLibrary from './useResourceLibrary';
import { useTranslation } from 'react-i18next';

const ResourceLibrary = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		tagsColumns,
		resourcesColumns,
		filteredTags,
		resources,
		selectedTagId,
		selectedResource,
		gridWidth,
		searchValue,
		openListPatientsModal,
		handleClickTagCard,
		handleClickResourceCard,
		handleChangeSearchValue,
		handleClickLink,
	} = useResourceLibrary();

	return (
		<div className={styles.resourceLibrary}>
			<PrivateTopBar
				title={t('resourceLibraryView:TITLE')}
				buttons={
					<div className={styles.search}>
						<Divider orientation="vertical" className={styles.divider} />
						<TextField
							placeholder={t('therapistButtons:SEARCH_INPUT')}
							value={searchValue}
							onChange={handleChangeSearchValue}
							InputProps={{
								startAdornment: <SearchOutlinedIcon color="disabled" className={styles.icon} />,
								disableUnderline: true,
							}}
						/>
					</div>
				}
			/>
			<div className={styles.container}>
				<Grid container spacing={3} className={styles.grid}>
					<Grid item xs={12} sm={gridWidth} >
						<SectionTitle title={t('resourceLibraryView:PROBLEMATIC_CARD_HEADER')} showDivider={false} />
						<div className={styles.column}>
							<CardsTable
								data={filteredTags.map(filteredTag => ({ ...filteredTag, name: t(filteredTag.name) }))}
								columnsDef={tagsColumns}
								onClick={handleClickTagCard}
								isLoading={false}
								stylizeSelected={true} />
						</div>
					</Grid>
					{
						selectedTagId &&
						<Grid item xs={12} sm={gridWidth} >
							<SectionTitle title={t('resourceLibraryView:RESOURCE_CARD_HEADER')} showDivider={false} />
							<div className={styles.column}>
								<CardsTable
									data={resources}
									columnsDef={resourcesColumns}
									onClick={handleClickResourceCard}
									isLoading={false}
									stylizeSelected={true} />
							</div>
						</Grid>
					}
					{
						selectedResource &&
						<Grid item xs={12} sm={gridWidth} >
							<div style={{ display: 'flex' }}>
								<SectionTitle title={t('resourceLibraryView:PREVIEW_CARD_HEADER')} showDivider={false} />
								<Button
									className={styles.shareIcon}
									startIcon={<ShareIcon />}
									onClick={handleClickLink}
								>
									{t('resourceLibraryView:BUTTON_LINK')}
								</Button>
							</div>
							<div className={styles.column}>
								<ResourcePreview resource={selectedResource as ResourceCard} showTitle={false} />
							</div>
						</Grid>
					}
				</Grid>
			</div>
			{
				openListPatientsModal &&
				<ListPatientsModal open={openListPatientsModal} onChange={handleClickLink} type={'resource'} objectId={selectedResource?.id} />
			}
		</div>
	);
};

export default ResourceLibrary;
