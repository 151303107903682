import logo from '../../assets/images/pragma-logo-sidebar.svg';
import React from 'react';
import styles from './SideBar.module.scss';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useSideBar from './useSideBar';
import { useTranslation } from 'react-i18next';

interface SideBarProps {
  children?: React.ReactNode;
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  path: string;
}

const tabClasses = { root: styles.tab, wrapper: styles.tabLabel, selected: styles.tabLabelSelected };

const SideBar: React.FC = (props: SideBarProps) => {
	const { t } = useTranslation();
	const {
		tabs,
		selectedTab,
		handleChange,
	} = useSideBar();

	const TabPanel = (props: TabPanelProps): JSX.Element => {
		const { value, path, children, ...other } = props;

		return (
			<div
				className={styles.content}
				role="tabpanel"
				hidden={value !== path}
				id={`tabpanel-${path}`}
				aria-labelledby={`tab-${path}`}
				{...other}
			>
				{value === path && (
					<>
						{children}
					</>
				)}
			</div>
		);
	};

	return (
		<div className={styles.sidebar}>
			<div className={styles.sidebarBoxShadow}>
				<div className={styles.logo}>
					<img src={logo} alt="pragma" />
				</div>
				<Tabs
					value={selectedTab}
					orientation="vertical"
					indicatorColor="secondary"
					textColor="secondary"
					onChange={handleChange}
					aria-label="sidebar"
					className={styles.tabs}
				>
					{
						tabs.map(tab => (
							<Tab key={tab.id} label={t(tab.label)} value={tab.path} icon={<tab.icon className={styles.tabIcon} />} classes={tabClasses} id={`tab-${tab.id}`} aria-controls={`tabpanel-${tab.id}`} />
						))
					}
				</Tabs>
			</div>
			{
				tabs.map(tab => (
					<TabPanel key={tab.id} value={selectedTab} path={tab.path}>
						{props.children}
					</TabPanel>
				))
			}
		</div>
	);
};

export default SideBar;
