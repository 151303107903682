import { Availability, SessionAvailability } from '../../../interfaces/SessionAvailability';
import { Day, Hour, Office } from '../../../interfaces/Session';
import { getCancelDaysByTherapist, getDaysByTherapist, getDurationsByTherapist, getHoursByTherapist } from './../../../services/AvailabilityService';
import { TherapistModalContext, UpdateTherapistModalContext } from './../../../contexts/TherapistModalContext';
import { AlertContext } from '../../../contexts/AlertContext';
import { DaysWeek } from '../../../interfaces/Generic';
import { DaysWeekArray } from '../../../services/GenericService';
import React from 'react';
import { updateTherapistAvailability } from '../../../services/TherapistService';
import useForm from '../../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface Time {
	days: Day[];
	hours: Hour[];
	cancelDays: Day[];
}

interface AvailabilityModalStore {
	isLoading: boolean;
	sessionDurationOptions: any;
	formValues: any;
	isValidForm: boolean;
	time: Time;
	handleCloseAvailabilityModal: () => void;
	handleConfirmNewSession: () => void;
	handleIncrement: () => void;
	handleDecrement: (index: number) => void;
	handleInputChange: (event: React.ChangeEvent<{ value: unknown; name: string }>) => void;
	handleCheckChange: (event: React.MouseEvent, value: string | string[], name: string) => void;
	handleTimeSelectorInput: (e: any, index: number) => void;
	handleDurationSelectorInput: (e: any, value: string | string[], index: number) => void;
	handlePlaceChange: (event: React.ChangeEvent<{ value: unknown; name: string }>, index: number) => void;
}

const initialFormValues: SessionAvailability = {
	availabilities: [{
		endTime: '',
		repeatingDays: [''],
		startTime: '',
		therapistOffice: {
			address: '',
		},
	}],
	sessionDuration: 0,
	cancellationNoticePeriod: 0,
};

const initialTimeValues: Time = {
	days: [],
	hours: [],
	cancelDays: [],
};

interface UseAvailabilityModalTherapistProps {
	places: Office[];
	therapistAvailability: SessionAvailability;
	fetchData: () => Promise<void>;
}

const useAvailabilityModalTherapist = (props: UseAvailabilityModalTherapistProps): AvailabilityModalStore => {
	const { t } = useTranslation();
	const { setIsOpen } = React.useContext(UpdateTherapistModalContext);
	const { isOpen } = React.useContext(TherapistModalContext);
	const { showDefaultError } = React.useContext(AlertContext);
	const { formValues, handleInputChange, handleCheckChange, reset, addFormValues } = useForm(initialFormValues);
	const [time, setTime] = React.useState(initialTimeValues);
	const [sessionDurationOptions, setSessionDurationOptions] = React.useState<Hour[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isValidForm, setIsValidForm] = React.useState<boolean>(false);
	const { places, therapistAvailability, fetchData } = props;
	const { getTherapistIdParam } = useTherapistSelected();

	const loadData = async (): Promise<void> => {
		setIsLoading(true);

		try {
			if (therapistAvailability.sessionDuration) {
				addFormValues({
					sessionDuration: `${therapistAvailability.sessionDuration} ${t('availabilityView:MINUTES')}`,
					cancellationNoticePeriod: therapistAvailability.cancellationNoticePeriod.toString().concat(' ').concat(therapistAvailability.cancellationNoticePeriod > 1 ? t('availabilityView:DAYS_LABEL') : t('availabilityView:DAY_LABEL')),
					availabilities: therapistAvailability.availabilities.map(availability => {
						availability.repeatingDays = availability.repeatingDays.map(repeatedDay => t(`availabilityView:${repeatedDay}`));
						availability.therapistOffice.address = places.find(place => place.id === availability.therapistOffice.id)?.address || '';

						return availability;
					}),
				});
			}

		} catch (e) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}

		const hours = await getHoursByTherapist();
		const days = await getDaysByTherapist();
		const durationsToDisplay = await getDurationsByTherapist();
		const cancelDays = await getCancelDaysByTherapist();

		const cancelDaysToDisplay = cancelDays.map((day, index) => ({
			...day,
			value: !index ? `${day.value} ${t('availabilityView:DAY_LABEL')}` : `${day.value} ${t('availabilityView:DAY_LABEL')}s`,
		}));

		setTime({ days, hours, cancelDays: cancelDaysToDisplay });
		setSessionDurationOptions(durationsToDisplay);
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const isValid = formValues.sessionDuration && formValues.cancellationNoticePeriod &&
			!formValues.availabilities.filter((availability: Availability) => !availability.repeatingDays.length || !availability.endTime || !availability.startTime).length;

		setIsValidForm(isValid);
	}, [formValues]);

	const handleCloseAvailabilityModal = (): void => {
		reset();
		setIsOpen(!isOpen);
	};

	const handleConfirmNewSession = async (): Promise<void> => {
		const sessionAvailability = {
			sessionDuration: parseInt(formValues.sessionDuration.replace(t('availabilityView:MINUTES'), '')),
			cancellationNoticePeriod: parseInt(formValues.cancellationNoticePeriod.replace(t('availabilityView:DAYS_LABEL'), '')),
			availabilities: formValues.availabilities.map((availability: Availability) => {
				availability.repeatingDays = availability.repeatingDays.filter((repeatedDay: string) => repeatedDay !== '')
					.map(repeatedDay => DaysWeek[DaysWeekArray.find(day => t(day.label) === repeatedDay)?.id || 0]);

				const place = places.find(place => place.address === availability.therapistOffice.address);

				if (place) {
					availability.therapistOffice = {
						id: place.id,
						address: place.address,
					};
				}

				return availability;
			}),
		};

		const therapistIdParam = getTherapistIdParam();

		await updateTherapistAvailability(therapistIdParam, sessionAvailability);
		await fetchData();
		handleCloseAvailabilityModal();
	};

	const handleDecrement = (index: number): void => {
		const { availabilities } = formValues;

		availabilities.splice(index, 1);
		addFormValues({ availabilities });
	};

	const handleIncrement = (): void => {
		const { availabilities } = formValues;
		const newAvailability = {
			endTime: '',
			repeatingDays: [''],
			startTime: '',
			therapistOffice: {
				address: '',
			},
		};

		availabilities.push(newAvailability);
		addFormValues({ availabilities });
	};

	const handleTimeSelectorInput = (event: React.ChangeEvent<{ value: unknown; name: string }>, index: number): void => {
		const { target } = event;
		const { availabilities } = formValues;
		const availability = availabilities[index];

		availability[target.name] = target.value;
		addFormValues({ availabilities });
	};

	const handleDurationSelectorInput = (event: React.MouseEvent, value: string | string[], index: number): void => {
		const { availabilities } = formValues;
		const availability = availabilities[index];

		availability.repeatingDays = value;
		addFormValues({ availabilities });
	};

	const handlePlaceChange = (event: React.ChangeEvent<{ value: unknown; name: string }>, index: number): void => {
		const { availabilities } = formValues;
		const availability = availabilities[index];

		availability.therapistOffice = {
			id: event.target.value,
		};
		addFormValues({ availabilities });
	};

	return {
		isLoading,
		sessionDurationOptions,
		formValues,
		isValidForm,
		time,
		handleCloseAvailabilityModal,
		handleConfirmNewSession,
		handleIncrement,
		handleDecrement,
		handleInputChange,
		handleCheckChange,
		handleTimeSelectorInput,
		handleDurationSelectorInput,
		handlePlaceChange,
	};
};

export default useAvailabilityModalTherapist;
