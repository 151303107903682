export default {
	es: {
		chatView: {
			CHAT: 'Mensajes',
			NO_USER_SELECTED: 'No user selected',
			OFFLINE: 'Desconectado',
			ONLINE: 'En linea',
			PLACEHOLDER_WRITE_MESSAGE: 'Escribe un mensaje...',
			SEND: 'Enviar',
			SEARCH_CONTACTS: 'Buscar contactos',
			TITLE: 'Chat',
			TODAY: 'Hoy',
		},
	},
	en: {
		chatView: {
			CHAT: 'Messages',
			NO_USER_SELECTED: 'No hay un usuario seleccionado',
			OFFLINE: 'Offline',
			ONLINE: 'Online',
			PLACEHOLDER_WRITE_MESSAGE: 'Wirte a message...',
			SEARCH_CONTACTS: 'Search contacts',
			SEND: 'Send',
			TITLE: 'Chat',
			TODAY: 'Today',
		},
	},
};
