export default {
	es: {
		patientInvitationsView: {
			MESSAGE_NO_INVITATIONS: 'No tienes invitaciones',
			TITLE_INVITATIONS: 'Invitaciones',
		},
		patientHomeView: {
			BUTTON_ACCEPT: 'Aceptar',
			BUTTON_CHANGE: 'Cambiar',
			BUTTON_DECLINE: 'Declinar',
			BUTTON_REQUEST_NEW_SESION: 'Solicitar nueva sesión',
			BUTTON_SEE_ALL: 'Ver todos',
			BUTTON_ENABLED: 'HabilitAR',
			BUTTON_DISABLED: 'Deshabilitar',
			FORMS: 'formularios',
			LASTEST_RESOURCES_SHARED_DOCUMENTS: 'últimos recursos y documentos compartidos',
			MODAL_BUTTON_CANCEL: 'Cancelar',
			MODAL_BUTTON_CONFIRM: 'Aceptar',
			MODAL_BUTTON_DELETE: 'Eliminar',
			MODAL_CALENDAR: 'calendario',
			MODAL_CHANGE_THERAPIST: 'Cambiar Terapeuta',
			MODAL_HOURS_AVAILABLE: 'horarios disponibles',
			MODAL_MESSAGE_NO_SESSION: 'No hay horarios disponibles',
			MODAL_NEW_SESSION: 'Nueva Sesión',
			NEXT_SESIONS: 'próximas sesiones',
			RESOURCES: 'recursos',
			SESSION_SITE_CONSULTING_ROOM: 'Consultorio',
			SESSION_SITE_PENDING: 'Pendiente',
			SESSION_SITE_TELEHEALTH: 'Telehealth',
			TITLE_HOME: 'Inicio',
			TITLE_NO_THERAPIST_SELECTED: 'No hay terapeuta seleccionado',
		},
		libraryView: {
			TITLE_LIBRARY: 'Biblioteca',
			BUTTON_DOWNLOAD_SELECTED_ITEMS: 'Descarga seleccionados',
			PREVIEW: 'vista previa',
			DESCRIPTION: 'descripción',
			INSTRUCTION: 'instrucción',
			REFERENCES: 'referencias',
			TAB_RESOURCES: 'Recursos',
			TAB_FORMS: 'Formularios',
			TAB_REPORTS: 'Reportes',
			FORMS_TEXT_PLACEHOLDER: 'Ingrese su respuesta...',
			FORMS_TEXT_VALIDATION: 'Respuesta demasiado corta',
			FORMS_NUMERIC_VALIDATION: 'Rango válido',
			FORMS_FIELD_REQUIRED: 'Este campo es requerido',
			FORMS_MIN_CHECKBOX_VALIDATION: 'Este campo es requerido. Marque al menos',
			FORMS_BUTTON_SAVE: 'Guardar',
			FORMS_BUTTON_RESET: 'Reiniciar',
			GENERIC: 'Otros',
		},
	},
	en: {
		patientInvitationsView: {
			MESSAGE_NO_INVITATIONS: 'You have no invitations',
			TITLE_INVITATIONS: 'Invitations',
			WARNING_MEESSAGE: 'Warning',
			CANCEL_THERAPIST_INVITATION_MESSAGE: 'Are you sure you want to cancel the invitation?',
			MODAL_CONFIRM_BUTTON: 'Confirmar',
			MODAL_CANCEL_BUTTON: 'Cancelar',
		},
		patientHomeView: {
			BUTTON_ACCEPT: 'Accept',
			BUTTON_CHANGE: 'Change',
			BUTTON_DECLINE: 'Decline',
			BUTTON_REQUEST_NEW_SESION: 'Request a new sesion',
			BUTTON_SEE_ALL: 'See all',
			BUTTON_ENABLED: 'Enable',
			BUTTON_DISABLED: 'Disable',
			FORMS: 'forms',
			LASTEST_RESOURCES_SHARED_DOCUMENTS: 'latest resources and shared documents',
			MODAL_BUTTON_CANCEL: 'Cancel',
			MODAL_BUTTON_CONFIRM: 'Confirm',
			MODAL_BUTTON_DELETE: 'Delete',
			MODAL_CALENDAR: 'calendar',
			MODAL_CHANGE_THERAPIST: 'Change Therapist',
			MODAL_HOURS_AVAILABLE: 'hours available',
			MODAL_MESSAGE_NO_SESSION: 'No schedules available',
			MODAL_NEW_SESSION: 'New Sesion',
			NEXT_SESIONS: 'next sesions',
			RESOURCES: 'resources',
			SESSION_SITE_CONSULTING_ROOM: 'Consulting room',
			SESSION_SITE_PENDING: 'Pending',
			SESSION_SITE_TELEHEALTH: 'Telehealth',
			TITLE_HOME: 'Home',
			TITLE_NO_THERAPIST_SELECTED: 'No therapist selected',
		},
		libraryView: {
			TITLE_LIBRARY: 'Library',
			BUTTON_DOWNLOAD_SELECTED_ITEMS: 'Download selected',
			PREVIEW: 'Preview',
			DESCRIPTION: 'description',
			INSTRUCTION: 'instruction',
			REFERENCES: 'references',
			TAB_RESOURCES: 'Resources',
			TAB_FORMS: 'Forms',
			TAB_REPORTS: 'Reports',
			FORMS_TEXT_PLACEHOLDER: 'Enter your answer...',
			FORMS_TEXT_VALIDATION: 'Answer is too short',
			FORMS_NUMERIC_VALIDATION: 'Valid range',
			FORMS_FIELD_REQUIRED: 'This field is required',
			FORMS_MIN_CHECKBOX_VALIDATION: 'This field is required. Check at least',
			FORMS_BUTTON_SAVE: 'Save',
			FORMS_BUTTON_RESET: 'Reset',
			GENERIC: 'Other',
		},
	},
};
