import { AuthContext, AuthUpdateContext } from '../../contexts/AuthContext';
import { setAuthToken, setRefreshToken } from '../../services/SessionStorageService';
import { signUpWithPassword, signUpWithPasswordAndInvitation } from '../../services/AuthService';
import { Auth } from '../../interfaces/Auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useRoutes } from '../../helpers/useRoutes';
import { useTranslation } from 'react-i18next';

interface SignUpStore {
	handleSignUpClick: (email: string | undefined, password: string | undefined) => void;
	isAuth: boolean;
	givenName: string | undefined;
	givenNameError: boolean;
	givenNameErrorMessage: string | undefined;
	familyName: string | undefined;
	familyNameError: boolean;
	familyNameErrorMessage: string | undefined;
	signUpError: string | undefined;
	validateGivenName: (name: string) => void;
	validateFamilyName: (name: string) => void;
	extraFieldsRequired: boolean;
}

export const useSignUp = (): SignUpStore => {
	const { t } = useTranslation();
	const history = useHistory();
	const query = useQueryParams();
	const { routes: ROUTES } = useRoutes();

	const [signUpError, setSignUpError] = React.useState<string | undefined>(undefined);
	const [givenName, setGivenName] = React.useState<string | undefined>(undefined);
	const [familyName, setFamilyName] = React.useState<string | undefined>(undefined);
	const [givenNameError, setGivenNameError] = React.useState<boolean>(false);
	const [givenNameErrorMessage, setGivenNameErrorMessage] = React.useState<string | undefined>(undefined);
	const [familyNameError, setFamilyNameError] = React.useState<boolean>(false);
	const [familyNameErrorMessage, setFamilyNameErrorMessage] = React.useState<string | undefined>(undefined);
	const [extraFieldsRequired, setExtraFieldsRequired] = React.useState<boolean>(true);
	const { setIsAuth } = React.useContext(AuthUpdateContext);
	const { isAuth } = React.useContext(AuthContext);

	const handleUserError = (error: string): string => {
		switch (error) {
			case 'User not found.':
				return t('view:SIGNIN_USER_NOT_FOUND');
			case 'The user exists already.':
				return t('view:SIGNIN_USER_ALREADY_EXISTS');
			case 'API url not defined':
				return 'API NOT DEFINED / API NO DEFINIDA';
			default:
				return error || 'UNKNOWN ERROR / ERROR DESCONOCIDO';
		}
	};

	const handleSignUpClick = async (email: string | undefined, password: string | undefined): Promise<void> => {
		const isInvitation = typeof query.code === 'string';

		if (email && password) {
			try {
				let signUpResult: Auth | null = null;

				if (isInvitation) {
					signUpResult = await signUpWithPasswordAndInvitation({
						username: email,
						password,
					}, query.code as string);
				} else if (givenName && familyName) {
					signUpResult = await signUpWithPassword(givenName, familyName, {
						username: email,
						password,
					});
				}

				if (signUpResult) {
					setAuthToken(signUpResult.access_token);
					setRefreshToken(signUpResult.refresh_token);
					setIsAuth(true);
				}
			} catch (error) {
				setSignUpError(handleUserError(error.message));
			}
		}
	};

	const validateGivenName = (name: string): void => {
		setGivenName(name);

		const error = name.length < 1;

		setGivenNameError(error);
		setGivenNameErrorMessage(error ? 'El nombre no es válido' : undefined);
	};

	const validateFamilyName = (name: string): void => {
		setFamilyName(name);

		const error = name.length < 1;

		setFamilyNameError(error);
		setFamilyNameErrorMessage(error ? 'El apellido no es válido' : undefined);
	};

	React.useEffect(() => {
		if (query.code && typeof query.code === 'string') {
			setExtraFieldsRequired(false);
		}
	}, [history, query, ROUTES]);

	return {
		handleSignUpClick,
		isAuth,
		givenName,
		givenNameError,
		givenNameErrorMessage,
		familyName,
		familyNameError,
		familyNameErrorMessage,
		signUpError,
		validateGivenName,
		validateFamilyName,
		extraFieldsRequired,
	};

};
