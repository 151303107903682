import { Calendar, globalizeLocalizer, Views } from 'react-big-calendar';
import { CalendarEvent, SessionEvent } from '../../interfaces/Session';
import CallIcon from '@material-ui/icons/Call';
import globalize from 'globalize';
import i18n from 'i18next';
import React from 'react';
import { SiteDateCard } from '../../interfaces/DateCard';
import styles from './Calendar.module.scss';
import useCalendar from './useCalendar';
import { useTranslation } from 'react-i18next';
require('globalize/lib/cultures/globalize.culture.es');

interface SessionsCalendarProps {
	selectedDate: Date | null;
	sessions: SessionEvent[];
	onClickSessionCard?: (sessionInfo: SessionEvent) => void;
	onChangeSelectedDate: (date: Date) => void;
}

const localizer = globalizeLocalizer(globalize);
const minTime = new Date();
const maxTime = new Date();

minTime.setHours(6);
minTime.setMinutes(0);

maxTime.setHours(22);
maxTime.setMinutes(0);
const consultingRoomBodyColor = '#F8BA03';
const telehealthBodyColor = '#4EDDFF';
const pendingSessionBodyColor = '#FFFFFF';
const consultingRoomHeaderColor = '#AF8F2E';
const telehealthHeaderColor = '3CA8DC';

const SessionsCalendar: React.FC<SessionsCalendarProps> = (props) => {
	const { t } = useTranslation();
	const { selectedDate, sessions, onClickSessionCard, onChangeSelectedDate } = props;
	const {
		events,
		onSelectEvent,
	} = useCalendar({ sessions, onClickSessionCard });

	const EventCalendar = ({ event }: {event: CalendarEvent}): JSX.Element => {
		return (
			<div
				className={styles.eventCalendar}
				style={{backgroundColor: event.confirmed ? event.site === SiteDateCard.ConsultingRoom ? consultingRoomBodyColor : telehealthBodyColor : pendingSessionBodyColor}}>
				<span style={{ fontSize: 16, marginBottom: 3, whiteSpace: 'nowrap' }}>{event.title}</span>
				{event.site === SiteDateCard.ConsultingRoom
					? <span style={{ fontSize: 14 }}>{event.therapistOffice.address}</span>
					: <div className={styles.phoneIconContainer}>
						<CallIcon className={styles.phoneIcon} />
					</div>
				}
			</div>
		);
	};

	return (
		<Calendar
			date={selectedDate || new Date()}
			localizer={localizer}
			events={events}
			defaultView={Views.WEEK}
			views={{ week: true }}
			defaultDate={new Date()}
			onSelectEvent={(event: CalendarEvent): void => onSelectEvent(event.id)}
			dayLayoutAlgorithm={'no-overlap'}
			culture={i18n.language}
			onNavigate={onChangeSelectedDate}
			min={minTime}
			max={maxTime}
			step={15}
			components={{
				event: EventCalendar,
			}}
			messages={{
				previous: t('patientsView:PREVIOUS'),
				next: t('patientsView:REPORTS_NEXT_BUTTON'),
				today: t('agendaView:TODAY_BUTTON'),
			}}
			tooltipAccessor={(event: CalendarEvent): string => event.title + (event.site === SiteDateCard.ConsultingRoom ? ' - ' + event.therapistOffice.address : '')}
			eventPropGetter={(item: CalendarEvent): any => ({
				style: {
					padding: 0,
					background: item.site === SiteDateCard.ConsultingRoom ? consultingRoomHeaderColor : telehealthHeaderColor,
					border: 'none',
					color: '#000000',
					fontWeight: 550,
					boxShadow: '3px 1px 6px -1px rgba(0,0,0,0.75)',
				},
			})}
		/>
	);
};

export default SessionsCalendar;
