import { About, AssistantHome, AssistantProfile, Blog, Faq, Functions, Home, InstitutionHome, InstitutionProfile, Library, Messages, PatientHome, Plans, Post, Profile, SignIn, SignUp, TherapistProfile } from './pages';
import { Redirect, Route, Switch} from 'react-router-dom';
import Agenda from './pages/therapist/Agenda/Agenda';
import AuthRoute from './components/AuthRoute/AuthRoute';
import Chat from './pages/Chat/Chat';
import CreateReport from './pages/therapist/Patients/patient/Reports/CreateReport/CreateReport';
import Documentation from './pages/therapist/Documentation/Documentation';
import Invitations from './pages/patient/Invitations/Invitations';
import Patient from './pages/therapist/Patients/patient/Patient';
import Patients from './pages/therapist/Patients/Patients';
import PrivateLayout from './pages/Layout/PrivateLayout';
import PublicLayout from './pages/Layout/PublicLayout';
import React from 'react';
import ResourceLibrary from './pages/therapist/ResourceLibrary/ResourceLibrary';
import Tasks from './pages/therapist/Tasks/Tasks';
import { useRoutes } from './helpers/useRoutes';
import { UserRole } from './interfaces/User';

const PublicRoutes = (): JSX.Element => {
	const { routes } = useRoutes();

	return (
		<PublicLayout>
			<Switch>
				<Route exact key={`/${routes.HOME}`} path={`/${routes.HOME}`} component={Home} />
				<Route exact key={`/${routes.PLANS}`} path={`/${routes.PLANS}`} component={Plans} />
				<Route exact key={`/${routes.FUNCTIONALITIES}`} path={`/${routes.FUNCTIONALITIES}`} component={Functions} />
				<Route exact key={`/${routes.ABOUT}`} path={`/${routes.ABOUT}`} component={About} />
				<Route exact key={`/${routes.FAQ}`} path={`/${routes.FAQ}`} component={Faq} />
				<Route exact key={`/${routes.BLOG}`} path={`/${routes.BLOG}`} component={Blog} />
				<Route key={`/${routes.POST}`} path={`/${routes.POST}/:id?`} component={Post} />
				<Route exact path={'/'}>
					<Redirect to={`/${routes.HOME}`} />
				</Route>
			</Switch>
		</PublicLayout>
	);
};

const PrivateRoutes = (): JSX.Element => {
	const { assistantRoutes, patientRoutes, therapistRoutes } = useRoutes();

	return (
		<>
			<PrivateLayout>
				<Switch>
					{/* ASSISTANTS */}
					<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/:therapistId/${assistantRoutes.AGENDA}`} Component={Agenda} roleRequired={UserRole.ASSISTANT} />
					<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/:therapistId/${assistantRoutes.TASKS}`} Component={Tasks} roleRequired={UserRole.ASSISTANT} />
					<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/:therapistId/${assistantRoutes.PATIENTS}`} Component={Patients} roleRequired={UserRole.ASSISTANT} />
					<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/:therapistId/${assistantRoutes.PATIENTS}/:id`} Component={Patient} roleRequired={UserRole.ASSISTANT} />
					<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/:therapistId/${assistantRoutes.RESOURCE_LIBRARY}`} Component={ResourceLibrary} roleRequired={UserRole.ASSISTANT} />
					<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/:therapistId/${assistantRoutes.DOCUMENTATION}`} Component={Documentation} roleRequired={UserRole.ASSISTANT} />
					<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${assistantRoutes.PROFILE}`} Component={AssistantProfile} roleRequired={UserRole.ASSISTANT} />
					<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/:therapistId/${assistantRoutes.PATIENTS}/:id/${assistantRoutes.REPORTS}/${assistantRoutes.NEW}`} Component={CreateReport} roleRequired={UserRole.ASSISTANT} />
					<Route exact path={`/${assistantRoutes.ASSISTANT}/`}>
						<Redirect to={`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/:therapistId/${assistantRoutes.AGENDA}`} />
					</Route>

					{/* PATIENTS */}
					<AuthRoute exact path={`/${patientRoutes.PATIENT}/${patientRoutes.CHAT}`} Component={Chat} roleRequired={UserRole.PATIENT} />
					<AuthRoute exact path={`/${patientRoutes.PATIENT}/${patientRoutes.HOME}`} Component={PatientHome} roleRequired={UserRole.PATIENT} />
					<AuthRoute exact path={`/${patientRoutes.PATIENT}/${patientRoutes.INVITATIONS}`} Component={Invitations} roleRequired={UserRole.PATIENT} />
					<AuthRoute exact path={`/${patientRoutes.PATIENT}/${patientRoutes.MESSAGES}`} Component={Messages} roleRequired={UserRole.PATIENT} />
					<AuthRoute exact path={`/${patientRoutes.PATIENT}/${patientRoutes.LIBRARY}`} Component={Library} roleRequired={UserRole.PATIENT} />
					<AuthRoute exact path={`/${patientRoutes.PATIENT}/${patientRoutes.PROFILE}`} Component={Profile} roleRequired={UserRole.PATIENT} />
					<Route exact path={`/${patientRoutes.PATIENT}/`}>
						<Redirect to={`/${patientRoutes.PATIENT}/${patientRoutes.HOME}`} />
					</Route>
					{/* THERAPISTS */}
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.PROFILE}`} Component={TherapistProfile} roleRequired={UserRole.THERAPIST} />
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.AGENDA}`} Component={Agenda} roleRequired={UserRole.THERAPIST} />
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.CHAT}`} Component={Chat} roleRequired={UserRole.THERAPIST} />
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.TASKS}`} Component={Tasks} roleRequired={UserRole.THERAPIST} />
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.PATIENTS}`} Component={Patients} roleRequired={UserRole.THERAPIST} />
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.PATIENTS}/:id`} Component={Patient} roleRequired={UserRole.THERAPIST} />
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.RESOURCE_LIBRARY}`} Component={ResourceLibrary} roleRequired={UserRole.THERAPIST} />
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.DOCUMENTATION}`} Component={Documentation} roleRequired={UserRole.THERAPIST} />
					<AuthRoute exact path={`/${therapistRoutes.THERAPIST}/${therapistRoutes.PATIENTS}/:id/${therapistRoutes.REPORTS}/${therapistRoutes.NEW}`} Component={CreateReport} roleRequired={UserRole.THERAPIST} />
					<Route exact path={`/${therapistRoutes.THERAPIST}/`}>
						<Redirect to={`/${therapistRoutes.THERAPIST}/${therapistRoutes.AGENDA}`} />
					</Route>
				</Switch>
			</PrivateLayout>
		</>
	);
};

const OtherRoutes = (): JSX.Element => {
	const { routes } = useRoutes();

	return (
		<Switch>
			<Route exact key={`/${routes.SIGNIN}`} path={`/${routes.SIGNIN}`} component={SignIn} />
			<Route exact key={`/${routes.SIGNUP}`} path={`/${routes.SIGNUP}`} component={SignUp} />
		</Switch>
	);
};

const Navigation = (): JSX.Element => {
	const { assistantRoutes, institutionRoutes, patientRoutes, therapistRoutes, routes } = useRoutes();

	return (
		<Switch>
			<AuthRoute exact path={`/${institutionRoutes.INSTITUTION}/${institutionRoutes.HOME}`} Component={InstitutionHome} roleRequired={UserRole.INSTITUTION} />
			<AuthRoute exact path={`/${institutionRoutes.INSTITUTION}/${institutionRoutes.PROFILE}`} Component={InstitutionProfile} roleRequired={UserRole.INSTITUTION} />
			<AuthRoute exact path={`/${assistantRoutes.ASSISTANT}/${assistantRoutes.HOME}`} Component={AssistantHome} roleRequired={UserRole.ASSISTANT} />
			<Route path={[`/${assistantRoutes.ASSISTANT}`, `/${patientRoutes.PATIENT}`, `/${therapistRoutes.THERAPIST}`]} component={PrivateRoutes} />
			<Route path={[`/${routes.SIGNIN}`, `/${routes.SIGNUP}`]} component={OtherRoutes} />
			<Route component={PublicRoutes} />
		</Switch>
	);
};

export default Navigation;
