import { Card, ContactForm } from '../../components';
import logo from '../../assets/images/pragma-isotipo.svg';
import React from 'react';
import styles from './ContactSection.module.scss';
import { useRoutes } from '../../helpers/useRoutes';
import { useTranslation } from 'react-i18next';

const ContactSection: React.FC = () => {
	const { t } = useTranslation();
	const { routes } = useRoutes();

	return (
		<div className={styles.container} id={routes.CONTACT}>
			<h2 className={styles.title}>{t('contact:SECTION_TITLE')}</h2>
			<div className={styles.cards}>
				<ContactForm />
				<Card className={styles.linkCard}>
					<img className={styles.logo} src={logo} alt={'pragma-logo'} />
					<h2 className={styles.title}>{t('contact:CARD_TITLE_FIRST_SECTION')}<br /><span className={styles.bold} >{t('contact:CARD_TITLE_SECOND_SECTION')}</span></h2>
					<p className={styles.subtitle}>{t('contact:CARD_DESCRIPTION')}</p>
				</Card>
			</div>
		</div>
	);
};

export default ContactSection;
