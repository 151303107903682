import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SessionEvent, SessionType } from '../../../../interfaces/Session';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Collapse from '@material-ui/core/Collapse';
import { createTheme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import i18n from 'i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NewSessionTypeModal from '../../../../components/Modals/NewSessionTypeModal/NewSessionTypeModal';
import { Patient } from '../../../../interfaces/Patient';
import React from 'react';
import styles from './SideMenu.module.scss';
import { ThemeProvider } from '@material-ui/styles';
import { TitleBox } from '../../../../components';
import UpdatePatientModal from '../../../../components/Modals/UpdatePatientModal/UpdatePatientModal';
import useSideMenu from './useSideMenu';
import { useTranslation } from 'react-i18next';

const datePickerTheme = createTheme({
	palette: {
		background: {
			paper: '#f5f6fa',
		},
		primary: {
			main: '#4ebed7',
			contrastText: '#f5f6fa',
		},
	},
});

type FilterType = 'Patient' | 'SessionType';

interface SideMenuProps {
	selectedDate: Date | null;
	sessions: SessionEvent[];
	patients: Patient[];
	sessionsTypes: SessionType[];
	onChangeSelectedDate: (date: Date | null) => void;
	onClickFilter: (filterType: FilterType, value: string) => void;
	fetchData: () => Promise<void>;
}

const SideAgendaMenu: React.FC<SideMenuProps> = (props) => {
	const [sessionTypeSelected, setSessionTypeSelected] = React.useState<string>('');
	const [patientSelected, setPatientSelected] = React.useState<string>('');
	const { selectedDate, sessions, onChangeSelectedDate, patients, sessionsTypes, fetchData } = props;
	const { t } = useTranslation();
	const {
		isSessionListCollapsed,
		isPatientListCollapsed,
		isOpenNewPatientModal,
		isOpenNewSessionTypeModal,
		handleOpenNewSessionTypeModal,
		handleOpenNewPatientModal,
		handleExpandSessionList,
		handleExpandPatientList,
	} = useSideMenu();

	return (
		<div className={styles.sideAgendaMenu}>
			<TitleBox type="text">
				<div className={styles.boxInfo}>
					<span>{t('agendaView:SESSIONS_THIS_WEEK_BOX')}:</span><span>{sessions.length}</span>
				</div>
			</TitleBox>
			<ThemeProvider theme={datePickerTheme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'en' ? enLocale : esLocale}>
					<DatePicker
						autoOk
						variant="static"
						openTo="date"
						value={selectedDate}
						onChange={onChangeSelectedDate}
						disableToolbar
					/>
				</MuiPickersUtilsProvider>
			</ThemeProvider>
			<ListItem button onClick={handleExpandPatientList} >
				<ListItemText primary={t('patientsView:TITLE').toUpperCase()} classes={{ primary: styles.listHeader }} />
				{isPatientListCollapsed ? <ExpandMore className={styles.icon} /> : <ExpandLess className={styles.icon} />}
			</ListItem>
			<div>
				<Collapse in={isPatientListCollapsed} timeout="auto" unmountOnExit>
					<List component="div" disablePadding className={styles.list}>
						{
							patients.map((sessionPatient: Patient) => (
								<ListItem
									button
									key={sessionPatient.givenName}
									onClick={(): void => setPatientSelected(sessionPatient.givenName)}
									className={patientSelected === sessionPatient.givenName ? styles.selected : ''}
								>
									<ListItemIcon className={styles.icon}>
										<CheckCircleIcon />
									</ListItemIcon>
									<ListItemText primary={`${sessionPatient.givenName} ${sessionPatient.familyName}`} />
								</ListItem>
							))
						}
					</List>
					<List>
						<ListItem button className={styles.listAddItem} onClick={handleOpenNewPatientModal}>
							<ListItemIcon className={styles.icon}>
								<AddIcon />
							</ListItemIcon>
							<ListItemText primary={t('patientsView:MODAL_ADD_PATIENT')} classes={{ primary: styles.itemWeight }} />
						</ListItem>
					</List>
				</Collapse>
			</div>
			<ListItem button onClick={handleExpandSessionList}>
				<ListItemText primary={t('agendaView:TYPES_OF_SESSIONS').toUpperCase()} classes={{ primary: styles.listHeader }} />
				{isSessionListCollapsed ? <ExpandMore className={styles.icon} /> : <ExpandLess className={styles.icon} />}
			</ListItem>
			<div>
				<Collapse in={isSessionListCollapsed} timeout="auto" unmountOnExit>
					<List component="div" disablePadding className={styles.list}>
						{
							sessionsTypes.map((sessionType: SessionType) => (
								<ListItem
									button
									key={sessionType.name}
									onClick={(): void => setSessionTypeSelected(sessionType.name)}
									className={sessionTypeSelected === sessionType.name ? styles.selected : ''}
								>
									<ListItemIcon >
										<CheckCircleIcon style={{ color: sessionType.color }} />
									</ListItemIcon>
									<ListItemText primary={sessionType.name} />
								</ListItem>
							))
						}
					</List>
					<List>
						<ListItem button className={styles.listAddItem} onClick={(): void => handleOpenNewSessionTypeModal()} >
							<ListItemIcon className={styles.icon}>
								<AddIcon />
							</ListItemIcon>
							<ListItemText primary={t('agendaView:ADD_SESSION_TYPE')} classes={{ primary: styles.itemWeight }} />
						</ListItem>
					</List>
				</Collapse>
			</div>
			<NewSessionTypeModal
				open={isOpenNewSessionTypeModal}
				onChange={handleOpenNewSessionTypeModal}
				fetchData={fetchData}
			/>
			<UpdatePatientModal
				open={isOpenNewPatientModal}
				onChange={handleOpenNewPatientModal}
				actionType={'ADD'}
				fetchData={fetchData}
			/>
		</div >
	);
};

export default SideAgendaMenu;
