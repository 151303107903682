import Divider from '@material-ui/core/Divider';
import React from 'react';
import styles from './SectionTitle.module.scss';
import { useTranslation } from 'react-i18next';

interface SectionTitleProps {
	title: string;
	showDivider?: boolean;
	rigthButton?: JSX.Element;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, showDivider = true, rigthButton }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.sectionTitle}>
			<div className={styles.text}>
				<p>{t(title)}</p>
				{rigthButton && rigthButton}
			</div>
			{showDivider && <Divider className={styles.divider}/>}
		</div>
	);
};

export default SectionTitle;
