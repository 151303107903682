import { getProfile, updateAssistant, updateAssistantAvatar } from '../../../services/AssistantService';
import { AlertContext } from '../../../contexts/AlertContext';
import { Assistant } from '../../../interfaces/Assistant';
import { AuthUpdateContext } from '../../../contexts/AuthContext';
import { Form } from '../../../interfaces/FormData';
import React from 'react';

interface ProfileStore {
	isLoading: boolean;
	assistantInfo: Assistant;
	openPersonalInfoModal: boolean;
	handleOpenPersonalInfoModal: () => void;
	handleUpdateAvatar: (e: any) => void;
	loadAssistant: () => Promise<void>;
	updateAssistantInfo: (formData: Form) => Promise<void>;
}

const useProfile = (): ProfileStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [assistantInfo, setAssistantInfo] = React.useState<Assistant>({} as Assistant);
	const [openPersonalInfoModal, setOpenPersonalInfoModal] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { fetchUser } = React.useContext(AuthUpdateContext);

	const loadAssistant = async (): Promise<void> => {
		setIsLoading(true);

		try {
			const assistant = await getProfile();

			setAssistantInfo(assistant);
		} catch (error) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		loadAssistant();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUpdateAvatar = async (e: any): Promise<void> => {
		setIsLoading(true);

		try {
			await updateAssistantAvatar(e?.target?.files);
			await loadAssistant();
			fetchUser();
		} catch (e) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	const handleOpenPersonalInfoModal = (): void => {
		setOpenPersonalInfoModal(prevState => !prevState);
	};

	const updateAssistantInfo = async (formData: Form): Promise<void> => {
		try {
			await updateAssistant(formData);
		} catch (e) {
			showDefaultError();
		}
	};

	return {
		isLoading,
		assistantInfo,
		openPersonalInfoModal,
		handleOpenPersonalInfoModal,
		handleUpdateAvatar,
		loadAssistant,
		updateAssistantInfo,
	};
};

export default useProfile;
