import { Frequency } from '../../../interfaces/Session';
import Modal from '../../Modal/Modal';
import React from 'react';
import SessionPicker from '../../SessionPicker/SessionPicker';
import { TherapistContext } from '../../../contexts/TherapistContext';
import useNewSessionModal from './useNewSessionModal';
import { useTranslation } from 'react-i18next';

interface NewSessionModalProps {
	open: boolean;
	onConfirm: () => Promise<void>;
	onChange: () => void;
}

const NewSessionModal: React.FC<NewSessionModalProps> = ({ open, onConfirm, onChange }) => {
	const { therapistSelected } = React.useContext(TherapistContext);
	const { t } = useTranslation();
	const {
		sessionSelected,
		handleCloseNewSession,
		handleConfirmNewSession,
		setSessionSelected,
	} = useNewSessionModal({ therapistId: therapistSelected.id, onConfirm, onChange });

	return (
		<Modal
			open={open}
			size="md"
			title={t('patientHomeView:MODAL_NEW_SESSION')}
			handleOnClose={handleCloseNewSession}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CONFIRM'),
				onClick: (): void => handleConfirmNewSession(sessionSelected),
				isDisabled: !sessionSelected,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CANCEL'),
				onClick: handleCloseNewSession,
			}}
		>
			<SessionPicker
				sessionSelected={sessionSelected}
				therapistId={therapistSelected.id}
				frequency={Frequency.ONE_TIME}
				onChange={onChange}
				onSessionChange={setSessionSelected}
			/>
		</Modal>
	);
};

export default NewSessionModal;
