import { Grid, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Option } from '../../../interfaces/Common';
import React from 'react';
import styles from './DurationSelector.module.scss';
import TextField from '@material-ui/core/TextField';
import useDurationSelector from './useDurationSelector';

export interface DurationSelectorProps {
	name: string;
	title?: string;
	cancelLabel?: string;
	sideLabel?: string;
	mainLabel: string;
	bigLabel?: boolean;
	multipleValues?: boolean;
	handleInputChange: (event: React.ChangeEvent<{ value: unknown; name: string }>) => void;
	handleCheckChange: (event: React.MouseEvent, value: string | string[], name: string) => void;
	values: Option[];
	fields: string[];
	defaultValuesToDisplay: string[];
}

export const DurationSelector: React.FC<DurationSelectorProps> = ({
	name,
	title,
	cancelLabel,
	sideLabel,
	mainLabel,
	bigLabel = false,
	multipleValues = false,
	handleInputChange,
	handleCheckChange,
	values,
	fields,
	defaultValuesToDisplay}) => {
	const { isPersonalized, disabledButtons, handleAddDuration, setIsPersonalized, setDisabledButtons } = useDurationSelector(multipleValues, handleCheckChange);

	React.useEffect(() => {
		if (!multipleValues) {
			setIsPersonalized(!defaultValuesToDisplay.includes(fields[0]));
		}

		setDisabledButtons(fields);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValuesToDisplay, fields]);

	return (
		<>
			<span className={styles.title}>{title}</span>
			<Grid container spacing={2} className={styles.durationSelector}>
				<Grid item xs={12} sm={6} md={bigLabel ? 5 : 2}>
					<span className={styles.text}>{mainLabel}</span>
				</Grid>
				<Grid item xs={12} sm={6} md={bigLabel ? 3 : 7} className={styles.durationSelector}>
					{
						!isPersonalized ?
							<ButtonGroup size="small" aria-label="small outlined button group" className={styles.buttonGroup}>
								{
									defaultValuesToDisplay.map((duration: string): JSX.Element => (
										<div key={duration} onClick={(event): void => handleAddDuration(event, duration, name)}>
											<Button
												className={styles.buttonTime}
												disabled={disabledButtons.includes(duration)}
												size="small"
											>
												{duration}
											</Button>
										</div>
									))
								}
							</ButtonGroup>
							:
							<TextField
								select
								variant="outlined"
								fullWidth
								onChange={handleInputChange}
								value={fields[0]}
								size="small"
								className={styles.inputSelect}
								name={name}
							>
								{
									values.map(({value, key}: Option) => (
										<MenuItem key={key} value={value} className={styles.inputSelect}>
											{value}
										</MenuItem>
									))
								}
							</TextField>
					}
				</Grid>
				<Grid item xs={12} sm={6} md={2}>
					<span className={styles.sideText} onClick={(): void => setIsPersonalized(!isPersonalized)}>{!isPersonalized ? sideLabel : cancelLabel}</span>
				</Grid>
			</Grid>
		</>
	);
};
