import { clearSessionStorage, getAuthToken, getRefreshToken, setAuthToken, setRefreshToken } from './SessionStorageService';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

axios.interceptors.request.use(async config => {
	const authToken = getAuthToken();

	if (authToken) {
		config.headers.Authorization = 'Bearer ' + authToken;
	}

	return config;
}, error => {
	return Promise.reject(error);
});

export const refreshToken = async (): Promise<any> => {
	const refreshToken = getRefreshToken();

	if (refreshToken) {
		if (apiUrl) {
			axios.create().post(
				`${apiUrl}/auth/signin`,
				{
					'grant_type': 'refresh_token',
					'client_id': clientId,
					'client_secret': clientSecret,
					'refresh_token': refreshToken,
				}
			).then(async result => {
				setAuthToken(result.data.access_token);
				setRefreshToken(result.data.refresh_token);

				return result.data;

			}).catch(() => {
				clearSessionStorage();
				window.location.href = apiUrl;
			});
		}

		return Promise.reject('API url not defined');
	}

	return Promise.reject('refresh token not defined');
};

axios.interceptors.response.use(async response => {
	return response.data;
}, async (error) => {
	const originalRequest = error.config;

	if (error.response && error.response.status === 401 && !originalRequest._retry) {
		originalRequest._retry = true;

		if (apiUrl) {
			refreshToken().then(async result => {
				axios.defaults.headers.Authorization = 'Bearer ' + result.access_token;
				originalRequest.headers['Authorization'] = 'Bearer ' + result.access_token;

				return axios(originalRequest);
			}).catch(() => {
				clearSessionStorage();
				window.location.href = apiUrl;
			});
		}
	}

	if (error.response) {
		throw ({
			name: error.response.data.name,
			message: error.response.data.message,
		});
	}

	return Promise.reject(error);
});
