import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '../../Button/Button';
import { InputBase } from '@material-ui/core';
import React from 'react';
import styles from './ChatForm.module.scss';
import { useTranslation } from 'react-i18next';

interface ChatFormProps {
	handleSubmit: (message: string) => void;
}

export const ChatForm: React.FC<ChatFormProps> = ({handleSubmit}) => {
	const [message, setMessage] = React.useState('');
	const { t } = useTranslation();

	const handleFormSubmit = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === 'Enter') {
			handleSubmit(message);
			setMessage('');
		}
	};

	const handleButtonClick = (): void => {
		handleSubmit(message);
		setMessage('');
	};

	return (
		<>
			<InputBase
				className={styles.inputMessage}
				onChange={(event): void => setMessage(event.target.value)}
				placeholder={t('chatView:PLACEHOLDER_WRITE_MESSAGE')}
				value={message}
				onKeyDown={handleFormSubmit}
				inputProps={{ maxLength: 4000 }}
			/>
			<Button
				onClick={handleButtonClick}
				title={t('chatView:SEND')}
				startIcon={<ArrowForwardIcon/>}
			/>
		</>
	);
};
