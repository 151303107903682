import React from 'react';
import styles from './NotificationBadge.module.scss';

interface NotificationBadgeProps {
	color?: 'red' | 'green';
	count?: number;
	size?: 'small' | 'medium';
}

const NotificationBadge: React.FC<NotificationBadgeProps> = ({ color, count, size }) => {
	return <div className={`${styles.badge} ${color === 'green' ? styles.colorGreen : styles.colorRed} ${size === 'small' ? styles.small : styles.medium}`}>{count}</div>;
};

export default NotificationBadge;
