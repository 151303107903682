import { Document, Page } from 'react-pdf/dist/entry.webpack';
import Modal from '../../Modal/Modal';
import React from 'react';
import styles from './PDFPreviewModal.module.scss';
import usePDFPreviewModal from './usePDFPreviewModal';

interface PDFPreviewModalProps {
	file: string | Blob;
	open: boolean;
	onClose: () => void;
}

const PDFPreviewModal: React.FC<PDFPreviewModalProps> = ({
	file,
	open,
	onClose,
}) => {

	const { options } = usePDFPreviewModal({file});

	return (
		<div className={styles.pdfPreview}>
			<Modal
				open={open}
				handleOnClose={onClose}
				isPrimaryButtonVisible={false}
				isSecondaryButtonVisible={false}
				size="md"
				showDividers={false}
				scroll="body"
			>
				<div className={styles.modalContainer}>
					<Document
						file={file}
						onLoadSuccess={(): void => {/* */}}
						options={options}
					>
						<Page pageNumber={1} width={800} />
					</Document>
				</div>
			</Modal>
		</div>
	);
};

export default PDFPreviewModal;
