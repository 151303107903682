import { Avatar, CardHeader, IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import styles from './TherapistProfileCard.module.scss';
import { useTranslation } from 'react-i18next';

export interface Options {
	icon?: JSX.Element;
	secondaryColor?: boolean;
	title: string;
	onClick: (therapistId: number) => void;
}

interface TherapistProfileCardProps {
	fullName: string;
	image?: string;
	isLoading?: boolean;
	therapistId: number;
	options?: Options[];
	onClick?: () => void;
}

const TherapistProfileCard: React.FC<TherapistProfileCardProps> = ({fullName, image, isLoading = false, onClick, therapistId, options}) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleMoreVertIconClick = (event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMoreVert = (): void => {
		setAnchorEl(null);
	};

	return (
		<div className={styles.profilePicture}>
			<Card className={styles.cardImage}>
				{isLoading &&
					<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
						<CircularProgress />
					</div>
				}
				{!isLoading &&
				<>
					<CardHeader
						action={
							options && options?.length > 0 &&
							<IconButton aria-label="settings" onClick={handleMoreVertIconClick}>
								<MoreVertIcon />
							</IconButton>
						}
					/>
					<div className={styles.actionAreaContent}>
						<CardActionArea
							onClick={onClick && onClick}
							className={styles.cardActionArea}
						>
							<Avatar className={styles.cardMedia} alt="profile-img" src={image} />
						</CardActionArea>
						<p className={styles.text}>{fullName}</p>
					</div>
				</>
				}
			</Card>
			{
				options && options?.length > 0 &&
				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleCloseMoreVert}
				>
					{options.map(o => (
						<MenuItem
							key={o.title}
							onClick={(): void => o.onClick(therapistId)}
							classes={{
								root: o.secondaryColor ? styles.secondary : '',
							}}
						>
							<ListItemIcon
								classes={{
									root: o.secondaryColor ? styles.secondary : '',
								}}
							>
								{o?.icon && o.icon}
							</ListItemIcon>
							{t(o.title)}
						</MenuItem>
					))}
				</Menu>
			}
		</div>
	);
};

export default TherapistProfileCard;
