import { Button as MaterialButton } from '@material-ui/core';
import React from 'react';
import style from './Button.module.scss';

export interface ButtonProps {
	title: string;
	onClick: (event: React.SyntheticEvent) => void;
	type?: 'PRIMARY' | 'SECONDARY' | 'TERTIARY' | 'LIGHT';
	startIcon?: JSX.Element;
	endIcon?: JSX.Element;
	size?: 'small' | 'medium' | 'large';
	fullWidth?: boolean;
	isDisabled?: boolean;
	classes?: string;
	variant?: 'text' | 'outlined' | 'contained';
	show?: boolean;
}

const Button: React.FC<ButtonProps> = ({ title, onClick, type, startIcon, endIcon, size, fullWidth, isDisabled, classes, variant }) => {
	const classtype = `${style.button} ${classes} ${type ? style[type.toLowerCase()] : style.primary}`;

	return (
		<div className={style.container}>
			<MaterialButton
				classes={{ root: classtype, disabled: style.disabled }}
				variant={variant ?? 'contained'}
				size={size}
				color="primary"
				onClick={onClick}
				startIcon={startIcon}
				endIcon={endIcon}
				fullWidth={fullWidth}
				disabled={isDisabled}
			>
				{title}
			</MaterialButton>
		</div>
	);
};

export default Button;
