import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import ChatIcon from '@material-ui/icons/Chat';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { LocationState } from '../../pages/patient/Library/useLibrary';
import React from 'react';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import { SvgIconComponent } from '@material-ui/icons';
import { useRoutes } from '../../helpers/useRoutes';
import { UserRole } from '../../interfaces/User';
import { useSessionStorage } from '../../hooks/useSessionStorage';

interface SideBarStore {
	tabs: Array<Tabs>;
	selectedTab: string;
	handleChange: (event: unknown, newValue: string) => void;
}

interface Tabs {
	id: string;
	path: string;
	icon: SvgIconComponent;
	label: string;
}

const useSiderBar = (): SideBarStore => {
	const history = useHistory();
	const location = useLocation<LocationState>();
	const { assistantRoutes, patientRoutes, therapistRoutes } = useRoutes();
	const { user } = React.useContext(AuthContext);
	const [selectedTab, setSelectedTab] = React.useState<string>('');
	const [tabs, setTabs] = React.useState<Array<Tabs>>([]);
	const { getItem } = useSessionStorage('selected_therapist_id_by_assistant');
	const therapistSelectedId = getItem();

	const assistantTabs: Array<Tabs> = [
		{
			id: assistantRoutes.AGENDA,
			path: `/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistSelectedId}/${assistantRoutes.AGENDA}`,
			icon: EventOutlinedIcon,
			label: 'agendaView:TITLE',
		},
		{
			id: assistantRoutes.TASKS,
			path: `/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistSelectedId}/${assistantRoutes.TASKS}`,
			icon: ListAltOutlinedIcon,
			label: 'tasksView:TITLE',
		},
		{
			id: assistantRoutes.PATIENTS,
			path: `/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistSelectedId}/${assistantRoutes.PATIENTS}`,
			icon: SupervisorAccountOutlinedIcon,
			label: 'patientsView:TITLE',
		},
		{
			id: assistantRoutes.RESOURCE_LIBRARY,
			path: `/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistSelectedId}/${assistantRoutes.RESOURCE_LIBRARY}`,
			icon: FolderOutlinedIcon,
			label: 'resourceLibraryView:TITLE',
		},
		{
			id: assistantRoutes.DOCUMENTATION,
			path: `/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistSelectedId}/${assistantRoutes.DOCUMENTATION}`,
			icon: InsertDriveFileOutlinedIcon,
			label: 'documentationView:TITLE',
		},
	];

	const patientTabs: Array<Tabs> = [
		{
			id: patientRoutes.HOME,
			path: `/${patientRoutes.PATIENT}/${patientRoutes.HOME}`,
			icon: HomeOutlinedIcon,
			label: 'patientHomeView:TITLE_HOME',
		},
		{
			id: patientRoutes.INVITATIONS,
			path: `/${patientRoutes.PATIENT}/${patientRoutes.INVITATIONS}`,
			icon: HomeOutlinedIcon,
			label: 'patientInvitationsView:TITLE_INVITATIONS',
		},
		// {
		// 	id: patientRoutes.MESSAGES,
		// 	path: `/${patientRoutes.PATIENT}/${patientRoutes.MESSAGES}`,
		// 	icon: ForumOutlinedIcon,
		// 	label: 'messagesView:TITLE_MESSAGES',
		// },
		{
			id: patientRoutes.LIBRARY,
			path: `/${patientRoutes.PATIENT}/${patientRoutes.LIBRARY}`,
			icon: FolderOutlinedIcon,
			label: 'libraryView:TITLE_LIBRARY',
		},
		{
			id: patientRoutes.CHAT,
			path: `/${patientRoutes.PATIENT}/${patientRoutes.CHAT}`,
			icon: ChatIcon,
			label: 'chatView:TITLE',
		},
	];

	const therapistTabs: Array<Tabs> = [
		{
			id: therapistRoutes.AGENDA,
			path: `/${therapistRoutes.THERAPIST}/${therapistRoutes.AGENDA}`,
			icon: EventOutlinedIcon,
			label: 'agendaView:TITLE',
		},
		{
			id: therapistRoutes.TASKS,
			path: `/${therapistRoutes.THERAPIST}/${therapistRoutes.TASKS}`,
			icon: ListAltOutlinedIcon,
			label: 'tasksView:TITLE',
		},
		{
			id: therapistRoutes.PATIENTS,
			path: `/${therapistRoutes.THERAPIST}/${therapistRoutes.PATIENTS}`,
			icon: SupervisorAccountOutlinedIcon,
			label: 'patientsView:TITLE',
		},
		{
			id: therapistRoutes.RESOURCE_LIBRARY,
			path: `/${therapistRoutes.THERAPIST}/${therapistRoutes.RESOURCE_LIBRARY}`,
			icon: FolderOutlinedIcon,
			label: 'resourceLibraryView:TITLE',
		},
		{
			id: therapistRoutes.DOCUMENTATION,
			path: `/${therapistRoutes.THERAPIST}/${therapistRoutes.DOCUMENTATION}`,
			icon: InsertDriveFileOutlinedIcon,
			label: 'documentationView:TITLE',
		},
		{
			id: therapistRoutes.CHAT,
			path: `/${therapistRoutes.THERAPIST}/${therapistRoutes.CHAT}`,
			icon: ChatIcon,
			label: 'chatView:TITLE',
		},
	];

	React.useEffect(() => {
		switch (user?.roles[0]) {
			case UserRole.PATIENT:
				setTabs(patientTabs);
				setSelectedTab(`/${patientRoutes.PATIENT}/${patientRoutes.HOME}`);
				break;
			case UserRole.THERAPIST:
				setTabs(therapistTabs);
				setSelectedTab(`/${therapistRoutes.THERAPIST}/${therapistRoutes.AGENDA}`);
				break;
			case UserRole.ASSISTANT:
				setTabs(assistantTabs);
				setSelectedTab(`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistSelectedId}/${assistantRoutes.AGENDA}`);
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const removeParamsFromRoute = React.useCallback((route: string): string => {
		const splitRoute = route.split('/');

		if (splitRoute?.length > 2) {
			const tabPath = user?.roles[0] === UserRole.ASSISTANT ? `/${splitRoute[1]}/${splitRoute[2]}/${splitRoute[3]}/${splitRoute[4]}` : `/${splitRoute[1]}/${splitRoute[2]}`;

			return therapistTabs.concat(patientTabs).concat(assistantTabs).some(t => t.path === tabPath) ? tabPath : selectedTab;
		}

		return route;
	}, [assistantTabs, patientTabs, selectedTab, therapistTabs, user]);

	React.useEffect(() => {
		// location.state &&
		// location.state.cardType &&
		setSelectedTab(removeParamsFromRoute(location.pathname));
	}, [location.pathname, removeParamsFromRoute]);

	const handleChange = (event: unknown, newValue: string): void => {
		setSelectedTab(newValue);
		history.push(newValue);
	};

	return {
		tabs,
		selectedTab,
		handleChange,
	};
};

export default useSiderBar;
