import InputText from '../../Inputs/InputText/InputText';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Modal from '../../Modal/Modal';
import { PersonalInfo } from '../../../interfaces/User';
import React from 'react';
import styles from './UpdatePatientModal.module.scss';
import { useTranslation } from 'react-i18next';
import useUpdatePatientModal from './useUpdatePatientModal';

interface UpdatePatientModalProps {
	data?: PersonalInfo;
	open: boolean;
	onChange: (refreshData: boolean) => void;
	fetchData?: () => Promise<void>;
	actionType?: 'ADD' | 'UPDATE';
}

const UpdatePatientModal: React.FC<UpdatePatientModalProps> = ({ data, open, actionType = 'UPDATE', fetchData, onChange }) => {
	const { t } = useTranslation();
	const {
		formData,
		formIsValid,
		errorMessageBuilder,
		handleClose,
		handleConfirm,
		handleChangeTextField,
	} = useUpdatePatientModal({ data, actionType, fetchData, onChange });

	return (
		<Modal
			open={open}
			title={t('patientsView:MODAL_ADD_PATIENT')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientsView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: !formIsValid,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientsView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
		>
			<div className={styles.content}>
				{
					formData && Object.keys(formData).length &&
					<>
						<InputText
							value={formData['name'].value}
							name="name"
							label={t('patientsView:MODAL_NAME_FIELD')}
							fullWidth
							onChange={handleChangeTextField}
							error={formData['name'].error}
							helperText={errorMessageBuilder(formData['name'].errorMessages)}
						/>
						<InputText
							value={formData['lastName'].value}
							name="lastName"
							label={t('patientsView:MODAL_LASTNAME_FIELD')}
							fullWidth
							onChange={handleChangeTextField}
							error={formData['lastName'].error}
							helperText={errorMessageBuilder(formData['lastName'].errorMessages)}
						/>
						<InputText
							value={formData['email'].value}
							name="email" label={t('patientsView:MODAL_EMAIL_FIELD')}
							fullWidth
							onChange={handleChangeTextField}
							error={formData['email'].error}
							helperText={errorMessageBuilder(formData['email'].errorMessages)}
						/>
						<div className={styles.footer}>
							<MailOutlineIcon color="primary" className={styles.footerIcon} />
							<span className={styles.footerText}>
								{t('patientsView:MODAL_FOOTER')}
							</span>
						</div>
					</>
				}
			</div>
		</Modal>
	);
};

export default UpdatePatientModal;
