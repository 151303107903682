import { Office, RequestSession, Session, SessionEventAPI, SessionType } from './../interfaces/Session';
import { AvailableTime } from '../interfaces/SessionAvailability';
import axios from 'axios';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;

export const findAllSessionsTypesByTherapist = (therapistId: number | string): Promise<SessionType[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/session-types`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});

};

export const findAllPlacesByTherapist = (therapistId: number | string): Promise<Office[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/offices`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findSessionEventsByTherapist = (therapistId: number | string, startDate: Date | null, endDate: Date | null): Promise<Array<SessionEventAPI>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/sessions/events`, {
			params: {
				startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
				endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
			},
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const addNewSessionType = (therapistId: number | string, sessionType: string): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/session-types`, { name: sessionType });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const addNewPlace = (therapistId: number | string, office: string): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/offices`, { address: office });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const addNewSession = (therapistId: number | string, session: Session): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/sessions`, session);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateSessionData = (therapistId: number | string, session: Session): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/therapists/${therapistId}/sessions/${session.id}`, session);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const removeSession = (therapistId: number | string, sessionId: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/therapists/${therapistId}/sessions/${sessionId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findSessionEventsByPatient = (therapistId: number | string, startDate: Date | null, endDate: Date | null): Promise<Array<SessionEventAPI>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me/sessions/events?therapistId=${therapistId}`, {
			params: {
				startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
				endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
			},
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findAvailableSessionByDate = (therapistId: number | string, startDate: string): Promise<AvailableTime[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/available-sessions`, {
			params: {
				date: moment(startDate).format('YYYY-MM-DD'),
			},
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findMyAvailableSessionByDate = (therapistId: number | string, startDate: string): Promise<AvailableTime[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/sessions-available`, {
			params: {
				date: moment(startDate).format('YYYY-MM-DD'),
			},
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const requestNewSession = (therapistId: number | string, requestSession: RequestSession): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/patients/me/sessions?therapistId=${therapistId}`, requestSession);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateStatus = (therapistId: number | string, sessionId: number, status: number): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/therapists/${therapistId}/sessions/${sessionId}/status`, { status });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findSessionById = (therapistId: number | string, sessionId: number): Promise<Session> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/sessions/${sessionId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
