import {
	answerDocumentByTherapist, getLinkedPatientDocuments,
	shareDocumentationToPatient,
	unlinkDocumentationFromPatient
} from '../../../../../services/TherapistService';
import {
	DocumentCard,
	DocumentShared
} from '../../../../../interfaces/Document';
import { AlertContext } from '../../../../../contexts/AlertContext';
import Avatar from '@material-ui/core/Avatar';
import { ColDef } from '../../../../../components/CardsTable/CardsTable';
import { createAnswerJson } from '../../../../../services/DocumentService';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';
import styles from './Documentation.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from 'react-router-dom';
import { UserRole } from '../../../../../interfaces/User';
import { useTherapistSelected } from '../../../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface DocumentationStore {
	openUnlinkDocumentModal: boolean;
	openShareDocumentModal: boolean;
	openFormPreviewModal: boolean;
	documents: DocumentCard[];
	selectedPatientDocumentation: DocumentShared | undefined;
	isLoading: boolean;
	columns: ColDef[];
	handleCloseUnlinkDocumentModal: () => void;
	handleConfirmUnlinkDocument: () => void;
	handleConfirmShareDocument: () => void;
	handleConfirmFormPreview: () => Promise<void>;
	handleCloseShareModal: () => void;
	handleCloseFormPreview: () => void;
}

export interface DocumentationProps {
	searchValue: string;
	selectedTab: number;
	isPatientEnabled: boolean;
}

const useDocumentation = (props: DocumentationProps): DocumentationStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [documents, setDocuments] = React.useState<Array<DocumentShared>>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [columns, setColumns] = React.useState<Array<ColDef>>([]);
	const [refreshData, setRefreshData] = React.useState<boolean>(false);
	const [selectedPatientDocumentationId, setSelectedPatientDocumentationId] =
		React.useState<number | undefined>();
	const [selectedPatientDocumentation, setSelectedPatientDocument] = React.useState<
		DocumentShared | undefined
	>();
	const [openUnlinkDocumentModal, setOpenUnlinkDocumentModal] =
		React.useState<boolean>(false);
	const [openShareDocumentModal, setOpenShareDocumentModal] =
		React.useState<boolean>(false);
	const [openFormPreviewModal, setOpenFormPreviewModal] =
		React.useState<boolean>(false);
	const { t } = useTranslation();
	const { getTherapistIdParam } = useTherapistSelected();
	const { id: patientIdString } = useParams<{ id: string }>();

	const handleClickShareButton = (id: number): void => {
		setSelectedPatientDocumentationId(id);

		setOpenShareDocumentModal((prevState) => !prevState);
	};

	const handleClickUnlinkButton = (id: number): void => {
		setSelectedPatientDocumentationId(id);
		setOpenUnlinkDocumentModal((prevState) => !prevState);
	};

	const handleClickFormPreviewButton = (id: number): void => {
		setSelectedPatientDocumentationId(id);
		setOpenFormPreviewModal((prevState) => !prevState);
	};

	const columnsDef: ColDef[] = [
		{ field: 'iconFile', headerName: '', sortable: false, flex: 1 },
		{
			field: 'name',
			headerName: 'patientsView:DOCUMENTATION_LINKED_DOCUMENTATION',
			sortable: false,
			flex: 6,
		},
		{
			field: 'createdAt',
			headerName: 'patientsView:DOCUMENTATION_DATE',
			flex: 4,
		},
		{
			field: 'sharedAvatar',
			headerName: 'patientsView:SHARED',
			sortable: false,
			flex: 4,
		},
		{
			field: 'status',
			headerName: 'patientsView:DOCUMENTATION_STATUS',
			flex: 4,
		},
		{
			field: 'avatarCompletedBy',
			headerName: 'patientsView:DOCUMENTATION_COMPLETED_BY',
			flex: 4,
		},
		{
			field: 'actions',
			headerName: 'patientsView:DOCUMENTATION_ACTIONS',
			sortable: false,
			flex: 4,
			map: false,
			buttons: [
				{
					key: 'delete',
					icon: (
						<Tooltip
							title={t('patientsView:DELETE_TOOLTIP') as string}
							aria-label={t('patientsView:DELETE_TOOLTIP')}
						>
							<DeleteOutlineIcon />
						</Tooltip>
					),
					onClick: handleClickUnlinkButton,
					style: styles.iconActions,
					hide: (): boolean => {
						return props.isPatientEnabled;
					},
				},
				{
					key: 'fileCopy',
					icon: <FileCopyOutlinedIcon />,
					style: styles.iconActions,
					onClick: handleClickFormPreviewButton,
					hide: (item): boolean => {
						const documenSharedtItem = item as unknown as DocumentShared;

						return !documenSharedtItem.userRole;
					},
				},
				{
					key: 'forward',
					icon: (
						<Tooltip
							title={t('patientsView:SHARE_TOOLTIP') as string}
							aria-label={t('patientsView:SHARE_TOOLTIP')}
						>
							<ForwardOutlinedIcon />
						</Tooltip>
					),
					onClick: handleClickShareButton,
					style: styles.iconActions,
					hide: (item): boolean => {
						const castedItem = item as unknown as DocumentShared;

						return !castedItem.shared && props.isPatientEnabled;
					},
				},
			],
		},
	];

	const preProcessing = (data: DocumentShared[]): void => {
		const processedData = data
			.filter((document) =>
				document.name
					.toUpperCase()
					.includes(props.searchValue.toUpperCase())
			)
			.map((doc): DocumentShared => {
				const iconFile = (
					<InsertDriveFileOutlinedIcon
						fontSize="small"
						className={styles.iconFile}
					/>
				);

				let completedByAvatar = undefined;
				let sharedAvatar = (
					<Avatar className={styles.sharedAvatar}>
						{t('patientsView:NO_AVATAR_LABEL')}
					</Avatar>
				);

				if (doc.userRole) {
					const classes = `${styles.avatarContent} ${
						doc.userRole === UserRole.THERAPIST
							? styles.avatarContentTherapist
							: styles.avatarContentPatient
					}`;

					completedByAvatar = (
						<div className={styles.avatar}>
							<Avatar className={classes}>
								{doc.userRole === UserRole.THERAPIST
									? 'T'
									: 'P'}
							</Avatar>
						</div>
					);
				}

				if (doc.shared) {
					sharedAvatar = (
						<Avatar className={styles.sharedAvatar}>
							{t('patientsView:YES_AVATAR_LABEL')}
						</Avatar>
					);
				}

				return {
					...doc,
					avatarCompletedBy: completedByAvatar,
					sharedAvatar: sharedAvatar,
					iconFile,
				};
			});

		setDocuments(processedData);
	};

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);

			const therapistIdParam = getTherapistIdParam();
			const data = await getLinkedPatientDocuments(therapistIdParam, +patientIdString);

			preProcessing(data);
			setColumns(columnsDef);
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	React.useEffect(() => {
		loadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.searchValue, refreshData]);

	React.useEffect(() => {
		if (selectedPatientDocumentationId !== undefined) {
			const patientDocumentSelected = documents.find(
				(patientDocument) => patientDocument.id === selectedPatientDocumentationId
			);

			setSelectedPatientDocument(patientDocumentSelected);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPatientDocumentationId]);

	const handleCloseUnlinkDocumentModal = (): void => {
		setOpenUnlinkDocumentModal((prevState) => !prevState);
	};

	const handleConfirmUnlinkDocument = async (): Promise<void> => {
		if (selectedPatientDocumentation) {
			const therapistIdParam = getTherapistIdParam();

			await unlinkDocumentationFromPatient(therapistIdParam, selectedPatientDocumentation.id);
			setRefreshData((prevState) => !prevState);
		}

		setOpenUnlinkDocumentModal((prevState) => !prevState);
	};

	const handleConfirmShareDocument = async (): Promise<void> => {
		if (selectedPatientDocumentation) {
			const therapistIdParam = getTherapistIdParam();

			await shareDocumentationToPatient(therapistIdParam, selectedPatientDocumentation.id);
			setRefreshData((prevState) => !prevState);
		}

		setOpenShareDocumentModal((prevState) => !prevState);
	};

	const handleCloseShareModal = (): void => {
		setOpenShareDocumentModal((prevState) => !prevState);
	};

	const handleConfirmFormPreview = async (): Promise<void> => {
		if (selectedPatientDocumentation && selectedPatientDocumentation.id) {

			const answers = createAnswerJson({
				id: selectedPatientDocumentation.id,
				title: selectedPatientDocumentation.name,
				description: selectedPatientDocumentation.description,
				sections: selectedPatientDocumentation.sections,
				showErrors: false,
			});
			const therapistIdParam = getTherapistIdParam();

			await answerDocumentByTherapist(
				therapistIdParam,
				selectedPatientDocumentation.id,
				answers
			);
			loadData();
		}
	};

	const handleCloseFormPreview = (): void => {
		setOpenFormPreviewModal((prevState) => !prevState);
	};

	return {
		openUnlinkDocumentModal,
		openShareDocumentModal,
		openFormPreviewModal,
		documents,
		selectedPatientDocumentation,
		isLoading,
		columns,
		handleCloseUnlinkDocumentModal,
		handleConfirmUnlinkDocument,
		handleConfirmShareDocument,
		handleConfirmFormPreview,
		handleCloseFormPreview,
		handleCloseShareModal,
	};
};

export default useDocumentation;
