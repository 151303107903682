import _debounce from 'lodash/debounce';
import { AlertContext } from '../../../contexts/AlertContext';
import { ColDef } from '../../../components/CardsTable/CardsTable';
import { DocumentCard } from '../../../interfaces/Document';
import { DocumentsByTag } from '../../../interfaces/Tag';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import { getTagsAndDocumentsByTherapist } from '../../../services/DocumentService';
import { GridSize } from '@material-ui/core/Grid';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';
import styles from './Documentation.module.scss';
import { Tooltip } from '@material-ui/core';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface DocumentationStore {
	tagsColumns: ColDef[];
	documentsColumns: ColDef[];
	filteredTags: DocumentsByTag[];
	documents: DocumentCard[];
	gridWidth: GridSize;
	selectedTagId: number | undefined;
	selectedDocument: DocumentCard | undefined;
	searchValue: string;
	openListPatientsModal: boolean;
	openFormPreviewModal: boolean;
	handleClickTagCard: (id: number) => void;
	handleClickDocumentCard: (id: number) => void;
	handleChangeSearchValue: (
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	handleClickLink: () => void;
	handleOpenFormValue: () => void;
}

const useDocumentation = (): DocumentationStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [searchValue, setSearchValue] = React.useState<string>('');
	const [tagsColumns, setTagsColumns] = React.useState<Array<ColDef>>([]);
	const [tags, setTags] = React.useState<Array<DocumentsByTag>>([]);
	const [filteredTags, setFilteredTags] = React.useState<
		Array<DocumentsByTag>
	>([]);
	const [selectedTagId, setSelectedTagIdId] = React.useState<
		number | undefined
	>(undefined);
	const [documentsColumns, setDocumentsColumns] = React.useState<
		Array<ColDef>
	>([]);
	const [documents, setDocuments] = React.useState<Array<DocumentCard>>([]);
	const [selectedDocument, setSelectedDocument] = React.useState<
		DocumentCard | undefined
	>(undefined);
	const [gridWidth, setGridWidth] = React.useState<GridSize>(12);
	const [openListPatientsModal, setOpenListPatientsModal] =
		React.useState<boolean>(false);
	const [openFormPreviewModal, setOpenFormPreviewModal] =
		React.useState<boolean>(false);
	const { t } = useTranslation();
	const { getTherapistIdParam } = useTherapistSelected();

	const handleOpenFormValue = (): void => {
		setOpenFormPreviewModal((prevState) => !prevState);
	};

	const tagColumnsDef: ColDef[] = [
		{
			field: 'folder',
			headerName: '',
			sortable: false,
			flex: 1,
			buttons: [
				{
					key: 'favourite',
					icon: <FolderOutlinedIcon fontSize="inherit" />,
					size: 'small',
				},
			],
		},
		{
			field: 'name',
			headerName: '',
			sortable: false,
			flex: 8,
			justify: 'left',
		},
	];

	const documentColumnsDef: ColDef[] = [
		{
			field: 'folder',
			headerName: '',
			sortable: false,
			flex: 1,
			buttons: [
				{
					key: 'folder',
					icon: <InsertDriveFileOutlinedIcon fontSize="inherit" />,
					style: styles.iconFolder,
					size: 'small',
				},
			],
		},
		{
			field: 'name',
			headerName: '',
			sortable: false,
			flex: 8,
			justify: 'left',
		},
		{
			field: 'actions',
			headerName: '',
			sortable: false,
			flex: 2,
			map: false,
			buttons: [
				{
					key: 'preview',
					icon: (
						<Tooltip
							title={t('patientsView:PREVIEW_TOOLTIP') as string}
							aria-label={t('patientsView:PREVIEW_TOOLTIP')}
						>
							<FileCopyOutlinedIcon />
						</Tooltip>
					),
					onClick: handleOpenFormValue,
					style: styles.iconActions,
				},
			],
		},
	];

	const getDocumentsByTag = (id: number | undefined): DocumentCard[] => {
		const tagDocuments = tags.find((tag) => tag.id === id)?.documents;

		return tagDocuments || [];
	};

	const loadData = async (): Promise<void> => {
		try {
			const therapistIdParam = getTherapistIdParam();
			const data = await getTagsAndDocumentsByTherapist(therapistIdParam);

			for (let i = data.length - 1; i >= 0; i--) {
				if (data[i].id === -1) {
					data[i].name = t(`${data[i].name}`);
					break;
				}
			}

			setTagsColumns(tagColumnsDef);
			setDocumentsColumns(documentColumnsDef);
			setTags(data);
			setFilteredTags(data);
		} catch (error) {
			showDefaultError();
		}
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (selectedTagId) {
			setDocuments(getDocumentsByTag(selectedTagId));
			setGridWidth(6);
			setSelectedDocument(undefined);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTagId]);

	const handleClickTagCard = (id: number): void => {
		setSelectedTagIdId(id);
		setGridWidth(6);
	};

	const handleClickDocumentCard = (id: number): void => {
		const document = documents.find((document) => document.id === id);

		setSelectedDocument(document);
	};

	const handleDebounce = (value: string, tags: DocumentsByTag[]): void => {
		if (value) {
			const data = tags.filter((tag) =>
				tag.name.toUpperCase().includes(value.toUpperCase())
			);

			setFilteredTags(data);
		} else {
			setFilteredTags(tags);
		}

		setSelectedTagIdId(undefined);
		setSelectedDocument(undefined);
		setGridWidth(12);
	};

	const debounceHandleChangeSearchValue = React.useCallback(
		_debounce(handleDebounce, 500),
		[]
	);

	const handleChangeSearchValue = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setSearchValue(event.target.value);
		debounceHandleChangeSearchValue(event.target.value, tags);
	};

	const handleClickLink = (): void => {
		setOpenListPatientsModal((prevState) => !prevState);
	};

	return {
		tagsColumns,
		documentsColumns,
		filteredTags,
		documents,
		selectedTagId,
		selectedDocument,
		gridWidth,
		searchValue,
		openListPatientsModal,
		openFormPreviewModal,
		handleClickTagCard,
		handleClickDocumentCard,
		handleChangeSearchValue,
		handleClickLink,
		handleOpenFormValue,
	};
};

export default useDocumentation;
