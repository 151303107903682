import { BlogCard, Checkbox, LoadingSpinner } from '../../components';
import closeMenuLogo from '../../assets/images/menu-close-mob.svg';
import filter from '../../assets/images/filter-icon.svg';
import React from 'react';
import styles from './Blog.module.scss';
import useBlog from './useBlog';
import { useTranslation } from 'react-i18next';

const Blog = (): JSX.Element => {
	const { t } = useTranslation();
	const [ isMenuVisible, setIsMenuVisible ] = React.useState(false);
	const { isLoadingPosts, isLoadingTags, posts, tags, applyFilter } = useBlog();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const getTagsComponents = (): JSX.Element => {
		return (
			isLoadingTags ? (
				<LoadingSpinner />
			) : (
				<div className={styles.checkboxes}>
					{
						tags.map(tag => (
							<div key={tag.id}><Checkbox checked={tag.isChecked} onChange={(): void => applyFilter(tag.id)} label={tag.name} classes={styles.filter}/></div>
						))
					}
				</div>
			)
		);
	};

	return (
		<>
			<div className={styles.blogContainer}>
				<div className={styles.pageTitleContainer}>
					<h1 className={styles.pageTitle}>{t('blogContent:TITLE')}</h1>
					<div className={styles.filterOption} onClick={(): void => setIsMenuVisible(true)}>
						<img src={filter} className={styles.filterIcon} alt="filter" />
						{t('blogContent:FILTER_RESPONSIVE')}
					</div>
				</div>
				<div className={styles.blogContent}>
					<div className={styles.postContainer}>
						{
							isLoadingPosts ? (
								<LoadingSpinner />
							) : (
								!posts.length ? (
									<div className={styles.noPost}>{t('blogContent:NO_POST')}</div>
								) : (
									posts.map(post => (
										<div className={styles.post} key={post.id}>
											<BlogCard
												id={post.id}
												image={post.imageUrl}
												title={post.title}
												summary={post.content}
												tags={post.tags.map(tag => tag.name)}
											/>
										</div>
									))
								)
							)
						}
					</div>
					<div className={styles.filters}>
						<p className={styles.filtersText}>{t('blogContent:FILTER')}</p>
						{
							getTagsComponents()
						}
					</div>
				</div>
			</div>
			<div className={`${styles.menu} ${isMenuVisible && styles.menuDisplay}`}>
				<div className={styles.menuHeader}>
					<span>{t('blogContent:FILTER')}</span>
					<div className={styles.closeMenuLogoContainer} onClick={(): void => setIsMenuVisible(false)}>
						<img src={closeMenuLogo} className={styles.closeMenuLogo} />
					</div>
				</div>
				{
					getTagsComponents()
				}
			</div>
		</>
	);
};

export default Blog;
