import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import React from 'react';
import styles from './PreviewWrapper.module.scss';

export interface QuestionPreviewProps {
	questionIndex: number;
	sectionIndex: number;
}

export interface PreviewWrapperProps {
	title: string;
	description?: string;
	required: boolean;
}

const PreviewWrapper: React.FC<PreviewWrapperProps> = ({ title, description, required, children }) => {
	return (
		<div className={styles.question}>
			<FormControl fullWidth>
				<FormLabel required={required} classes={{ root: styles.questionTitle, asterisk: styles.questionTitleRequired }}>{title}</FormLabel>
				{description && <FormLabel className={styles.questionDescription}>{description}</FormLabel>}
				{children}
			</FormControl>
		</div>
	);
};

export default PreviewWrapper;
