import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import styles from './TitleBox.module.scss';

type TitleBoxType = 'text' | 'selector';

interface TextProps {
	type: TitleBoxType;
}

interface SelectorProps extends TextProps {
	title: string;
	checked: boolean;
	onChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type TitleBoxProps = TextProps | SelectorProps;

const TitleBox: React.FC<TitleBoxProps> = (props) => {
	const { title, checked, onChangeCheckbox } = props as SelectorProps;

	return (
		<Box className={styles.box}>
			{
				props.type === 'text'
					? <div className={styles.text}>{props.children}</div>
					: <div className={styles.selector}>
						<FormControlLabel
							control={
								<Checkbox
									name="selectAll"
									classes={{ root: styles.checkbox, checked: styles.checkboxChecked}}
									color="default"
									checked={checked}
									onChange={onChangeCheckbox}
									size="small"
									checkedIcon={<CheckBoxOutlinedIcon />}
								/>
							}
							classes={{ label: styles.label }}
							label={title}
						/>
					</div>
			}
		</Box>
	);
};

export default TitleBox;
