import { Form } from '../../../interfaces/DocumentForm';
import FormPreview from '../FormPreview';
import Modal from '../../Modal/Modal';
import React from 'react';
import useFormPreview from '../useFormPreview';
import { useTranslation } from 'react-i18next';

interface PreviewModalProps {
	open: boolean;
	form: Form;
	handleClose: () => void;
	handleValidate?: () => Promise<void>;
}

const PreviewModal: React.FC<PreviewModalProps> = (props) => {
	const {
		formModel,
		sectionsToShow,
		isFirstStep,
		isLastStep,
		handleBack,
		handleClosePreviewModal,
		handleNextButton,
	} = useFormPreview({form: props.form, onConfirm: props.handleValidate, onClose: props.handleClose});
	const { t } = useTranslation();

	return (
		<Modal
			title={t('documentationView:FORM_PREVIEW_TITLE')}
			open={props.open}
			handleOnClose={handleClosePreviewModal}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: isLastStep() ? t('documentationView:MODAL_PREVIEW_BUTTON_VALIDATE') : t('documentationView:MODAL_PREVIEW_BUTTON_NEXT'),
				onClick: handleNextButton,
			}}
			isSecondaryButtonVisible={!isFirstStep()}
			secondaryButtonProps={{
				title: t('documentationView:MODAL_PREVIEW_BUTTON_BACK'),
				onClick: handleBack,
			}}
			size='lg'
			scroll={'body'}
			showDividers={false}
		>
			<FormPreview
				sectionToShow={sectionsToShow}
				form={formModel}
			/>
		</Modal>
	);
};

export default PreviewModal;
