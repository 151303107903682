import { getCivilStatusObject, getCountries, getGuardianObject, getSexObject } from '../../../services/GenericService';
import React, { Reducer } from 'react';
import { AlertContext } from '../../../contexts/AlertContext';
import { Assistant } from '../../../interfaces/Assistant';
import { Form } from '../../../interfaces/FormData';
import { Institution } from '../../../interfaces/Institution';
import { OptionSelect } from '../../../interfaces/Common';
import { PatientProfile } from '../../../interfaces/Patient';
import { TherapistInfo } from '../../../interfaces/User';

interface Action {
	type: string;
	value?: string | Date;
	name?: string;
	isUpdated?: boolean;
	data?: Form;
}

interface PersonalInfoModalProps {
	data: PatientProfile | TherapistInfo | Assistant | Institution;
	getUser: () => Promise<void>;
	onChange: () => void;
	updateUser: (formData: Form) => Promise<void>;
}

interface PersonalInfoModalStore {
	formData: Form;
	formIsUpdated: boolean;
	countries: Array<OptionSelect>;
	genders: Array<OptionSelect>;
	maritalStatuses: Array<OptionSelect>;
	tutorOptions: Array<OptionSelect>;
	handleChangeTextField: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleChangeSelect: (value: string, name: string) => void;
	handleChangeDate: (data: Date, key: string) => void;
	handleClose: () => void;
	handleConfirm: () => void;
	setFormData: (value: any) => void;
}

const reducer = (state: Form, action: Action): Form => {
	switch (action.type) {
		case 'update_all':
			return action.data as Form;
		case 'update': {
			const name = action.name as string;
			const value = action.value as string;
			const isUpdated = action.isUpdated as boolean;

			return { ...state, [name]: { ...state[name], value: value, isUpdated: isUpdated } };
		}

		default:
			return state;
	}
};

const usePersonalInfoModal = (props: PersonalInfoModalProps): PersonalInfoModalStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [formData, setFormData] = React.useReducer<Reducer<Form, Action>>(reducer, {} as Form);
	const [formIsUpdated, setFormIsUpdated] = React.useState<boolean>(false);
	const [countries, setCountries] = React.useState<Array<OptionSelect>>([]);

	const loadData = async (): Promise<void> => {
		try {
			const countries = await getCountries();

			setCountries(countries.map(country => ({ ...country, name: `country:${country.name}` })));
		} catch (error) {
			showDefaultError();
		}
	};

	React.useEffect(() => {
		loadData();

		const data = Object.keys(props.data).reduce((acc, key) => {
			const value = {
				name: key,
				value: (props.data as (PatientProfile | TherapistInfo | Assistant))[key],
				label: key.split(/(?=[A-Z])/).map(item => item.toUpperCase()).join('_'),
				isUpdated: false,
			};

			return ({...acc, [key]: value});
		}, {});

		setFormData({ type: 'update_all', data });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data]);

	React.useEffect(() => {
		const isUpdated = Object.keys(formData).some((key) => formData[key].isUpdated);

		setFormIsUpdated(isUpdated);
	}, [formData]);

	const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setFormData({ type: 'update', name: event.target.name, value: event.target.value, isUpdated: event.target.value !== props.data[event.target.name] });
	};

	const handleChangeSelect = (value: string, name: string): void => {
		setFormData({ type: 'update', name, value, isUpdated: value !== props.data[name] });
	};

	const handleChangeDate = (date: Date, key: string): void => {
		setFormData({ type: 'update', name: key, value: date, isUpdated: date.toString() !== props.data[key] });
	};

	const handleClose = (): void => {
		props.onChange();
	};

	const handleConfirm = async (): Promise<void> => {
		await props.updateUser(formData);
		await props.getUser();
		props.onChange();
	};

	return ({
		formData,
		formIsUpdated,
		countries,
		genders: getSexObject(),
		maritalStatuses: getCivilStatusObject(),
		tutorOptions: getGuardianObject(),
		handleChangeTextField,
		handleChangeSelect,
		handleChangeDate,
		handleClose,
		handleConfirm,
		setFormData,
	});
};

export default usePersonalInfoModal;
