import { DateQuestion, Section } from '../../../../interfaces/DocumentForm';
import { FormContext, FormUpdateContext } from '../../../../contexts/FormContext';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DateStore {
	question: DateQuestion;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (date: Date | null) => void;
}

const useDatePreview = (props: QuestionPreviewProps): DateStore => {
	const { t } = useTranslation();
	const {
		formModel,
		formModel: { showErrors },
	} = React.useContext(FormContext);
	const { handleUpdateQuestion } = React.useContext(FormUpdateContext);
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = formModel.sections[props.sectionIndex].questions[props.questionIndex] as DateQuestion;

	const isValueValid = (value: Date | null): boolean => {
		const hasValue = value !== null;

		if (!hasValue && question.required) {
			return false;
		}

		return true;
	};

	React.useEffect(() => {
		const isValid = isValueValid(question.value);

		setErrorMessage(t('libraryView:FORMS_FIELD_REQUIRED'));
		handleUpdateQuestion({ ...question, isValid }, formModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (value: Date | null): void => {
		const isValid = isValueValid(value);

		if (isValid) {
			setErrorMessage('');
		}

		handleUpdateQuestion({ ...question, value, isValid: isValid }, formModel.sections[props.sectionIndex] as Section);
	};

	return {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	};
};

export default useDatePreview;
