import AppBar from '@material-ui/core/AppBar';
import InputText from '../../Inputs/InputText/InputText';
import Modal from '../../Modal/Modal';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { SignatureType } from '../../../interfaces/User';
import styles from './SignatureModal.module.scss';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useSignatureModal from './useSignatureModal';
import { useTranslation } from 'react-i18next';

export interface SignatureModalProps {
	open: boolean;
	getUser: () => void;
	onChange: () => void;
	onSave: (signatureImage: string) => void;
}

const SignatureModal: React.FC<SignatureModalProps> = ({ open, getUser, onChange, onSave }) => {
	const { t } = useTranslation();
	const {
		signatureValue,
		selectedTab,
		sigCanvas,
		isInvalidSignature,
		handleClear,
		handleClose,
		handleConfirm,
		handleChangeTab,
		handleChangeInput,
		handleOnEndCanvas,
	} = useSignatureModal({ getUser, onChange, onSave });

	return (
		<Modal
			open={open}
			title={t('profileView:UPDATE_SIGNATURE')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: isInvalidSignature,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
			leftActionButtons={[{
				title: t('profileView:MODAL_BUTTON_CLEAR'),
				onClick: handleClear,
				type: 'SECONDARY',
				show: true,
			}]}
			scroll="body"
		>
			<div className={styles.container}>
				<AppBar className={styles.appBar} position="static" variant="outlined">
					<Tabs
						classes={{ root: styles.tabs, indicator: styles.tabsIndicator }}
						value={selectedTab}
						onChange={handleChangeTab}
						TabIndicatorProps={{ children: <span /> }}
						centered
					>
						<Tab label={t('profileView:WRITE_TAB_OPTION')} className={styles.tab} id="simple-tab-0" aria-controls="simple-tabpanel-0" />
						<Tab label={t('profileView:DRAW_TAB_OPTION')} className={styles.tab} id="simple-tab-1" aria-controls="simple-tabpanel-1" />
					</Tabs>
				</AppBar>
				{
					selectedTab === SignatureType.Write &&
					(
						<div>
							<InputText
								value={signatureValue}
								name="place"
								label={t('profileView:FULL_NAME_LABEL')}
								fullWidth
								onChange={handleChangeInput}
								error={isInvalidSignature}
								helperText={t('profileView:HELPER_TEXT')}
							/>
							<div className={styles.writeContainer}>
								<canvas id="canvasSignatureWritter" />
							</div>
						</div>
					)
				}
				{
					selectedTab === SignatureType.Draw &&
					(
						<SignatureCanvas
							ref={sigCanvas}
							onEnd={handleOnEndCanvas}
							canvasProps={{
								className: styles.signatureDrawCanvasContainer,
							}}
						/>
					)
				}
			</div>
		</Modal >
	);
};

export default SignatureModal;
