import InputText from '../../Inputs/InputText/InputText';
import Modal from '../../Modal/Modal';
import React from 'react';
import ReactLoading from 'react-loading';
import styles from './NewSessionTypeModal.module.scss';
import useNewSessionTypeModal from './useNewSessionTypeModal';
import { useTranslation } from 'react-i18next';

interface NewSessionTypeModalProps {
	open: boolean;
	fetchData: () => Promise<void>;
	onChange: () => void;
}

const NewSessionTypeModal: React.FC<NewSessionTypeModalProps> = ({ open, fetchData, onChange }) => {
	const { t } = useTranslation();
	const {
		error,
		sessionType,
		handleChange,
		formIsValid,
		handleClose,
		handleConfirm,
		isLoading,
	} = useNewSessionTypeModal({ fetchData, onChange });

	return (
		<Modal
			open={open}
			title={t('agendaView:MODAL_NEW_SESSION_TYPE')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientsView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: !formIsValid,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientsView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
			showDividers={false}
		>
			{
				!isLoading ?
					<div className={styles.content} >
						<InputText
							value={sessionType}
							name="sessionType" label={t('agendaView:SESSION_TYPE_TAB')}
							fullWidth
							onChange={handleChange}
							error={error.hasError}
							helperText={error.text} />
					</div>
					:
					<ReactLoading type="spin" color="#4ebed7" height={'20%'} width={'20%'} />
			}
		</Modal>
	);
};

export default NewSessionTypeModal;
