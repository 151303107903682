import { getPost, getPosts, getTags } from '../../api/public/PostsRepository';
import { Post } from '../../interfaces/Post';
import { Tag } from '../../interfaces/Tag';

export const getAllPosts = async (tags: number[]): Promise<Post[]> => {
	const response = await getPosts(tags);

	return response.data;
};

export const getAllTagsForPosts = async (): Promise<Tag[]> => {
	const response = await getTags();

	return response.data;
};

export const getPostById = async (id: number): Promise<Post> => {
	const response = await getPost(id);

	return response.data;
};
