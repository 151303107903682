import { Avatar, CardHeader } from '@material-ui/core';
import Button from '../../Button/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styles from './TherapistRequestProfileCard.module.scss';
import { useTranslation } from 'react-i18next';

interface TherapistRequestProfileCardProps {
	fullName: string;
	image?: string;
	isLoading?: boolean;
	onAccept: () => Promise<void>;
	onReject: () => void;
}

const TherapistRequestProfileCard: React.FC<TherapistRequestProfileCardProps> = ({fullName, isLoading = false, image, onAccept, onReject}) => {
	const { t } = useTranslation();

	return (
		<div className={styles.profilePicture}>
			<Card className={styles.cardImage}>
				{isLoading &&
					<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
						<CircularProgress />
					</div>
				}
				{!isLoading &&
				<>
					<CardHeader
						action={
							<div className={styles.headerAction}/>
						}
					/>
					<div className={styles.actionAreaContent}>
						<CardActionArea
							className={styles.cardActionArea}
						>
							<Avatar className={styles.cardMedia} alt="profile-img" src={image} />
						</CardActionArea>
						<p className={styles.text}>{fullName}</p>
						<div className={styles.buttons}>
							<Button
								fullWidth
								onClick={onAccept}
								size='large'
								title={t('assistant:ACCEPT')}
							/>
							<Button
								fullWidth
								onClick={onReject}
								size='large'
								title={t('assistant:REJECT')}
								type='SECONDARY'
							/>
						</div>
					</div>
				</>
				}
			</Card>
		</div>
	);
};

export default TherapistRequestProfileCard;
