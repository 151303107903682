
import Modal from '../../Modal/Modal';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import ReactLoading from 'react-loading';
import SessionCalendar from '../../Calendar/Calendar';
import styles from './SessionsAvailabilityModal.module.scss';
import useSessionsAvailabilityModal from './useSessionsAvailabilityModal';
import { useTranslation } from 'react-i18next';

interface SessionsAvailabilityModalProps {
	open: boolean;
	onChange: () => void;
}

const SessionsAvailabilityModal: React.FC<SessionsAvailabilityModalProps> = ({ open, onChange }) => {
	const { t } = useTranslation();
	const {
		isLoading,
		selectedDate,
		sessions,
		handleChangeDate,
		handleClose,
	} = useSessionsAvailabilityModal({ onChange });

	return (
		<Modal
			open={open}
			title={t('agendaView:MODAL_BUTTON_AVAILABILITY')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('buttons:CLOSE'),
				onClick: handleClose,
			}}
			showDividers={false}
			size={'lg'}
			scroll={'body'}
		>
			{
				!isLoading ?
					<Paper elevation={0} className={styles.modalContent}>
						<div className={styles.calendar}>
							<SessionCalendar
								selectedDate={selectedDate}
								sessions={sessions}
								onChangeSelectedDate={handleChangeDate}
							/>
						</div>
					</Paper>
					:
					<ReactLoading type="spin" color="#4ebed7" height={'20%'} width={'20%'} />
			}
		</Modal >
	);
};

export default SessionsAvailabilityModal;
