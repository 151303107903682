import { debounce, DebouncedFunc } from 'lodash';
import React, { useEffect } from 'react';
import { UserRowCardProps } from '../UserRowCard/UserRowCard';
import { UserSelectorProps } from '../../UserSelector/UserSelector';

export interface UserFilterListCardProps {
	userSelector: UserSelectorProps;
	fullWidth?: boolean;
	filter?: boolean;
	title?: string;
	className?: string;
}

interface UserFilterListCardInterface {
	data: UserRowCardProps[];
	debouncedResults: DebouncedFunc<(filterPattern: string) => void>;
}

export const useUserFilterListCard = (props: UserFilterListCardProps): UserFilterListCardInterface => {
	const [data, setData] = React.useState<UserRowCardProps[]>([]);

	const filterUsers = React.useCallback((filterPattern: string): void => {
		if (filterPattern) {
			const filterData = props.userSelector.users?.filter(u => u.title.toLocaleLowerCase().includes(filterPattern.toLocaleLowerCase()));

			setData(filterData);
		} else {
			setData(props.userSelector.users);
		}
	}, [props.userSelector.users]);

	const debouncedResults = React.useMemo(() => {
		return debounce(filterUsers, 300);
	}, [filterUsers]);

	useEffect(() => {
		setData(props.userSelector.users);

		return (): void => debouncedResults.cancel();
	}, [debouncedResults, props.userSelector.users]);

	return {
		data,
		debouncedResults,
	};
};
