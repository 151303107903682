import * as AuthRepository from '../api/AuthRepository';
import { Auth } from '../interfaces/Auth';
import { Credentials } from '../interfaces/User';

export const signInWithPassword = async (credentials: Credentials, code: string | null): Promise<Auth> => {
	return AuthRepository.signInWithPassword(credentials, code);
};

export const signInWithGoogle = async (idToken: string): Promise<Auth> => {
	return AuthRepository.signInWithGoogle(idToken);
};

export const signUpWithPassword = async (givenName: string, familyName: string, credentials: Credentials): Promise<Auth> => {
	return AuthRepository.signUpWithPasswordTherapist(givenName, familyName, credentials);
};

export const signUpWithPasswordAndInvitation = async (credentials: Credentials, code: string): Promise<Auth> => {
	return AuthRepository.signUpWithPasswordPatient(credentials, code);
};

export const signOut = async (): Promise<unknown> => {
	return AuthRepository.signOut();
};
