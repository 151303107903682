import { Button as ActionButton, Divider, GoogleAuthButton, TextField } from '../../components/';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import logo from '../../assets/images/pragma-logo-signin.svg';
import React from 'react';
import { Redirect } from 'react-router-dom';
import styles from './SignUp.module.scss';
import { useSignUp } from './useSignUp';
import { useSignUpValidation } from './useSignUpValidation';
import { useTranslation } from 'react-i18next';

const SignUp: React.FC = () => {
	const { t } = useTranslation();
	const { handleSignUpClick, isAuth, givenName, givenNameError, givenNameErrorMessage, familyName, familyNameError, familyNameErrorMessage, signUpError, validateGivenName, validateFamilyName, extraFieldsRequired } = useSignUp();
	const {
		validateEmail,
		validatePassword,
		validateConfirmPassword,
		email,
		emailError,
		emailErrorMessage,
		password,
		passwordError,
		passwordErrorMessage,
		confirmPassword,
		confirmPasswordError,
		confirmPasswordErrorMessage,
	} = useSignUpValidation();

	if (isAuth) {
		return (
			<Redirect to="/" />
		);
	}

	return (
		<div className={styles.signup}>
			<div className={styles.signupContainer}>
				<div className={styles.signupLogoContainer}>
					<img src={logo} alt={'logo'} className={styles.signupLogo} />
				</div>
				<span className={styles.signupTitle}>{t('signup:SIGNUP_MESSAGE')}</span>
				<div className={styles.signupFormContainer}>
					<GoogleAuthButton
						onClick={(): void => { console.log('google auth coming soon...'); }}
						showIcon={true}
						text={t('view:SIGNIN_BUTTON_GOOGLE')}
					/>
					<Divider className={styles.signupDivider} text={t('view:SIGNIN_SPAN_OR')} />
					<div className={styles.signupForm}>
						{
							extraFieldsRequired && (
								<>
									<div className={styles.formField}>
										<TextField
											error={givenNameError}
											errorMessage={givenNameErrorMessage}
											placeholder={t('signup:SIGNUP_LABEL_GIVEN_NAME')}
											onChange={validateGivenName}
											required={true}
											value={givenName || ''}
										/>
									</div>
									<div className={styles.formField}>
										<TextField
											error={familyNameError}
											errorMessage={familyNameErrorMessage}
											placeholder={t('signup:SIGNUP_LABEL_FAMILY_NAME')}
											onChange={validateFamilyName}
											required={true}
											value={familyName || ''}
										/>
									</div>
								</>
							)
						}
						<div className={styles.formField}>
							<TextField
								error={emailError}
								errorMessage={emailErrorMessage}
								placeholder={t('view:SIGNIN_LABEL_EMAIL')}
								onChange={validateEmail}
								required={true}
								type="email"
								value={email || ''}
							/>
						</div>
						<div className={styles.formField}>
							<TextField
								error={passwordError}
								errorMessage={passwordErrorMessage}
								placeholder={t('view:SIGNIN_LABEL_PASSWORD')}
								onChange={validatePassword}
								required={true}
								type="password"
								value={password || ''}
							/>
						</div>
						<div className={styles.formField}>
							<TextField
								error={confirmPasswordError}
								errorMessage={confirmPasswordErrorMessage}
								placeholder={t('changePasswordView:CONFIRM_PASSWORD_LABEL')}
								onChange={validateConfirmPassword}
								type="password"
								value={confirmPassword || ''}
							/>
						</div>
						{signUpError && (
							<div className={styles.signupError}>
								<ErrorIcon style={{ marginRight: 5 }} />
								{signUpError}
							</div>
						)}
						<div className={styles.signupFooter}></div>
						<ActionButton
							onClick={(): void => handleSignUpClick(email, password)}
							title={t('signup:SIGNUP_BUTTON')}
							isDisabled={emailError || passwordError || !email || !password || !confirmPassword || password !== confirmPassword || (extraFieldsRequired && (!givenName || givenNameError || !familyName || familyNameError))}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
