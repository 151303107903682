export const convertTimeToSeconds = (time: string): number => {
	const splitedTime = time.split(':');
	const totalSeconds = (parseInt(splitedTime[0]) * 3600) + (parseInt(splitedTime[1]) * 60);

	return totalSeconds;
};

export const isToday = (date: Date): boolean => {
	try {
		const today = new Date();
		const dateToCheck = new Date(date);

		return today.getDate() === dateToCheck.getDate() && today.getMonth() === dateToCheck.getMonth() && today.getFullYear() === dateToCheck.getFullYear();
	} catch(e) {
		return false;
	}
};

