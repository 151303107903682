interface ResourceCardsProps {
	onClick?: (id: number) => void;
}

interface ResourceCardsStore {
	handleClick: (id: number) => void;
}

const useResourceCards = (props: ResourceCardsProps): ResourceCardsStore => {
	const handleClick = (id: number): void => {
		if(props.onClick) {
			props.onClick(id);
		}
	};

	return {
		handleClick,
	};
};

export default useResourceCards;
