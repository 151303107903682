import { Form, QuestionTypes, Section } from '../interfaces/DocumentForm';
import React, { ReactNode } from 'react';

export interface FormProviderStore {
	formModel: Form;
	sectionsToShow: string;
}

export interface FormUpdateProviderStore {
	handleResetDocument: (sectionsToReset?: string[]) => void;
	handleSectionsToShow: (sectionId: string) => void;
	handleUpdateQuestion: (question: QuestionTypes, section: Section) => void;
	hideErrorsInForm: () => void;
	isFormValid: () => boolean;
	setFormModel: (form: Form) => void;
	showErrorsInForm: () => void;
}

export interface TQuestion {
	questionId: string;
	nextSection: string | undefined;
}

export const FormContext = React.createContext({} as FormProviderStore);
export const FormUpdateContext = React.createContext({} as FormUpdateProviderStore);

const FormProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [formModel, setFormModel] = React.useState<Form>({} as Form);
	const [sectionsToShow, setSectionsToShow] = React.useState<string>('');

	const isFormValid = (): boolean => {
		// setFormModel({ ...formModel, showErrors: true });

		for (let i = 0; i < formModel.sections.length; i++) {
			for (let j = 0; j < formModel.sections[i].questions.length; j++) {
				if (!formModel.sections[i].questions[j].isValid) {
					return false;
				}
			}
		}

		return true;
	};

	const resetDocument = (document: Form, sectionsToReset?: string[]): Form => {
		const newDocument = { ...document };

		newDocument.showErrors = false;
		newDocument.sections.forEach(section => {
			if (sectionsToReset && !sectionsToReset.includes(section.id)) {
				return;
			}

			section.questions.forEach(question => {
				type typeOfQuestion = typeof question;

				(question as typeOfQuestion).value = question.type === 'DateQuestion' ? null : '';
				question.isValid = true;
			});
		});

		return newDocument;
	};

	const handleResetDocument = (sectionsToReset?: string[]): void => {
		setFormModel(resetDocument(formModel, sectionsToReset));
	};

	const handleSectionsToShow = (sectionId: string): void => {
		if (sectionId !== sectionsToShow) {
			setSectionsToShow(sectionId);
		}
	};

	const hideErrorsInForm = (): void => setFormModel({ ...formModel, showErrors: false });

	const showErrorsInForm = (): void => setFormModel({ ...formModel, showErrors: true });

	const handleUpdateQuestion = (question: QuestionTypes, section: Section): void => {
		const sectionIndex = formModel.sections.findIndex(s => s.order === section.order);

		if (sectionIndex > -1) {
			const questionIndex = section.questions.findIndex(q => q.order === question.order);

			if (questionIndex > -1) {
				const formModelToUpdate = { ...formModel };

				// const {questions} = formModelToUpdate.sections[sectionIndex];
				// const isLastQuestion = questions.length === question.order;

				formModelToUpdate.sections[sectionIndex].questions[questionIndex] = question;
				setFormModel(formModelToUpdate);

				// if (isLastQuestion) { handleLastQuestions(); }
			}
		}
	};

	const store: FormProviderStore = {
		formModel,
		sectionsToShow,
	};

	const updateStore: FormUpdateProviderStore = {
		handleSectionsToShow,
		handleUpdateQuestion,
		handleResetDocument,
		hideErrorsInForm,
		isFormValid,
		setFormModel,
		showErrorsInForm,
	};

	return (
		<FormContext.Provider value={store}>
			<FormUpdateContext.Provider value={updateStore}>
				{ children }
			</FormUpdateContext.Provider>
		</FormContext.Provider>
	);
};

export { FormProvider };
export const FormConsumer = FormContext.Consumer;
