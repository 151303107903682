import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useCallback } from 'react';
import styles from './SectionTab.module.scss';

interface TabInfo {
	index: number;
	title: string;
	children: JSX.Element;
}

interface TabPanelInfo {
	index: number;
	value: number;
	children: JSX.Element;
}

interface SectionTabsProps {
	sectionTab: TabInfo[];
}

interface TabPanelProps {
	id: string;
	'aria-controls': string;
}

const TabPanel = (info: TabPanelInfo): JSX.Element => {

	return (
		<div
			role="tabpanel"
			hidden={info.value !== info.index}
			id={`scrollable-force-tabpanel-${info.index}`}
			aria-labelledby={`scrollable-force-tab-${info.index}`}
		>
			{info.value === info.index && (
				<Box className={styles.containerTab}>
					{info.children}
				</Box>
			)}
		</div>
	);
};

const setTabPanel = (index: number): TabPanelProps => {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`,
	};
};

const SectionTab: React.FC<SectionTabsProps> = ({ sectionTab }) => {
	const [value, setValue] = React.useState(0);

	const onChangeTab = useCallback((event: unknown, newValue: number) => {
		setValue(newValue);
	}, []);

	return (
		<div>
			<Tabs
				value={value}
				onChange={onChangeTab}
				centered
				className={styles.container}
				classes={{ indicator: styles.tabIndicator }}
			>
				{sectionTab.map((childTab) => {
					const { index, title } = childTab;

					return (
						<Tab key={index} label={title} {...setTabPanel(index)} className={`${styles.tabContainer} ${value === index && styles.tabContainerSelected}`} />
					);
				})}
			</Tabs>

			{sectionTab.map((childTabPanel) => {
				const { index, children } = childTabPanel;

				return (
					<TabPanel value={value} key={index} index={index}>
						{children}
					</TabPanel>
				);
			})}

		</div>
	);
};

export default SectionTab;
