export const replaceLabels = (text: string): string => {
	return text.replace('[username]', 'This is the username');
};

const options = {
	day: 'numeric', month: 'long', year: 'numeric',
};

export const formatDate = (date: string): string => {
	return new Date(date + ' EDT').toLocaleDateString(['en-US'], options);
};
