import Button from '@material-ui/core/Button';
import { Card } from '@material-ui/core';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Input from '@material-ui/core/Input';
import { keyValue } from '../../../interfaces/Common';
import React from 'react';
import styles from './ProfileCard.module.scss';
import { useTranslation } from 'react-i18next';

interface ProfileCardProps {
	data: keyValue;
	fieldsToDisplay?: string[];
	fullWidth?: boolean;
	showEditBtn?: boolean;
	title: string;
	onClickBtn?: () => void;
}

const hiddenFields: string[] = ['id', 'acceptedAt', 'avatar', 'enabled', 'signature', 'instituteLogo'];
const inputClasses = { root: styles.inputRoot, input: styles.input, underline: styles.inputUnderline };

const ProfileCard: React.FC<ProfileCardProps> = ({ data, fieldsToDisplay, fullWidth, showEditBtn, title, onClickBtn }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.profileCard}>
			<Card className={`${styles.cardForm} ${fullWidth && styles.maxWidth}`}>
				<div className={`${styles.content} ${fullWidth && styles.maxWidth}`}>
					<div className={styles.header}>
						<p className={styles.headerTitle}>{title}</p>
						{showEditBtn &&
							<Button
								color="primary"
								className={styles.headerEditButtom}
								startIcon={<EditOutlined />}
								onClick={onClickBtn}
							>
								{t('profileView:EDIT_BUTTON')}
							</Button>
						}
					</div>
					{(Object.keys(data)).map((key) => {
						if (hiddenFields.includes(key) || (fieldsToDisplay && !fieldsToDisplay.includes(key))) {

							return null;
						}

						const value = data[key];
						const label = key.split(/(?=[A-Z])/).map(item => item.toUpperCase()).join('_');

						return (
							<Input
								key={label}
								id={label}
								classes={inputClasses}
								placeholder=""
								fullWidth
								readOnly
								startAdornment={
									<span className={styles.label}>{t(`profileView:${label}`)}</span>
								}
								value={t(value)}
								disabled
							/>
						);
					})}
				</div>
			</Card>
		</div>
	);
};

export default ProfileCard;
