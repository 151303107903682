import { Resource, ResourceCard, StatusResource } from '../interfaces/Resource';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const findAllResources = (): Promise<Array<Resource>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/resources`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findAllMyResources = (therapistId: number | string): Promise<Array<Resource>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/resources`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findResourcesLinkedToPatient = (therapistId: number | string, patientId: number): Promise<Array<Resource>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/resources`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findPatientResourcesByTherapist = (therapistId: number | string): Promise<Array<ResourceCard>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me/therapists/${therapistId}/resources`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const linkResourceToPatient = (therapistId: number | string, resourceId: number, patientId: number): Promise<void> => {
	if (apiUrl) {
		return axios.put(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/resources/${resourceId}`, { status: StatusResource.Linked });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const unlinkResourceFromPatient = (therapistId: number | string, resourceId: number, patientId: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/resources/${resourceId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const shareResourceToPatient = (therapistId: number | string, resourceId: number, patientId: number): Promise<void> => {
	if (apiUrl) {
		return axios.put(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/resources/${resourceId}`, { status: StatusResource.Shared });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
