import { Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import React from 'react';
import styles from './ProfilePictureCard.module.scss';
import { useTranslation } from 'react-i18next';

interface ProfilePictureCardProps {
	fullName: string;
	image?: string;
	isAllowedToEdit?: boolean;
	isLoading?: boolean;
	onSave?: (e: any) => void;
}

const ProfilePictureCard: React.FC<ProfilePictureCardProps> = ({ fullName, image, children, isAllowedToEdit, isLoading = false, onSave }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.profilePicture}>
			<Card className={styles.cardImage}>
				{isLoading &&
					<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
						<CircularProgress />
					</div>
				}
				{isAllowedToEdit && !isLoading ?
					<CardActionArea className={styles.cardActionArea}>
						<Avatar className={styles.cardMedia} alt="profile-img" src={image} />
						<div>
							<input type="file" onChange={onSave} id="contained-button-file" accept="image/*" multiple style={{display: 'none'}} />
							<label htmlFor="contained-button-file">
								<div className={styles.cardActionAreaOverlay}></div>
								<div className={styles.cardActionAreaDetails}>
									<PhotoCameraOutlinedIcon className={styles.cardActionAreaDetailsIcon} />
									<span >{t('profileView:CHANGE_PROFILE_PICTURE')}</span>
								</div>
							</label>
						</div>
					</CardActionArea> :
					!isLoading && <Avatar className={styles.cardMedia} alt="profile-img" src={image} />
				}
				{!isLoading &&
					<p className={styles.text}>{fullName}</p>
				}
				{children}
			</Card>
		</div>
	);
};

export default ProfilePictureCard;
