import { CardsTable, FormPreviewModal, PrivateTopBar } from '../../../components';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { FormProvider } from '../../../contexts/FormContext';
import Grid from '@material-ui/core/Grid';
import ListPatientsModal from '../../../components/Modals/ListPatientsModal/ListPatientsModal';
import React from 'react';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import ShareIcon from '@material-ui/icons/Share';
import styles from './Documentation.module.scss';
import TextField from '@material-ui/core/TextField';
import useDocumentation from './useDocumentation';
import { useTranslation } from 'react-i18next';

const Documentation = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		tagsColumns,
		documentsColumns,
		filteredTags,
		documents,
		selectedTagId,
		selectedDocument,
		gridWidth,
		searchValue,
		openListPatientsModal,
		openFormPreviewModal,
		handleClickTagCard,
		handleClickDocumentCard,
		handleChangeSearchValue,
		handleClickLink,
		handleOpenFormValue,
	} = useDocumentation();

	return (
		<div className={styles.documentation}>
			<PrivateTopBar
				title={t('documentationView:TITLE')}
				buttons={
					<div className={styles.search}>
						<Divider
							orientation="vertical"
							className={styles.divider}
						/>
						<TextField
							placeholder={t('therapistButtons:SEARCH_INPUT')}
							value={searchValue}
							onChange={handleChangeSearchValue}
							InputProps={{
								startAdornment: (
									<SearchOutlinedIcon
										color="disabled"
										className={styles.icon}
									/>
								),
								disableUnderline: true,
							}}
						/>
					</div>
				}
			/>
			<div className={styles.container}>
				<Grid container spacing={3} className={styles.grid}>
					<Grid item xs={12} sm={gridWidth}>
						<SectionTitle
							title={t(
								'documentationView:PROBLEMATIC_CARD_HEADER'
							)}
							showDivider={false}
						/>
						<div className={styles.column}>
							<CardsTable
								data={filteredTags}
								columnsDef={tagsColumns}
								onClick={handleClickTagCard}
								isLoading={false}
								stylizeSelected={true}
							/>
						</div>
					</Grid>
					{selectedTagId && (
						<Grid item xs={12} sm={gridWidth}>
							<div style={{ display: 'flex' }}>
								<SectionTitle
									title={t(
										'documentationView:DOCUMENTS_CARD_HEADER'
									)}
									showDivider={false}
								/>
								{selectedDocument && (
									<Button
										className={styles.shareIcon}
										startIcon={<ShareIcon />}
										onClick={handleClickLink}
									>
										{t('documentationView:BUTTON_LINK')}
									</Button>
								)}
							</div>
							<div className={styles.column}>
								<CardsTable
									data={documents}
									columnsDef={documentsColumns}
									onClick={handleClickDocumentCard}
									isLoading={false}
									stylizeSelected={true}
								/>
							</div>
						</Grid>
					)}
				</Grid>
			</div>
			{
				selectedDocument && openListPatientsModal &&
				<ListPatientsModal
					open={openListPatientsModal}
					onChange={handleClickLink}
					type={'document'}
					objectId={selectedDocument?.id}
				/>
			}
			{selectedDocument && openFormPreviewModal && (
				<FormProvider>
					<FormPreviewModal
						open={openFormPreviewModal}
						form={{
							id: selectedDocument.id,
							title: selectedDocument.name,
							description: selectedDocument.description,
							sections: selectedDocument.sections,
							showErrors: false,
						}}
						handleClose={handleOpenFormValue}
					/>
				</FormProvider>
			)}
		</div>
	);
};

export default Documentation;
