import { getProfile, updateInstitution, updateInstitutionAvatar } from '../../../services/InstitutionService';
import { AlertContext } from '../../../contexts/AlertContext';
import { AuthUpdateContext } from '../../../contexts/AuthContext';
import { Form } from '../../../interfaces/FormData';
import { Institution } from '../../../interfaces/Institution';
import React from 'react';

interface ProfileStore {
	isLoading: boolean;
	institutionInfo: Institution;
	openPersonalInfoModal: boolean;
	handleOpenPersonalInfoModal: () => void;
	handleUpdateAvatar: (e: any) => void;
	loadInstitution: () => Promise<void>;
	updateInstitutionInfo: (formData: Form) => Promise<void>;
}

const useProfile = (): ProfileStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [institutionInfo, setInstitutionInfo] = React.useState<Institution>({} as Institution);
	const [openPersonalInfoModal, setOpenPersonalInfoModal] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { fetchUser } = React.useContext(AuthUpdateContext);

	const loadInstitution = async (): Promise<void> => {
		setIsLoading(true);

		try {
			const institution = await getProfile();

			setInstitutionInfo(institution);
		} catch (error) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		loadInstitution();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUpdateAvatar = async (e: any): Promise<void> => {
		setIsLoading(true);

		try {
			await updateInstitutionAvatar(e?.target?.files);
			await loadInstitution();
			fetchUser();
		} catch (e) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	const handleOpenPersonalInfoModal = (): void => {
		setOpenPersonalInfoModal(prevState => !prevState);
	};

	const updateInstitutionInfo = async (formData: Form): Promise<void> => {
		try {
			await updateInstitution(formData);
		} catch (e) {
			showDefaultError();
		}
	};

	return {
		isLoading,
		institutionInfo: institutionInfo,
		openPersonalInfoModal,
		handleOpenPersonalInfoModal,
		handleUpdateAvatar,
		loadInstitution: loadInstitution,
		updateInstitutionInfo: updateInstitutionInfo,
	};
};

export default useProfile;
