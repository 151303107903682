import { ChatContact, ChatMessage } from '../../../interfaces/Chat';
import { ChatContext, ChatUpdateContext } from '../../../contexts/ChatContext';
import { AuthContext } from '../../../contexts/AuthContext';
import React from 'react';
import { SocketUpdateContext } from '../../../contexts/SocketContext';

interface ChatContentHook {
	activeContact: ChatContact | undefined;
	currentUserId: number | undefined;
	messageDivRef: React.RefObject<HTMLUListElement>;
	messages: ChatMessage[];
	cleanChat: () => void;
	handleSubmit: (message: string) => void;
}

export const useChatContent = (): ChatContentHook => {
	const { user } = React.useContext(AuthContext);
	const { handleEmitMessage } = React.useContext(SocketUpdateContext);
	const { chatState } = React.useContext(ChatContext);
	const { cleanChat } = React.useContext(ChatUpdateContext);
	const messageDivRef = React.useRef<HTMLUListElement>(null);

	React.useEffect(() => {
		if (messageDivRef.current) {
			messageDivRef.current.scrollTop = messageDivRef.current.scrollHeight;
		}
	}, [chatState.messages]);

	const handleSubmit = (message: string): void => {
		if(user?.id && chatState.activeContact?.id) {
			handleEmitMessage(message, user.id, chatState.activeContact.id);
		}
	};

	return {
		activeContact: chatState.activeContact,
		currentUserId: user?.id,
		messageDivRef,
		messages: chatState.messages,
		cleanChat,
		handleSubmit,
	};
};
