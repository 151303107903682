import Card from './Card';
import React from 'react';
import styles from './SmallCard.module.scss';

interface SmallCardProps {
	image: string;
	title: string;
	text: string;
}

const SmallCard: React.FC<SmallCardProps> = ({ image, title, text }) => {
	return (
		<Card className={styles.smallCard}>
			<div className={styles.cardContent}>
				<img className={styles.cardImage} src={image} alt="icon" />
				<div className={styles.cardTextContainer}>
					<h5 className={styles.cardTextTitle}>{title}</h5>
					<p className={styles.cardText}>{text}</p>
				</div>
			</div>
		</Card>
	);
};

export default SmallCard;
