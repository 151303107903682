import React, { ReactNode } from 'react';
import { AuthContext } from './AuthContext';
import { getTherapistsByCurrentPatient } from '../services/PatientService';
import { TherapistCard } from '../interfaces/TherapistCard';
import { UserRole } from '../interfaces/User';

export interface TherapistStore {
	badge: boolean;
	isOpen: boolean;
	therapistsByCurrentPatient: TherapistCard[];
	therapistSelected: TherapistCard;
}

export interface UpdateTherapistStore {
	setBadge: (badge: boolean) => void;
	setIsOpen: (isOpen: boolean) => void;
	setTherapistSelected: (therapist: TherapistCard) => void;
	enableDisabled: () => void;
}

export const TherapistContext = React.createContext({} as TherapistStore);
export const UpdateTherapistContext = React.createContext({} as UpdateTherapistStore);

export const TherapistProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [therapistsByCurrentPatient, setTherapistByCurrentPatient] = React.useState<TherapistCard[]>([]);
	const [therapistSelected, setTherapistSelected] = React.useState<TherapistCard>({} as TherapistCard);
	const [badge, setBadge] = React.useState<boolean>(false);
	const { user } = React.useContext(AuthContext);

	const loadData = async (): Promise<void> => {
		try {
			const therapists = await getTherapistsByCurrentPatient();

			if (therapists.length) {
				setTherapistSelected(therapists[0]);
				setTherapistByCurrentPatient(therapists);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const enableDisabled = (): void => {
		const therapist = { ...therapistSelected };

		therapist.enabled = !therapist.enabled;
		setTherapistSelected(therapist);
	};

	React.useEffect(() => {
		if (user?.roles[0] === UserRole.PATIENT) {
			loadData();
		}
	}, [user]);

	const store: TherapistStore = {
		badge,
		isOpen,
		therapistsByCurrentPatient,
		therapistSelected,
	};

	const updateStore: UpdateTherapistStore = {
		setBadge,
		setIsOpen,
		setTherapistSelected,
		enableDisabled,
	};

	return (
		<TherapistContext.Provider value={store}>
			<UpdateTherapistContext.Provider value={updateStore}>
				{children}
			</UpdateTherapistContext.Provider>
		</TherapistContext.Provider>
	);
};
