export default {
	es: {
		institution: {
			ADD_THERAPIST: 'Agregar terapeuta',
			BUTTON_ADD_THERAPIST: 'Agregar',
			BUTTON_ADDED_THERAPIST: 'Agregado',
			DELETE_THERAPIST: 'Eliminar terapeuta',
			TITLE_INSTITUTIONS: 'Instituciones',
			MODAL_BUTTON_CANCEL: 'Cancelar',
			MODAL_BUTTON_CONFIRM: 'Confirmar',
			MODAL_FOOTER: 'No pudes encontrarlo?',
			MODAL_INVITE_THERAPIST_TITLE: 'Invitar terapeuta',
			SEARCH: 'Buscar: ',
			THERAPIST: 'Terapeutas: ',
			WARNING_MEESSAGE: 'Advertencia',
			DELETE_THERAPIST_CONFIRMATION_MESSAGE: '¿Estás seguro de que quieres eliminar a este terapeuta?',
			MODAL_CONFIRM_BUTTON: 'Confirmar',
			MODAL_CANCEL_BUTTON: 'Cancelar',
		},
	},
	en: {
		institution: {
			ADD_THERAPIST: 'Add therapist',
			BUTTON_ADD_THERAPIST: 'Add',
			BUTTON_ADDED_THERAPIST: 'Added',
			DELETE_THERAPIST: 'Delete therapist',
			TITLE_INSTITUTIONS: 'Institutions',
			MODAL_BUTTON_CANCEL: 'Cancel',
			MODAL_BUTTON_CONFIRM: 'Confirm',
			MODAL_FOOTER: 'Could not you find him/her?',
			MODAL_INVITE_THERAPIST_TITLE: 'Invite therapist',
			SEARCH: 'Search: ',
			THERAPIST: 'Therapists: ',
			WARNING_MEESSAGE: 'Warning',
			DELETE_THERAPIST_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this therapist?',
			MODAL_CONFIRM_BUTTON: 'Confirm',
			MODAL_CANCEL_BUTTON: 'Cancel',
		},
	},
};
