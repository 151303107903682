export default {
	es: {
		job: {
			PSYCHOLOGIST: 'Psicólogo',
			PSYCHIATRIST: 'Psiquiatra',
			PHONOAUDIOLOGIST: 'Fonoaudiólogo',
		},
	},
	en: {
		job: {
			PSYCHOLOGIST: 'Psychologist',
			PSYCHIATRIST: 'Pychiatrist',
			PHONOAUDIOLOGIST: 'Phonoaudiologist',
		},
	},
};
