import { acceptTherapistRequest, answerPatientDocument, findAllTherapistsByPatient, findCurrentPatient, findPatientDocumentsByTherapist, getPreview, getReportPreview, getReportsByTherapist, readPatientDocument, rejectTherapistRequest, therapistRequest, updateAvatar, updatePatient, updateTherapistStatus } from '../api/PatientRepository';
import { convertFileToFormData, getCivilStatus, getCivilStatusObject, getCountries, getCountry, getGuardian, getSex, getSexObject } from './GenericService';
import { DocumentShared, StatusDocumentCard } from '../interfaces/Document';
import { Patient, PatientBasicInfo, PatientProfile, PatientProfileBasicInfo, PatientTherapistRequest } from '../interfaces/Patient';
import { Answer } from '../interfaces/DocumentForm';
import { findPatientResourcesByTherapist } from '../api/ResourceLibraryRepository';
import { Form } from '../interfaces/FormData';
import { formatDate } from '../helpers/Fomatting';
import { mapTherapistToTherapistCard } from './TherapistService';
import { Report } from '../interfaces/Report';
import { ResourceCard } from '../interfaces/Resource';
import { Therapist } from '../interfaces/Therapist';
import { TherapistCard } from '../interfaces/TherapistCard';

export const mapPatientToPersonalInfo = (patient: Patient): PatientProfile => {
	return ({
		id: patient.id,
		name: patient?.givenName,
		acceptedAt: patient.acceptedAt ?? null,
		enabled: patient.enabled ?? false,
		lastName: patient?.familyName,
		birthdate: patient?.birthday ? formatDate(patient.birthday) : '',
		country: getCountry(patient?.country?.name || ''),
		city: patient?.city || '',
		postalCode: patient?.zipCode || '',
		phone: patient?.phoneNumber || '',
		email: patient?.email || '',
		gender: patient?.sex ? getSex(patient.sex) : '',
		maritalStatus: patient?.civilStatus ? getCivilStatus(patient.civilStatus) : '',
		job: patient?.job || '',
		tutor: patient?.guardian ? getGuardian(patient.guardian) : '',
		avatar: patient?.avatarFile?.url || '',
	});
};

export const mapFormDataToPatient = async (formData: Form): Promise<Patient> => {
	const sexObject = getSexObject().find(sex => sex.name === formData.gender.value.toString());
	const civilStatusObject = getCivilStatusObject().find(civilStatus => civilStatus.name === formData.maritalStatus.value.toString());
	const countryObject = await getCountries().then(countries => {
		return countries.find(country => country.name === formData.country.value.toString().split('country:')[1]);
	});

	return {
		id: parseInt(formData.id.value.toString()),
		givenName: formData.name.value.toString(),
		familyName: formData.lastName.value.toString(),
		birthday: formData.birthdate.value.toString() !== '' ? formData.birthdate.value.toString() : undefined,
		country: countryObject,
		city: formData.city.value.toString(),
		zipCode: formData.postalCode.value.toString(),
		phoneNumber: formData.phone.value.toString(),
		email: formData.email.value.toString(),
		sex: sexObject ? sexObject.id : 0,
		civilStatus: civilStatusObject ? civilStatusObject.id : 0,
		job: formData.job.value.toString(),
		guardian: formData.tutor.value.toString() === 'personalInfo:GUARDIAN_YES',
	};
};

const mapFormDataToPatientBasicInfo = (formData: Form): PatientBasicInfo => {
	return {
		id: parseInt(formData.id.value.toString()),
		givenName: formData.name.value.toString(),
		familyName: formData.lastName.value.toString(),
	};
};

const mapPatientBasicInfoToPatientProfileBasicInfo = (patient: PatientBasicInfo): PatientProfileBasicInfo => {
	return {
		id: patient.id,
		name: patient.givenName,
		lastName: patient.familyName,
		avatar: patient.avatarFile?.url || '',
	};
};

const mapDocumentShared = (docInfo: DocumentShared): DocumentShared => {
	let docCardStatus: StatusDocumentCard = docInfo.hasBeenRead ? StatusDocumentCard.Read : StatusDocumentCard.Unread;

	if (docInfo?.userRole) {
		docCardStatus = StatusDocumentCard.IsReady;
	}

	return { ...docInfo, hasBeenRead: docCardStatus, id: docInfo.sharedDocumentId };
};

export const updatePatientBasicInfo = async (data: Form): Promise<void> => {
	return await updatePatient(mapFormDataToPatientBasicInfo(data));
};

export const getCurrentPatient = async (): Promise<PatientProfileBasicInfo> => {
	return mapPatientBasicInfoToPatientProfileBasicInfo(await findCurrentPatient());
};

export const getResourcesByTherapist = async (therapistId: number | string): Promise<Array<ResourceCard>> => {
	return await findPatientResourcesByTherapist(therapistId);
};

export const getPatientDocumentsByTherapist = async (id: number): Promise<Array<DocumentShared>> => {
	const patientDocuments = await findPatientDocumentsByTherapist(id);

	return patientDocuments.map((patiendDocument) => mapDocumentShared(patiendDocument));
};

export const updatePatientAvatar = async (files: File[]): Promise<void> => {
	const data = await convertFileToFormData(files[0]);

	return await updateAvatar(data);
};

export const getTherapistsByCurrentPatient = async (): Promise<Array<TherapistCard>> => {
	const therapists = await findAllTherapistsByPatient();

	return therapists.map((therapist: Therapist) => mapTherapistToTherapistCard(therapist));
};

export const answerDocumentByPatient = async (sharedDocumentId: number, answers: Answer[]): Promise<void> => {
	return await answerPatientDocument(sharedDocumentId, answers);
};

export const readDocumentByPatient = async (sharedDocumentId: number): Promise<void> => {
	return await readPatientDocument(sharedDocumentId);
};

export const getPreviewDocument = async (sharedDocumentId: number): Promise<Blob> => {
	return await getPreview(sharedDocumentId);
};

export const getPatientReportsByTherapist = async (therapistId: number | string): Promise<Report[]> => {
	return await getReportsByTherapist(therapistId).catch(() => []);
};

export const getReportPreviewPdf = async (reportId: number): Promise<Blob> => {
	return await getReportPreview(reportId);
};

export const getTherapistRequests = async (): Promise<PatientTherapistRequest[]> => {
	return await therapistRequest();
};

export const acceptRequest = async (therapistId: number): Promise<void> => {
	return await acceptTherapistRequest(therapistId);
};

export const rejectRequest = async (therapistId: number): Promise<void> => {
	return await rejectTherapistRequest(therapistId);
};

export const enableDisabledTherapist = async (therapistId: number): Promise<void> => {
	return await updateTherapistStatus(therapistId);
};
