import React from 'react';

interface UseFormStore {
	formValues: any;
	handleInputChange: (event: React.ChangeEvent<{ value: unknown; name: string }>) => void;
	handleCheckChange: (event: React.MouseEvent, value: string | string[], name: string) => void;
	handleInputDateChange: (date: Date | null) => void;
	reset: () => void;
	setInitValues: (values: any) => void;
	addFormValues: (values: Record<string, any>) => void;
	removeFormValues: (values: string[]) => void;
}

const useForm = (initialState = {}): UseFormStore => {
	const [formValues, setValues] = React.useState<any>(initialState);

	const reset = (): void => {
		setValues(initialState);
	};

	const setInitValues = (values: any): void => {
		setValues(values);
	};

	const handleInputChange = (event: React.ChangeEvent<{ value: unknown; name: string; checked?: boolean }>): void => {
		const { target } = event;

		setValues({ ...formValues, [target.name]: target.checked ? target.checked : target.value });
	};

	const handleCheckChange = (event: React.MouseEvent, value: string | string[], name: string): void => {
		event.preventDefault();

		setValues({ ...formValues, [name]: value });
	};

	const handleInputDateChange = (date: Date | null): void => {
		setValues({ ...formValues, date: date });
	};

	const addFormValues = (values: Record<string, any>): void => {
		setValues({ ...formValues, ...values });
	};

	const removeFormValues = (values: string[]): void => {
		const formValuesUpdated = { ...formValues };

		values.forEach(value => delete formValuesUpdated[value]);

		setValues({ ...formValuesUpdated });
	};

	return {
		formValues,
		handleInputChange,
		handleCheckChange,
		handleInputDateChange,
		reset,
		setInitValues,
		addFormValues,
		removeFormValues,
	};
};

export default useForm;
