import { getCurrentPatient, updatePatientAvatar, updatePatientBasicInfo } from '../../services/PatientService';
import { PatientProfile, PatientProfileBasicInfo } from '../../interfaces/Patient';
import { AlertContext } from '../../contexts/AlertContext';
import { AuthUpdateContext } from '../../contexts/AuthContext';
import { Form } from '../../interfaces/FormData';
import React from 'react';

interface LibraryStore {
	data: PatientProfileBasicInfo | PatientProfile;
	isLoading: boolean;
	open: boolean;
	getCurrentPatientInfo: () => Promise<void>;
	updatePatient: (formData: Form) => Promise<void>;
	handleOpen: () => void;
	handleUpdateAvatar: (e: any) => void;
}

const useProfile = (): LibraryStore => {
	const [data, setData] = React.useState<PatientProfileBasicInfo | PatientProfile >({} as PatientProfileBasicInfo | PatientProfile);
	const [open, setOpen] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { fetchUser } = React.useContext(AuthUpdateContext);
	const { showDefaultError } = React.useContext(AlertContext);

	const getCurrentPatientInfo = async (): Promise<void> => {
		setIsLoading(true);

		try {
			const user = await getCurrentPatient();

			setData(user);
		} catch (error) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		getCurrentPatientInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpen = (): void => {
		setOpen(prevState => !prevState);
	};

	const updatePatient = async (formData: Form): Promise<void> => {
		await updatePatientBasicInfo(formData);
	};

	const handleUpdateAvatar = async (e: any): Promise<void> => {
		setIsLoading(true);

		try {
			await updatePatientAvatar(e?.target?.files);
			await getCurrentPatientInfo();
			await fetchUser();
		} catch (e) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}

	};

	return ({
		data,
		isLoading,
		open,
		getCurrentPatientInfo,
		updatePatient,
		handleOpen,
		handleUpdateAvatar,
	});
};

export default useProfile;
