import { Button, Card, TextField } from '..';
import React, { EventHandler, SyntheticEvent } from 'react';
import { sendEmail } from '../../services/public/ContactService';
import { Snackbar } from '@material-ui/core';
import styles from './ContactForm.module.scss';
import { useTranslation } from 'react-i18next';

export interface ContactData {
	name: string;
	email: string;
	phone: string;
	message: string;
}

const ContactForm: React.FC = () => {
	const { t } = useTranslation();
	const [name, setName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [phone, setPhone] = React.useState<string>('');
	const [message, setMessage] = React.useState<string>('');
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [isSending, setIsSending] = React.useState(false);

	const showSnackbarMessage = (): void => {
		setOpenSnackbar(true);
		setTimeout(() => {
			setOpenSnackbar(false);
		}, 3000);
	};

	const handleSubmit: EventHandler<SyntheticEvent> = async (e) => {
		e.preventDefault();

		try {
			setIsSending(true);
			await sendEmail(name, email, phone, message);
			setSnackbarMessage(t('contact:FORM_SENT'));
		} catch (error) {
			setSnackbarMessage(t('contact:FORM_NOT_SENT'));
		} finally {
			setIsSending(false);
		}

		showSnackbarMessage();
	};

	const onChangeName = (value: string): void => {
		setName(value);
	};

	const onChangeEmail = (value: string): void => {
		setEmail(value);
	};

	const onChangePhone = (value: string): void => {
		setPhone(value);
	};

	const onChangeMessage = (value: string): void => {
		setMessage(value);
	};

	const isEmailValid = (email: string): boolean => {
		const validEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

		return validEmail.test(email);
	};

	return (
		<Card className={styles.form}>
			<div className={styles.formLayout}>
				<h4 className={styles.formTitle}>{t('contact:CONTACT_FORM_TITLE')}</h4>
				<h5 className={styles.formSubtitle}>{t('contact:CONTACT_FORM_SUBTITLE')}</h5>
				<div className={styles.formComponentWrapper}>
					<TextField
						placeholder={`${t('contact:CONTACT_FORM_NAME')} (*)`}
						onChange={onChangeName}
						value={name}
					/>
				</div>
				<div className={styles.formComponentWrapper}>
					<TextField
						placeholder={`${t('contact:CONTACT_FORM_EMAIL')} (*)`}
						onChange={onChangeEmail}
						value={email}
					/>
				</div>
				<div className={styles.formComponentWrapper}>
					<TextField
						placeholder={`${t('contact:CONTACT_FORM_PHONE')}`}
						onChange={onChangePhone}
						value={phone}
					/>
				</div>
				<div className={styles.formComponentWrapper}>
					<TextField
						placeholder={`${t('contact:CONTACT_FORM_MESSAGE')} (*)`}
						onChange={onChangeMessage}
						value={message}
						multiline={true}
					/>
				</div>
				<div className={styles.formComponentWrapper}>
					<Button
						title={t('contact:CONTACT_FORM_SEND_BUTTON')}
						onClick={handleSubmit}
						type={'PRIMARY'}
						fullWidth
						size="large"
						isDisabled={!name || !email || !isEmailValid(email) || !message || isSending}
					/>
				</div>
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={openSnackbar}
				autoHideDuration={6000}
				message={snackbarMessage}
			/>
		</Card>
	);
};

export default ContactForm;
