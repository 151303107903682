import React, { ReactNode } from 'react';

export interface TherapistModalStore {
	isOpen: boolean;
}

export interface UpdateTherapistModalStore {
	setIsOpen: (isOpen: boolean) => void;
}

export const TherapistModalContext = React.createContext({} as TherapistModalStore);
export const UpdateTherapistModalContext = React.createContext({} as UpdateTherapistModalStore);

export const TherapistModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const store: TherapistModalStore = {
		isOpen,
	};

	const updateStore: UpdateTherapistModalStore = {
		setIsOpen,
	};

	return (
		<TherapistModalContext.Provider value={store}>
			<UpdateTherapistModalContext.Provider value={updateStore}>
				{children}
			</UpdateTherapistModalContext.Provider>
		</TherapistModalContext.Provider>
	);
};
