import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditOutlined from '@material-ui/icons/EditOutlined';
import { formatDate } from '../../../../helpers/Fomatting';
import { Note } from '../../../../interfaces/Note';
import React from 'react';
import styles from './NoteDetail.module.scss';
import { useTranslation } from 'react-i18next';

interface NotesCardProps {
	note: Note;
	onEditNote?: () => void;
}

const NoteDetail: React.FC<NotesCardProps> = ({ note, onEditNote }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.noteDetail}>
			<Card className={styles.card} variant="outlined">
				<CardContent className={styles.content}>
					<div className={styles.titleContainer}>
						<div className={styles.title}>{formatDate(note.title)}</div>
						{
							onEditNote &&
							<Button
								color="primary"
								className={styles.editButton}
								startIcon={<EditOutlined />}
								onClick={onEditNote}
							>
								{t('profileView:EDIT_BUTTON')}
							</Button>
						}
					</div>
					<div className={styles.description}>
						{note.description}
					</div>
				</CardContent>
			</Card>
		</div >
	);
};

export default NoteDetail;
