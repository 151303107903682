interface UsePDFPreview {
	options: object;
}

interface UsePDFPreviewProps {
	file: string | Blob;
}

const usePDFPreviewModal = (props: UsePDFPreviewProps): UsePDFPreview => {

	const options = props.file instanceof Blob ? {
		cMapUrl: 'cmaps/',
		cMapPacked: true,
	} : {};

	return {
		options,
	};
};

export default usePDFPreviewModal;
