import { AuthContext, AuthUpdateContext } from '../../contexts/AuthContext';
import { setAuthToken, setRefreshToken } from '../../services/SessionStorageService';
import React from 'react';
import { signInWithPassword } from '../../services/AuthService';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

interface SignInStore {
	handleSignInClick: (email: string | undefined, password: string | undefined) => void;
	isAuth: boolean;
	rememberMe: boolean;
	setRememberMe: (value: boolean) => void;
	signInError: string | undefined;
}

export const useSignIn = (): SignInStore => {
	const { t } = useTranslation();
	const query = useQueryParams();

	const { setItem, isKeyStored, unsetItem } = useLocalStorage('email');
	const [rememberMe, setRememberMe] = React.useState<boolean>(isKeyStored());
	const [signInError, setSignInError] = React.useState<string | undefined>(undefined);
	const { setIsAuth } = React.useContext(AuthUpdateContext);
	const { isAuth } = React.useContext(AuthContext);

	const handleUserError = (error: string): string => {
		switch (error) {
			case 'User not found.':
				return t('view:SIGNIN_USER_NOT_FOUND');
			case 'The user exists already.':
				return t('view:SIGNIN_USER_ALREADY_EXISTS');
			case 'API url not defined':
				return 'API NOT DEFINED / API NO DEFINIDA';
			default:
				return error || 'UNKNOWN ERROR / ERROR DESCONOCIDO';
		}
	};

	const handleSignInClick = async (email: string | undefined, password: string | undefined): Promise<void> => {
		if (rememberMe) {
			setItem(email || '');
		} else {
			unsetItem();
		}

		if (email && password) {
			try {
				const code = typeof query.code == 'string' ? query.code : null;

				const authenticateResult = await signInWithPassword({ username: email, password }, code);

				setAuthToken(authenticateResult.access_token);
				setRefreshToken(authenticateResult.refresh_token);
				setIsAuth(true);
			} catch (error) {
				setSignInError(handleUserError(error.message));
			}
		}
	};

	return {
		handleSignInClick,
		isAuth,
		rememberMe,
		setRememberMe,
		signInError,
	};
};
