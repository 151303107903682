import { Button as CustomButtom, DateCards, DocumentCards, NewSessionModal, PrivateTopBar, ResourceCards, SectionTitle, TherapistChangeCard, UpdateTherapistModal } from '../../../components';
import Button from '@material-ui/core/Button';
import FolderOutlined from '@material-ui/icons/FolderOutlined';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styles from './Home.module.scss';
import useHome from './useHome';
import { useTranslation } from 'react-i18next';
import { usePatient } from '../hooks/usePatient';

const Home = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		documents,
		open,
		openUpdateTherapist,
		resources,
		sessions,
		therapistSelectedId,
		fetchData,
		handleAcceptSession,
		handleClickDocument,
		handleClickResource,
		handleDeclineSession,
		handleOpenNewSession,
		handleOpenUpdateTherapist,
	} = useHome();

	const { updateTherapistStatus } = usePatient();

	const seeAllButton = (
		<Button
			className={styles.seeAllButton}
			startIcon={<FolderOutlined />}
		>
			{t('patientHomeView:BUTTON_SEE_ALL')}
		</Button>
	);

	return (
		<div className={styles.home}>
			<PrivateTopBar title={t('patientHomeView:TITLE_HOME')} />
			<div className={styles.container}>
				<Grid container spacing={3}>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={12} sm={6} md={4}>
							<TherapistChangeCard onChange={handleOpenUpdateTherapist} updateStatus={updateTherapistStatus} />
						</Grid>
						{
							therapistSelectedId &&
							<Grid item xs={12} sm={6} md={8} className={styles.requestButton}>
								<CustomButtom
									title={t('patientHomeView:BUTTON_REQUEST_NEW_SESION')}
									onClick={handleOpenNewSession}
								/>
							</Grid>
						}
					</Grid>
					{
						therapistSelectedId &&
						<>
							<Grid item xs={12} md={4} className={styles.section}>
								<SectionTitle title="patientHomeView:NEXT_SESIONS" />
								<DateCards
									data={sessions}
									showMonthYearSelector
									onDecline={handleDeclineSession}
									onAccept={handleAcceptSession}
									fetchData={fetchData}
								/>
							</Grid>
							<Grid item xs={12} md={8} className={styles.section}>
								<SectionTitle title="patientHomeView:LASTEST_RESOURCES_SHARED_DOCUMENTS" rigthButton={seeAllButton}/>
								<Grid container spacing={3}>
									<Grid item xs={12} md={6}>
										<SectionTitle title="patientHomeView:RESOURCES" showDivider={false} />
										<ResourceCards
											data={resources}
											onClick={handleClickResource}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<SectionTitle title="patientHomeView:FORMS" showDivider={false}/>
										<DocumentCards
											data={documents}
											onClick={handleClickDocument}
										/>
									</Grid>
								</Grid>
							</Grid>
						</>}
				</Grid>
			</div>
			{
				open &&
				<NewSessionModal
					open={open}
					onConfirm={(): Promise<void> => fetchData(new Date())}
					onChange={handleOpenNewSession}
				/>
			}
			{
				openUpdateTherapist &&
				<UpdateTherapistModal
					open={openUpdateTherapist}
					onChange={handleOpenUpdateTherapist}
				/>
			}
		</div>
	);
};

export default Home;
