interface ReportConfirmationModalStore {
	handleClose: () => void;
	handleConfirm: () => void;
}

interface ReportConfirmationModalProps {
	onChange: () => void;
	onConfirm: () => void;
}

const useReportConfirmationModal = (props: ReportConfirmationModalProps): ReportConfirmationModalStore => {
	const handleClose = (): void => {
		props.onChange();
	};

	const handleConfirm = async (): Promise<void> => {
		props.onConfirm();
		handleClose();
	};

	return {
		handleClose,
		handleConfirm,
	};
};

export default useReportConfirmationModal;
