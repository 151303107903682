import { DefaultInput } from '..';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import useNumberIntegerPreview from './useNumberIntegerPreview';

const NumberIntegerPreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	} = useNumberIntegerPreview(props);
	const { min, max, value, isValid } = question;

	return (
		<>
			<DefaultInput
				placeholder={`${min} - ${max}`}
				value={value}
				onChange={handleOnChange}
				isError={showErrors && !isValid}
				errorMessage={showErrors && !isValid ? errorMessage : ''}
			/>
		</>
	);
};

export default NumberIntegerPreview;
