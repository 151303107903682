export default {
	es: {
		plans: {
			PLAN_TITLE: 'Empiece su prueba gratis de 30 días',
			WAY_TO_PAY: 'Elija un método de pago',
			WAY_TO_PAY_DESCRIPTION: 'Cualquiera sea el método de pago que elija, se empezará a cobrar recién en 30 días. Puede cancelar en cualquier momento.',

		},
	},
	en: {
		plans: {
			PLAN_TITLE: 'Start your 30-day free trial',
			WAY_TO_PAY: 'Choose a payment method',
			WAY_TO_PAY_DESCRIPTION: 'Whichever payment method you choose, you will only start charging in 30 days. You can cancel at any time.',
		},
	},
};
