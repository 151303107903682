import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const markTaskAsDeleted = (taskId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/reminders/${taskId}/mark-as-deleted`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const markTasksAsRead = (taskIds: number[]): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/reminders/mark-as-read`, {
			reminderIds: taskIds,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const markTaskAsStarred = (taskId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/reminders/${taskId}/mark-as-starred`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
