export enum ConfirmationTypes {
	cancelInstitutionInvitation = 'cancelInstitutionInvitation',
	deleteInstitution = 'deleteInstitution',
	cancelAssistantInvitation = 'cancelAssistantInvitation',
	deleteAssistant = 'deleteAssistant',
	cancelTherapistInvitation = 'cancelTherapistInvitation',
	deleteTherapist = 'deleteTherapist',
}

export interface ConfirmationModalInterface {
	content: string;
	title: string;
	onClose: () => void;
	onConfirm: () => Promise<void>;
}
