import { addNewNote, deleteNote, findAllNotesByPatient, findNoteByPatient, updateNote } from '../api/NoteRepository';
import { Note, TherapistNote } from '../interfaces/Note';

const mapTherapistNoteToNote = (note: TherapistNote): Note => {
	return ({
		id: note.id,
		title: note.date,
		description: note.text,
		checked: false,
		hadSession: !!note.hadSession,
	});
};

const mapNoteToTherapistNote = (note: Note): TherapistNote => {
	return ({
		id: note.id,
		date: note.title,
		text: note.description,
	});
};

export const getNotesByPatient = async (therapistId: number | string, patientId: number): Promise<Array<Note>> => {
	return (await findAllNotesByPatient(therapistId, patientId)).rows.map(therapistNote => mapTherapistNoteToNote(therapistNote));
};

export const getNoteByPatient = async (therapistId: number | string, noteId: number): Promise<Note> => {
	return mapTherapistNoteToNote(await findNoteByPatient(therapistId, noteId));
};

export const addNote = async (therapistId: number | string, patientId: number, data: Note): Promise<void> => {
	return await addNewNote(therapistId, patientId, mapNoteToTherapistNote(data));
};

export const editNote = async (therapistId: number | string, noteId: number, data: Note): Promise<void> => {
	return await updateNote(therapistId, noteId, mapNoteToTherapistNote(data));
};

export const removeNote = async (therapistId: number | string, noteId: number): Promise<void> => {
	return await deleteNote(therapistId, noteId);
};
