import { DocumentCard, StatusDocumentCard } from '../../../interfaces/Document';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';
import styles from './DocumentCards.module.scss';
import TurnedInOutlinedIcon from '@material-ui/icons/TurnedInOutlined';
import useDocumentCards from './useDocumentCards';
import { useTranslation } from 'react-i18next';

interface DocumentCardsProps {
	data: Array<DocumentCard>;
	activeCard?: number;
	onClick?: (id: number) => void;
}

const DocumentCards: React.FC<DocumentCardsProps> = ({ data, activeCard, onClick }) => {
	const { t } = useTranslation();
	const { handleClick } = useDocumentCards({ onClick });

	const toCompleteIcon = (
		<Avatar className={`${styles.secondaryIcon} ${styles.secondaryIconComplete}`}>
			<ArrowForwardOutlinedIcon className={styles.secondaryIconCompleteArrow} />
		</Avatar>);

	const checkIcon = (
		<CheckCircleIcon className={`${styles.secondaryIcon} ${styles.secondaryIconCheck}`} />
	);

	return <>
		{
			data.map(item => {
				const cardId = item.id;
				const isSelected = activeCard === cardId;
				const isReady = item.hasBeenRead === StatusDocumentCard.IsReady;
				const cardClasses = { root: `${styles.resourceCard} ${isSelected && styles.active}` };
				const secondaryIcon = isReady ?	checkIcon : toCompleteIcon;
				const statusCard = t(`document:STATUS_${isReady ?	'READY' : 'TO_COMPLETE'}`);

				return (
					<Card key={cardId} classes={cardClasses} variant="outlined" onClick={(): void => handleClick(cardId)}>
						<CardActionArea classes={{root: styles.resourceCardArea}}	onClick={(): void => {/* Do nothing */} }>
							<CardContent className={styles.resourceCardContent}>
								<div className={styles.resourceCardContentItems}>
									{item.hasBeenRead === StatusDocumentCard.Unread && <TurnedInOutlinedIcon className={styles.bookmarkIcon} />}
									<InsertDriveFileOutlinedIcon className={styles.primaryIcon} />
									<span className={styles.primaryText}>{item.name}</span>
								</div>
								<div className={styles.resourceCardContentItems}>
									<span className={`${styles.secondaryText} ${isReady ? styles.secondaryTextComplete : styles.secondaryTextCheck}`}>
										{statusCard}
									</span>
									{secondaryIcon}
								</div>
							</CardContent>
						</CardActionArea>
					</Card>);
			})
		}
	</>;
};

export default DocumentCards;
