import { Checkbox, ModalBottomBar, ModalTab } from '../../index';
import { Frequency, Session, SessionType } from '../../../interfaces/Session';
import { TherapistModalContext, UpdateTherapistModalContext } from '../../../contexts/TherapistModalContext';
import { DatePicker } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import Modal from '../../Modal/Modal';
import NewSessionTypeModal from '../../../components/Modals/NewSessionTypeModal/NewSessionTypeModal';
import { Option } from '../../../interfaces/Common';
import { Patient } from '../../../interfaces/Patient';
import React from 'react';
import ReactLoading from 'react-loading';
import { SessionAvailability } from '../../../interfaces/SessionAvailability';
import SessionPicker from '../../SessionPicker/SessionPicker';
import SessionsAvailabilityModal from '../../../components/Modals/SessionsAvailabilityModal/SessionsAvailabilityModal';
import styles from './NewSessionModalTherapist.module.scss';
import UpdatePatientModal from '../UpdatePatientModal/UpdatePatientModal';
import useNewSessionModalTherapist from './useNewSessionModalTherapist';
import { useTranslation } from 'react-i18next';

export type ActionType = 'Add' | 'Update';
interface NewSessionModalTherapistProps {
	open: boolean;
	action: ActionType;
	session?: Session;
	isLoading: boolean;
	patients: Patient[];
	sessionsTypes: SessionType[];
	therapistAvailability: SessionAvailability;
	fetchData: () => Promise<void>;
	onChange: () => void;
}

const NewSessionModalTherapist: React.FC<NewSessionModalTherapistProps> = (props: NewSessionModalTherapistProps) => {
	const { t } = useTranslation();
	const { open, onChange, action, session, patients, sessionsTypes, therapistAvailability, fetchData, isLoading } = props;
	const [isOpenPatientModal, setIsOpenPatientModal] = React.useState<boolean>(false);
	const [isOpenNewSessionTypeModal, setIsOpenNewSessionTypeModal] = React.useState<boolean>(false);
	const [isOpenSessionsAvailabilityModal, setIsOpenSessionsAvailabilityModal] = React.useState<boolean>(false);
	const { setIsOpen } = React.useContext(UpdateTherapistModalContext);
	const { isOpen } = React.useContext(TherapistModalContext);

	const {
		formValues,
		frequencies,
		isValidForm,
		sessionSelected,
		handleInputChange,
		handleCloseNewSession,
		handleConfirmNewSession,
		handleUpdateSession,
		handleSessionChange,
		handleEndDate,
		handleCheckbox,
	} = useNewSessionModalTherapist({ onChange, session, patients, sessionsTypes, therapistAvailability, action });

	return (
		<Modal
			open={open}
			size="md"
			title={t('patientHomeView:MODAL_NEW_SESSION')}
			handleOnClose={handleCloseNewSession}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CONFIRM'),
				onClick: (): void => action === 'Add' ? handleConfirmNewSession() : handleUpdateSession(),
				isDisabled: !isValidForm,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CANCEL'),
				onClick: handleCloseNewSession,
			}}
			isTertiaryButtonVisible
			tertiaryButtonProps={{
				title: t('agendaView:MODAL_BUTTON_AVAILABILITY'),
				onClick: (): void => setIsOpenSessionsAvailabilityModal((prevState) => !prevState),
			}}
			scroll="body"
		>
			{!isLoading ?
				<div className={styles.sessionModalTherapist}>
					<div className={styles.sectionContainer}>
						<ModalTab
							tabName={t('agendaView:ADD_PATIENT_TAB')}
							name={'patient'}
							placeholder={t('agendaView:SELECT_PATIENT_TAB')}
							selectOptions={patients.map(
								(patient: Patient): Option => {
									return {
										key: patient.id,
										value: `${patient.givenName} ${patient.familyName}`,
									};
								}
							)}
							buttonTitle={t('agendaView:ADD_PATIENT_BUTTON')}
							tertiaryButton={{
								show: true,
								onClick: (): void => setIsOpenPatientModal(!isOpenPatientModal),
							}}
							handleInputChange={handleInputChange}
							optionSelected={formValues.patient}
						/>
						<ModalTab
							tabName={t('agendaView:SESSION_TYPE_TAB')}
							name={'sessionType'}
							placeholder={t('agendaView:SELECT_SESSION_TAB')}
							selectOptions={sessionsTypes.map(
								(sessionType: SessionType): Option => {
									return {
										key: sessionType.id,
										value: sessionType.name,
									};
								}
							)}
							buttonTitle={t('agendaView:ADD_SESSION_TYPE_BUTTON')}
							tertiaryButton={{
								show: true,
								onClick: (): void => setIsOpenNewSessionTypeModal(!isOpenNewSessionTypeModal),
							}}
							handleInputChange={handleInputChange}
							optionSelected={formValues.sessionType}
						/>
						<ModalTab
							tabName={t('agendaView:FREQUENCY_TAB')}
							name={'frequency'}
							placeholder={t('agendaView:SELECT_FREQUENCY_TAB')}
							selectOptions={frequencies.map(frequency => ({ key: frequency.key, value: t(`agendaView:${frequency.value}`) }))}
							buttonTitle={t('availabilityView:TITLE')}
							tertiaryButton={{
								show: true,
								onClick: (): void => setIsOpen(!isOpen),
							}}
							handleInputChange={handleInputChange}
							optionSelected={formValues.frequency}
						/>
					</div>
					{
						formValues.frequency != Frequency.ONE_TIME &&
						<Grid container spacing={1}>
							<Grid item xs={2}>
								<Checkbox
									label={t('agendaView:ADD_END_DATE')}
									checked={formValues.isEndDateMandatory}
									onChange={(): void => handleCheckbox(!formValues.isEndDateMandatory)}
								/>
							</Grid>
							<Grid item xs={4}>
								{
									formValues.isEndDateMandatory &&
									<DatePicker
										style={{ width: '100%' }}
										inputVariant="outlined"
										disablePast
										margin="dense"
										id="date-picker-dialog"
										value={formValues.endDate}
										onChange={(date): void => handleEndDate(date)}
										label={t('agendaView:SELECT_END_DATE')}
									/>
								}
							</Grid>
						</Grid>
					}

					<hr style={{ borderTop: '1px' }} />

					<SessionPicker
						sessionSelected={sessionSelected}
						endDate={formValues.frequency != Frequency.ONE_TIME && formValues.isEndDateMandatory ? formValues.endDate : null}
						frequency={formValues.frequency}
						onSessionChange={handleSessionChange}
					/>

					<hr style={{ borderTop: '1px' }} />

					<div className={styles.bottomContainer}>
						<ModalBottomBar
							iconName={t('agendaView:TELEHEALTH')}
							isChecked={formValues['telehealth']}
							name={'telehealth'}
							primaryOption={'No'}
							secondaryOption={'Yes'}
							handleInputChange={handleInputChange}
						/>
					</div>
				</div>
				:
				<ReactLoading type="spin" color="#4ebed7" height={'20%'} width={'20%'} />
			}
			<SessionsAvailabilityModal
				open={isOpenSessionsAvailabilityModal}
				onChange={(): void => setIsOpenSessionsAvailabilityModal((value: boolean) => !value)}
			/>
			<UpdatePatientModal
				open={isOpenPatientModal}
				onChange={(): void => setIsOpenPatientModal((value: boolean) => !value)}
				actionType={'ADD'}
				fetchData={fetchData}
			/>
			<NewSessionTypeModal
				open={isOpenNewSessionTypeModal}
				onChange={(): void => setIsOpenNewSessionTypeModal((value: boolean) => !value)}
				fetchData={fetchData}
			/>
		</Modal>
	);
};

export default NewSessionModalTherapist;
