import {
	addSession,
	updateSession
} from '../../../services/SessionService';
import { Frequency, Session, SessionType } from './../../../interfaces/Session';
import { ActionType } from './NewSessionModalTherapist';
import { AlertContext } from '../../../contexts/AlertContext';
import { DateCard } from '../../../interfaces/DateCard';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { Option } from '../../../interfaces/Common';
import { Patient } from './../../../interfaces/Patient';
import React from 'react';
import { SessionAvailability } from '../../../interfaces/SessionAvailability';
import useForm from '../../../hooks/useForm';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface NewSessionModalProps {
	action: ActionType;
	session?: Session;
	patients: Patient[];
	sessionsTypes: SessionType[];
	therapistAvailability: SessionAvailability;
	onChange: () => void;
}

interface NewSessionModalStore {
	formValues: FormData;
	frequencies: Option[];
	isValidForm: boolean;
	sessionSelected: DateCard | null;
	handleCloseNewSession: () => void;
	handleInputChange: (event: React.ChangeEvent<{ value: unknown; name: string }>) => void;
	handleConfirmNewSession: () => void;
	handleUpdateSession: () => void;
	handleSessionChange: (sessionCard: DateCard) => void;
	handleEndDate: (date: MaterialUiPickersDate) => void;
	handleCheckbox: (value: boolean) => void;
}

interface FormData {
	id?: number;
	patient: string;
	sessionType: string;
	frequency: Frequency;
	date: Date;
	telehealth: boolean;
	availability: DateCard | null;
	endDate: Date | null;
	isEndDateMandatory: boolean;
}

const initialFormValues: FormData = {
	patient: '',
	sessionType: '',
	frequency: Frequency.ONE_TIME,
	date: new Date(),
	telehealth: false,
	availability: null,
	endDate: null,
	isEndDateMandatory: false,
};

const useNewSessionModal = (props: NewSessionModalProps): NewSessionModalStore => {
	const [isValidForm, setIsValidForm] = React.useState<boolean>(false);
	const [sessionSelected, setSessionSelected] = React.useState<DateCard | null>(null);
	const { showDefaultError } = React.useContext(AlertContext);
	const { getTherapistIdParam } = useTherapistSelected();

	initialFormValues.frequency = Frequency.ONE_TIME;
	const { addFormValues, formValues, handleInputChange, reset } = useForm(initialFormValues);
	const { action, sessionsTypes, session, onChange } = props;

	const fetchData = async (): Promise<void> => {
		if (action === 'Update' && session) {
			const [startDateYear, startDateMonth, startDateDay] = session.startDate.split('-');
			const startDate = new Date(parseInt(startDateYear), parseInt(startDateMonth) - 1, parseInt(startDateDay));
			const [startTimeHour, startTimeMinute] = session.startTime.split(':');
			const [endDateYear, endDateMonth, endDateDay] = session.endDate?.split('-') ?? [];
			const endDate = new Date(parseInt(endDateYear), parseInt(endDateMonth) - 1, parseInt(endDateDay));
			const [endTimeHour, endTimeMinute] = session.endTime.split(':');

			startDate.setHours(parseInt(startTimeHour), parseInt(startTimeMinute));
			endDate.setHours(parseInt(endTimeHour), parseInt(endTimeMinute));

			setSessionSelected({
				id: startDate.getTime(),
				startTime: startDate,
				endTime: endDate,
				therapistOffice: session.therapistOffice.id ? {
					id: session.therapistOffice.id,
					address: session.therapistOffice.address ?? '',
				} : undefined,
			});

			addFormValues({
				availability: {
					startTime: startDate,
					endTime: endDate,
					therapistOffice: {
						id: session.therapistOffice.id,
						address: session.therapistOffice.address,
					},
				},
				frequency: session.frequency.toString() ?? Frequency.ONE_TIME,
				telehealth: session.telehealth,
				patient: session.patient.id,
				sessionType: sessionsTypes.find(sessionType => sessionType.id === session.therapistSessionType?.id)?.name ?? '',
				endDate: session.endDate ? new Date(session.endDate) : null,
				isEndDateMandatory: Boolean(session.endDate),
			});
		} else {
			addFormValues({
				...initialFormValues,
				frequency: Frequency.ONE_TIME,
			});
		}
	};

	React.useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	React.useEffect(() => {
		const mandatoryProperties = ['availability', 'patient', 'frequency'];
		const isValid = mandatoryProperties.every(property => {
			return Object.entries(formValues).some(([key, value]) => property === key && value);
		});

		setIsValidForm(isValid);
	}, [formValues]);

	const handleCloseNewSession = (): void => {
		onChange();
		reset();
		setSessionSelected(null);
	};

	const convertFormData = (): Session => {
		const values: FormData = formValues as FormData;

		const { availability, patient, frequency, sessionType } = values;
		const patientId = +patient;
		const sessionTypeId = +sessionType;
		const frequencyId = +frequency;
		const startTime = moment(availability?.startTime || '', 'HH:mm');
		const endTime = moment(availability?.endTime, 'HH:mm');
		const startDate = new Date(availability?.startTime || '');
		const endDate = new Date(availability?.endTime || '');

		startDate.setHours(startTime.hour(), startTime.minute());
		endDate.setHours(endTime.hour(), endTime.minute());

		return {
			id: session?.id,
			endDate: frequencyId !== 0 && values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null,
			endTime: moment(endDate).format('HH:mm'),
			frequency: frequencyId,
			patient: {
				id: patientId,
			},
			therapistOffice: {
				id: availability?.therapistOffice?.id || 0,
			},
			startDate: moment(startDate).format('YYYY-MM-DD'),
			startTime: moment(startDate).format('HH:mm'),
			therapistSessionType: sessionTypeId
				? {
					id: sessionTypeId,
				} : undefined,
			telehealth: Boolean(values.telehealth),
		};
	};

	const handleConfirmNewSession = async (): Promise<void> => {
		try {
			const data = convertFormData();
			const therapistIdParam = getTherapistIdParam();

			await addSession(therapistIdParam, data);
		} catch (error) {
			showDefaultError();
		} finally {
			handleCloseNewSession();
		}
	};

	const handleUpdateSession = async (): Promise<void> => {
		try {
			const data = convertFormData();
			const therapistIdParam = getTherapistIdParam();

			await updateSession(therapistIdParam, data);
		} catch (error) {
			showDefaultError();
		} finally {
			handleCloseNewSession();
		}
	};

	const handleSessionChange = (sessionCard: DateCard): void => {
		setSessionSelected(sessionCard);
		addFormValues({ availability: sessionCard });
	};

	const handleEndDate = (date: MaterialUiPickersDate): void => {
		addFormValues({ endDate: date });
	};

	const handleCheckbox = (value: boolean): void => {
		addFormValues({ isEndDateMandatory: value });
	};

	return {
		formValues,
		frequencies: Object.entries(Frequency).map(([key, value]) => ({ key: value, value: key })),
		isValidForm,
		sessionSelected,
		handleInputChange,
		handleCloseNewSession,
		handleConfirmNewSession,
		handleUpdateSession,
		handleSessionChange,
		handleEndDate,
		handleCheckbox,
	};
};

export default useNewSessionModal;
