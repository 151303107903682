import { Card } from '../../components';
import check from '../../assets/images/func-check.png';
import React from 'react';
import security from '../../assets/images/icon-security.png';
import styles from './SecuritySection.module.scss';
import { useTranslation } from 'react-i18next';

const SecuritySection: React.FC = () => {
	const { t } = useTranslation();
	const infraCards = [
		t('functions:CARD_INFRA_ENCRYPTION'),
		t('functions:CARD_INFRA_EQUIPMENT'),
		t('functions:CARD_INFRA_CONNECTION'),
		t('functions:CARD_INFRA_COPY_SEC'),
		t('functions:CARD_INFRA_TECHNIC'),
	];

	return (
		<div className={styles.functionSecurity}>
			<div className={styles.functionSecurityDescription}>
				<h2 className={styles.title}>{t('functions:FUNCTION_SECURITY')}</h2>
				<p className={styles.description}>{t('functions:FUNCTION_SECURITY_DESCRIPTION')}</p>
			</div>

			<div className={styles.functionSecurityCard}>
				<Card>
					<div className={styles.cardContainer}>
						<img src={security} alt="" className={styles.cardimage} />
						<h2 className={styles.cardTitle}>{t('functions:FUNCTION_INFRA')}</h2>
					</div>
					<div className={styles.internal} />
					{
						infraCards.map(title =>
							<div key={title}>
								<div className={styles.functionInfraCard}>
									<img src={check} alt="" className={styles.cardimage} />
									<p className={styles.cardTitle}>{title}</p>
								</div>
								<div className={styles.internal} />
							</div>
						)
					}
				</Card>
			</div>
		</div>

	);
};

export default SecuritySection;
