import { Button, PrivateTopBar, TherapistButtons } from '../../../../components';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Documentation from './Documentation/Documentation';
import Info from './Info/Info';
import React from 'react';
import Reports from './Reports/Reports';
import Resources from './Resources/Resources';
import styles from './Patient.module.scss';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import usePatient from './usePatient';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

enum TabsEnum {
	INFO = 0,
	DOCUMENTATION = 1,
	RESOURCES = 2,
	REPORTS = 3,
}

const Patient = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		therapistPatient,
		selectedTab,
		searchValue,
		handleChangeTabs,
		handleCreateReport,
		handleChangeSearchValue,
		getTherapistPatientInfo,
		handleResendInvitation,
	} = usePatient();

	const TabPanel = (props: TabPanelProps): JSX.Element => {
		const { value, index, children, ...other } = props;

		return (
			<div className={styles.tabPanel} role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
				{value === index && (
					<>{children}</>
				)}
			</div>
		);
	};

	return (
		<div className={styles.patient}>
			<PrivateTopBar
				title={`${therapistPatient.name} ${therapistPatient.lastName}`}
				buttons={
					<TherapistButtons
						onChangeSearchInput={handleChangeSearchValue}
						searchValue={searchValue}
						showSearchInput={Boolean(selectedTab !== TabsEnum.INFO)}
					/>
				}
			/>
			<div className={styles.container}>
				<AppBar className={styles.appBar} position="static" variant="outlined">
					<Tabs
						classes={{ root: styles.tabs, indicator: styles.tabsIndicator }}
						value={selectedTab}
						onChange={handleChangeTabs}
						aria-label="simple tabs example"
						TabIndicatorProps={{ children: <span /> }}
					>
						<Tab label={t('patientsView:PATIENT_TAB_INFO')} className={styles.tab} id="simple-tab-0" aria-controls="simple-tabpanel-0" />
						<Tab label={t('patientsView:PATIENT_TAB_DOCUMENTATION')} className={styles.tab} id="simple-tab-1" aria-controls="simple-tabpanel-1" />
						<Tab label={t('patientsView:PATIENT_TAB_RESOURCES')} className={styles.tab} id="simple-tab-2" aria-controls="simple-tabpanel-2" />
						<Tab label={t('patientsView:PATIENT_TAB_REPORTS')} className={styles.tab} id="simple-tab-3" aria-controls="simple-tabpanel-3" />
						{
							selectedTab === TabsEnum.REPORTS &&
							<Box display="flex" flexGrow={1} justifyContent="flex-end" >
								<Button title={t('patientsView:REPORTS_CREATE_BUTTON')} onClick={handleCreateReport} isDisabled={!therapistPatient.enabled} />
							</Box>
						}
						{
							selectedTab === TabsEnum.INFO &&
							<Box display="flex" flexGrow={1} justifyContent="flex-end" >
								<Button title={t('patientsView:RESEND_TOOLTIP')} onClick={(): Promise<void> => handleResendInvitation()} isDisabled={!!therapistPatient.acceptedAt} />
							</Box>
						}
					</Tabs>
				</AppBar>
				<TabPanel value={selectedTab} index={TabsEnum.INFO}>
					<Info user={therapistPatient} getTherapistPatientInfo={getTherapistPatientInfo} />
				</TabPanel>
				<TabPanel value={selectedTab} index={TabsEnum.DOCUMENTATION}>
					<Documentation
						searchValue={searchValue}
						selectedTab={selectedTab}
						isPatientEnabled={therapistPatient.enabled}
					/>
				</TabPanel>
				<TabPanel value={selectedTab} index={TabsEnum.RESOURCES}>
					<Resources
						searchValue={searchValue}
						selectedTab={selectedTab}
						isPatientEnabled={therapistPatient.enabled}
					/>
				</TabPanel>
				<TabPanel value={selectedTab} index={TabsEnum.REPORTS}>
					<Reports
						searchValue={searchValue}
						selectedTab={selectedTab}
					/>
				</TabPanel>
			</div>
		</div>
	);
};

export default Patient;
