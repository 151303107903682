import { enableDisabledTherapist } from '../../../services/PatientService';
import React from 'react';
import { UpdateTherapistContext } from '../../../contexts/TherapistContext';

export interface UsePatientStore {
	updateTherapistStatus: (therapistId: number) => Promise<void>;
}

export const usePatient = (): UsePatientStore => {
	const { enableDisabled } = React.useContext(UpdateTherapistContext);

	const updateTherapistStatus = async (therapistId: number): Promise<void> => {
		await enableDisabledTherapist(therapistId);
		enableDisabled();
	};

	return {
		updateTherapistStatus,
	};
};
