import { Alert } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Modal from '../../Modal/Modal';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import styles from './ListPatientsModal.module.scss';
import useListPatientsModal from './useListPatientsModal';
import { useTranslation } from 'react-i18next';

export interface ListPatientsModalProps {
	open: boolean;
	onChange: () => void;
	type: 'resource' | 'document';
	objectId?: number;
}

const ListPatientsModal: React.FC<ListPatientsModalProps> = ({ open, onChange, type, objectId }) => {
	const { t } = useTranslation();
	const {
		isLinked,
		patientsCards,
		handleClose,
		handleCloseSnackbar,
		handleConfirm,
		handleChangeSelected,
		renderMessage,
	} = useListPatientsModal({ open, onChange, type, objectId });

	return (
		<>
			<Modal
				open={open}
				title={t('resourceLibraryView:MODAL_TITLE_LINK_PATIENTS')}
				handleOnClose={handleClose}
				isPrimaryButtonVisible
				primaryButtonProps={{
					title: t(
						'resourceLibraryView:MODAL_BUTTON_CONFIRM_LINK_PATIENTS'
					),
					onClick: handleConfirm,
					isDisabled: false,
				}}
				isSecondaryButtonVisible
				secondaryButtonProps={{
					title: t(
						'resourceLibraryView:MODAL_BUTTON_CANCEL_LINK_PATIENTS'
					),
					onClick: handleClose,
				}}
			>
				<Box mb={2} mx={1}>
					{patientsCards.length ? (
						patientsCards.map((item) => {
							const selectedStyle = item.selected
								? styles.selected
								: styles.simple;

							return (
								<Card
									key={item.id}
									classes={{
										root: styles.listPatientsModalCardContent,
									}}
								>
									<CardActionArea
										onClick={(): void =>
											handleChangeSelected(item.id)
										}
									>
										<CardContent
											className={`${styles.cardContent} ${selectedStyle}`}
										>
											<div className={styles.title}>
												{`${item.givenName} ${item.familyName}`}
											</div>
										</CardContent>
									</CardActionArea>
								</Card>
							);
						})
					) : (
						<div className={styles.listPatientsModalCardNoData}>
							{t('messages:NO_DATA')}
						</div>
					)}
				</Box>
			</Modal>
			<Snackbar
				open={isLinked !== undefined}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert severity={isLinked ? 'success' : 'error'}>
					{ renderMessage()}
				</Alert>
			</Snackbar>
		</>
	);
};

export default ListPatientsModal;
