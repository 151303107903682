import { CivilStatus, Country, Job, Sex } from '../interfaces/Generic';
import { getCountries as getCountriesRepo, getJobs as getJobsRepo} from '../api/GenericRepository';
import { OptionSelect } from '../interfaces/Common';

export const getCountries = async (): Promise<Array<Country>> => {
	return await getCountriesRepo();
};

export const getJobs = async (): Promise<Array<Job>> => {
	return await getJobsRepo();
};

export const getGuardian = (guardian: boolean): string => {
	if (guardian) {
		return 'personalInfo:GUARDIAN_YES';
	}

	return 'personalInfo:GUARDIAN_NO';
};

export const getGuardianObject = (): Array<OptionSelect> => {
	return [
		{
			id: 0,
			name: getGuardian(false),
		},
		{
			id: 1,
			name: getGuardian(true),
		},
	];
};

export const getSex = (sex: Sex): string => {
	switch (sex) {
		case Sex.Female:
			return 'personalInfo:SEX_FEMALE';
		case Sex.Male:
		default:
			return 'personalInfo:SEX_MALE';
	}
};

export const getSexObject = (): Array<OptionSelect> => {
	return [
		{
			id: Sex.Female,
			name: getSex(Sex.Female),
		},
		{
			id: Sex.Male,
			name: getSex(Sex.Male),
		},
	];
};

export const getCivilStatus = (civilStatus: CivilStatus): string => {
	switch (civilStatus) {
		case CivilStatus.Divorced:
			return 'personalInfo:CIVIL_STATUS_DIVORCED';
		case CivilStatus.Married:
			return 'personalInfo:CIVIL_STATUS_MARRIED';
		case CivilStatus.Widowed:
			return 'personalInfo:CIVIL_STATUS_WIDOWED';
		case CivilStatus.Single:
		default:
			return 'personalInfo:CIVIL_STATUS_SINGLE';
	}
};

export const getCivilStatusObject = (): Array<OptionSelect> => {
	return [
		{
			id: CivilStatus.Divorced,
			name: getCivilStatus(CivilStatus.Divorced),
		},
		{
			id: CivilStatus.Married,
			name: getCivilStatus(CivilStatus.Married),
		},
		{
			id: CivilStatus.Widowed,
			name: getCivilStatus(CivilStatus.Widowed),
		},
		{
			id: CivilStatus.Single,
			name: getCivilStatus(CivilStatus.Single),
		},
	];
};

export const getCountry = (countryCode: string): string => countryCode ? `country:${countryCode}` : '';

export const getJob = (jobId: string): string => jobId ? `job:${jobId}` : '';

export const convertBase64ToFormData = async (base64: string): Promise<FormData> => {
	const data = new FormData();
	const blob = await fetch(base64)
		.then(res => res.blob()).then(res => res);

	data.append('file', blob);

	return data;
};

export const convertFileToFormData = async (file: File): Promise<FormData> => {
	const data = new FormData();
	const blob = new Blob([file], {type: 'image'});

	data.append('file', blob);

	return data;
};

export const DaysWeekArray = [{
	id: 1,
	label: 'availabilityView:MONDAY',
}, {
	id: 2,
	label: 'availabilityView:TUESDAY',
}, {
	id: 3,
	label: 'availabilityView:WEDNESDAY',
}, {
	id: 4,
	label: 'availabilityView:THURSDAY',
}, {
	id: 5,
	label: 'availabilityView:FRIDAY',
}, {
	id: 6,
	label: 'availabilityView:SATURDAY',
}, {
	id: 7,
	label: 'availabilityView:SUNDAY',
}];
