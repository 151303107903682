import { getTherapistPatient, reSendInvitationToPatient } from '../../../../services/TherapistService';
import { AlertContext } from '../../../../contexts/AlertContext';
import { PatientProfile } from '../../../../interfaces/Patient';
import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useRoutes } from '../../../../helpers/useRoutes';
import { useTherapistSelected } from '../../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface PatientStore {
	therapistPatient: PatientProfile;
	isLoading: boolean;
	selectedTab: number;
	searchValue: string;
	handleChangeTabs: (event: unknown, newValue: number) => void;
	handleCreateReport: () => void;
	handleChangeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
	getTherapistPatientInfo: () => Promise<void>;
	handleResendInvitation: () => Promise<void>;
}

export interface PatientParams {
	id: string;
}

const usePatient = (): PatientStore => {
	const history = useHistory();
	const { therapistRoutes } = useRoutes();
	const { showDefaultError, showAlert } = React.useContext(AlertContext);
	const [therapistPatient, setTherapistPatient] = React.useState<PatientProfile>({} as PatientProfile);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectedTab, setSelectedTab] = React.useState<number>(0);
	const [searchValue, setSearchValue] = React.useState<string>('');
	const { getTherapistIdParam } = useTherapistSelected();
	const { id: patientIdString } = useParams<PatientParams>();
	const { t } = useTranslation();
	const patientId = +patientIdString;

	const getTherapistPatientInfo = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const data = await getTherapistPatient(therapistIdParam, patientId);

			setTherapistPatient(data);
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	React.useEffect(() => {
		getTherapistPatientInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeTabs = (event: unknown, newValue: number): void => {
		setSearchValue('');
		setSelectedTab(newValue);
	};

	const handleCreateReport = (): void => {
		const createReportPath = `${therapistRoutes.REPORTS}/${therapistRoutes.NEW}`;

		history.push(`${history.location.pathname}/${createReportPath}`);
	};

	const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(event.target.value);
	};

	const handleResendInvitation = async (): Promise<void> => {
		try {
			const therapistIdParam = getTherapistIdParam();

			await reSendInvitationToPatient(therapistIdParam, patientId);

			showAlert({ message: t('patientsView:INVITATION_RESENT_SUCCESSFULLY')});
		} catch (error) {
			showDefaultError();
		}
	};

	return ({
		therapistPatient,
		isLoading,
		selectedTab,
		searchValue,
		handleChangeTabs,
		handleCreateReport,
		handleChangeSearchValue,
		getTherapistPatientInfo,
		handleResendInvitation,
	});
};

export default usePatient;
