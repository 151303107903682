import { SiteDateCard } from '../interfaces/DateCard';

export enum Frequency {
	ONE_TIME = '0',
	DAILY = '1',
	WEEKLY = '2',
	MONTHLY = '3',
}

export interface SessionType {
	id?: number;
	name: string;
	color?: string;
}

export interface Session {
	id?: number;
	endDate: string | null;
	endTime: string;
	frequency: number;
	patient: {
		id: number;
		givenName?: string;
		familyName?: string;
	};
	therapistOffice: {
		id: number;
		address?: string;
	};
	startDate: string;
	startTime: string;
	therapistSessionType?: {
		id: number;
		name?: string;
	};
	telehealth: boolean;
	status?: number;
}

export interface RequestSession {
	endTime: string;
	startDate: string;
	startTime: string;
}

export interface Office {
	id: number;
	address: string;
}

export interface Hour {
	key: number;
	value: string;
}

export interface Day {
	key: number;
	value: string;
}

export interface HoursPerDay {
	day: string;
	hours: string[];
}

export interface SessionTypeFilter extends SessionType {
	selected: boolean;
}

export interface SessionEvent {
	id?: number;
	date: Date;
	startTime: string;
	endTime: string;
	type: SessionType;
	site?: SiteDateCard;
	patient: {
		id?: number;
		givenName: string;
		familyName: string;
	};
	frequency: Frequency;
	confirmed: boolean;
	therapistOffice: {
		id?: number;
		address: string;
	};
}

export interface SessionEventAPI {
	id?: number;
	date: string;
	endTime: string;
	frequency: Frequency;
	patient: {
		id?: number;
		familyName: string;
		givenName: string;
	};
	startTime: string;
	status: number;
	telehealth: boolean;
	therapistOffice: {
		id?: number;
		address: string;
	};
	therapistSessionType: SessionType;
}

export interface CalendarEvent extends SessionEvent {
	id: number;
	title: string;
	start: Date;
	end: Date;
}
