import { Day, Hour } from './../interfaces/Session';

const daysToDisplay: Day[] = [
	{
		key: 1,
		value: 'Monday',
	},
	{
		key: 2,
		value: 'Tuesday',
	},
	{
		key: 3,
		value: 'Wednesday',
	},
	{
		key: 4,
		value: 'Thursday',
	},
	{
		key: 5,
		value: 'Friday',
	},
	{
		key: 6,
		value: 'Saturday',
	},
	{
		key: 7,
		value: 'Sunday',
	},
];

const durationsToDisplay: Hour[] = [
	{
		key: 1,
		value: '15 min',
	},
	{
		key: 2,
		value: '20 min',
	},
	{
		key: 3,
		value: '25 min',
	},
	{
		key: 4,
		value: '30 min',
	},
	{
		key: 5,
		value: '35 min',
	},
	{
		key: 6,
		value: '40 min',
	},
	{
		key: 7,
		value: '45 min',
	},
	{
		key: 8,
		value: '50 min',
	},
	{
		key: 9,
		value: '55 min',
	},
	{
		key: 10,
		value: '60 min',
	},
	{
		key: 11,
		value: '70 min',
	},
	{
		key: 12,
		value: '80 min',
	},
	{
		key: 13,
		value: '90 min',
	},
	{
		key: 14,
		value: '120 min',
	},
	{
		key: 15,
		value: '150 min',
	},
	{
		key: 16,
		value: '180 min',
	},
];

const cancelDaysToDisplay: Day[] = [
	{
		key: 1,
		value: '1',
	},
	{
		key: 2,
		value: '2',
	},
	{
		key: 3,
		value: '3',
	},
	{
		key: 4,
		value: '4',
	},
	{
		key: 5,
		value: '5',
	},
	{
		key: 6,
		value: '6',
	},
	{
		key: 7,
		value: '7',
	},
	{
		key: 8,
		value: '8',
	},
	{
		key: 9,
		value: '9',
	},
	{
		key: 10,
		value: '10',
	},
	{
		key: 11,
		value: '11',
	},
	{
		key: 12,
		value: '12',
	},
	{
		key: 13,
		value: '13',
	},
	{
		key: 14,
		value: '14',
	},
	{
		key: 15,
		value: '15',
	},
];

const hoursToDisplay: Hour[] = [
	{
		key: 1,
		value: '09:00',
	},
	{
		key: 2,
		value: '10:00',
	},
	{
		key: 3,
		value: '11:00',
	},
	{
		key: 4,
		value: '12:00',
	},
	{
		key: 5,
		value: '13:00',
	},
	{
		key: 6,
		value: '14:00',
	},
	{
		key: 7,
		value: '15:00',
	},
	{
		key: 8,
		value: '16:00',
	},
	{
		key: 9,
		value: '17:00',
	},
	{
		key: 10,
		value: '18:00',
	},
	{
		key: 11,
		value: '19:00',
	},
];

export const findHoursByTherapist = (): Promise<Hour[]> => {
	return Promise.resolve(hoursToDisplay);
};

export const findDaysByTherapist = (): Promise<Day[]> => {
	return Promise.resolve(daysToDisplay);
};

export const findDurationsByTherapist = (): Promise<Hour[]> => {
	return Promise.resolve(durationsToDisplay);
};

export const findCancelDaysByTherapist = (): Promise<Day[]> => {
	return Promise.resolve(cancelDaysToDisplay);
};
