export default {
	es: {
		buttons: {
			TRY_NOW_FOR_FREE: '¡Probar Gratis Ahora!',
			TRY_FOR_FREE: 'Probar Gratis',
			CLOSE: 'Cerrar',
		},
	},
	en: {
		buttons: {
			TRY_NOW_FOR_FREE: 'Try Free Now!',
			TRY_FOR_FREE: 'Try Free',
			CLOSE: 'Close',
		},
	},
};
