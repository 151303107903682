import { Button } from '@material-ui/core';
import googleLogo from '../../assets/images/google-logo.svg';
import React from 'react';
import styles from './GoogleAuthButton.module.scss';

interface GoogleAuthButtonProps {
	onClick: () => void;
	showIcon: boolean;
	text: string;
}

const GoogleIcon: React.FC = () => <img src={googleLogo} alt={'google-logo'} height="24px" width="24px" />;

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({ onClick, showIcon, text }) => {
	return (
		<Button
			className={styles.googleSigninButton}
			onClick={onClick}
			startIcon={showIcon ? <GoogleIcon /> : undefined}
			variant={'contained'}
		>
			{text}
		</Button>
	);
};

export default GoogleAuthButton;

