import Navigation from '../../Navigation';
import React from 'react';
import styles from './Layout.module.scss';

const Layout = (): JSX.Element => {
	return (
		<div className={styles.content}>
			<Navigation />
		</div>
	);
};

export default Layout;
