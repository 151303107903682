import { getAllPosts, getAllTagsForPosts } from '../../services/public/PostsService';
import { Post } from '../../interfaces/Post';
import React from 'react';
import { Tag } from '../../interfaces/Tag';

interface TagUi extends Tag {
	isChecked: boolean;
}

interface UseBlogStore {
	isLoadingPosts: boolean;
	isLoadingTags: boolean;
	posts: Post[];
	tags: TagUi[];
	applyFilter: (tagId: number) => void;
}

const useBlog = (): UseBlogStore => {
	const [ isLoadingPosts, setIsLoadingPosts ] = React.useState(false);
	const [ isLoadingTags, setIsLoadingTags ] = React.useState(false);
	const [ posts, setPosts ] = React.useState<Post[]>([]);
	const [ tags, setTags ] = React.useState<TagUi[]>([]);

	const fetchPosts = async (): Promise<void> => {
		try {
			setIsLoadingPosts(true);
			setPosts(await getAllPosts(tags.filter(tag => tag.isChecked).map(tag => tag.id)));
		} catch (e) {
			console.error('Error while loading posts.');
		} finally {
			setIsLoadingPosts(false);
		}
	};

	const fetchTags = async (): Promise<void> => {
		try {
			setIsLoadingTags(true);
			setTags((await getAllTagsForPosts()).map(tag => ({ ...tag, isChecked: false })));
		} catch (e) {
			console.error('Error while loading tags.');
		} finally {
			setIsLoadingTags(false);
		}
	};

	const applyFilter = (tagId: number): void => {
		const tagsTemp = [...tags ];
		const tag = tagsTemp.find(tag => tag.id === tagId);

		if (tag) {
			tag.isChecked = !tag.isChecked;
		}

		fetchPosts();
	};

	React.useEffect((): void => {
		fetchPosts();
		fetchTags();
	}, []);

	return {
		isLoadingPosts,
		isLoadingTags,
		posts,
		tags,
		applyFilter,
	};
};

export default useBlog;
