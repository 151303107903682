import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { DocumentShared } from '../../../interfaces/Document';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';
import { Report } from '../../../interfaces/Report';
import { ResourceCard } from '../../../interfaces/Resource';
import styles from './ResourceCards.module.scss';
import useResourceCards from './useResourceCards';
import { useTranslation } from 'react-i18next';

interface ResourceCardsProps {
	data: Array<ResourceCard | Report | DocumentShared>;
	activeCard?: number;
	onClick?: (id: number) => void;
	showCheckbox?: boolean;
	selectedItems?: Array<number>;
	handleSelectedItems?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ResourceCards: React.FC<ResourceCardsProps> = ({ data, activeCard, onClick, handleSelectedItems, showCheckbox = false, selectedItems = [] }) => {
	const { t } = useTranslation();
	const { handleClick } = useResourceCards({ onClick });

	return <>
		{
			data.length ?
				data.map(item => {
					const cardId = item.id;
					const isSelected = activeCard === cardId;

					const cardClasses = { root: `${styles.resourceCard} ${isSelected && styles.active}` };

					return (
						<Card key={cardId} classes={cardClasses} variant="outlined">
							<CardContent className={styles.resourceCardContent}>
								<div className={styles.resourceCardContentItems}>
									{showCheckbox &&
										<Checkbox
											classes={{ root: styles.checkbox, checked: styles.checkboxChecked }}
											color="default"
											size="small"
											checkedIcon={<CheckBoxOutlinedIcon />}
											checked={selectedItems.includes(item.id as number)}
											name={(item.id as number).toString()}
											onChange={handleSelectedItems}
										/>}
									<div className={styles.resourceCardArea} onClick={(): void => handleClick((cardId as number))}>
										<InsertDriveFileOutlinedIcon className={styles.primaryIcon} />
										<span className={styles.primaryText}>{item.name}</span>
									</div>
								</div>
							</CardContent>
						</Card>
					);
				})
				: <div className={styles.resourceCardNoData}>{t('messages:NO_DATA')}</div>
		}
	</>;
};

export default ResourceCards;
