import { readTasks, softDeleteTask, updateStarredStatus } from '../../../services/TaskService';
import { Task, TaskType } from '../../../interfaces/Task';
import { AlertContext } from '../../../contexts/AlertContext';
import { ColDef } from '../../../components/CardsTable/CardsTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { getTasks } from '../../../services/TherapistService';
import { Option } from '../../../interfaces/Common';
import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import styles from './Tasks.module.scss';
import { UpdateTherapistContext } from '../../../contexts/TherapistContext';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface TasksStore {
	isLoading: boolean;
	isOpenNewPatientModal: boolean;
	columns: ColDef[];
	patientOptions: Option[];
	pendingTask: number;
	tasks: Task[];
	taskTypeOptions: Option[];
	handleOpenNewPatientModal: () => void;
	handlePatientFilter: (patientId: number) => void;
	handleTaskTypeFilter: (value: number) => void;
}

const useTasks = (): TasksStore => {
	const { setBadge } = React.useContext(UpdateTherapistContext);
	const { showDefaultError } = React.useContext(AlertContext);
	const [allTasks, setAllTasks] = React.useState<Array<Task>>([]);
	const [tasks, setTasks] = React.useState<Array<Task>>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isOpenNewPatientModal, setIsOpenNewPatientModal] = React.useState<boolean>(false);
	const [columns, setColumns] = React.useState<Array<ColDef>>([]);
	const [patientOptions, setPatientOptions] = React.useState<Array<Option>>([]);
	const [pendingTask, setPendingTask] = React.useState<number>(0);
	const [patientIdFilter, setPatientIdFilter] = React.useState<number>(0);
	const [taskTypeFilter, setTaskTypeFilter] = React.useState<TaskType>();
	const { t } = useTranslation();
	const { getTherapistIdParam } = useTherapistSelected();

	const taskTypeOptions: Option[] = [
		{
			key: TaskType.AssignResource,
			value: t('reminderType:ASSIGN_RESOURCE'),
		},
		{
			key: TaskType.ConfirmSession,
			value: t('reminderType:CONFIRM_SESSION'),
		},
		{
			key: TaskType.FillMyData,
			value: t('reminderType:FILL_MY_OWN_DATA'),
		},
		{
			key: TaskType.FillPatientData,
			value: t('reminderType:FILL_PATIENT_DATA'),
		},
		{
			key: TaskType.FillSessionData,
			value: t('reminderType:FILL_SESSION_DATA'),
		},
	];

	const updateToDeleteStatus = async (id: number): Promise<void> => {
		await softDeleteTask(id);
		setTasks(tasks => tasks.filter(t => t.id !== id));
	};

	const updateFavoriteStatus = async (id: number): Promise<void> => {
		await updateStarredStatus(id);
		setTasks(tasks => tasks.map(task => {
			if (task.id === id) {
				return { ...task, isStarred: !task.isStarred };
			}

			return task;
		}));
	};

	const handleOpenNewPatientModal = (): void => {
		setIsOpenNewPatientModal(prevState => !prevState);
	};

	const columnsDef: ColDef[] = [
		{
			field: 'isStarred', headerName: '', sortable: false, flex: 1,
			buttons: [
				{
					key: 'favourite',
					icon: <StarIcon fontSize="inherit" />,
					style: (item): string => {
						return (item as unknown as Task).isStarred ? styles.iconFavourite : '';
					},
					size: 'small',
					onClick: updateFavoriteStatus,
				},
			],
		},
		{ field: 'title', headerName: 'Title', sortable: false, flex: 8 },
		{ field: 'date', headerName: 'Date', flex: 4 },
		{ field: 'patientName', headerName: 'Patient Name', flex: 8 },
		{
			field: 'actions',
			headerName: 'Actions',
			sortable: false,
			flex: 2,
			map: false,
			buttons: [
				{
					key: 'delete',
					icon: <DeleteOutlineIcon />,
					style: styles.iconActions,
					onClick: updateToDeleteStatus,
				},
			],
		},
	];

	const markAsRead = async(taskIds: number[]): Promise<void> => {
		if (taskIds.length > 0) {
			await readTasks(taskIds);
		}
	};

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);

			const therapistIdParam = getTherapistIdParam();
			const data = await getTasks(therapistIdParam);
			const taskToRead: number[] = [];
			const patients: Option[] = [];

			data.forEach(task => {
				task.title = t(`${task.title}`, { patientName: task.patientName });

				if (!patients.some(x => x.key === task.patientId)) {
					patients.push({ key: task.patientId, value: task.patientName});
				}

				if (!task.isRead && task.id) {
					taskToRead.push(task.id);
				}
			});

			setBadge(false);
			markAsRead(taskToRead);
			setAllTasks(data);
			setTasks(data);
			setPatientOptions(patients);
			setPendingTask(data.length);
		} catch (error) {
			showDefaultError();
		}

		setColumns(columnsDef);
		setIsLoading(false);
	};

	const handleFilter = (patientId: number | undefined, taskType: TaskType | undefined): void => {
		const taskToShow = allTasks.reduce(( result: Task[], task: Task) => {
			if ((patientId === undefined || task.patientId === patientId) && (taskType === undefined || task.type === taskType)) {
				result.push(task);
			}

			return result;
		}, []);

		setTasks(taskToShow);
		setPendingTask(taskToShow.length);
	};

	const handlePatientFilter = (patientId: number): void => {
		setPatientIdFilter(patientId);
		handleFilter(patientId, taskTypeFilter);
	};

	const handleTaskTypeFilter = (taskTypeValue: number): void => {
		setTaskTypeFilter(taskTypeValue);
		handleFilter(patientIdFilter, taskTypeValue);
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return ({
		isLoading,
		isOpenNewPatientModal,
		columns,
		patientOptions,
		pendingTask,
		tasks,
		taskTypeOptions,
		handleOpenNewPatientModal,
		handlePatientFilter,
		handleTaskTypeFilter,
	});
};

export default useTasks;
