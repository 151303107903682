import React from 'react';

interface DateCardsStore {
	selectedMonth: number;
	selectedYear: number;
	handleClickLeftArrow: () => void;
	handleClickRightArrow: () => void;
	handleChange: (id: string) => void;
	handleDecline: (id: number) => void;
	handleAccept: (id: number) => void;
}

interface DateCardsProps {
	onChange?: (value: string) => void;
	onDecline?: (sessionId: number) => void;
	onAccept?: (sessionId: number) => void;
	fetchData?: (date: Date) => void;
}

const useDateCards = (props: DateCardsProps): DateCardsStore => {
	const [selectedMonth, setSelectedMonth] = React.useState<number>(new Date().getMonth());
	const [selectedYear, setSelectedYear] = React.useState<number>(new Date().getFullYear());
	const { onChange, onDecline, onAccept, fetchData } = props;

	React.useEffect(() => {
		if (fetchData) {
			const date = new Date(selectedYear, selectedMonth);

			fetchData(date);
		}
	}, [fetchData, selectedMonth, selectedYear]);

	const handleClickLeftArrow = (): void => {
		if(selectedMonth > 0) {
			setSelectedMonth(selectedMonth - 1);
		}
		else {
			setSelectedMonth(11);
			setSelectedYear(prevYear => prevYear - 1);
		}
	};

	const handleClickRightArrow = (): void => {
		if(selectedMonth < 11) {
			setSelectedMonth(selectedMonth + 1 );
		}
		else {
			setSelectedMonth(0);
			setSelectedYear(prevYear => prevYear + 1);
		}
	};

	const handleChange = (id: string): void => {
		if(onChange) {
			onChange(id);
		}
	};

	const handleDecline = (id: number): void => {
		if(onDecline) {
			onDecline(id);
		}
	};

	const handleAccept = (id: number): void => {
		if(onAccept) {
			onAccept(id);
		}
	};

	return {
		selectedMonth,
		selectedYear,
		handleClickLeftArrow,
		handleClickRightArrow,
		handleChange,
		handleDecline,
		handleAccept,
	};
};

export default useDateCards;
