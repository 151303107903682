import { getPostById } from '../../services/public/PostsService';
import { Post } from '../../interfaces/Post';
import React from 'react';
import { useParams } from 'react-router-dom';

interface UsePostStore {
	isLoadingPost: boolean;
	post: Post | undefined;
}

const usePost = (): UsePostStore => {
	const { id } = useParams<{ id: string }>();
	const [ isLoadingPost, setIsLoadingPost ] = React.useState(false);
	const [ post, setPost ] = React.useState<Post>();

	const fetchPost = async (): Promise<void> => {
		try {
			setIsLoadingPost(true);
			setPost(await getPostById(+id));
		} catch (e) {
			console.error('Error while loading the post.');
		} finally {
			setIsLoadingPost(false);
		}
	};

	React.useEffect(() => {
		if (id != null) {
			fetchPost();
		}
	}, [id]);

	return {
		isLoadingPost,
		post,
	};
};

export default usePost;
