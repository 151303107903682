import { ChatContext, ChatUpdateContext } from '../../../contexts/ChatContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ChatContact } from '../../../interfaces/Chat';
import { getChatConversations } from '../../../services/ChatService';
import React from 'react';
import { SocketUpdateContext } from '../../../contexts/SocketContext';
import { useLocation } from 'react-router-dom';

interface ContactListHook {
	activeContactId: number | undefined;
	filteredContacts: ChatContact[];
	handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSelectContact: (contactId: number) => Promise<void>;
	searchTerm: string;
}

export const useContactList = (): ContactListHook => {
	const [searchTerm, setSearchTerm] = React.useState('');
	const { user } = React.useContext(AuthContext);
	const { chatState } = React.useContext(ChatContext);
	const { activateChat, loadMessages } = React.useContext(ChatUpdateContext);
	const { handleReadMessages } = React.useContext(SocketUpdateContext);
	const location = useLocation<{patientId: number}>();

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSearchTerm(event.target.value);
	};

	const filteredContacts = chatState.contacts.filter((contact) =>
		contact.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const onSelectContact = React.useCallback(async (contactId: number): Promise<void> => {
		const contact = chatState.contacts.find(contact => contact.id === contactId);

		if (contact) {
			activateChat(contact);
			const messages = await getChatConversations(contact.id);

			if (user?.id) {
				handleReadMessages(contact.id, user.id);
			}

			loadMessages(messages);
		}
	}, [activateChat, chatState.contacts, handleReadMessages, loadMessages, user]);

	React.useEffect(() => {
		if (location.state?.patientId) {
			const contact = chatState.contacts.find(contact => contact.id === location.state.patientId);

			if (contact) {
				onSelectContact(contact.id);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		activeContactId: chatState.activeContact?.id,
		filteredContacts,
		handleSearch,
		onSelectContact,
		searchTerm,
	};
};
