export default {
	es: {
		functions: {
			FUNCTION_MAIN_TITLE: 'Conozca todas las facilidades que Pragma le ofrece para agilizar su práctica profesional cotidiana',
			FUNCTION_CALENDAR: 'Agenda y Calendario',
			CARD_CALENDAR_USE_TITLE: 'Uso fácil de agenda y calendario',
			CARD_CALENDAR_USE_DESCRIPTION: 'Programe sesiones únicas o periódicas, administre sus horarios de trabajo y obtenga acceso rápido a las notas e informes relacionados con las citas directamente desde su calendario. Esta sección se conecta directamente con sus registros, notas, informes y planes de tratamiento para ayudarlo a mantenerse organizado.',
			CARD_CALENDAR_SYNC_TITLE: 'Sincronización de calendario móvil',
			CARD_CALENDAR_SYNC_DESCRIPTION: 'Acceda a su agenda utilizando la app de PRAGMA en su teléfono o tablet. Sincronice su calendario con su dispositivo favorito, y tendrá acceso a sus citas donde esté.',
			FUNCTION_MORE: 'Más sobre Pragma',
			CARD_MORE_HIGHLIGHT: 'Telehealth y Chat',
			CARD_MORE_TITLE: 'Comuníquese directamente con sus clientes para reprogramar citas, discutir pagos o recordarles traer material a una sesión',
			CARD_MORE_DESCRIPTION: 'Envíe mensajes seguros que cumplan con HIPAA. Realice entrevistas mediante teleconferencias seguras con sus pacientes, desde cualquier lugar y dispositivo. Todos los datos están protegidos con encriptación, sus llamadas son anónimas y ninguna información suya se almacena para satisfacer las necesidades de cumplimiento de su práctica.',
			CARD_DOCUMENTATION_HIGHLIGHT: 'Documentación',
			CARD_DOCUMENTATION_TITLE: 'Acceda a sus notas de progreso, entrevistas de admisión, evaluaciones e informes en cualquier momento y lugar',
			CARD_DOCUMENTATION_DESCRIPTION: 'Nuestras plantillas están diseñadas exclusivamente para todas las disciplinas de salud mental, atendiendo a las necesidades específicas de cada profesión. Nuestro asistente, permite acelerar el proceso de elaboración de informes para transmitir con claridad, especifidad y consistencia lo observado a la entidad que lo solicita. Además puede integrar su logo y firma digital en cada reporte.',
			CARD_RESOURCES_HIGHLIGHT: 'Biblioteca de recursos',
			CARD_RESOURCES_TITLE: 'Nuestra biblioteca de recursos lo ayudará a comprender y tratar los problemas de manera más efectiva.',
			CARD_RESOURCES_DESCRIPTION: 'Nuestra biblioteca integral de recursos de salud mental está diseñada para profesionales como usted, en el formato perfecto para sus pacientes. Todos nuestros recursos forman parte de la literatura científica desarrollada por nuestro equipo altamente calificado, diseñados específicamente para ayudar a nuestros pacientes a comprender y tratar sus problemas de manera más efectiva. Sistema de búsqueda inteligente para encontrar rápidamente el recurso ideal para cada situación.',
			FUNCTION_PORTAL: 'Portal de Pacientes',
			FUNCTION_PORTAL_DESCRIPTION: 'El portal de PRAGMA es su portal de pacientes personalizado para la gestión online y la admisión de pacientes. Permite que los pacientes soliciten citas, completen formularios y reciban recursos psicoeducativos desde nuestra biblioteca.',
			PORTAL_TABS_ADMISSION: 'Admisión de nuevos pacientes',
			PORTAL_TABS_FUNCTION: 'Funciones disponibles para sus pacientes',
			PORTAL_TABS_COMUNICATION: 'Comunicación Segura',
			FUNCTION_SECURITY: 'Seguridad',
			FUNCTION_SECURITY_DESCRIPTION: 'La seguridad es nuestra principal prioridad, y trabajamos arduamente para mantener sus registros seguros. Tomamos medidas de seguridad extraordinarias para proteger sus datos.',
			FUNCTION_INFRA: 'Infraestructura de seguridad de primera clase',
			CARD_INFRA_ENCRYPTION: 'Cifrado de datos y firewalls.',
			CARD_INFRA_EQUIPMENT: 'Equipo dedicado y capacitado en HIPAA.',
			CARD_INFRA_CONNECTION: 'Conexión segura entre nuestros servidores y su navegador.',
			CARD_INFRA_COPY_SEC: 'Copias de seguridad de rutina y reparación de pérdidas, que se respaldan regularmente en servidores externos.',
			CARD_INFRA_TECHNIC: 'Técnicas que satisfacen los requisitos descriptos en la Regla de Privacidad y Seguridad de HIPAA.',
			TAB_ADMISSION_CLIENT: '• Los clientes pueden completar, firmar y enviar formularios de admisión en línea.',
			TAB_ADMISSION_UTILITIES: '• Utilice nuestras plantillas para obtener datos relevantes importantes e integrarlos a la carpeta de cada caso.',
			TAB_ADMISSION_RESOURCES: '• Envíe a los pacientes recursos psicoeducativos, tareas e instrucciones desde nuestra biblioteca directamente al portal de su paciente.',
			TAB_FUNCTION_PACIENT: '• Los pacientes nuevos pueden programar sesiones en función de la disponibilidad de la agenda.',
			TAB_FUNCTION_ACCESSIBILITY: '• Pueden acceder de forma segura a sus cuentas del Portal desde cualquier lugar y dispositivo.',
			TAB_FUNCTION_COMUNICATION: '• Pueden comunicarse directamente a través del chat privado y seguro.',
			TAB_FUNCTION_ALLOW: '• Les permite leer o imprimir el material psicoeducativo recibido.',
			TAB_COMUNICATION_SEND: '• Envíe mensajes compatibles con HIPAA y comparta archivos de forma segura con sus clientes a través del canal de chat privado.',
			TAB_COMUNICATION_TRACE: '• Rastree el estado, reenvíe o revoque formularios. Envíe nuevos formularios de manera segura en el curso del tratamiento.',
			TAB_COMUNICATION_UPDATE: '• Los pacientes pueden actualizar información personal desde su cuenta del Portal de pacientes.',
		},
	},
	en: {
		functions: {
			FUNCTION_MAIN_TITLE: 'Learn about all the facilities that Pragma offers you to streamline your daily professional practice',
			FUNCTION_CALENDAR: 'Agenda and Calendar',
			CARD_CALENDAR_USE_TITLE: 'Easy use of agenda and calendar',
			CARD_CALENDAR_USE_DESCRIPTION: 'Schedule one-time or recurring sessions, manage your work schedules, and get quick access to appointment-related notes and reports right from your calendar. This section connects directly to your records, notes, reports, and treatment plans to help you stay organized.',
			CARD_CALENDAR_SYNC_TITLE: 'Mobile calendar sync',
			CARD_CALENDAR_SYNC_DESCRIPTION: 'Access your agenda using the PRAGMA app on your phone or tablet. Synchronize your calendar with your favorite device, and you will have access to your appointments wherever you are.',
			FUNCTION_MORE: 'More about Pragma',
			CARD_MORE_HIGHLIGHT: 'Telehealth and Chat',
			CARD_MORE_TITLE: 'Contact your clients directly to reschedule appointments, discuss payments, or remind them to bring material to a session',
			CARD_MORE_DESCRIPTION: 'Send secure messages that are HIPAA compliant. Conduct secure teleconference interviews with your patients, from anywhere and on any device. All data is protected with encryption, your calls are anonymous, and no information about you is stored to meet the compliance needs of your practice.',
			CARD_DOCUMENTATION_HIGHLIGHT: 'Documentation',
			CARD_DOCUMENTATION_TITLE: 'Access your progress notes, intake interviews, assessments, and reports anytime, anywhere',
			CARD_DOCUMENTATION_DESCRIPTION: 'Our templates are exclusively designed for all mental health disciplines, meeting the specific needs of each profession. Our assistant allows to accelerate the process of preparing reports to transmit with clarity, specificity and consistency the observed to the entity that requests it. You can also integrate your logo and digital signature in each report.',
			CARD_RESOURCES_HIGHLIGHT: 'Resource Library',
			CARD_RESOURCES_TITLE: 'Our resource library will help you understand and deal with problems more effectively.',
			CARD_RESOURCES_DESCRIPTION: 'Our comprehensive library of mental health resources is designed for professionals like you, in the perfect format for your patients. All of our resources are part of the scientific literature developed by our highly qualified team, specifically designed to help our patients understand and treat their problems more effectively. Smart search system to quickly find the ideal resource for each situation.',
			FUNCTION_PORTAL: 'Patient Portal',
			FUNCTION_PORTAL_DESCRIPTION: 'The PRAGMA portal is your personalized patient portal for online management and patient admission. It allows patients to request appointments, fill out forms, and receive psychoeducational resources from our library.',
			FUNCTION_PORTAL_TABS_ADMISSION: 'Admission of new patients',
			FUNCTION_PORTAL_TABS_FUNCTION: 'Features available to your patients',
			FUNCTION_PORTAL_TABS_COMUNICATION: 'Secure Communication',
			FUNCTION_INFRA: 'First-class security infrastructure',
			PORTAL_TABS_ADMISSION: 'Admission of new patients',
			PORTAL_TABS_FUNCTION: 'Features available to your patients',
			PORTAL_TABS_COMUNICATION: 'Secure Communication',
			FUNCTION_SECURITY: 'Security',
			FUNCTION_SECURITY_DESCRIPTION: 'Security is our top priority, and we work hard to keep your records safe. We take extraordinary security measures to protect your data.',
			CARD_INFRA_ENCRYPTION: 'Data encryption and firewalls.',
			CARD_INFRA_EQUIPMENT: 'Dedicated and trained HIPAA team.',
			CARD_INFRA_CONNECTION: 'Secure connection between our servers and your browser.',
			CARD_INFRA_COPY_SEC: 'Routine backups and repeat losses, regularly backed up to external servers.',
			CARD_INFRA_TECHNIC: 'Techniques that meet the requirements outlined in the HPAA Privacy and Security Rule.',
			TAB_ADMISSION_CLIENT: '• Clients can complete, sign, and submit intake forms online.',
			TAB_ADMISSION_UTILITIES: '• Use our templates to get important relevant data and integrate it into each case folder.',
			TAB_ADMISSION_RESOURCES: '• Send patients psychoeducational resources, assignments and instructions from our library directly to your patient portal.',
			TAB_FUNCTION_PACIENT: '• New patients can schedule sessions based on schedule availability.',
			TAB_FUNCTION_ACCESSIBILITY: '• They can securely access their Portal accounts from anywhere and device.',
			TAB_FUNCTION_COMUNICATION: '• They can communicate directly through private and secure chat.',
			TAB_FUNCTION_ALLOW: '• It allows them to read or print the psychoeducational material received.',
			TAB_COMUNICATION_SEND: '• Send HIPAA compliant messages and share files securely with your customers through the private chat channel.',
			TAB_COMUNICATION_TRACE: '• Track status, forward or revoke forms. Submit new forms safely in the course of treatment.',
			TAB_COMUNICATION_UPDATE: '• Patients can update personal information from their Patient Portal account',
		},
	},
};
