import axios from 'axios';
import { OptionSelect } from '../interfaces/Common';

const apiUrl = process.env.REACT_APP_API_URL;

export const getCountries = (): Promise<Array<OptionSelect>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/countries`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getJobs = (): Promise<Array<OptionSelect>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/jobs`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
