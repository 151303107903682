import { Avatar, Card, CardContent } from '@material-ui/core';
import React from 'react';
import styles from './UserRowCard.module.scss';

export interface UserRowCardProps {
	userId: number;
	title: string;
	subTitle?: string;
	avatar?: string;
	actions?: JSX.Element;
}

const UserRowCard: React.FC<UserRowCardProps> = (props) => {
	return (
		<div>
			<Card className={styles.card} >
				<CardContent className={styles.content}>
					<div className={styles.info}>
						<Avatar className={styles.cardMedia} alt="profile-img" src={props.avatar}/>
						<div className={styles.userName}>
							<div className={styles.title}>{props.title}</div>
							{
								props.subTitle &&
									<div className={styles.subTitle}>{props.subTitle}</div>
							}
						</div>
					</div>
					{
						props.actions &&
							<div className={styles.actions}>
								{props.actions}
							</div>
					}
				</CardContent>
			</Card>
		</div>
	);
};

export default UserRowCard;
