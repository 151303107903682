import React from 'react';
import { TherapistCard } from '../../../interfaces/TherapistCard';
import { UpdateTherapistContext } from '../../../contexts/TherapistContext';

interface UpdateTherapistModalStore {
	selected: TherapistCard | null;
	handleChangeTherapistSelected: (therapist: TherapistCard) => void;
	handleClose: () => void;
	handleConfirm: () => void;
}

interface UpdateTherapistModalProps {
	onChange: () => void;
}

const useUpdateTherapistModal = (props: UpdateTherapistModalProps): UpdateTherapistModalStore => {
	const [selected, setSelected] = React.useState<TherapistCard | null>(null);
	const { setTherapistSelected } = React.useContext(UpdateTherapistContext);

	const handleChangeTherapistSelected = (therapist: TherapistCard): void => {
		setSelected(therapist);
	};

	const handleClose = (): void => {
		setSelected(null);
		props.onChange();
	};

	const handleConfirm = (): void => {
		if (selected) {
			setTherapistSelected(selected);
		}

		props.onChange();
	};

	return {
		selected,
		handleChangeTherapistSelected,
		handleClose,
		handleConfirm,
	};
};

export default useUpdateTherapistModal;
