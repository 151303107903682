import { BooleanQuestion, Form, QuestionTypes, RadioQuestion } from '../../interfaces/DocumentForm';
import { FormContext, FormUpdateContext } from '../../contexts/FormContext';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';

interface FormPreviewStore {
	formModel: Form;
	sectionsToShow: string;
	isFirstStep: () => boolean;
	isLastStep: () => boolean;
	handleBack: () => void;
	handleClosePreviewModal: () => void;
	handleNextButton: () => Promise<void>;
}
interface FormPreviewProps {
	form: Form;
	onConfirm?: () => Promise<void>;
	onClose?: () => void;
}

const useFormPreview = (props: FormPreviewProps): FormPreviewStore => {
	const { formModel, sectionsToShow } = React.useContext(FormContext);
	const { handleResetDocument, handleSectionsToShow, hideErrorsInForm, isFormValid, setFormModel, showErrorsInForm } = React.useContext(FormUpdateContext);
	const [firstStep, setFirstStep] = React.useState<string>();
	const [lastStep, setLastStep] = React.useState<string>();
	const [sectionsAnswered, setSectionsAnswered] = React.useState<string[]>([]);

	React.useEffect(() => {
		setFormModel(cloneDeep(props.form));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setSteps = React.useCallback(() => {
		setFirstStep(formModel.sections[0]?.id);
		setLastStep(formModel.sections[formModel.sections.length - 1]?.id);
	}, [formModel.sections]);

	React.useEffect(() => {
		if (formModel && formModel.sections) {
			if (sectionsToShow) {
				const currentSection = formModel.sections.find(x => x.id === sectionsToShow);

				if (currentSection) {
					handleSectionsToShow(currentSection.id);
				}

			} else {
				handleSectionsToShow(formModel?.sections[0]?.id);
			}

			setSteps();
		}
	}, [formModel, formModel.sections, handleSectionsToShow, sectionsToShow, setSteps]);

	const getNextSection = (): string => {
		const currentStepOrder = formModel.sections.find(x => x.id === sectionsToShow)?.order;

		if (currentStepOrder) {
			const nextSectionId = formModel.sections.find(x => x.order === currentStepOrder + 1)?.id;

			if (nextSectionId) {
				return nextSectionId;
			}
		}

		return sectionsToShow;
	};

	const handleBack = (): void => {
		let answeredSection = sectionsAnswered.slice();

		answeredSection = answeredSection.filter(s => s !== sectionsToShow);
		setSectionsAnswered(answeredSection);
		hideErrorsInForm();
		handleSectionsToShow(answeredSection[answeredSection.length - 1]);
	};

	const handleClosePreview = (): void => {
		handleSectionsToShow('');
		handleResetDocument();
		setSectionsAnswered([]);

		if (props.onClose) {
			props.onClose();
		}
	};

	const handleNextButton = async(): Promise<void> => {
		if (!isFormValid()) {
			showErrorsInForm();

			return;
		}

		if (sectionsToShow === lastStep) {
			const sectionsToClean = formModel.sections.reduce((sectionsIds: string[], section) => {
				if (!sectionsAnswered.includes(section.id) && section.id !== lastStep) {
					sectionsIds.push(section.id);
				}

				return sectionsIds;
			}, []);

			if (sectionsToClean.length) {
				handleResetDocument(sectionsToClean);
			}

			if(props.onConfirm) {
				await props.onConfirm();
			}

			handleClosePreview();

			return;
		}

		const currentSection = formModel.sections.find(x => x.id === sectionsToShow);
		let nextSection: string = '';

		if (currentSection) {
			currentSection.questions.sort(x => x.order).forEach((question: QuestionTypes) => {
				if (question.type === 'BooleanQuestion' || question.type === 'RadioQuestion' || question.type === 'SelectQuestion') {
					(question as BooleanQuestion | RadioQuestion | RadioQuestion).options.forEach(option => {
						if (option.goTo && option.id === question.value) {
							nextSection = option.goTo;
						}
					});
				}
			});
		}

		const answeredSection = sectionsAnswered.slice();

		if (!answeredSection.includes(sectionsToShow)) {
			answeredSection.push(sectionsToShow);
		}

		setSectionsAnswered(answeredSection);
		hideErrorsInForm();

		if (nextSection) {
			if (nextSection === 'NEXT_SECTION') {
				nextSection = getNextSection();
			} else if (nextSection === 'END' && lastStep) {
				nextSection = lastStep;
			}
		} else {
			nextSection = getNextSection();
		}

		handleSectionsToShow(nextSection);
	};

	const isFirstStep = (): boolean => sectionsToShow === firstStep;

	const isLastStep = (): boolean => sectionsToShow === lastStep;

	return {
		formModel,
		sectionsToShow,
		// handleConfirm,
		isFirstStep,
		isLastStep,
		handleBack,
		handleClosePreviewModal: handleClosePreview,
		handleNextButton,
		// handleReset,
	};
};

export default useFormPreview;
