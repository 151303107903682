import { LoadingSpinner, StepTitle } from '../../../../../../../components';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import React from 'react';
import styles from './Preview.module.scss';
import TextField from '@material-ui/core/TextField';
import usePreview from './usePreview';
import { useTranslation } from 'react-i18next';
import WYSIWYGEditor from '../../../../../../../components/WYSIWYGEditor';

const Preview = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		isLoading,
		report,
		handleChangeTitle,
		handleEditPreview,
	} = usePreview();

	return (
		<div className={styles.preview}>
			{isLoading
				? <LoadingSpinner />
				:
				<Box>
					<StepTitle title="patientsView:REPORTS_PREVIEW_TITLE" subtitle="patientsView:REPORTS_PREVIEW_SUBTITLE" />
					<Alert severity="warning" className={styles.alert}>{t('patientsView:REPORTS_PREVIEW_WARNING')}</Alert>
					<Card className={styles.titleCard} elevation={0}>
						<TextField
							id="reportTitle"
							InputProps={{ classes: { input: styles.text } }}
							variant="outlined"
							value={report.title}
							placeholder={t('patientsView:REPORTS_PREVIEW_TEXT_PLACEHOLDER')}
							onChange={handleChangeTitle}
							fullWidth
						/>
					</Card>
					<WYSIWYGEditor
						value={report.previewValue}
						onChange={handleEditPreview}
					/>
				</Box>
			}
		</div>
	);
};

export default Preview;
