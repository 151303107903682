import { Avatar, Badge, Card, CardActionArea, CardContent } from '@material-ui/core';
import NotificationBadge from '../../NotificationBadge/NotificationBadge';
import React from 'react';
import styles from './ChatUserCard.module.scss';

export interface ChatUserCardProps {
	actions?: JSX.Element;
	active?: boolean;
	avatar?: string;
	className?: string;
	onClick?: (userId: number) => void;
	online?: boolean;
	subTitle?: string;
	title: string;
	userId: number;
}

const ChatUserCard: React.FC<ChatUserCardProps> = (props) => {
	const classnames: string = `${styles.card} ${props.className || ''} ${props.active ? styles.active : ''}`;

	const handleClick = (): void => {
		if (props.onClick) {
			props.onClick(props.userId);
		}
	};

	const Content = (): JSX.Element => {
		return (
			<CardContent className={styles.content}>
				<div className={styles.info}>
					<Badge
						overlap='circular'
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						badgeContent={props.online ? <NotificationBadge color='green' size='small'/> : <></>}
					>
						<Avatar className={styles.cardMedia} alt="profile-img" src={props.avatar}/>
					</Badge>
					<div className={styles.userName}>
						<div className={styles.title}>{props.title}</div>
						{
							props.subTitle &&
									<div className={styles.subTitle}>{props.subTitle}</div>
						}
					</div>
				</div>
				{
					props.actions &&
							<div className={styles.actions}>
								{props.actions}
							</div>
				}
			</CardContent>
		);
	};

	return (
		<div>
			<Card className={classnames}>
				{
					props.onClick &&
					(
						<CardActionArea onClick={handleClick}>
							<Content/>
						</CardActionArea>
					)
				}
				{
					!props.onClick &&
					(
						<Content/>
					)
				}
			</Card>
		</div>
	);
};

export default ChatUserCard;
