import { Assistant } from '../../../interfaces/Assistant';
import { Form } from '../../../interfaces/FormData';
import { FormTextField } from './Fields';
import Modal from '../../Modal/Modal';
import React from 'react';
import usePersonalInfoModal from './usePersonalInfoModal';
import { useTranslation } from 'react-i18next';

export interface AssistantInfoModalProps {
	data: Assistant;
	open: boolean;
	getUser: () => Promise<void>;
	updateUser: (formData: Form) => Promise<void>;
	onChange: () => void;
}

const AssistantPersonalInfoModal: React.FC<AssistantInfoModalProps> = ({ data, open, getUser, updateUser, onChange }) => {
	const { t } = useTranslation();
	const {
		formData,
		formIsUpdated,
		handleChangeTextField,
		handleClose,
		handleConfirm,
	} = usePersonalInfoModal({ data, getUser, updateUser, onChange });

	return (
		<Modal
			open={open}
			title={t('profileView:PERSONAL_INFO')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: !formIsUpdated,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
			scroll="body"
		>
			<div>
				{
					formData && Object.keys(formData).length > 0
						?
						<>
							<FormTextField formValue={formData['familyName']} handleChangeTextField={handleChangeTextField} />
							<FormTextField formValue={formData['givenName']} handleChangeTextField={handleChangeTextField} />
						</>
						: <div>Loading</div>
				}
			</div>
		</Modal>
	);
};

export default AssistantPersonalInfoModal;
