import {
	addNewPlace,
	addNewSession,
	addNewSessionType,
	findAllPlacesByTherapist,
	findAllSessionsTypesByTherapist,
	findAvailableSessionByDate,
	findMyAvailableSessionByDate,
	findSessionById,
	findSessionEventsByPatient,
	findSessionEventsByTherapist,
	removeSession,
	requestNewSession,
	updateSessionData,
	updateStatus
} from '../api/SessionRepository';
import { DateCard, SiteDateCard } from '../interfaces/DateCard';
import { Office, Session, SessionEvent, SessionEventAPI, SessionType } from './../interfaces/Session';
import { AvailableTime } from '../interfaces/SessionAvailability';
import i18n from 'i18next';
import moment from 'moment';

export const getSessionsTypesByTherapist = async (therapistId: number | string): Promise<SessionType[]> => {
	return await findAllSessionsTypesByTherapist(therapistId);
};

export const getPlacesByTherapist = async (therapistId: number | string): Promise<Office[]> => {
	return await findAllPlacesByTherapist(therapistId);
};

const mapSessionEventAPIToSessionEvent = (sessionEvent: SessionEventAPI): SessionEvent => {
	return ({
		id: sessionEvent.id,
		date: moment(sessionEvent.date, 'YYYY-MM-DD').toDate(),
		startTime: moment(sessionEvent.startTime, 'HH:mm').local().format('HH:mm'),
		endTime: moment(sessionEvent.endTime, 'HH:mm').local().format('HH:mm'),
		type: {
			id: sessionEvent.therapistSessionType?.id,
			name: sessionEvent.therapistSessionType?.name,
			color: sessionEvent.telehealth ? '#45ddff' : '#fcba03',
		},
		site: sessionEvent.telehealth ? SiteDateCard.Telehealth : SiteDateCard.ConsultingRoom,
		patient: {
			id: sessionEvent.patient.id,
			givenName: sessionEvent.patient.givenName,
			familyName: sessionEvent.patient.familyName,
		},
		frequency: sessionEvent.frequency,
		confirmed: sessionEvent.status === 1,
		therapistOffice: sessionEvent.therapistOffice,
	});
};

const mapSessionEventAPIToDateCard = (sessionEvent: SessionEventAPI): DateCard => {
	return {
		id: sessionEvent.id ?? 0,
		startTime: moment(sessionEvent.date + ' ' + sessionEvent.startTime).toDate(),
		endTime: moment(sessionEvent.date + ' ' + sessionEvent.endTime).toDate(),
		status: sessionEvent.status,
		site: sessionEvent.telehealth ? SiteDateCard.Telehealth : SiteDateCard.ConsultingRoom,
		therapistOffice: {
			id: sessionEvent.therapistOffice?.id ?? 0,
			address: sessionEvent.therapistOffice?.address,
		},
	};
};

const mapAvailableTimeToDateCard = (data: AvailableTime, date: string): DateCard => {
	const dateonly = moment(date).format('YYYY-MM-DD');

	const startTimeDate = moment(dateonly + ' ' + data.startTime, 'YYYY-MM-DD HH:mm').toDate();
	const endTimeDate = moment(dateonly + ' ' + data.endTime, 'YYYY-MM-DD HH:mm').toDate();

	return {
		id: startTimeDate.getTime(),
		startTime: startTimeDate,
		endTime: endTimeDate,
		therapistOffice: data.therapistOffice,
	};
};

export const getSessionEventsByTherapist = async (therapistId: number | string, dayOfWeek: Date | null): Promise<Array<SessionEvent>> => {
	let week: moment.unitOfTime.StartOf;

	switch (i18n.language) {
		case 'es':
			week = 'isoWeek';
			break;
		case 'en':
		default:
			week = 'week';
			break;
	}

	const startDate = moment(dayOfWeek).startOf(week);
	const endDate = moment(dayOfWeek).startOf(week).add(6, 'days');
	const sessionEvents = await findSessionEventsByTherapist(therapistId, startDate.toDate(), endDate.toDate());

	return sessionEvents.map(sessionEvent => mapSessionEventAPIToSessionEvent(sessionEvent));
};

export const getSessionEventsByPatient = async (therapistId: number | string, startDate: Date): Promise<Array<DateCard>> => {
	const startDateDayOne = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
	const endDate = moment(startDateDayOne).add(1, 'months');

	const sessionEvents = await findSessionEventsByPatient(therapistId, startDateDayOne, endDate.toDate());

	return sessionEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map(sessionEvent => mapSessionEventAPIToDateCard(sessionEvent));
};

export const addSessionType = async (therapistId: number | string, sessionType: string): Promise<void> => {
	return await addNewSessionType(therapistId, sessionType);
};

export const addPlace = async (therapistId: number | string, place: string): Promise<void> => {
	return await addNewPlace(therapistId, place);
};

export const addSession = async (therapistId: number | string, session: Session): Promise<void> => {
	return await addNewSession(therapistId, session);
};

export const updateSession = async (therapistId: number | string, session: Session): Promise<void> => {
	return await updateSessionData(therapistId, session);
};

export const deleteSession = async (therapistId: number | string, sessionId: number): Promise<void> => {
	return await removeSession(therapistId, sessionId);
};

export const getAvailableSessionTherapistByDate = async (therapistId: number | string, startDate: string): Promise<DateCard[]> => {
	const availabilitiesSessionTime = await findAvailableSessionByDate(therapistId, startDate);

	return availabilitiesSessionTime.map(availabilitySessionTime => mapAvailableTimeToDateCard(availabilitySessionTime, startDate));
};

export const getMyAvailableSessionTherapistByDate = async (therapistId: number | string, startDate: string): Promise<DateCard[]> => {
	const availabilitiesSessionTime = await findMyAvailableSessionByDate(therapistId, startDate);

	return availabilitiesSessionTime.map(availabilitySessionTime => mapAvailableTimeToDateCard(availabilitySessionTime, startDate));
};

export const requestNewTherapistSession = async (therapistId: number | string, dateCard: DateCard): Promise<void> => {
	const session = {
		startDate: moment(dateCard.startTime).format('YYYY-MM-DD'),
		startTime: moment(dateCard.startTime).format('HH:mm'),
		endTime: moment(dateCard.endTime).format('HH:mm'),
	};

	await requestNewSession(therapistId, session);
};

export const updateSessionStatus = async (therapistId: number | string, sessionId: number, status: number): Promise<void> => {
	return await updateStatus(therapistId, sessionId, status);
};

export const getSessionById = async (therapistId: number | string, sessionId: number): Promise<Session> => {
	return await findSessionById(therapistId, sessionId);
};
