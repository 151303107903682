import Box from '@material-ui/core/Box';
import Modal from '../../Modal/Modal';
import React from 'react';
import TherapistCards from '../../Cards/TherapistCards/TherapistCards';
import { useTranslation } from 'react-i18next';
import useUpdateTherapistModal from './useUpdateTherapistModal';

interface UpdateTherapistModalProps {
	open: boolean;
	onChange: () => void;
}

const UpdateTherapistModal: React.FC<UpdateTherapistModalProps> = ({ open, onChange }) => {
	const { t } = useTranslation();
	const {
		selected,
		handleClose,
		handleConfirm,
		handleChangeTherapistSelected,
	} = useUpdateTherapistModal({ onChange });

	return (
		<Modal
			open={open}
			title={t('patientHomeView:MODAL_CHANGE_THERAPIST')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: !selected,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
		>
			<Box mb={2} mx={1}>
				<TherapistCards onClick={handleChangeTherapistSelected} />
			</Box>
		</Modal>
	);
};

export default UpdateTherapistModal;
