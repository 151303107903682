import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import styles from './FaqItem.module.scss';

interface FaqItemProps {
	title: string;
	body: string;
}

const FaqItem: React.FC<FaqItemProps> = ({ title, body }) => {
	const [expanded, setExpanded] = React.useState<boolean>(false);

	const handleChange = (event: unknown, isExpanded: boolean): void => {
		setExpanded(isExpanded);
	};

	return (
		<Accordion
			className={styles.head}
			expanded={expanded}
			onChange={handleChange}
		>
			<AccordionSummary
				className={`${styles['heading']} ${expanded && styles['Mui-expanded']}`}
				expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
			>
				<p className={styles.headingTitle} >{title}</p>
			</AccordionSummary>
			<AccordionDetails className={styles.body}>
				<p>{body}</p>
			</AccordionDetails>
		</Accordion>
	);
};

export default FaqItem;
