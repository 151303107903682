import { deleteTherapist, findInstitution, findTherapistToInvite, inviteTherapistById, sendInvitationToTherapist, updateAvatar, updateInstitutionInfo } from '../api/InstitutionRepository';
import { Institution, InstitutionTherapist } from '../interfaces/Institution';
import { BasicInfo } from '../interfaces/User';
import { convertFileToFormData } from './GenericService';
import { Form } from '../interfaces/FormData';
import { UserRowCardProps } from '../components/Cards/UserRowCard/UserRowCard';

export interface TherapistToInvite extends UserRowCardProps {
	invited?: boolean;
}

const mapFormToInstitution = (form: Form): Institution => {
	return {
		name: form.name.value.toString(),
	} as Institution;
};

const mapInstitutionTherapistToUserRow = (therapit: InstitutionTherapist): TherapistToInvite => {
	return {
		avatar: therapit.avatarFile?.url,
		invited: therapit.invited,
		userId: therapit.id,
		title: `${therapit.givenName} ${therapit.givenName}`,
	};
};

export const getProfile = async (): Promise<Institution> => {
	return await findInstitution();
};

export const getTherapistToInvite = async (): Promise<UserRowCardProps[]> => {
	const therapits = await findTherapistToInvite();

	return therapits.map(mapInstitutionTherapistToUserRow);
};

export const sendInvitation = async (email: string, lastName: string, name: string): Promise<void> => {
	const data: BasicInfo = { name, lastName, email };

	return await sendInvitationToTherapist(data);
};

export const inviteTherapist = async (therapistId: number): Promise<void> => {
	return await inviteTherapistById(therapistId);
};

export const updateInstitutionAvatar = async (files: File[]): Promise<void> => {
	const data = await convertFileToFormData(files[0]);

	return await updateAvatar(data);
};

export const updateInstitution = async (institution: Form): Promise<void> => {
	return await updateInstitutionInfo(mapFormToInstitution(institution));
};

export const removeTherapist = async (therapistId: number): Promise<void> => {
	return await deleteTherapist(therapistId);
};
