
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import styles from './DatePicker.module.scss';

interface DatePickerProps {
	value: Date | null;
	onChange: (value: Date | null) => void;
	fullWidth?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
	return (
		<KeyboardDatePicker
			className={styles.datePicker}
			placeholder="2018/10/10"
			value={props.value}
			onChange={(date: Date | null): void => props.onChange(date)}
			inputVariant="outlined"
			size="small"
			format="MM/dd/yyyy"
			fullWidth={props.fullWidth}
		/>
	);
};

export default DatePicker;
