import { CalendarEvent, SessionEvent } from '../../interfaces/Session';
import moment from 'moment';
import React from 'react';

interface CalendarProps {
	sessions: SessionEvent[];
	onClickSessionCard?: (sessionInfo: SessionEvent) => void;
}

interface CalendarStore {
	events: CalendarEvent[];
	onSelectEvent: (eventId: number) => void;
}

const useCalendar = (props: CalendarProps): CalendarStore => {
	const [events, setEvents] = React.useState<CalendarEvent[]>([]);
	const { sessions, onClickSessionCard } = props;

	React.useEffect(() => {
		const events: CalendarEvent[] = sessions.map(session => {
			const date = new Date(session.date);
			const startTime = moment(session.startTime, 'HH:mm');
			const endTime = moment(session.endTime, 'HH:mm');

			return ({
				...session,
				id: session.id!,
				title: `${session.patient.givenName[0]}. ${session.patient.familyName}`,
				start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), startTime.hour(), startTime.minute()),
				end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), endTime.hour(), endTime.minute()),
			});
		});

		setEvents(events);
	}, [sessions]);

	const onSelectEvent = (eventId: number): void => {
		const session = sessions.find(session => session.id === eventId);

		if (session && onClickSessionCard) {
			onClickSessionCard(session);
		}
	};

	return {
		events,
		onSelectEvent,
	};
};

export default useCalendar;
