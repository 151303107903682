import io, { Socket } from 'socket.io-client';
import { ChatEvents } from '../interfaces/Chat';
import { getAuthToken } from '../services/SessionStorageService';
import React from 'react';
import { refreshToken } from '../services/axiosInterceptor';

interface UseSocketStore {
	socket: Socket | null;
	connectSocket: () => void;
	disconnectSocket: () => void;
}

export const useSocket = (serverPath: string): UseSocketStore => {
	const [socket, setSocket] = React.useState<Socket | null>(null);

	const connectSocket = React.useCallback(() => {
		const socketTemp = io(serverPath, {
			autoConnect: true,
			forceNew: true,
			transports: ['websocket'],
			auth: {
				token: getAuthToken(),
			},
		});

		setSocket(socketTemp);

	}, [serverPath]);

	React.useEffect((): void => {
		socket?.on(ChatEvents.connectError, (error) => {
			if (error?.httpCode === 401) {
				refreshToken().then(() => {
					connectSocket();
				});
			}
		});
	}, [connectSocket, socket]);

	const disconnectSocket = React.useCallback(() => {
		socket?.disconnect();
	}, [socket]);

	return {
		socket,
		connectSocket,
		disconnectSocket,
	};
};
