import { getAvailableSessionTherapistByDate, getMyAvailableSessionTherapistByDate } from '../../services/SessionService';
import { DateCard } from '../../interfaces/DateCard';
import { Frequency } from '../../interfaces/Session';
import React from 'react';
import { useTherapistSelected } from '../../hooks/useTherapistSelected';

interface SessionPickerProps {
	sessionSelected: DateCard | null;
	therapistId?: number;
	endDate?: Date | null;
	frequency: Frequency;
	onChange?: () => void;
	onSessionChange: (sessionCard: DateCard) => void;
}

interface SessionPickerStore {
	availableDates: Array<DateCard>;
	sessionDate: Date | null;
	handleChangeSessionDate: (sessionDate: Date | null) => void;
	handleChangeSessionSelected: (sessionId: string) => void;
}

const useSessionPicker = (props: SessionPickerProps): SessionPickerStore => {
	const { sessionSelected, frequency, endDate, therapistId = null, onSessionChange } = props;
	const [availableDates, setAvailableDates] = React.useState<Array<DateCard>>([]);
	const [sessionDate, setSessionDate] = React.useState<Date | null>(sessionSelected?.startTime ? new Date(sessionSelected?.startTime) : new Date());
	const { getTherapistIdParam } = useTherapistSelected();

	const fetch = async (): Promise<void> => {
		if (sessionDate) {
			let availableTime = null;

			if (therapistId !== null) {
				availableTime = await getAvailableSessionTherapistByDate(therapistId, sessionDate.toString());
			} else {
				const therapistIdParam = getTherapistIdParam();

				availableTime = await getMyAvailableSessionTherapistByDate(therapistIdParam, sessionDate.toString());
			}

			setAvailableDates(availableTime);
		}
	};

	React.useEffect(() => {
		fetch();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [therapistId, sessionDate, frequency, endDate]);

	const handleChangeSessionSelected = (sessionId: string): void => {
		const availableDate = availableDates.find(date => date.id.toString() === sessionId);

		if (availableDate) {
			onSessionChange(availableDate);
		}
	};

	const handleChangeSessionDate = (value: Date | null): void => {
		setSessionDate(value);
	};

	return {
		availableDates,
		sessionDate,
		handleChangeSessionDate,
		handleChangeSessionSelected,
	};
};

export default useSessionPicker;
