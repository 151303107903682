import { FaqItem } from '../../components';
import React from 'react';
import styles from './Faq.module.scss';
import { useTranslation } from 'react-i18next';

const faqData: [string, string][] = [
	['faq:WHAT_IS_PRAGMA_QUESTION', 'faq:WHAT_IS_PRAGMA_ANSWER'],
	['faq:WHO_CAN_USE_PRAGMA_QUESTION', 'faq:WHO_CAN_USE_PRAGMA_ANSWER'],
	['faq:HOW_TO_REGISTER_QUESTION', 'faq:HOW_TO_REGISTER_ANSWER'],
	['faq:FOR_WHICH_DEVICES_QUESTION', 'faq:FOR_WHICH_DEVICES_ANSWER'],
	['faq:COSTS_QUESTION', 'faq:COSTS_ANSWER'],
	['faq:TEAMS_PLANS_QUESTION', 'faq:TEAMS_PLANS_ANSWER'],
	['faq:HOW_LONG_TO_SETUP_QUESTION', 'faq:HOW_LONG_TO_SETUP_ANSWER'],
	['faq:WHAT_SUPPORT_QUESTION', 'faq:WHAT_SUPPORT_ANSWER'],
	['faq:CAN_I_SUGGEST_QUESTION', 'faq:CAN_I_SUGGEST_ANSWER'],
	['faq:HOW_CAN_I_CANCEL_QUESTION', 'faq:HOW_CAN_I_CANCEL_ANSWER'],
	['faq:WHERE_IS_MY_DATA_QUESTION', 'faq:WHERE_IS_MY_DATA_ANSWER'],
];

const Faq = (): JSX.Element => {
	const { t } = useTranslation();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<h1 className={styles.faqTitle}>{t('faq:FAQ_TITLE')}</h1>
			<div className={styles.faqItems}>
				{
					faqData.map(([title, body]) => {
						return (
							<FaqItem key={title} title={t(title)} body={t(body)} />
						);
					})
				}
			</div>

		</>
	);
};

export default Faq;
