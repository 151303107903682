import { PrivateTopBar, ProfileCard, ProfilePictureCard } from '../../../components';
import AssistantPersonalInfoModal from '../../../components/Modals/PersonalInfoModal/AssistantPersonalInfoModal';
import Grid from '@material-ui/core/Grid';
import { keyValue } from '../../../interfaces/Common';
import React from 'react';
import styles from './Profile.module.scss';
import useProfile from './useProfile';
import { useTranslation } from 'react-i18next';

const Profile = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		isLoading,
		assistantInfo,
		openPersonalInfoModal,
		handleOpenPersonalInfoModal,
		handleUpdateAvatar,
		loadAssistant,
		updateAssistantInfo,
	} = useProfile();

	const personalInfoFields = ['email', 'familyName', 'givenName'];

	return (
		<div className={styles.profile}>
			<PrivateTopBar
				title={t('profileView:TITLE_PROFILE')}
			/>
			<div className={styles.container}>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={4}>
						<ProfilePictureCard fullName={`${assistantInfo.givenName} ${assistantInfo.familyName}`} image={assistantInfo?.avatarFile?.url} onSave={handleUpdateAvatar} isLoading={isLoading} isAllowedToEdit/>
					</Grid>
				</Grid>
				<Grid container spacing={1} style={{ marginTop: 10 }}>
					<Grid item xs={12} sm={4}>
						<ProfileCard title={t('profileView:PERSONAL_INFO')} showEditBtn onClickBtn={handleOpenPersonalInfoModal} data={assistantInfo as unknown as keyValue} fieldsToDisplay={personalInfoFields}/>
					</Grid>
				</Grid>
			</div>
			{
				openPersonalInfoModal &&
				<AssistantPersonalInfoModal data={assistantInfo} open={openPersonalInfoModal} onChange={handleOpenPersonalInfoModal} getUser={loadAssistant} updateUser={updateAssistantInfo} />
			}
		</div >
	);
};

export default Profile;
