import FormControl from '@material-ui/core/FormControl';
import { Select as MaterialSelect } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Option } from '../../interfaces/Common';
import React from 'react';
import styles from './Select.module.scss';

interface SelectProps {
	options: Option[];
	defaultValue?: Option['key'];
	dense?: boolean;
	fullWidth?: boolean;
	onChange: (event: React.ChangeEvent<{value: unknown}>) => void;
	value?: string;
}

const Select: React.FC<SelectProps> = ({ options, defaultValue, dense, fullWidth, onChange, value }): JSX.Element => {
	return (
		<div className={styles.select}>
			<FormControl variant="outlined" size="small" fullWidth={fullWidth}>
				<MaterialSelect
					classes={{ root: styles.root, icon: styles.icon }}
					color="primary"
					id="demo-simple-select-outlined"
					value={value ? value : defaultValue}
					onChange={onChange}
					variant="outlined"
				>
					<MenuItem value={undefined} dense={dense}>
						<em>None</em>
					</MenuItem>
					{options.map(option =>
						<MenuItem key={option.key} value={option.key} dense={dense}>{option.value}</MenuItem>
					)}
				</MaterialSelect>
			</FormControl>
		</div>
	);};

export default Select;
