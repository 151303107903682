import { Section } from './DocumentForm';
import { Tag } from './Tag';
import { UserRole } from './User';

export enum StatusDocumentCard {
	Unread = 0,
	Read = 1,
	IsReady = 2,
}

export interface Document {
	id: number;
	name: string;
	description: string;
	createdAt: string;
	avatarCompletedBy?: JSX.Element;
	iconFile?: JSX.Element;
	tags: Tag[];
	sections: Section[];
}

export interface DocumentCard extends Document {
	selected?: boolean;
	sharedAvatar?: JSX.Element;
	hasBeenRead?: StatusDocumentCard;
	reportDocument?: Blob | null;
}

export interface DocumentShared extends DocumentCard {
	sharedDocumentId: number;
	linkedAt: Date;
	sharedAt: Date;
	documentId: number;
	shared: boolean;
	userRole?: UserRole;
	status: 'COMPLETED' | 'UNCOMPLETED';
}

export interface DocumentSharedBody {
	id: number;
	content: string;
}
