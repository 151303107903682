import { CardsTable, ResourcePreview } from '../../../../../components';
import useResources, { ResourcesProps } from './useResources';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Modal from '../../../../../components/Modal/Modal';
import React from 'react';
import { ResourceCard } from '../../../../../interfaces/Resource';
import SectionTitle from '../../../../../components/SectionTitle/SectionTitle';
import styles from './Resources.module.scss';
import { useTranslation } from 'react-i18next';

const Resources = (props: ResourcesProps): JSX.Element => {
	const {
		resources,
		columns,
		isLoading,
		openUnlinkResourceModal,
		openShareResourceModal,
		selectedResource,
		gridWidth,
		handleCloseUnlinkResourceModal,
		handleConfirmUnlinkResource,
		handleConfirmShareResource,
		handleClosePreviewCard,
		handleCloseShareModal } = useResources(props);
	const { t } = useTranslation();

	return (
		<div className={styles.resources}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={gridWidth} >
					<CardsTable data={resources} columnsDef={columns} isLoading={isLoading} />
				</Grid>
				{
					selectedResource && gridWidth == 6 &&
					<Grid item xs={12} sm={gridWidth} >
						<div style={{ display: 'flex' }}>
							<SectionTitle title={t('resourceLibraryView:PREVIEW_CARD_HEADER')} showDivider={false} />
							<Button
								className={styles.closeIcon}
								startIcon={<CloseIcon />}
								onClick={handleClosePreviewCard}
							>
								{t('patientsView:CLOSE_BUTTON')}
							</Button>
						</div>
						<div className={styles.column}>
							<ResourcePreview resource={selectedResource as ResourceCard} showTitle={false} />
						</div>
					</Grid>
				}
			</Grid>
			<Modal
				open={openUnlinkResourceModal}
				title={t('patientsView:UNLINK_RESOURCE')}
				handleOnClose={handleCloseUnlinkResourceModal}
				isPrimaryButtonVisible
				primaryButtonProps={{
					title: t('patientsView:REPORTS_CONFIRM_BUTTON'),
					onClick: handleConfirmUnlinkResource,
					isDisabled: false,
				}}
				isSecondaryButtonVisible
				secondaryButtonProps={{
					title: t('patientsView:MODAL_BUTTON_CANCEL'),
					onClick: handleCloseUnlinkResourceModal,
				}}
				showDividers={false}
			>
				{t('patientsView:UNLINK_RESOURCE_MODAL_CONTENT')}
			</Modal>
			<Modal
				open={openShareResourceModal}
				title={t('patientsView:SHARE_RESOURCE')}
				handleOnClose={handleCloseShareModal}
				isPrimaryButtonVisible
				primaryButtonProps={{
					title: t('patientsView:REPORTS_CONFIRM_BUTTON'),
					onClick: handleConfirmShareResource,
					isDisabled: false,
				}}
				isSecondaryButtonVisible
				secondaryButtonProps={{
					title: t('patientsView:MODAL_BUTTON_CANCEL'),
					onClick: handleCloseShareModal,
				}}
				showDividers={false}
			>
				{t('patientsView:SHARE_RESOURCE_MODAL_CONTENT')}
			</Modal>
		</div >
	);
};

export default Resources;
