/* eslint-disable @typescript-eslint/member-delimiter-style */
import { ChatContact, ChatMessage } from '../interfaces/Chat';

export interface ChatStateInterface {
	activeContact: ChatContact | undefined;
	contacts: ChatContact[];
	messages: ChatMessage[];
}

export type ChatActions =
	{type: 'ACTIVATE_CHAT', activeContact: ChatContact} |
	{type: 'CLEAN_CHAT'} |
	{type: 'LOAD_CONTACTS', contacts: ChatContact[]} |
	{type: 'LOAD_MESSAGES', messages: ChatMessage[]} |
	{type: 'NEW_MESSAGE', message: ChatMessage};

export const chatReducer = ( state: ChatStateInterface, action: ChatActions ): ChatStateInterface => {
	switch ( action.type ) {
		case 'ACTIVATE_CHAT':
			if ( state.activeContact === action.activeContact ) {
				return state;
			}

			return {
				...state,
				activeContact: action.activeContact,
				messages: [],
			};
		case 'CLEAN_CHAT':
			return {
				...state,
				activeContact: undefined,
				messages: [],
			};

		case 'LOAD_CONTACTS': {
			const contacts = action.contacts.map((contact: ChatContact) => {
				if ( contact.id === state.activeContact?.id ) {
					return {
						...contact,
						unreadMessages: 0,
					};
				}

				return contact;
			});

			return {
				...state,
				contacts,
			};
		}

		case 'LOAD_MESSAGES':
			return {
				...state,
				messages: [ ...action.messages ],
			};
		case 'NEW_MESSAGE':
			if ( state.activeContact?.id === action.message.fromId || state.activeContact?.id === action.message.toId
			) {
				return {
					...state,
					messages: [...state.messages, action.message],
				};
			}

			return state;
		default:
			return state;
	}

};

