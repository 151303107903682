import Button, { ButtonProps } from '../Button/Button';
import { Close } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import styles from './Modal.module.scss';

interface ModalProps {
	open: boolean;
	handleOnClose: () => void;
	title?: string;
	isPrimaryButtonVisible: boolean;
	primaryButtonProps?: ButtonProps;
	isSecondaryButtonVisible: boolean;
	secondaryButtonProps?: ButtonProps;
	isTertiaryButtonVisible?: boolean;
	tertiaryButtonProps?: ButtonProps;
	leftActionButtons?: ButtonProps[];
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	showDividers?: boolean;
	scroll?: 'body' | 'paper';
}

const Modal: React.FC<ModalProps> = (props) => {
	return (
		<div className={styles.container}>
			<Dialog
				open={props.open}
				onClose={props.handleOnClose}
				fullWidth
				maxWidth={props.size ?? 'sm'}
				PaperProps={{ classes: { rounded: styles.containerRounded } }}
				scroll={props.scroll ?? 'paper'}
			>
				<div className={styles.title}>
					{
						props.title &&
						<div className={styles.titleText}>
							{props.title}
							{
								props.isTertiaryButtonVisible && props.tertiaryButtonProps
								&& (
									<div className={styles.tertiaryButton}>
										<Button
											type="TERTIARY"
											startIcon={<SettingsOutlinedIcon />}
											title={props.tertiaryButtonProps.title}
											variant="text"
											onClick={props.tertiaryButtonProps.onClick}
											isDisabled={props.tertiaryButtonProps.isDisabled}
										/>
									</div>
								)
							}
						</div>
					}
					<IconButton
						className={styles.titleCloseIcon}
						onClick={props.handleOnClose}
					>
						<Close />
					</IconButton>
				</div>
				<DialogContent className={styles.content} dividers={props.showDividers ?? true}>
					{props.children}
				</DialogContent>
				{
					(props.leftActionButtons?.length
						|| props.isSecondaryButtonVisible
						|| props.isPrimaryButtonVisible)
					&&
					<DialogActions
						className={styles.dialogActions}
					>
						{
							props.leftActionButtons?.length &&
							<div className={styles.actionsButtons}>
								{
									props.leftActionButtons.map(button => button.show && (
										<div style={{ marginLeft: 8 }}>
											<Button
												type="SECONDARY"
												title={button.title}
												onClick={button.onClick}
												isDisabled={button.isDisabled}
											/>
										</div>
									))
								}
							</div>
						}
						{
							props.isSecondaryButtonVisible && props.secondaryButtonProps
							&& (
								<Button
									type="SECONDARY"
									title={props.secondaryButtonProps.title}
									onClick={props.secondaryButtonProps.onClick}
									isDisabled={props.secondaryButtonProps.isDisabled}
								/>
							)
						}
						{
							props.isPrimaryButtonVisible && props.primaryButtonProps
							&& (
								<Button
									type="PRIMARY"
									title={props.primaryButtonProps.title}
									onClick={props.primaryButtonProps.onClick}
									isDisabled={props.primaryButtonProps.isDisabled}
								/>
							)
						}
					</DialogActions>
				}
			</Dialog>
		</div >
	);
};

export default Modal;
