import { acceptRequest, getTherapistRequests, rejectRequest } from '../../../services/PatientService';
import { ConfirmationModalInterface, ConfirmationTypes } from '../../../interfaces/ConfirmationModels';
import { PatientTherapistRequest } from '../../../interfaces/Patient';
import React from 'react';

interface UseInvitationsStore {
	therapistRequests: PatientTherapistRequest[];
	openConfirmationModal: boolean;
	confirmationModalState: ConfirmationModalInterface;
	handleAcceptTherapist: (therapistId: number) => Promise<void>;
	handleConfirmationModal: (confirmationType: ConfirmationTypes, therapistId: number) => void;
}

export const useInvitations = (): UseInvitationsStore => {
	const [therapistRequests, setTherapistRequests] = React.useState<PatientTherapistRequest[]>([]);
	const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);
	const [confirmationModalState, setConfirmationModalState] = React.useState<ConfirmationModalInterface>({} as ConfirmationModalInterface);

	const loadData = async(): Promise<void> => {
		const therapistRequests = await getTherapistRequests();

		setTherapistRequests(therapistRequests);
	};

	const handleCloseConfirmationModal = (): void => {
		setOpenConfirmationModal(false);
	};

	const handleAcceptTherapist = async(therapistId: number): Promise<void> => {
		await acceptRequest(therapistId);
		await loadData();
	};

	const handleRejectTherapist = async(therapistId: number): Promise<void> => {
		await rejectRequest(therapistId);
		handleCloseConfirmationModal();
		await loadData();
	};

	React.useEffect(() => {
		loadData();
	}, []);

	const handleConfirmationModal = (confirmationType: ConfirmationTypes, therapistId: number): void => {
		switch (confirmationType) {
			case ConfirmationTypes.cancelTherapistInvitation:
				setConfirmationModalState({
					content: 'patientInvitationsView:CANCEL_THERAPIST_INVITATION_MESSAGE',
					title: 'patientInvitationsView:WARNING_MEESSAGE',
					onClose: handleCloseConfirmationModal,
					onConfirm: async(): Promise<void> => await handleRejectTherapist(therapistId),
				});
				setOpenConfirmationModal(true);

				break;
			default:
				break;
		}
	};

	return {
		therapistRequests,
		openConfirmationModal,
		confirmationModalState,
		handleAcceptTherapist,
		// handleRejectTherapist,
		handleConfirmationModal,
	};
};
