import { ChatForm } from '../ChatForm/ChatForm';
import ChatUserCard from '../ChatUserCard/ChatUserCard';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import IncomingMessage from '../IncomingMessage/IncomingMessage';
import { isToday } from '../../../helpers/DateTime';
import moment from 'moment';
import OutgoingMessage from '../OutgoingMessage/OutgoingMessage';
import React from 'react';
import styles from './ChatContent.module.scss';
import { useChatContent } from './useChatContent';
import { useTranslation } from 'react-i18next';

const ChatContent: React.FC = () => {
	const { activeContact, currentUserId, messageDivRef, messages, cleanChat, handleSubmit } = useChatContent();
	const { t } = useTranslation();
	let lastDisplayedDate: string = '';

	return (
		<div className={styles.chatContent}>
			{
				activeContact?.id &&
			(
				<>
					<ChatUserCard
						avatar={activeContact?.avatar}
						className={styles.chatUserCard}
						title={activeContact?.name || ''}
						userId={activeContact.id}
						actions={
							<IconButton
								onClick={(): void => cleanChat()}
							>
								<CloseIcon />
							</IconButton>
						}
					/>
					<ul className={styles.messages} ref={messageDivRef}>
						{messages.map((message) => {
							const messageDate = moment(message.createdAt).format('DD/MM/YYYY');

							const messagesToDisplay: JSX.Element[] = [
								message.toId === currentUserId
									? <IncomingMessage key={message.id} text={message.messages} date={message.createdAt}/>
									: <OutgoingMessage key={message.id} text={message.messages} date={message.createdAt}/>,
							];

							if (messageDate !== lastDisplayedDate) {
								messagesToDisplay.unshift(
									<div key={messageDate} className={styles.bubbleDate}>
										{
											isToday(message.createdAt) ? t('chatView:TODAY') : messageDate
										}
									</div>
								);

								lastDisplayedDate = messageDate;
							}

							return messagesToDisplay;
						}
						)}
					</ul>
					<div className={styles.chatForm}>
						<ChatForm
							handleSubmit={handleSubmit}
						/>
					</div>
				</>
			)
			}
			{
				!activeContact?.id &&
				(
					<h1>{t('chatView:NO_USER_SELECTED')}</h1>
				)
			}
		</div>
	);
};

export default ChatContent;
