export default {
	es: {
		about: {
			MAIN_TITLE: 'Una solución que lo ayudará a hacer crecer su práctica.',
			MAIN_DESCRIPTION: 'Como profesionales de la salud mental, sabemos que su objetivo principal es lograr los mejores resultados con sus pacientes. Mientras tanto, tiene la obligación y el estrés que conlleva desarrollar su propia práctica. Con PRAGMA, buscamos proporcionar la solución que lo ayudrá a hacer crecer su práctica. Hemos creado una aplicación de software asequible e impactante para ayudarlo a enfocar su tiempo y energía en lo que más disfruta: ayudar a sus pacientes. Estamos convencidos de que nuestras herramientas pueden optimizar los tratamientos y conducir a mejores resultados.',
			MAIN_TEAM_TITLE: 'El equipo detrás de Pragma',
			TREATMENT_TITLE: 'Planes de tratamiento',
			TREATMENT_ADULT_TITLE: 'Psicoterapia en adultos',
			TREATMENT_ADULT_DESCRIPTION: 'Descripción o información adicional lorem ipsum dolor sit amet',
			TREATMENT_TEEN_TITLE: 'Psicoterapia en adolescentes',
			TREATMENT_TEEN_DESCRIPTION: 'Descripción o información adicional lorem ipsum dolor sit amet',
			TREATMENT_CHILDREN_TITLE: 'Psicoterapia en niños',
			TREATMENT_CHILDREN_DESCRIPTION: 'Descripción o información adicional lorem ipsum dolor sit amet',
			TREATMENT_OLDER_TITLE: 'Psicoterapia en adultos mayores',
			TREATMENT_OLDER_DESCRIPTION: 'Descripción o información adicional lorem ipsum dolor sit amet',
			TREATMENT_LANGUAGE_TITLE: 'Language therapy',
			TREATMENT_LANGUAGE_DESCRIPTION: 'Descripción o información adicional lorem ipsum dolor sit amet',
			TREATMENT_PSYCHIATRY_TITLE: 'Terapia para psiquiatría',
			TREATMENT_PSYCHIATRY_DESCRIPTION: 'Descripción o información adicional lorem ipsum dolor sit amet',
			TREATMENT_PSYCHOPEDAGOGICAL_TITLE: 'Tratamiento psicopedagógico',
			TREATMENT_PSYCHOPEDAGOGICAL_DESCRIPTION: 'Descripción o información adicional lorem ipsum dolor sit amet',
			RESOURCES_TITLE: 'Recursos psicoeducativos',
			BUSINESS_TITLE: 'Empresas asociadas',
			RESOURCES_PSYCHOEDUCATIONAL: 'Recursos psicoeducativos en salud mental',
			RESOURCES_PSYCHOTHERAPY: 'Psicoterapia cognitiva. Manual psicoeducativo del trabajo clínico',
			RESOURCES_TOOLS: 'Caja de herramientas para TCC',
			TEAM_NAME_1: 'Martín Jozami',
			TEAM_TITLE_1: 'Lic. en Psicología. Fundador de Pragma',
			TEAM_DESCRIPTION_1: 'Profesor, Investigador y Supervisor | Beneficiario beca doctoral CONICET | Mg. En Neuropsicología | Mg. En Psicoterapia Cognitiva | Posgrado en evaluación neuropsicológica | Posgrado en Neuropsicología Infantojuvenil',
			TEAM_NAME_2: 'Laurencia Moyano',
			TEAM_TITLE_2: 'Lic. en Psicología',
			TEAM_DESCRIPTION_2: 'Profesora Universitaria | Especialista en Psicoterapia Cognitiva | Posgrado en Evaluación y Diagnóstico Neuropsicológico | Posgrado en Terapias Cognitivas de Tercera Generación',
			TEAM_NAME_3: 'María José Gutierrez',
			TEAM_TITLE_3: 'Lic. en Psicología',
			TEAM_DESCRIPTION_3: 'Profesora Universitaria | Investigadora (becaria doctoral CONICET | Especialista en Psicoterapia Cognitiva | Posgrado en evaluación neuropsicológica | Posgrado en Neuropsicología Infantojuvenil | Autora de diversos artículos de investigación con referato',
			TEAM_NAME_4: 'Axioma Studios',
			TEAM_TITLE_4: 'Socio Tecnológico',
			TEAM_DESCRIPTION_4: 'Equipo de profesionales especializados en las últimas tendencias de computación a cargo del desarrollo de Pragma',
		},
	},
	en: {
		about: {
			MAIN_TITLE: 'A solution that will help you grow your practice.',
			MAIN_DESCRIPTION: 'As mental health professionals, we know that your primary goal is to achieve the better results with your patients. In the meantime, you have the obligations and stress that comes with developing your own practice. With PRAGMA, we seek to provide the solution that helps you grow your practice. We have created an affordable and impactful software application to help you focus your time and energy on what you enjoy the most - helping your patients.	We are convinced that our tools can optimize treatments and lead to better results.',
			MAIN_TEAM_TITLE: 'The team behind Pragma',
			TREATMENT_TITLE: 'Treatment plans',
			TREATMENT_ADULT_TITLE: 'Psychotherapy in adults',
			TREATMENT_ADULT_DESCRIPTION: 'Descripcion o información adicional lorem ipsum dolor sit amet',
			TREATMENT_TEEN_TITLE: 'Psychotherapy in adolescents',
			TREATMENT_TEEN_DESCRIPTION: 'Descripcion o información adicional lorem ipsum dolor sit amet',
			TREATMENT_CHILDREN_TITLE: 'Psychotherapy in children',
			TREATMENT_CHILDREN_DESCRIPTION: 'Descripcion o información adicional lorem ipsum dolor sit amet',
			TREATMENT_OLDER_TITLE: 'Psychotherapy in older adults',
			TREATMENT_OLDER_DESCRIPTION: 'Descripcion o información adicional lorem ipsum dolor sit amet',
			TREATMENT_LANGUAGE_TITLE: 'Terapia del lenguaje',
			TREATMENT_LANGUAGE_DESCRIPTION: 'Descripcion o información adicional lorem ipsum dolor sit amet',
			TREATMENT_PSYCHIATRY_TITLE: 'Therapy for psychiatry',
			TREATMENT_PSYCHIATRY_DESCRIPTION: 'Descripcion o información adicional lorem ipsum dolor sit amet',
			TREATMENT_PSYCHOPEDAGOGICAL_TITLE: 'Psychopedagogical treatment',
			TREATMENT_PSYCHOPEDAGOGICAL_DESCRIPTION: 'Descripcion o información adicional lorem ipsum dolor sit amet',
			RESOURCES_TITLE: 'Psychoeducational resources',
			BUSINESS_TITLE: 'Affiliates',
			RESOURCES_PSYCHOEDUCATIONAL: 'Psychoeducational resources in mental health',
			RESOURCES_PSYCHOTHERAPY: 'Cognitive psychotherapy. Psychoeducational manual clinical work',
			RESOURCES_TOOLS: 'Toolbox for TCC',
			TEAM_NAME_1: 'Martín Gabriel Jozami Nassif',
			TEAM_TITLE_1: 'Lic. in Psychology. Founder of Pragma ',
			TEAM_DESCRIPTION_1: 'Teacher, Researcher and Supervisor | CONICET doctoral scholarship recipient | Mg. In Neuropsychology | Mg. In Cognitive Psychotherapy | Postgraduate degree in neuropsychological evaluation | Postgraduate in Child and Youth Neuropsychology ',
			TEAM_NAME_2: 'Laurencia Moyano',
			TEAM_TITLE_2: 'Lic. in Psychology ',
			TEAM_DESCRIPTION_2: 'University Professor | Cognitive Psychotherapy Specialist | Postgraduate in Neuropsychological Assessment and Diagnosis | Postgraduate in Third Generation Cognitive Therapies',
			TEAM_NAME_3: 'María José Gutierrez',
			TEAM_TITLE_3: 'Lic. in Psychology ',
			TEAM_DESCRIPTION_3: 'University Professor | Researcher (CONICET doctoral fellow | Specialist in Cognitive Psychotherapy | Postgraduate in Neuropsychological Assessment | Postgraduate in Child and Adolescent Neuropsychology | Author of various research articles with reference ',
			TEAM_NAME_4: 'Axioma Studios',
			TEAM_TITLE_4: 'Technological Partner',
			TEAM_DESCRIPTION_4: 'Team of professionals specialized in the latest computing trends in charge of the development of Pragma',
		},
	},
};
