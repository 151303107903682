import { Card, CombinedCard } from '../../components';
import agenda from '../../assets/images/icon-agenda-tareas.png';
import funcCardDocumentation from '../../assets/images/func-card-documentation.png';
import funcCardMore from '../../assets/images/func-card-more.png';
import funcCardResources from '../../assets/images/func-card-resources.png';
import functionalityImage from '../../assets/images/billboard-img-funtionality.png';
import PortalSection from './PortalSection';
import React from 'react';
import reminder from '../../assets/images/icon-reminder.png';
import SecuritySection from './SecuritySection';
import styles from './Functions.module.scss';
import synchronize from '../../assets/images/icon-synchronization.png';
import { useTranslation } from 'react-i18next';

const Functions = (): JSX.Element => {
	const { t } = useTranslation();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const calendarCards: [string, string, string][] = [
		[
			agenda,
			t('functions:CARD_CALENDAR_USE_TITLE'),
			t('functions:CARD_CALENDAR_USE_DESCRIPTION'),
		],
		[
			synchronize,
			t('functions:CARD_CALENDAR_SYNC_TITLE'),
			t('functions:CARD_CALENDAR_SYNC_DESCRIPTION'),
		],
		[
			reminder,
			t('functions:CARD_CALENDAR_SYNC_TITLE'),
			t('functions:CARD_CALENDAR_SYNC_DESCRIPTION'),
		],
	];

	const morePragmaCards: [string, string, string, string][] = [
		[
			funcCardMore,
			t('functions:CARD_MORE_HIGHLIGHT'),
			t('functions:CARD_MORE_TITLE'),
			t('functions:CARD_MORE_DESCRIPTION'),
		],
		[
			funcCardDocumentation,
			t('functions:CARD_DOCUMENTATION_HIGHLIGHT'),
			t('functions:CARD_DOCUMENTATION_TITLE'),
			t('functions:CARD_DOCUMENTATION_DESCRIPTION'),
		],
		[
			funcCardResources,
			t('functions:CARD_RESOURCES_HIGHLIGHT'),
			t('functions:CARD_RESOURCES_TITLE'),
			t('functions:CARD_RESOURCES_DESCRIPTION'),
		],
	];

	return (
		<>
			<div className={styles.functionMain}>
				<div className={styles.functionMainContent}>
					<div className={styles.functionsImageContainer}>
						<img src={functionalityImage} alt="" className={styles.functionsImage} />
					</div>
					<div className={styles.content}>
						<span className={styles.contentTitle}>{t('functions:FUNCTION_MAIN_TITLE')}</span>
						{/* <div className={styles.bottomComponent}>
							<Button onClick={console.log} title={t('buttons:TRY_NOW_FOR_FREE')} type={'PRIMARY'} />
						</div> */}
					</div>
				</div>
			</div>
			<div className={styles.functionContainer}>
				<div className={styles.functionCalendar}>
					<h2 className={styles.functionCalendarTitle}>{t('functions:FUNCTION_CALENDAR')}</h2>
					<div className={styles.calendarCardsContainer}>
						{
							calendarCards.map(([src, title, desc]) =>
								<Card className={styles.cardContainer} key={title} >
									<img src={src} className={styles.image} />
									<h4 className={styles.cardTitle}>{title}</h4>
									<p className={styles.cardDescription}>{desc}</p>
								</Card>)
						}
					</div>
				</div>
				<PortalSection />
				<div className={styles.functionMore}>
					<h2 className={styles.titleMore}>{t('functions:FUNCTION_MORE')}</h2>
					{
						morePragmaCards.map(([src, highlight, title, description], index) => (
							<div className={styles.functionMoreCard} key={highlight} >
								<CombinedCard
									key={highlight}
									image={src}
									highlight={highlight}
									title={title}
									description={description}
									imagePosition={index % 2 == 0 ? 'LEFT' : 'RIGHT'}
								/>
							</div>
						))
					}
				</div>
				<SecuritySection />
			</div>

		</>
	);
};

export default Functions;
