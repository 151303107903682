import { PatientPersonalInfoModal, PrivateTopBar, ProfileCard, ProfilePictureCard } from '../../components';

import Grid from '@material-ui/core/Grid';
import { keyValue } from '../../interfaces/Common';
import { PatientProfile } from '../../interfaces/Patient';
import React from 'react';
import styles from './Profile.module.scss';
import useProfile from './useProfile';
import { useTranslation } from 'react-i18next';

const Profile = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		data,
		isLoading,
		open,
		getCurrentPatientInfo,
		updatePatient,
		handleOpen,
		handleUpdateAvatar,
	} = useProfile();

	return (
		<div className={styles.profile}>
			<PrivateTopBar title={t('profileView:TITLE_PROFILE')} />
			<div className={styles.container}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={6} lg={4} xl={3}>
						<ProfilePictureCard fullName={`${data.name} ${data.lastName}`} image={data.avatar} onSave={handleUpdateAvatar} isLoading={isLoading} isAllowedToEdit />
					</Grid>
					<Grid item xs={12} md={6} lg={4} xl={3}>
						<ProfileCard title={t('profileView:PERSONAL_INFO')} data={data as keyValue} showEditBtn onClickBtn={handleOpen} fieldsToDisplay={['name', 'lastName']} />
					</Grid>
				</Grid>
			</div>
			<PatientPersonalInfoModal data={data as PatientProfile} open={open} onChange={handleOpen} getUser={getCurrentPatientInfo} updateUser={updatePatient} isPatient />
		</div>
	);
};

export default Profile;
