import React from 'react';

interface ResourcePreviewStore {
	open: boolean;
	handleOpen: () => void;
	handleClose: () => void;
}

const useResourcePreview = (): ResourcePreviewStore => {
	const [open, setOpen] = React.useState<boolean>(false);

	const handleOpen = (): void => {
		setOpen(prevState => !prevState);
	};

	const handleClose = (): void => {
		handleOpen();
	};

	return {
		open,
		handleOpen,
		handleClose,
	};
};

export default useResourcePreview;
