import { AuthContext } from '../../../../../contexts/AuthContext';
import { Form } from '../../../../../interfaces/FormData';
import React from 'react';
import { updateTherapistPatient } from '../../../../../services/TherapistService';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../../../helpers/useRoutes';
import { UserRole } from '../../../../../interfaces/User';
import { useTherapistSelected } from '../../../../../hooks/useTherapistSelected';

interface ResourcePreviewStore {
	isAssistant: boolean;
	open: boolean;
	handleOpen: () => void;
	handleRedirectToChat: (patientId: number | undefined) => void;
	updateTherapistPatientInfo: (formData: Form) => Promise<void>;
}

const useInfo = (): ResourcePreviewStore => {
	const [open, setOpen] = React.useState<boolean>(false);
	const { getTherapistIdParam } = useTherapistSelected();
	const { user } = React.useContext(AuthContext);
	const history = useHistory();
	const { therapistRoutes } = useRoutes();

	const handleOpen = (): void => {
		setOpen(prevState => !prevState);
	};

	const handleRedirectToChat = (patientId: number | undefined): void => {
		if (patientId) {
			history.push({
				pathname: `/${therapistRoutes.THERAPIST}/${therapistRoutes.CHAT}`,
				state: { patientId },
			});
		}
	};

	const updateTherapistPatientInfo = async (formData: Form): Promise<void> => {
		const therapistIdParam = getTherapistIdParam();

		await updateTherapistPatient(therapistIdParam, formData);
	};

	return {
		isAssistant: user?.roles?.includes?.(UserRole.ASSISTANT) ?? false,
		open,
		handleOpen,
		handleRedirectToChat,
		updateTherapistPatientInfo,
	};
};

export default useInfo;
