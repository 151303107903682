import { NewSessionModalTherapist, PrivateTopBar, SessionPreviewModal, TherapistButtons } from '../../../components';
import { TherapistModalContext, UpdateTherapistModalContext } from '../../../contexts/TherapistModalContext';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import AvailabilityModalTherapist from '../../../components/Modals/AvailabilityModalTherapist/AvailabilityModalTherapist';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import SessionCalendar from '../../../components/Calendar/Calendar';
import SideMenu from './SideMenu/SideMenu';
import styles from './Agenda.module.scss';
import useAgenda from './useAgenda';
import { useTranslation } from 'react-i18next';

const Agenda = (): JSX.Element => {
	const { isOpen } = React.useContext(TherapistModalContext);
	const { setIsOpen } = React.useContext(UpdateTherapistModalContext);
	const { t } = useTranslation();
	const {
		openNewSessionModal,
		openSessionPreviewModal,
		selectedDate,
		sessions,
		sessionModalTherapistAction,
		selectedSession,
		places,
		patients,
		sessionsTypes,
		therapistAvailability,
		isLoading,
		handleOpenNewSessionModal,
		handleOnConfirmNewSessionModal,
		handleChangeSelectedDate,
		handleClickCalendarSessionCard,
		handleClickSideMenuFilter,
		handleOnEditSession,
		handleOnCloseSessionPreviewModal,
		handleRefreshSessions,
		fetchData,
	} = useAgenda();

	return (
		<div className={styles.agenda}>
			<PrivateTopBar
				title={t('agendaView:TITLE')}
				buttons={
					<TherapistButtons
						button={{
							text: t('therapistButtons:NEW_SESSION_BUTTON'),
							icon: <AddOutlinedIcon />,
							onClick: handleOpenNewSessionModal,
						}}
						showChangeWorkHours={{
							show: true,
							onClick: (): void => setIsOpen(!isOpen),
						}}
					/>
				}
			/>
			<div className={styles.container}>
				<Grid container>
					<Grid item xs={12} sm={3} style={{ maxWidth: 360 }}>
						<SideMenu
							selectedDate={selectedDate}
							sessions={sessions}
							patients={patients}
							sessionsTypes={sessionsTypes}
							onChangeSelectedDate={handleChangeSelectedDate}
							onClickFilter={handleClickSideMenuFilter}
							fetchData={fetchData}
						/>
					</Grid>
					<Grid item xs={12} sm={9}>
						<SessionCalendar
							selectedDate={selectedDate}
							sessions={sessions}
							onClickSessionCard={handleClickCalendarSessionCard}
							onChangeSelectedDate={handleChangeSelectedDate}
						/>
					</Grid>
				</Grid>
			</div>
			<NewSessionModalTherapist
				open={openNewSessionModal}
				action={sessionModalTherapistAction}
				session={selectedSession}
				patients={patients}
				sessionsTypes={sessionsTypes}
				therapistAvailability={therapistAvailability}
				isLoading={isLoading}
				onChange={handleOnConfirmNewSessionModal}
				fetchData={fetchData}
			/>
			{
				isOpen &&
				<AvailabilityModalTherapist
					places={places}
					therapistAvailability={therapistAvailability}
					fetchData={fetchData}
				/>
			}
			{
				selectedSession &&
				<SessionPreviewModal
					open={openSessionPreviewModal}
					session={selectedSession}
					onEditSession={handleOnEditSession}
					onClose={handleOnCloseSessionPreviewModal}
					onRefreshData={handleRefreshSessions} />
			}
		</div>
	);
};

export default Agenda;
