import { PatientBasicInfo, PatientTherapistRequest } from '../interfaces/Patient';
import { Answer } from '../interfaces/DocumentForm';
import axios from 'axios';
import { DocumentShared } from '../interfaces/Document';
import { Report } from '../interfaces/Report';
import { Therapist } from '../interfaces/Therapist';

const apiUrl = process.env.REACT_APP_API_URL;

export const updatePatient = (patient: PatientBasicInfo): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/patients/me`, patient);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findCurrentPatient = (): Promise<PatientBasicInfo> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateAvatar = async (data: FormData): Promise<void> => {
	if (apiUrl) {
		const avatarFileId = await axios.post(`${apiUrl}/files`, data);

		return axios.patch(`${apiUrl}/patients/me`, { avatarFile: {id: avatarFileId} });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findAllTherapistsByPatient = async (): Promise<Therapist[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me/therapists`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findPatientDocumentsByTherapist = (therapistId: number | string): Promise<DocumentShared[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me/${therapistId}/documents`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const answerPatientDocument = (sharedDocumentId: number, answers: Answer[]): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/patients/me/${sharedDocumentId}/complete`,
			{
				answer: answers,
			});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const readPatientDocument = (sharedDocumentId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/patients/me/${sharedDocumentId}/read`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getReportPreview = (reportId: number): Promise<Blob> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me/report/${reportId}`, {
			responseType: 'blob',
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getPreview = (sharedDocumentId: number): Promise<Blob> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me/shared-document/${sharedDocumentId}/report`, {
			responseType: 'blob',
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getReportsByTherapist = (therapistId: number | string): Promise<Report[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me/therapists/${therapistId}/report`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const therapistRequest = (): Promise<PatientTherapistRequest[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/patients/me/therapists/requests`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const acceptTherapistRequest = (therapistId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/patients/me/therapists/invitations/${therapistId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const rejectTherapistRequest = (therapistId: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/patients/me/therapists/invitations/${therapistId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateTherapistStatus = (therapistId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/patients/me/therapists/status/${therapistId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
