import { Button, Modal } from '../..';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styles from './ReportConfirmationModal.module.scss';
import useReportConfirmationModal from './useReportConfirmationModal';
import { useTranslation } from 'react-i18next';

interface ReportConfirmationModalProps {
	open: boolean;
	onChange: () => void;
	onConfirm: () => void;
}

const ReportConfirmationModal: React.FC<ReportConfirmationModalProps> = ({ open, onChange, onConfirm }) => {
	const { t } = useTranslation();
	const { handleClose, handleConfirm } = useReportConfirmationModal({ onChange, onConfirm });

	return (
		<Modal
			open={open}
			handleOnClose={handleClose}
			isPrimaryButtonVisible={false}
			isSecondaryButtonVisible={false}
			showDividers={false}
		>
			<div className={styles.content} >
				<div className={styles.title}>{t('patientsView:REPORTS_CONFIRMATION_MODAL_TITLE')}</div>
				<div className={styles.subtitle}>{t('patientsView:REPORTS_CONFIRMATION_MODAL_SUBTITLE')}</div>
				<Alert severity="warning" className={styles.alert}>{t('patientsView:REPORTS_PREVIEW_WARNING')}</Alert>
				<Divider className={styles.divider} />
				<Grid container spacing={3}>
					<Grid item sm={6}>
						<Button classes={styles.button} title={t('patientsView:REPORTS_CONFIRMATION_MODAL_CANCEL_BUTTON')} onClick={onChange} type="SECONDARY" fullWidth size="large" />
					</Grid>
					<Grid item sm={6}>
						<Button classes={styles.button} title={t('patientsView:REPORTS_CONFIRMATION_MODAL_CONFIRM_BUTTON')} onClick={handleConfirm} fullWidth size="large" />
					</Grid>
				</Grid>
			</div>
		</Modal>
	);
};

export default ReportConfirmationModal;
