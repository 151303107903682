import { getMyInfo } from '../services/UserService';
import { PanelUserExtended } from '../interfaces/User';
import React from 'react';
import { useSessionStorage } from '../hooks/useSessionStorage';

export interface AuthProviderStore {
	isAuth: boolean;
	token: string;
	user: PanelUserExtended | null;
}

export interface AuthUpdateProviderStore {
	setIsAuth: (isAuth: boolean) => void;
	setToken: (token: string) => void;
	fetchUser: () => void;
}

export const AuthContext = React.createContext({} as AuthProviderStore);
export const AuthUpdateContext = React.createContext({} as AuthUpdateProviderStore);

const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
	const { isKeyStored, getItem} = useSessionStorage('token');
	const [isAuth, setIsAuth] = React.useState<boolean>(isKeyStored());
	const [token, setToken] = React.useState<string>(getItem());
	const [user, setUser] = React.useState<PanelUserExtended | null>(null);

	const fetchUser = React.useCallback((): void => {
		if (isAuth) {
			(async (): Promise<void> => {
				try {
					const myUser = await getMyInfo();

					setUser(myUser);
				} catch (e) {
					if ((e as any).name === 'unauthorized_request') {
						setIsAuth(false);
					} else {
						throw new Error(e as any);
					}
				}
			})();
		}
	}, [isAuth]);

	React.useEffect(() => {
		fetchUser();
	}, [fetchUser]);

	const store: AuthProviderStore = {
		isAuth,
		token,
		user,
	};

	const updateStore: AuthUpdateProviderStore = {
		setIsAuth,
		setToken,
		fetchUser,
	};

	return (
		<AuthContext.Provider value={store}>
			<AuthUpdateContext.Provider value={updateStore}>
				{children}
			</AuthUpdateContext.Provider>
		</AuthContext.Provider>
	);
};

export { AuthProvider };
export const AuthConsumer = AuthContext.Consumer;
