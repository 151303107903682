import { isEmailValid, passwordValidation } from '../../services/UserService';
import React from 'react';

interface SignUpValidationStore {
	validateEmail: (newEmail: string) => void;
	validatePassword: (newPass: string) => void;
	validateConfirmPassword: (newPass: string) => void;
	email: string | undefined;
	emailError: boolean;
	emailErrorMessage: string | undefined;
	password: string | undefined;
	passwordError: boolean;
	passwordErrorMessage: string | undefined;
	confirmPassword: string | undefined;
	confirmPasswordError: boolean;
	confirmPasswordErrorMessage: string | undefined;
}

export const useSignUpValidation = (): SignUpValidationStore => {
	const [email, setEmail] = React.useState<string | undefined>(undefined);
	const [password, setPassword] = React.useState<string | undefined>(undefined);
	const [confirmPassword, setConfirmPassword] = React.useState<string | undefined>(undefined);
	const [emailError, setEmailError] = React.useState<boolean>(false);
	const [emailErrorMessage, setEmailErrorMessage] = React.useState<string | undefined>(undefined);
	const [passwordError, setPasswordError] = React.useState<boolean>(false);
	const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string | undefined>(undefined);
	const [confirmPasswordError, setConfirmPasswordError] = React.useState<boolean>(false);
	const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = React.useState<string | undefined>(undefined);

	const validateEmail = (newEmail: string): void => {
		setEmail(newEmail);

		const validEmail = isEmailValid(newEmail);

		setEmailError(!validEmail);
		setEmailErrorMessage(!validEmail ? 'El correo no es válido' : undefined);
	};

	const validatePassword = (newPass: string): void => {
		setPassword(newPass);
		const [hasErrors, errorMessage] = passwordValidation(newPass);

		setPasswordError(hasErrors);
		setPasswordErrorMessage(hasErrors ? errorMessage : undefined);
	};

	const validateConfirmPassword = (newPass: string): void => {
		setConfirmPassword(newPass);
	};

	React.useEffect((): void => {
		setConfirmPasswordError(password !== confirmPassword);
		setConfirmPasswordErrorMessage(password !== confirmPassword ? 'Las contraseñas no coinciden' : undefined);
	}, [password, confirmPassword]);

	return {
		email,
		password,
		confirmPassword,
		validateEmail,
		validatePassword,
		validateConfirmPassword,
		emailError,
		emailErrorMessage,
		passwordError,
		passwordErrorMessage,
		confirmPasswordError,
		confirmPasswordErrorMessage,
	};
};
