import { DatePicker, LoadingSpinner, StepTitle, TitleBox } from '../../../../../../../components';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CheckIcon from '@material-ui/icons/Check';
import { formatDate } from '../../../../../../../helpers/Fomatting';
import Grid from '@material-ui/core/Grid';
import NoteDetail from '../../../../../../../components/Cards/NotesCard/NoteDetail/NoteDetail';
import React from 'react';
import Steppers from '../../../../../../../components/Cards/NotesCard/Steppers/Steppers';
import styles from './Notes.module.scss';
import useNotes from './useNotes';
import { useTranslation } from 'react-i18next';

const Notes = (): JSX.Element => {
	const { t } = useTranslation();
	const { notes, activeStep, handleStep, isLoading, handleAddNote, selectAll } = useNotes();

	return (
		<div className={styles.notes}>
			{isLoading
				? <LoadingSpinner />
				:
				<Box>
					<StepTitle title="patientsView:REPORTS_NOTES_TITLE" subtitle="patientsView:REPORTS_NOTES_SUBTITLE" />
					<Box mt={3}>
						<Grid container spacing={4}>
							<Grid item xs={12} md={6} lg={4}>
								<TitleBox type="selector" title={t('patientsView:REPORTS_SELECT_ALL')} checked={selectAll} onChangeCheckbox={handleAddNote} />
								<Card className={styles.filter} elevation={0}>
									<div className={styles.filterTop}>
										<span>{t('patientsView:REPORTS_NOTES_SELECT_BY_PERIOD')}</span>
										<Button className={styles.actionButton} color="primary" onClick={(): void => { /* Do nothing */ }} startIcon={<CheckIcon />}>
											{t('patientsView:REPORTS_APPLY_BUTTON')}
										</Button>
									</div>
									<div className={styles.filterInput}>
										<span>{t('patientsView:REPORTS_NOTES_FILTER_FROM')}:</span>
										<DatePicker
											value={new Date()}
											onChange={(): void => {/* Do nothing */ }}
										/>
										<span>{t('patientsView:REPORTS_NOTES_FILTER_TO')}:</span>
										<DatePicker
											value={new Date()}
											onChange={(): void => {/* Do nothing */ }}
										/>
									</div>
								</Card>
								<Card className={styles.steppers} elevation={0}>
									<Steppers notes={notes} activeStep={activeStep} onClick={handleStep} onChangeCheckbox={handleAddNote} showCheckbox />
								</Card>
							</Grid>
							<Grid item xs={12} lg={8} className={styles.noteDetail}>
								<TitleBox type="text">
									<div className={styles.titleBox}>
										{t('libraryView:PREVIEW')}
										<span>
											{notes.length && formatDate(notes[activeStep].title)}
										</span>
									</div>
								</TitleBox>
								{notes.length && <NoteDetail note={notes[activeStep]} />}
							</Grid>
						</Grid>

					</Box>
				</Box>
			}
		</div>
	);
};

export default Notes;
