import { DateCard } from '../../interfaces/DateCard';
import DateCards from '../Cards/DateCard/DateCards';
import { DatePicker } from '@material-ui/pickers';
import { Frequency } from '../../interfaces/Session';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import styles from './SessionPicker.module.scss';
import useSessionPicker from './useSessionPicker';
import { useTranslation } from 'react-i18next';

interface SessionPickerProps {
	therapistId?: number;
	sessionSelected: DateCard | null;
	endDate?: Date | null;
	frequency: Frequency;
	onChange?: () => void;
	onSessionChange: (sessionCard: DateCard) => void;
}

const SessionPicker: React.FC<SessionPickerProps> = ({ therapistId, sessionSelected, endDate, frequency, onSessionChange, onChange }) => {
	const { t } = useTranslation();
	const {
		availableDates,
		sessionDate,
		handleChangeSessionDate,
		handleChangeSessionSelected,
	} = useSessionPicker({ sessionSelected, therapistId, endDate, frequency, onSessionChange, onChange });

	return (<Grid container spacing={3}>
		<Grid item xs={12} md={6}>
			<SectionTitle title="patientHomeView:MODAL_CALENDAR" />
			<div className={styles.sessionModalDatePicker}>
				<DatePicker
					autoOk
					variant="static"
					openTo="date"
					value={sessionDate}
					onChange={(date): void => handleChangeSessionDate(date)}
					disableToolbar
					disablePast
				/>
			</div>
		</Grid>
		<Grid item xs={12} md={6} style={{ paddingRight: 4 }}>
			<SectionTitle title="patientHomeView:MODAL_HOURS_AVAILABLE" />
			{
				sessionSelected?.startTime &&
				!availableDates.some(date => date.id === new Date(sessionSelected?.startTime ?? '').getTime()) &&
				<div style={{ fontSize: 12, paddingRight: 7 }}>
					<b>{`*${t('agendaView:TIME_SELECTED')}`}</b>
					<DateCards
						data={
							[{
								id: sessionSelected?.id,
								startTime: sessionSelected?.startTime,
								endTime: sessionSelected?.endTime,
								therapistOffice: sessionSelected?.therapistOffice,
							}]}
						noDataMessage={t('patientHomeView:MODAL_MESSAGE_NO_SESSION')}
						value={sessionSelected?.id?.toString() ?? ''}
						onChange={handleChangeSessionSelected}
						fromPatient
					/>
				</div>
			}
			<div className={styles.sessionModalDateCards}>
				<DateCards
					data={availableDates}
					noDataMessage={t('patientHomeView:MODAL_MESSAGE_NO_SESSION')}
					value={sessionSelected?.id?.toString() ?? ''}
					onChange={handleChangeSessionSelected}
					fromPatient
				/>
			</div>
		</Grid>
	</Grid>);
};

export default SessionPicker;
