import {
	getMyUserInfo,
	updateUserSignature as updateUserSignatureRepo
} from '../api/UserRepository';
import { PanelUserExtended } from '../interfaces/User';

export const getMyInfo = async (): Promise<PanelUserExtended> => {
	return await getMyUserInfo();
};

export const updateUserSignature = async (id: number, signature: string): Promise<void> => {
	return await updateUserSignatureRepo(id, signature);
};

export const isEmailValid = (email: string): boolean => {
	const validEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

	return validEmail.test(email);
};

export const passwordValidation = (text: string): [boolean, string] => {
	// Minimum eight characters, Maximum 32, any character, at least one uppercase letter, one lowercase letter and one number
	const validCharacters = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]{8,32}$/g;
	let error = false;
	let errorMessage = '';

	if (!validCharacters.test(text)) {
		errorMessage = 'La contraseña debe tener entre 8 y 32 caracteres, y al menos una letra mayúscula, una minúscula y un número';
		error = true;
	}

	return [error, errorMessage] as [
		boolean,
		string
	];
};
