import { Modal, PrivateTopBar, TherapistProfileCard } from '../../../components';
import AddTherapistProfileCard from '../../../components/Cards/AddTherapistProfileCard/AddTherapistProfileCard';
import React from 'react';
import styles from './Home.module.scss';
import { useHome } from './useHome';
import { useTranslation } from 'react-i18next';

const Home = (): JSX.Element => {
	const { menuOptions, therapists, openConfirmationModal, confirmationModalState } = useHome();
	const { t } = useTranslation();

	return (
		<div className={styles.institutiontHome}>
			<PrivateTopBar title={t('institution:THERAPISTS')} />
			<div className={styles.content}>
				<AddTherapistProfileCard />
				{
					therapists && therapists.length > 0 &&
					therapists.map((therapist) => {
						return (
							<>
								<TherapistProfileCard
									key={`key-${therapist.familyName} ${therapist.givenName}`}
									fullName={`${therapist.familyName} ${therapist.givenName}`}
									image={therapist.avatarFile?.url}
									therapistId={therapist.id}
									options={menuOptions}
								/>
							</>
						);
					})
				}
			</div>
			{
				openConfirmationModal &&
				<Modal
					open={openConfirmationModal}
					title={t(`${confirmationModalState.title}`)}
					handleOnClose={confirmationModalState.onClose}
					isPrimaryButtonVisible
					primaryButtonProps={{
						title: t('institution:MODAL_CONFIRM_BUTTON'),
						onClick: confirmationModalState.onConfirm,
						isDisabled: false,
					}}
					isSecondaryButtonVisible
					secondaryButtonProps={{
						title: t('institution:MODAL_CANCEL_BUTTON'),
						onClick: confirmationModalState.onClose,
					}}
					showDividers={false}
				>
					{t(`${confirmationModalState.content}`)}
				</Modal>
			}
		</div>
	);
};

export default Home;
