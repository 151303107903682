const tokenKey = 'token';
const resfreshTokenKey = 'refresh_token';

export function getAuthToken(): string | null {
	return sessionStorage.getItem(tokenKey);
}

export function setAuthToken(token: string): void {
	sessionStorage.setItem(tokenKey, token);
}

export function getRefreshToken(): string | null {
	return sessionStorage.getItem(resfreshTokenKey);
}

export function setRefreshToken(resfreshToken: string): void {
	sessionStorage.setItem(resfreshTokenKey, resfreshToken);
}

export function removeToken(): void {
	sessionStorage.removeItem(tokenKey);
}

export function clearSessionStorage(): void {
	sessionStorage.clear();
}
