
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import styles from './InputText.module.scss';
import TextField from '@material-ui/core/TextField';

interface InputTextProps {
	value: string;
	name: string;
	label?: string;
	fullWidth: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	error?: boolean;
	helperText?: string | React.ReactNode;
	startIcon?: JSX.Element;
	variant?: 'filled' | 'outlined' | 'standard';
	placeholder?: string;
	className?: string;
	inputProps?: string;
}

const InputText: React.FC<InputTextProps> = (props) => {

	return (
		<TextField
			id={props.name}
			className={props.className}
			label={props.label}
			name={props.name}
			value={props.value}
			onChange={props.onChange}
			InputLabelProps={{ shrink: false }}
			inputProps={{ className: props.inputProps || styles.inputText }}
			fullWidth={props.fullWidth}
			error={props.error}
			helperText={props.helperText}
			variant={props.variant || 'standard'}
			placeholder={props.placeholder}
			InputProps={{
				startAdornment: (
					props.startIcon &&
					<InputAdornment position="start">
						{props.startIcon}
					</InputAdornment>
				),
			}}
		/>
	);
};

export default InputText;

