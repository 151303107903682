export default {
	es: {
		contact: {
			SECTION_TITLE: 'Contáctenos',
			CONTACT_FORM_TITLE: '¿Tiene alguna duda?',
			CONTACT_FORM_SUBTITLE: 'Estamos disponibles y ansiamos saber de usted.',
			CONTACT_FORM_NAME: 'Nombre',
			CONTACT_FORM_EMAIL: 'Email',
			CONTACT_FORM_PHONE: 'Teléfono',
			CONTACT_FORM_MESSAGE: 'Mensaje',
			CONTACT_FORM_SEND_BUTTON: 'Enviar',
			FORM_SENT: '¡Mensaje enviado!',
			FORM_NOT_SENT: 'Hubo un error al enviar el email. Por favor inténtelo nuevamente.',
			CARD_TITLE_FIRST_SECTION: 'Pruebe Pragma hoy mismo',
			CARD_TITLE_SECOND_SECTION: '¡es gratis por un mes!',
			CARD_DESCRIPTION: 'Aprenda cómo puede ayudarle nuestra plataforma en su práctica profesional cotidiana',
		},
	},
	en: {
		contact: {
			SECTION_TITLE: 'Contact us',
			CONTACT_FORM_TITLE: 'Do you have any questions?',
			CONTACT_FORM_SUBTITLE: 'We are available and eager to hear from you.',
			CONTACT_FORM_NAME: 'Name',
			CONTACT_FORM_EMAIL: 'Email',
			CONTACT_FORM_PHONE: 'Phone',
			CONTACT_FORM_MESSAGE: 'Message',
			CONTACT_FORM_SEND_BUTTON: 'Send',
			FORM_SENT: 'Message Sent!',
			FORM_NOT_SENT: 'There was an error while sending email. Please try again.',
			CARD_TITLE_FIRST_SECTION: 'Try Pragma today',
			CARD_TITLE_SECOND_SECTION: 'first month for free!',
			CARD_DESCRIPTION: 'Learn how our platform can help you at your daily professional practice',
		},
	},
};
