import { FormControlLabel, Checkbox as MaterialCheckbox } from '@material-ui/core';
import { CheckBoxOutlined } from '@material-ui/icons';
import React from 'react';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
	label: string;
	onChange: (value: boolean) => void;
	checked: boolean;
	classes?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, onChange, checked, classes }) => {
	const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
		onChange(event.target.checked);
	};

	return (
		<FormControlLabel
			control={
				<MaterialCheckbox
					name={label}
					onChange={onChangeCheckbox}
					checked={checked}
					className={`${styles['regularCheckbox']} ${styles['MuiCheckbox-root']} ${checked && styles['Mui-checked']}`}
					color={'default'}
					checkedIcon={<CheckBoxOutlined />}
				/>
			}
			classes={{ label: classes }}
			label={<span style={{ fontSize: 14, fontWeight: 600, color: '#252f47' }}>{label}</span>}
		/>
	);
};

export default Checkbox;
