import funcTabsAdmission from '../../assets/images/func-tabs-admission.png';
import funcTabsComunication from '../../assets/images/func-tabs-comunication.png';
import funcTabsFunction from '../../assets/images/func-tabs-function.png';
import React from 'react';
import { SectionTab } from '../../components';
import styles from './PortalSection.module.scss';
import { useTranslation } from 'react-i18next';

const PortalSection: React.FC = () => {
	const { t } = useTranslation();

	const TabTitle = [
		t('functions:PORTAL_TABS_ADMISSION'),
		t('functions:PORTAL_TABS_FUNCTION'),
		t('functions:PORTAL_TABS_COMUNICATION'),
	];

	const TabDescriptionList = [
		[t('functions:TAB_ADMISSION_CLIENT'), t('functions:TAB_ADMISSION_UTILITIES'), t('functions:TAB_ADMISSION_RESOURCES')],
		[t('functions:TAB_FUNCTION_PACIENT'), t('functions:TAB_FUNCTION_ACCESSIBILITY'), t('functions:TAB_FUNCTION_COMUNICATION'), t('functions:TAB_FUNCTION_ALLOW')],
		[t('functions:TAB_COMUNICATION_SEND'), t('functions:TAB_COMUNICATION_TRACE'), t('functions:TAB_COMUNICATION_UPDATE')],
	];

	const tabLists = [
		{
			index: 0,
			title: TabTitle[0],
			children:
				<div className={styles.sectionTabContainer}>
					<img src={funcTabsAdmission} alt="" className={styles.imageClassName} />
					<div className={styles.descriptionContent}>
						{
							TabDescriptionList[0].map((data, i) => (
								<p key={i}> {data} </p>
							))
						}
					</div>
				</div>,
		},
		{
			index: 1,
			title: TabTitle[1],
			children:
				<div className={styles.sectionTabContainer}>
					<img src={funcTabsFunction} alt="" className={styles.imageClassName} />
					<div className={styles.descriptionContent}>
						{
							TabDescriptionList[1].map((data, i) => (
								<p key={i}> {data} </p>
							))
						}
					</div>
				</div>,
		},
		{
			index: 2,
			title: TabTitle[2],
			children:
				<div className={styles.sectionTabContainer}>
					<img src={funcTabsComunication} alt="" className={styles.imageClassName} />
					<div className={styles.descriptionContent}>
						{
							TabDescriptionList[1].map((data, i) => (
								<p key={i}> {data} </p>
							))
						}
					</div>
				</div>,
		},
	];

	return (
		<div className={styles.functionPortal}>
			<div className={styles.functionPortalDescription}>
				<h2 className={styles.title}>{t('functions:FUNCTION_PORTAL')}</h2>
				<p className={styles.description}>{t('functions:FUNCTION_PORTAL_DESCRIPTION')}</p>
			</div>
			<div>
				<SectionTab sectionTab={tabLists} />
			</div>
		</div>
	);
};

export default PortalSection;
