import UserRowCard, { UserRowCardProps } from '../Cards/UserRowCard/UserRowCard';
import React from 'react';
import styles from './UserSelector.module.scss';

export interface UserSelectorProps {
	users: UserRowCardProps[];
	height: {height: string};
}

const UserSelector: React.FC<UserSelectorProps> = (props) => {

	return (
		<div className={styles.container} style={props.height}>
			{
				props.users && props.users.map(u => (
					<>
						<UserRowCard
							actions={u.actions}
							avatar={u.avatar}
							key={u.title}
							subTitle={u.subTitle}
							title={u.title}
							userId={u.userId}
						/>
					</>
				))
			}
		</div>
	);
};

export default UserSelector;
