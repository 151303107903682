import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditOutlined from '@material-ui/icons/EditOutlined';
import React from 'react';
import styles from './SignatureCard.module.scss';
import { TherapistInfo } from '../../../interfaces/User';
import { useTranslation } from 'react-i18next';

interface SignCardProps {
	onOpenModal: () => void;
	therapistInfo: TherapistInfo;
}

const SignCard: React.FC<SignCardProps> = ({ therapistInfo, onOpenModal }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.signature}>
			<Card className={styles.card}>
				<CardContent className={styles.content}>
					<div className={styles.header}>
						<p className={styles.title}>{t('profileView:SIGNATURE')}</p>
						<Button
							color="primary"
							className={styles.headerEditButton}
							startIcon={<EditOutlined />}
							onClick={onOpenModal} >
							{t('profileView:UPDATE_SIGNATURE')}
						</Button>

					</div>
					<div className={styles.container}>
						{
							therapistInfo.signature &&
							<img src={therapistInfo.signature} alt="profileSignature" className={styles.signatureImage} />
						}
					</div>
				</CardContent>
			</Card>
		</div >
	);
};

export default SignCard;
