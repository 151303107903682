import { Institution, InstitutionTherapist } from '../interfaces/Institution';
import axios from 'axios';
import { BasicInfo } from '../interfaces/User';

const apiUrl = process.env.REACT_APP_API_URL;

export const findInstitution = (): Promise<Institution> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/institutions/me`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findTherapistToInvite = (): Promise<InstitutionTherapist[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/institutions/me/therapists/invitations`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const sendInvitationToTherapist = (data: BasicInfo): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/institutions/me/therapist`, {
			givenName: data.name,
			familyName: data.lastName,
			mail: data.email,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const inviteTherapistById = (therapistId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/institutions/me/therapists/invite`, {
			therapistId,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateAvatar = async (data: FormData): Promise<void> => {
	if (apiUrl) {
		const avatarFileId = await axios.post(`${apiUrl}/files`, data);

		return axios.patch(`${apiUrl}/institutions/me/`, { avatarFile: {id: avatarFileId} });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateInstitutionInfo = (institution: Institution): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/institutions/me/`, institution);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const deleteTherapist = (therapistId: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/institutions/me/therapists/${therapistId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
