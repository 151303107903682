const isEmail = (email: string): boolean => {
	const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

	return emailRegex.test(email);
};

const isRequired = (value: string): boolean => {
	return value.length > 0;
};

const maxLenght = (value: string, maxLenght: number): boolean => {
	return value.length <= maxLenght;
};

export {
	isEmail,
	isRequired,
	maxLenght
};
