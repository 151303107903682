import AddIcon from '@material-ui/icons/Add';
import { Button } from '../index';
import Grid from '@material-ui/core/Grid';
import { MenuItem } from '@material-ui/core';
import { Option } from '../../interfaces/Common';
import React from 'react';
import styles from './ModalTab.module.scss';
import TextField from '@material-ui/core/TextField';

export interface ModalTabProps {
	tabName: string;
	name: string;
	placeholder: string;
	selectOptions: Option[];
	tertiaryButton?: {
		show: boolean;
		onClick: () => void;
	};
	buttonTitle: string;
	handleInputChange: (event: React.ChangeEvent<{ value: unknown; name: string }>) => void;
	optionSelected: string | number;
}

const ModalTab: React.FC<ModalTabProps> = ({ tabName, name, handleInputChange, placeholder, selectOptions, buttonTitle, tertiaryButton, optionSelected }) => (
	<Grid container spacing={1} className={styles.ModalTab}>
		<Grid item xs={2}>
			<span className={styles.text}>{tabName}</span>
		</Grid>
		<Grid item xs={4} className={styles.select}>
			<TextField
				label={placeholder}
				select
				variant="outlined"
				fullWidth
				onChange={handleInputChange}
				value={optionSelected}
				size="small"
				name={name}
			>
				{
					selectOptions.map((option) => (
						<MenuItem key={option.key} value={option.key} className={styles.inputSelect}>
							{option.value}
						</MenuItem>
					))
				}
			</TextField>
		</Grid>
		<Grid item xs={3}>
			{tertiaryButton?.show && <Button
				type="TERTIARY"
				startIcon={<AddIcon />}
				title={buttonTitle}
				variant="text"
				onClick={tertiaryButton.onClick}
			/>}
		</Grid>
	</Grid>
);

export default ModalTab;
