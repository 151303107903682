import { Avatar, CardHeader } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '../../Button/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import InviteTherapistModal from '../../Modals/InviteTherapistModal/InviteTherapistModal';
import React from 'react';
import styles from './AddTherapistProfileCard.module.scss';
import { useTranslation } from 'react-i18next';

interface AddTherapistProfileCardProps {
	isLoading?: boolean;
}

const AddTherapistProfileCard: React.FC<AddTherapistProfileCardProps> = ({isLoading = false}) => {
	const [open, setOpen] = React.useState<boolean>(false);
	const { t } = useTranslation();

	return (
		<div className={styles.profilePicture}>
			<Card className={styles.cardImage}>
				{isLoading &&
					<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
						<CircularProgress />
					</div>
				}
				{!isLoading &&
				<>
					<CardHeader
						action={
							<div className={styles.headerAction}/>
						}
					/>
					<div className={styles.actionAreaContent}>
						<CardActionArea
							className={styles.cardActionArea}
						>
							<Avatar className={styles.cardMedia} alt="profile-img" />
						</CardActionArea>
						<div className={styles.addButton}>
							<Button
								fullWidth
								title={t('institution:ADD_THERAPIST')}
								onClick={(): void => setOpen(!open)}
								size='large'
								startIcon={<AddIcon />} />
						</div>
					</div>
				</>
				}
			</Card>
			{
				open &&
				<InviteTherapistModal
					open={open}
					onClose={(): void => setOpen(!open)}
				/>
			}
		</div>
	);
};

export default AddTherapistProfileCard;
