export default {
	es: {
		clinicalSituation: {
			CHRONIC: 'Crónico',
			ACUTE: 'Agudo',
		},
	},
	en: {
		clinicalSituation: {
			CHRONIC: 'Chronic',
			ACUTE: 'Acute',
		},
	},
};
