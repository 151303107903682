import { addNote, editNote } from '../../../services/NoteService';
import { AlertContext } from '../../../contexts/AlertContext';
import { Note } from '../../../interfaces/Note';
import React from 'react';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface CreateNoteStore {
	date: string;
	noteContent: string;
	handleChangeNote: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	formIsValid: boolean;
	handleClose: () => void;
	handleConfirm: () => void;
	isLoading: boolean;
}

interface NoteModalProps {
	note?: Note;
	onChange: () => void;
	patientId?: number;
	noteId?: number;
	loadData: () => void;
}

const useNoteModal = (props: NoteModalProps): CreateNoteStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const date = new Date().toString();
	const [noteContent, setNoteContent] = React.useState<string>('');
	const [formIsValid, setFormIsValid] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { getTherapistIdParam } = useTherapistSelected();

	React.useEffect(() => {
		if (props.note) {
			setNoteContent(props.note.description);
		}
	}, [props.note]);

	const handleChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		const { value } = event.target;

		setNoteContent(value);
		setFormIsValid(value !== '');
	};

	const handleClose = (): void => {
		props.onChange();
	};

	const handleConfirm = async (): Promise<void> => {
		const data: Note = {
			title: date,
			description: noteContent,
		};

		try {
			setIsLoading(true);

			if (props.patientId) {
				const therapistIdParam = getTherapistIdParam();

				if (!props.noteId) {
					await addNote(therapistIdParam, props.patientId, data);
				} else {
					await editNote(therapistIdParam, props.noteId, data);
				}

				props.loadData();
			}

		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
		handleClose();
	};

	return {
		date,
		noteContent,
		handleChangeNote,
		formIsValid,
		handleConfirm,
		handleClose,
		isLoading,
	};
};

export default useNoteModal;
