export default {
	es: {
		footer: {
			COPYRIGHT_MESSAGE: 'Copyright Pragma 2020. Todos los derechos reservados.',
		},
	},
	en: {
		footer: {
			COPYRIGHT_MESSAGE: 'Copyright Pragma 2020. All rights reserved.',
		},
	},
};
