import { CardsTable, Pagination, PrivateTopBar, TherapistButtons, UpdatePatientModal } from '../../../components';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import styles from './Patients.module.scss';
import usePatients from './usePatients';
import { useTranslation } from 'react-i18next';

const Patients = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		filteredPatients,
		isLoading,
		columns,
		openPatientModal,
		searchValue,
		handleOnClickPatient,
		handleOpenPatientModal,
		handleOnConfirmPatientModal,
		handleChangeSearchValue,
	} = usePatients();

	return (
		<div className={styles.patients}>
			<PrivateTopBar
				title={t('patientsView:TITLE')}
				buttons={
					<TherapistButtons
						onChangeSearchInput={handleChangeSearchValue}
						searchValue={searchValue}
						button={{
							text: t('therapistButtons:ADD_PATIENT_BUTTON'),
							icon: <AddOutlinedIcon />,
							onClick: handleOpenPatientModal,
						}}
						showSearchInput
					/>
				}
			/>
			<div className={styles.container}>
				<Grid container spacing={3}>
					<Grid container item xs={12} spacing={3} className={styles.header} justifyContent="flex-end">
						<Grid item xs={12} sm={6} md={3} implementation="css" smDown component={Hidden} />
						<Grid container item xs={12} sm={6} md={3} justifyContent="flex-end">
							<Pagination from={1} to={filteredPatients.length} total={filteredPatients.length} navigate={(): void => { /* Do nothing */ }} />
						</Grid>
					</Grid>
					<Grid container item xs={12} className={styles.table}>
						<CardsTable data={filteredPatients} columnsDef={columns} onClick={handleOnClickPatient} isLoading={isLoading} />
					</Grid>
				</Grid>
			</div>
			<UpdatePatientModal
				open={openPatientModal}
				onChange={handleOnConfirmPatientModal}
				actionType={'ADD'}
			/>
		</div>
	);
};

export default Patients;
