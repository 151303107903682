import { LoadingSpinner, ResourceCards, StepTitle, TitleBox } from '../../../../../../../components';
import Box from '@material-ui/core/Box';
import { DocumentShared } from '../../../../../../../interfaces/Document';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import ReportPreview from '../../../../../../../components/ReportPreview/ReportPreview';
import styles from './Documents.module.scss';
import useDocument from './useDocuments';
import { useTranslation } from 'react-i18next';

const Documents = (): JSX.Element => {
	const { t } = useTranslation();

	const {
		isLoading,
		formsInfo,
		activeItem,
		selectedItems,
		selectAll,
		handleClickActiveCard,
		handleFormsSelect,
	} = useDocument();

	return (
		<div className={styles.documents}>
			{isLoading
				? <LoadingSpinner />
				:
				<>
					<StepTitle title="patientsView:REPORTS_DOCUMENTATION_TITLE" subtitle="patientsView:REPORTS_DOCUMENTATION_SUBTITLE" />
					<Box mt={3}>
						<Grid container spacing={4}>
							<Grid item xs={12} md={6} lg={4}>
								<TitleBox type="selector" title={t('patientsView:REPORTS_SELECT_ALL')} checked={selectAll} onChangeCheckbox={handleFormsSelect} />
								<ResourceCards
									data={formsInfo as Array<DocumentShared>}
									activeCard={activeItem?.id}
									onClick={handleClickActiveCard}
									showCheckbox
									selectedItems={selectedItems}
									handleSelectedItems={handleFormsSelect}
								/>
							</Grid>
							<Grid item xs={12} lg={8}>
								{activeItem && <ReportPreview reportPreview={activeItem?.reportDocument || null} />}
							</Grid>
						</Grid>
					</Box>
				</>
			}
		</div>
	);
};

export default Documents;
