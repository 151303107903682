import { DateCard } from '../../../interfaces/DateCard';
import React from 'react';
import { requestNewTherapistSession } from './../../../services/SessionService';

interface NewSessionModalProps {
	therapistId: number;
	onConfirm: () => Promise<void>;
	onChange: () => void;
}

interface NewSessionModalStore {
	sessionSelected: DateCard | null;
	handleCloseNewSession: () => void;
	handleConfirmNewSession: (availableDate: DateCard | null) => void;
	setSessionSelected: (session: DateCard) => void;
}

const useNewSessionModal = (props: NewSessionModalProps): NewSessionModalStore => {
	const [sessionSelected, setSessionSelected] = React.useState<DateCard | null>(null);
	const { therapistId, onConfirm, onChange } = props;

	const handleCloseNewSession = (): void => {
		onChange();
		setSessionSelected(null);
	};

	const handleConfirmNewSession = async (availableDate: DateCard | null): Promise<void> => {
		if (availableDate) {
			await requestNewTherapistSession(therapistId, availableDate);
		}

		await onConfirm();
		handleCloseNewSession();
	};

	return {
		sessionSelected,
		handleCloseNewSession,
		handleConfirmNewSession,
		setSessionSelected,
	};
};

export default useNewSessionModal;
