import { Card, PlanCard } from '../../components';
import React from 'react';
import styles from './Plans.module.scss';
import { useTranslation } from 'react-i18next';

const Plans = (): JSX.Element => {
	const [planSelected, setPlanSelected] = React.useState<string>();
	const changePlan = (plan: string): void => {
		setPlanSelected(plan);
	};

	const { t } = useTranslation();

	const planCards: [string, string, string, string[]][] = [
		[t('plan:TYPE_BASIC'), '$100', '/' + t('plan:MONTH'), [t('plan:PLAN_CARD_ITEM_UNLIMIT'), t('plan:PLAN_CARD_ITEM_PORTAL'), t('plan:PLAN_CARD_ITEM_NOTE'), t('plan:PLAN_CARD_ITEM_ONLINE'), t('plan:PLAN_CARD_ITEM_FORMS'), t('plan:PLAN_CARD_ITEM_ASSISTANT'), t('plan:PLAN_CARD_ITEM_SECURE'), t('plan:PLAN_CARD_ITEM_HIPAA')]],
		[t('plan:TYPE_PREMIUM'), '$150', '/' + t('plan:MONTH'), [t('plan:PLAN_CARD_ITEM_FUNCTIONALITY') + t('plan:TYPE_BASIC'), t('plan:PLAN_CARD_ITEM_MORE'), t('plan:PLAN_CARD_ITEM_READING'), t('plan:PLAN_CARD_ITEM_LIBRARY_FORMS'), t('plan:PLAN_CARD_ITEM_SECRETARY'), t('plan:PLAN_CARD_ITEM_TELEHEALTH')]],
		[t('plan:TYPE_INSTITUTIONAL'), '$200', '/' + t('plan:MONTH'), [t('plan:PLAN_CARD_ITEM_FUNCTIONALITY') + t('plan:TYPE_PREMIUM'), t('plan:PLAN_CARD_ITEM_MORE'), t('plan:PLAN_CARD_ITEM_READING'), t('plan:PLAN_CARD_ITEM_LIBRARY_FORMS'), t('plan:PLAN_CARD_ITEM_SECRETARY'), t('plan:PLAN_CARD_ITEM_TELEHEALTH')]],
	];

	return (
		<div className={styles.container}>
			<h1 className={styles.planTitle}>{t('plans:PLAN_TITLE')}</h1>
			<div className={styles.plansContainer}>
				{
					planCards.map(([title, price, unit, descriptionArray]) => (
						<PlanCard
							key={title}
							price={price}
							unit={unit}
							title={title}
							description={
								<>
									{
										descriptionArray.map((data) => (
											<p key={data}> {data} </p>
										))
									}
								</>
							}
							onClick={console.log}
							selected={planSelected === title}
							onClickPlanCard={changePlan}
						/>
					))
				}
			</div>
			<div className={styles.payment}>
				<h1 className={styles.paymentTitle}>{t('plans:WAY_TO_PAY')}</h1>
				<h3 className={styles.paymentDescription}>{t('plans:WAY_TO_PAY_DESCRIPTION')}</h3>
			</div>
			<Card className={styles.paymentCard}>Metodo de Pago</Card>
		</div>
	);
};

export default Plans;
