export default {
	es: {
		options: {
			HOME: 'home',
			PLANS: 'planes',
			FUNCTIONALITIES: 'funcionalidades',
			ABOUT: 'acerca de pragma',
			FAQ: 'preguntas frecuentes',
			BLOG: 'blog',
			ACCESS: 'INGRESAR',
			FREE_TRIAL: 'PROBAR GRATIS',
			CONTACT: 'contactanos',
		},
		therapistButtons: {
			CHANGE_WORK_HOURS: 'Cambiar horarios de trabajo',
			SET_REMINDER_BUTTON: 'Configurar recordatorios',
			NEW_SESSION_BUTTON: 'Nueva sesión',
			ADD_PATIENT_BUTTON: 'Agregar paciente',
			UPDATE_PATIENT_BUTTON: 'Editar paciente',
			SEARCH_INPUT: 'Buscar',
		},
	},
	en: {
		options: {
			HOME: 'home',
			PLANS: 'plans',
			FUNCTIONALITIES: 'functionalities',
			ABOUT: 'about',
			FAQ: 'faq',
			BLOG: 'blog',
			ACCESS: 'ACCESS',
			FREE_TRIAL: 'FREE TRIAL',
			CONTACT: 'contact us',
		},
		therapistButtons: {
			CHANGE_WORK_HOURS: 'Change work hours',
			SET_REMINDER_BUTTON: 'Set reminders',
			NEW_SESSION_BUTTON: 'New session',
			ADD_PATIENT_BUTTON: 'Add patient',
			UPDATE_PATIENT_BUTTON: 'Update patient',
			SEARCH_INPUT: 'Search',
		},
	},
};
