export default {
	es: {
		assistant: {
			ACCEPT: 'Aceptar',
			CHOOSE_THERAPIST: 'Seleccione un terapeuta: ',
			DELETE_THERAPIST: 'Eliminar terapeuta',
			REJECT: 'Rechazar',
			WARNING_MEESSAGE: 'Advertencia',
			DELETE_THERAPIST_CONFIRMATION_MESSAGE: '¿Estás seguro que deseas eliminar este terapeuta?',
			CANCEL_THERAPIST_CONFIRMATION_INVITATION: '¿Estás seguro que deseas cancelar esta invitación?',
			MODAL_CONFIRM_BUTTON: 'Confirmar',
			MODAL_CANCEL_BUTTON: 'Cancelar',
		},
	},
	en: {
		assistant: {
			ACCEPT: 'Accept',
			CHOOSE_THERAPIST: 'Choose an therapist: ',
			DELETE_THERAPIST: 'Delete therapist',
			REJECT: 'Reject',
			WARNING_MEESSAGE: 'Warning',
			DELETE_THERAPIST_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this therapist?',
			CANCEL_THERAPIST_CONFIRMATION_INVITATION: 'Are you sure you want to cancel this invitation?',
			MODAL_CONFIRM_BUTTON: 'Confirm',
			MODAL_CANCEL_BUTTON: 'Cancel',
		},
	},
};
