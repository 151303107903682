import {
	findAllMyResources,
	findResourcesLinkedToPatient,
	linkResourceToPatient as linkResourceToPatientRepo,
	shareResourceToPatient as shareResourceToPatientRepo,
	unlinkResourceFromPatient as unlinkResourceFromPatientRepo
} from '../api/ResourceLibraryRepository';
import { Resource } from '../interfaces/Resource';
import { ResourcesByTag } from '../interfaces/Tag';

interface ResourceByTagMap {
	[key: number]: {
		resources: Array<Resource>;
		name: string;
	};
}

const mapResourcesToGroupedByTags = (resources: Resource[]): ResourcesByTag[] => {
	const resourcesByTagMap: ResourceByTagMap = {};

	resources.forEach(resource => {
		if (resource.tags && resource.tags.length) {
			resource.tags.forEach(tag => {
				if (resourcesByTagMap[tag.id]) {
					resourcesByTagMap[tag.id].resources.push(resource);
				} else {
					resourcesByTagMap[tag.id] = {
						name: tag.name,
						resources: [resource],
					};
				}
			});
		} else {
			if (resourcesByTagMap[-1]) {
				resourcesByTagMap[-1].resources.push(resource);
			} else {
				resourcesByTagMap[-1] = {
					name: 'libraryView:GENERIC',
					resources: [resource],
				};
			}
		}
	});

	const resourcesByTag: ResourcesByTag[] = [];

	Object.keys(resourcesByTagMap).forEach((tagId) => {
		resourcesByTag.push({
			id: +tagId,
			...resourcesByTagMap[+tagId],
		});
	});

	return resourcesByTag;
};

export const getTagsAndResourcesByTherapist = async (therapistId: number | string): Promise<Array<ResourcesByTag>> => {
	const myResources = mapResourcesToGroupedByTags(await findAllMyResources(therapistId));

	return myResources;
};

export const getResourcesLinkedToPatient = async (therapistId: number | string, patientId: number): Promise<Array<Resource>> => {
	return await findResourcesLinkedToPatient(therapistId, patientId);
};

export const linkResourceToPatient = async (therapistId: number | string, resourceId: number, patientId: number): Promise<void> => {
	return await linkResourceToPatientRepo(therapistId, resourceId, patientId);
};

export const unlinkResourceFromPatient = async (therapistId: number | string, resourceId: number, patientId: number): Promise<void> => {
	return await unlinkResourceFromPatientRepo(therapistId, resourceId, patientId);
};

export const shareResourceToPatient = async (therapistId: number | string, resourceId: number, patientId: number): Promise<void> => {
	return await shareResourceToPatientRepo(therapistId, resourceId, patientId);
};
