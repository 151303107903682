import { FormContext, FormUpdateContext } from '../../../../contexts/FormContext';
import { NumberDecimalQuestion, Section } from '../../../../interfaces/DocumentForm';
import { isDecimalNumber } from '../../../../helpers/Validations';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NumberDecimalStore {
	question: NumberDecimalQuestion;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleOnBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const isNumber = (value: string): boolean => {
	const coercedValue = Number(value);

	return !(coercedValue !== coercedValue);
};

const useNumberDecimalPreview = (props: QuestionPreviewProps): NumberDecimalStore => {
	const { t } = useTranslation();
	const { formModel, formModel: { showErrors } } = React.useContext(FormContext);
	const { handleUpdateQuestion } = React.useContext(FormUpdateContext);
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = formModel.sections[props.sectionIndex].questions[props.questionIndex] as NumberDecimalQuestion;

	const isValueValid = (value: string): boolean => {
		if (value.length) {
			if (isNumber(value)) {
				const { min, max } = question;
				const belongsRange = +value >= min && +value <= max;

				setErrorMessage(belongsRange ? '' : `${t('formsView:MODAL_PREVIEW_NUMERIC_VALIDATION')}: ${min} - ${max}`);

				return belongsRange;
			}
		}

		setErrorMessage(question.required ? t('formsView:MODAL_PREVIEW_FIELD_REQUIRED') : '' );

		return false;
	};

	React.useEffect(() => {
		const isValid = isValueValid(question.value.toString());

		setErrorMessage(t('libraryView:FORMS_FIELD_REQUIRED'));

		handleUpdateQuestion({ ...question, isValid }, formModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {value} = event.target;

		if (!isDecimalNumber(value)) {
			return;
		}

		const isValid = isValueValid(value);

		handleUpdateQuestion({ ...question, value, isValid: isValid }, formModel.sections[props.sectionIndex] as Section);
	};

	const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
		const value = Number(event.target.value);

		if (value) {
			handleUpdateQuestion({ ...question, value: value.toString() }, formModel.sections[props.sectionIndex] as Section);
		}
	};

	return {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
		handleOnBlur,
	};
};

export default useNumberDecimalPreview;
