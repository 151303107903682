import { AuthContext } from '../contexts/AuthContext';
import React from 'react';
import { UserRole } from '../interfaces/User';
import { useSessionStorage } from './useSessionStorage';

interface UseUserStore {
	getTherapistIdParam: () => number | string;
}

export const useTherapistSelected = (): UseUserStore => {
	const { user } = React.useContext(AuthContext);
	const { getItem } = useSessionStorage('selected_therapist_id_by_assistant');
	const therapistSelectedId = getItem();

	const getTherapistIdParam = React.useCallback((): number | string => {
		if (user?.roles[0] === UserRole.ASSISTANT && therapistSelectedId) {
			return +therapistSelectedId;
		}

		return 'me';
	}, [therapistSelectedId, user]);

	return {
		getTherapistIdParam,
	};
};
