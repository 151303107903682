import { AssistantInfo, Location } from './Generic';
import { UserRowCardProps } from '../components/Cards/UserRowCard/UserRowCard';

export enum UserRole {
	ASSISTANT = 5,
	INSTITUTION = 6,
	PATIENT = 3,
	THERAPIST = 4,
}

export enum SignatureType {
	Draw = 0,
	Write = 1,
}
export interface Scope {
	id: string;
	name: string;
	description?: string;
}
export interface User extends Credentials {
	id?: number;
	scope: Scope;
	isDisabled: boolean;
	alreadyLogged: boolean;
}

export interface PanelUser {
	id?: number;
	isDisabled: boolean;
	name: string;
	roles: [UserRole];
	avatarUrl?: string;
	updatedAt?: string;
}

export interface PanelUserExtended extends PanelUser {
	username: string;
}

export interface Credentials {
	username: string;
	password?: string;
}

export interface BasicInfo {
	[key: string]: string | string [] | Date | number | Location[] | undefined | AssistantInfo | UserRowCardProps[] | boolean | null;
	id?: number;
	name: string;
	lastName: string;
	email: string;
}

export interface PersonalInfo extends BasicInfo {
	birthdate: string;
	country: string;
	city: string;
	postalCode: string;
	phone: string;
	gender: string;
	maritalStatus?: string;
	job: string;
	tutor?: string;
}
export interface TherapistInfo extends PersonalInfo {
	assistant?: AssistantInfo;
	enrollment: string;
	institutions: UserRowCardProps[];
	signature?: string;
	avatar?: string;
	treatedPatients?: string[];
	clinicalSituations?: string[];
	locations?: Location[];
	instituteLogo?: string;
}
