import { createTheme } from '@material-ui/core/styles';

const muiTheme = createTheme({
	typography: {
		fontFamily: [
			'Source Sans Pro',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'Oxygen',
			'Ubuntu',
			'Cantarell',
			'Fira Sans',
			'Droid Sans',
			'"Helvetica Neue"',
			'sans-serif',
		].join(','),
	},
	palette: {
		primary: {
			main: '#587dd6',
		},
		secondary: {
			main: '#4ebed7',
		},
	},
});

export default muiTheme;
