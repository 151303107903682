import axios from 'axios';
import { ChatMessage } from '../interfaces/Chat';

const apiUrl = process.env.REACT_APP_API_URL;

export const getConverstations = (toId: number): Promise<ChatMessage[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/conversations/${toId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

