import { getTherapistToInvite, inviteTherapist, sendInvitation, TherapistToInvite } from '../../../services/InstitutionService';
import { isEmail, isRequired } from '../../../helpers/FormFieldsValidator';
import React, { useEffect } from 'react';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { Button } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { cloneDeep } from 'lodash';
import styles from './inviteTherapistModal.module.scss';
import { UserRowCardProps } from '../../Cards/UserRowCard/UserRowCard';
import { useTranslation } from 'react-i18next';

export interface InviteTherapistModalProps {
	open: boolean;
	onClose: () => void;
}

interface FormData {
	[key: string]: {
		error: boolean;
		errorMessage: string;
		value: string;
		showError: boolean;
		validation: (value: string) => boolean;
	};
}

interface InviteTherapistModalInterface {
	formData: FormData;
	therapists: UserRowCardProps[];
	handleChangeTextField: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleSave: () => void;
}

const initialFormData: FormData = {
	name: {
		error: false,
		errorMessage: 'invitations:FIELD_REQUIRED',
		value: '',
		showError: false,
		validation: isRequired,
	},
	lastName: {
		error: false,
		errorMessage: 'invitations:FIELD_REQUIRED',
		value: '',
		showError: false,
		validation: isRequired,
	},
	email: {
		error: false,
		errorMessage: 'EMAIL_INVALID_FORMAT',
		value: '',
		showError: false,
		validation: isEmail,
	},
};

export const useInviteTherapistModal = (props: InviteTherapistModalProps): InviteTherapistModalInterface => {
	const { t } = useTranslation();
	const [formData, setFormData] = React.useState<FormData>(initialFormData);
	const [therapists, setTherapists] = React.useState<UserRowCardProps[]>([]);

	const handleChangeTextField = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { name, value } = event.target;

		const error = !formData[name].validation(value.trim());

		setFormData({
			...formData,
			[name]: { ...formData[name], error: error, value: value },
		});
	};

	const handleSave = async(): Promise<void> => {
		const isFormValid = !Object.keys(formData).some(
			(key) => formData[key].error === true
		);

		if (isFormValid) {
			await sendInvitation(formData.email.value, formData.lastName.value, formData.name.value);
			props.onClose();
		} else {
			const formDataClone = cloneDeep(formData);

			Object.keys(formDataClone).forEach((item) => {
				formDataClone[item].showError = formDataClone[item].error;
			});

			setFormData(formDataClone);
		}
	};

	const loadTherapist = async (): Promise<void> => {
		const therapistsToInvite = await getTherapistToInvite();

		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		setTherapists(therapistsToInvite.map(addButtons));
	};

	useEffect(() => {
		loadTherapist();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const inviteTherapistById = async(id: number): Promise<void> => {
		await inviteTherapist(id);
		setTherapists(therapists => therapists.map((t) => {
			if (t.userId === id) {
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				return addButtons({...t, invited: true});
			}

			return t;
		}));
	};

	const addButtons = (row: TherapistToInvite): UserRowCardProps => {
		return {
			...row,
			actions: (
				<div className={styles.actions}>
					{
						row.invited
							? <>{t('institution:BUTTON_ADDED_THERAPIST')}<CheckCircleRoundedIcon className={styles.invitedIcon}/></>
							: <Button className={styles.addButton} endIcon={<AddCircleRoundedIcon className={styles.addIcon} />} onClick={(): Promise<void> => inviteTherapistById(row.userId)} variant={'text'}>{t('institution:BUTTON_ADD_THERAPIST')}</Button>
					}
				</div>
			),
		};
	};

	return {
		formData,
		therapists,
		handleChangeTextField,
		handleSave,
	};
};
