import React from 'react';

interface SideMenuStore {
	isSessionListCollapsed: boolean;
	isPatientListCollapsed: boolean;
	isOpenNewPatientModal: boolean;
	isOpenNewSessionTypeModal: boolean;
	handleOpenNewSessionTypeModal: () => void;
	handleOpenNewPatientModal: () => void;
	handleExpandSessionList: () => void;
	handleExpandPatientList: () => void;
}

const useSideMenu = (): SideMenuStore => {
	const [isSessionListCollapsed, setIsSessionListCollapsed] = React.useState<boolean>(false);
	const [isPatientListCollapsed, setIsPatientsListCollapsed] = React.useState<boolean>(false);
	const [isOpenNewSessionTypeModal, setIsOpenNewSessionTypeModal] = React.useState<boolean>(false);
	const [isOpenNewPatientModal, setIsOpenNewPatientModal] = React.useState<boolean>(false);

	const handleExpandSessionList = (): void => {
		setIsSessionListCollapsed(prevState => !prevState);
	};

	const handleExpandPatientList = (): void => {
		setIsPatientsListCollapsed(prevState => !prevState);
	};

	const handleOpenNewSessionTypeModal = (): void => {
		setIsOpenNewSessionTypeModal(prevState => !prevState);
	};

	const handleOpenNewPatientModal = (): void => {
		setIsOpenNewPatientModal(prevState => !prevState);
	};

	return ({
		isSessionListCollapsed,
		isPatientListCollapsed,
		isOpenNewPatientModal,
		isOpenNewSessionTypeModal,
		handleOpenNewSessionTypeModal,
		handleOpenNewPatientModal,
		handleExpandSessionList,
		handleExpandPatientList,
	});
};

export default useSideMenu;
