import React from 'react';
import styles from './StepTitle.module.scss';
import { useTranslation } from 'react-i18next';

interface StepTitleProps {
	title: string;
	subtitle: string;
}

const StepTitle: React.FC<StepTitleProps> = ({ title, subtitle }): JSX.Element => {
	const { t } = useTranslation();

	return (
		<>
			<div className={styles.title}>{t(title)}</div>
			<div className={styles.subtitle}>{t(subtitle)}</div>
		</>
	);};

export default StepTitle;
