export enum TaskType {
	// 0: Confirm session
	ConfirmSession,
	// 1: Fill my own data
	FillMyData,
	// 2: Fill patient data
	FillPatientData,
	// 3: Assign resource for the first time
	AssignResource,
	// 4: Fill session data for patient
	FillSessionData,
	// 5: Request to join an institution
	RequestToJoinInstitution

}

export interface Badge {
	badge: boolean;
}

export interface Reminder {
	createdAt: Date;
	id: number;
	institution: {
		id: number;
		name: string;
	};
	isDeleted: boolean;
	isRead: boolean;
	isStarred: boolean;
	patient: {
		familyName: string;
		givenName: string;
		id: number;
	};
	therapist: {
		familyName: string;
		givenName: string;
		id: number;
	};
	type: TaskType;
}

export interface Task {
	date: string;
	id?: number;
	isRead: boolean;
	isStarred: boolean;
	title: string;
	type: TaskType;
	patientId: number;
	patientName: string;
}
