import { answerDocumentByPatient, getPatientDocumentsByTherapist, getPatientReportsByTherapist, getPreviewDocument, getReportPreviewPdf, getResourcesByTherapist, readDocumentByPatient } from '../../../services/PatientService';
import { DocumentCard, StatusDocumentCard } from '../../../interfaces/Document';
import React, { useEffect } from 'react';
import { createAnswerJson } from '../../../services/DocumentService';
import { Report } from '../../../interfaces/Report';
import { ResourceCard } from '../../../interfaces/Resource';
import { TherapistContext } from '../../../contexts/TherapistContext';
import { useLocation } from 'react-router-dom';

type LibraryCardType = ResourceCard | DocumentCard | Report;

interface LibraryStore {
	activeItem?: LibraryCardType;
	data: Array<LibraryCardType>;
	openPDFPreview: boolean;
	openUpdateTherapist: boolean;
	selectedTab: number;
	therapistSelectedId: number;
	handleChangeTabs: (event: unknown, newValue: number) => void;
	handleClickActiveCard: (id: number) => void;
	handleClickActiveReportCard: (id: number) => void;
	handleConfirmFormPreview: () => Promise<void>;
	handleOpenPDFPreview: () => void;
	handleOpenUpdateTherapist: () => void;
}

export interface LocationState {
	id: string;
	cardType: string;
}

const tabs = [
	{
		id: 0,
		name: 'resources',
	},
	{
		id: 1,
		name: 'documents',
	},
	{
		id: 2,
		name: 'reports',
	},
];

const useLibrary = (): LibraryStore => {
	const location = useLocation<LocationState>();
	const [data, setData] = React.useState<Array<LibraryCardType>>([]);
	const [selectedTab, setSelectedTab] = React.useState<number>(0);
	const [activeItem, setActiveItem] = React.useState<LibraryCardType>();
	const [openUpdateTherapist, setOpenUpdateTherapist] = React.useState<boolean>(false);
	const [openPDFPreview, setOpenPDFPreview] = React.useState<boolean>(false);
	const { therapistSelected } = React.useContext(TherapistContext);

	const isDocument = (doc: DocumentCard | ResourceCard | Report): doc is DocumentCard => {
		return (doc as DocumentCard).hasBeenRead !== undefined;
	};

	const isReport = (report: DocumentCard | ResourceCard | Report): report is Report => {
		return (report as Report).reportContent !== undefined;
	};

	const readDocument = React.useCallback(async (item: DocumentCard): Promise<void> => {
		if (item.hasBeenRead === StatusDocumentCard.Unread) {
			await readDocumentByPatient(item.id);

			setData(data => data.map(doc => {
				if (doc.id === item.id) {
					return {...doc, hasBeenRead: StatusDocumentCard.Read };
				}

				return doc;
			}));
		}
	}, []);

	const setReportToDocument = async (doc: DocumentCard): Promise<DocumentCard> => {
		if (doc.hasBeenRead === StatusDocumentCard.IsReady && !doc.reportDocument) {
			doc.reportDocument = await getPreviewDocument(doc.id);
		}

		return doc;
	};

	const setReportToReport = async (report: Report): Promise<Report> => {
		if (!report.file) {
			report.file = await getReportPreviewPdf(report.id);
		}

		return report;
	};

	const onClickDocument = React.useCallback(async (doc: DocumentCard) => {
		await readDocument(doc);

		return await setReportToDocument(doc);
	}, [readDocument]);

	const onClickReport = React.useCallback(async (doc: Report) => {
		return await setReportToReport(doc);
	}, []);

	const loadData = React.useCallback(async (): Promise<void> => {
		let response: Array<LibraryCardType> = [];

		if (therapistSelected.id) {
			switch (selectedTab) {
				case 0:
					response = await getResourcesByTherapist(therapistSelected.id);
					break;
				case 1:
					response = await getPatientDocumentsByTherapist(therapistSelected.id);
					break;
				case 2:
					response = await getPatientReportsByTherapist(therapistSelected.id);
					break;
				default:
					break;
			}

			setData(response);
		}

	}, [selectedTab, therapistSelected.id]);

	const setDefaultActiveItem = React.useCallback(async (): Promise<void> => {
		if (!activeItem && data.length > 0) {
			let item = location.state && location.state.id ? data.find(item => item.id === +location.state.id) : data[0];

			if (item && isDocument(item)) {
				item = await onClickDocument(item);
			}

			if (item && isReport(item)) {
				item = await onClickReport(item);
			}

			setActiveItem(item);
		}
	}, [activeItem, data, location.state, onClickDocument, onClickReport]);

	React.useEffect(() => {
		loadData();
	}, [loadData, therapistSelected]);

	useEffect(() => {
		setDefaultActiveItem();
	}, [setDefaultActiveItem]);

	React.useEffect(() => {
		const tabId = tabs.find(item => item.name === location.state?.cardType)?.id;

		setSelectedTab(tabId ?? 0);
	}, [location.state]);

	const handleChangeTabs = (event: unknown, newValue: number): void => {
		setActiveItem(undefined);
		setData([]);
		setSelectedTab(newValue);
	};

	const handleClickActiveCard = async(id: number): Promise<void> => {
		let item = data.find(item => item.id === id);

		if (item?.id === activeItem?.id) {
			return;
		}

		if (item && isDocument(item)) {
			item = await onClickDocument(item);
		}

		if (item && isReport(item)) {
			item = await onClickReport(item);
		}

		setActiveItem(item);
	};

	const handleOpenUpdateTherapist = (): void => {
		setOpenUpdateTherapist(prevState => !prevState);
	};

	const handleOpenPDFPreview = (): void => {
		setOpenPDFPreview(prevState => !prevState);
	};

	const handleClickActiveReportCard = async(id: number): Promise<void> => {
		await handleClickActiveCard(id);
		handleOpenPDFPreview();
	};

	const handleConfirmFormPreview = async (): Promise<void> => {
		if (activeItem && activeItem.id) {
			const answers = createAnswerJson({
				id: (activeItem as DocumentCard).id,
				title: (activeItem as DocumentCard).name,
				description: (activeItem as DocumentCard).description,
				sections: (activeItem as DocumentCard).sections,
				showErrors: false,
			});

			await answerDocumentByPatient(
				activeItem.id,
				answers
			);
			const docWithReport = await setReportToDocument({ ...activeItem, hasBeenRead: StatusDocumentCard.IsReady } as DocumentCard);

			setActiveItem(docWithReport);
			setData(data => data.map(doc => {
				if (doc.id === activeItem.id) {
					return docWithReport;
				}

				return doc;
			}));
		}
	};

	return ({
		activeItem,
		data,
		openPDFPreview,
		openUpdateTherapist,
		selectedTab,
		therapistSelectedId: therapistSelected.id,
		handleChangeTabs,
		handleClickActiveCard,
		handleClickActiveReportCard,
		handleConfirmFormPreview,
		handleOpenPDFPreview,
		handleOpenUpdateTherapist,
	});
};

export default useLibrary;
