import { ChatContact, ChatMessage } from '../interfaces/Chat';
import { chatReducer, ChatStateInterface } from '../reducers/ChatReducer';
import React from 'react';

export interface ChatProviderStore {
	chatState: ChatStateInterface;
}

export interface ChatUpdateProviderStore {
	activateChat: (activeContact: ChatContact) => void;
	cleanChat: () => void;
	newMessage: (message: ChatMessage) => void;
	loadContacts: (contacts: ChatContact[]) => void;
	loadMessages: (messages: ChatMessage[]) => void;
}

export const ChatContext = React.createContext({} as ChatProviderStore);
export const ChatUpdateContext = React.createContext({} as ChatUpdateProviderStore);

const initialState: ChatStateInterface = {
	activeContact: undefined,
	contacts: [],
	messages: [],
};

export const ChatProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
	const [chatState, dispatch] = React.useReducer(chatReducer, initialState);

	const activateChat = React.useCallback((activeContact: ChatContact): void => {
		dispatch({type: 'ACTIVATE_CHAT', activeContact });
	}, []);

	const cleanChat = React.useCallback((): void => {
		dispatch({type: 'CLEAN_CHAT'});
	}, []);

	const newMessage = React.useCallback((message: ChatMessage): void => {
		dispatch({type: 'NEW_MESSAGE', message });
	}, []);

	const loadContacts = React.useCallback((contacts: ChatContact[]): void => {
		dispatch({type: 'LOAD_CONTACTS', contacts});
	}, []);

	const loadMessages = React.useCallback((messages: ChatMessage[]): void => {
		dispatch({type: 'LOAD_MESSAGES', messages});
	}, []);

	return (
		<ChatContext.Provider value={{chatState}}>
			<ChatUpdateContext.Provider value={{activateChat, cleanChat, newMessage, loadContacts, loadMessages}}>
				{
					children
				}
			</ChatUpdateContext.Provider>
		</ChatContext.Provider>
	);
};
