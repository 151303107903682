import Button from '../Button/Button';
import React from 'react';
import styles from './PlanCard.module.scss';
import Tag from '../Tag/Tag';
import { useTranslation } from 'react-i18next';

interface PlanCardProps {
	price: string;
	unit: string;
	title: string;
	description: JSX.Element;
	onClick: () => void;
	selected?: boolean;
	onClickPlanCard: (value: string) => void;
}

const PlanCard: React.FC<PlanCardProps> = ({ price, unit, title, description, onClick, selected, onClickPlanCard }) => {
	const planClassName = `${styles.plan} ${selected && styles.planSelected}`;
	const BottomClassName = `${styles.contentBottom} ${selected && styles.contentBottomSelected}`;
	const { t } = useTranslation();
	const handlePlanCard = (): void => {
		onClickPlanCard(title);
	};

	return (
		<div className={planClassName} onClick={handlePlanCard}>
			<div className={styles.planContainer}>
				<div className={styles.contentHighlight}><Tag text={title} /></div>
				<div>
					<span className={styles.planPrice}>{price}</span><span className={styles.planUnit}>{unit}</span>
					<div className={styles.planDescription}>
						{description}
					</div>
				</div>
				<div className={styles.planButton}>
					<Button onClick={onClick} title={selected ? t('plan:START') : `${t('plan:SELECT')} ${title}`} type={'PRIMARY'} />
				</div>
			</div>
			<div className={BottomClassName}>{selected ? <span className={styles.bottomText}>{t('plan:SELECTPLAN')}</span> : null}</div>
		</div>
	);
};

export default PlanCard;
