import { acceptInstitutionRequest, deleteInstitution, getAssistantInvitationEmail, getInstitutionsRequests, getTherapist, rejectInstitutionRequest, updateTherapist, updateTherapistAvatar, updateTherapistInstituteLogo, updateTherapistSignature } from '../../../services/TherapistService';
import { ConfirmationModalInterface, ConfirmationTypes } from '../../../interfaces/ConfirmationModels';
import { AlertContext } from '../../../contexts/AlertContext';
import { AuthUpdateContext } from '../../../contexts/AuthContext';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Form } from '../../../interfaces/FormData';
import { IconButton } from '@material-ui/core';
import React from 'react';
import { TherapistInfo } from '../../../interfaces/User';
import { UserRowCardProps } from '../../../components/Cards/UserRowCard/UserRowCard';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface LibraryStore {
	assistantInvitationEmail: string | null;
	institutionRequests: UserRowCardProps[];
	isLoading: boolean;
	isLogoLoading: boolean;
	therapistInfo: TherapistInfo;
	openPersonalInfoModal: boolean;
	openPersonalInfoExtensionModal: boolean;
	openSignatureModal: boolean;
	openConfirmationModal: boolean;
	confirmationModalState: ConfirmationModalInterface;
	handleOpenPersonalInfoModal: () => void;
	handleOpenPersonalInfoExtensionModal: () => void;
	handleOpenSignatureModal: () => void;
	handleUpdateAvatar: (e: any) => void;
	handleUpdateSignature: (signatureImage: string) => void;
	handleUpdateInstituteLogo: (e: any) => void;
	loadTherapist: () => Promise<void>;
	updateTherapistInfo: (formData: Form) => Promise<void>;
}

const useProfile = (): LibraryStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [therapistInfo, setTherapistInfo] = React.useState<TherapistInfo>({} as TherapistInfo);
	const [openPersonalInfoModal, setOpenPersonalInfoModal] = React.useState<boolean>(false);
	const [openPersonalInfoExtensionModal, setOpenPersonalInfoExtensionModal] = React.useState<boolean>(false);
	const [openSignatureModal, setOpenSignatureModal] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLogoLoading, setIsLogoLoading] = React.useState<boolean>(false);
	const [institutionRequests, setInstitutionRequests] = React.useState<UserRowCardProps[]>([]);
	const [assistantInvitationEmail, setAssistantInvitationEmail] = React.useState<string | null>(null);
	const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);
	const [confirmationModalState, setConfirmationModalState] = React.useState<ConfirmationModalInterface>({} as ConfirmationModalInterface);
	const { fetchUser } = React.useContext(AuthUpdateContext);
	const { getTherapistIdParam } = useTherapistSelected();

	const loadTherapist = async (): Promise<void> => {
		setIsLoading(true);

		try {
			const therapistIdParam = getTherapistIdParam();
			const therapist = await getTherapist(therapistIdParam);
			const assistantInfo = await getAssistantInvitationEmail(therapistIdParam);
			const requestFromInstitutions = await getInstitutionsRequests(therapistIdParam);

			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			setInstitutionRequests(requestFromInstitutions.map(addButtonsToInstitutionRequest));
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			setTherapistInfo({...therapist, institutions: therapist.institutions.map(addButtonsToInstitution)});
			setAssistantInvitationEmail(assistantInfo.email ?? null);
		} catch (error) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		loadTherapist();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseConfirmationModal = (): void => {
		setOpenConfirmationModal(false);
	};

	const handleAcceptInstitution = async (institutionId: number): Promise<void> => {
		const therapistIdParam = getTherapistIdParam();

		await acceptInstitutionRequest(therapistIdParam, institutionId);
		await loadTherapist();
	};

	const handleCancelInstitution = async (institutionId: number): Promise<void> => {
		const therapistIdParam = getTherapistIdParam();

		await rejectInstitutionRequest(therapistIdParam, institutionId);
		handleCloseConfirmationModal();
		await loadTherapist();
	};

	const handleDeleteInstitution = async (institutionId: number): Promise<void> => {
		const therapistIdParam = getTherapistIdParam();

		await deleteInstitution(therapistIdParam, institutionId);
		handleCloseConfirmationModal();
		await loadTherapist();
	};

	const handleConfirmationModal = (confirmationType: ConfirmationTypes, id: number): void => {
		switch (confirmationType) {
			case ConfirmationTypes.cancelInstitutionInvitation:
				setConfirmationModalState({
					content: 'profileView:CANCEL_INSTITTUION_INVITATION',
					title: 'profileView:WARNING_MEESSAGE',
					onClose: handleCloseConfirmationModal,
					onConfirm: async(): Promise<void> => await handleCancelInstitution(id),
				});
				setOpenConfirmationModal(true);

				break;
			case ConfirmationTypes.deleteInstitution:
				setConfirmationModalState({
					content: 'profileView:DELETE_INSTITTUION_CONFIRMATION_MESSAGE',
					title: 'profileView:WARNING_MEESSAGE',
					onClose: handleCloseConfirmationModal,
					onConfirm: async(): Promise<void> => await handleDeleteInstitution(id),
				});
				setOpenConfirmationModal(true);
				break;
			default:
				break;
		}
	};

	const addButtonsToInstitutionRequest = (row: UserRowCardProps): UserRowCardProps => {
		return {
			...row,
			actions: (
				<div>
					<IconButton onClick={(): Promise<void> => handleAcceptInstitution(row.userId)}><CheckIcon/></IconButton>
					<IconButton onClick={(): void => handleConfirmationModal(ConfirmationTypes.cancelInstitutionInvitation, row.userId)}><ClearIcon/></IconButton>
				</div>
			),
		};
	};

	const addButtonsToInstitution = (row: UserRowCardProps): UserRowCardProps => {
		return {
			...row,
			actions: (
				<div>
					<IconButton onClick={(): void => handleConfirmationModal(ConfirmationTypes.deleteInstitution, row.userId)}><ClearIcon/></IconButton>
				</div>
			),
		};
	};

	const handleUpdateSignature = async (signatureImage: string): Promise<void> =>{
		const therapistIdParam = getTherapistIdParam();

		await updateTherapistSignature(therapistIdParam, signatureImage);
	};

	const handleUpdateAvatar = async (e: any): Promise<void> => {
		setIsLoading(true);

		try {
			const therapistIdParam = getTherapistIdParam();

			await updateTherapistAvatar(therapistIdParam, e?.target?.files);
			await loadTherapist();
			fetchUser();
		} catch (e) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	const handleUpdateInstituteLogo = async (e: any): Promise<void> => {
		setIsLogoLoading(true);

		try {
			const therapistIdParam = getTherapistIdParam();

			await updateTherapistInstituteLogo(therapistIdParam, e?.target?.files);
			await loadTherapist();
			fetchUser();
		} catch (e) {
			showDefaultError();
		} finally {
			setIsLogoLoading(false);
		}
	};

	const handleOpenPersonalInfoModal = (): void => {
		setOpenPersonalInfoModal(prevState => !prevState);
	};

	const handleOpenPersonalInfoExtensionModal = (): void => {
		setOpenPersonalInfoExtensionModal(prevState => !prevState);
	};

	const handleOpenSignatureModal = (): void => {
		setOpenSignatureModal(prevState => !prevState);
	};

	const updateTherapistInfo = async (formData: Form): Promise<void> => {
		setIsLogoLoading(true);

		try {
			const therapistIdParam = getTherapistIdParam();

			await updateTherapist(therapistIdParam, formData);
		} catch (e) {
			showDefaultError();
		} finally {
			setIsLogoLoading(false);
		}
	};

	return {
		assistantInvitationEmail,
		institutionRequests,
		isLoading,
		isLogoLoading,
		therapistInfo,
		openPersonalInfoModal,
		openPersonalInfoExtensionModal,
		openSignatureModal,
		confirmationModalState,
		openConfirmationModal,
		handleOpenPersonalInfoModal,
		handleOpenPersonalInfoExtensionModal,
		handleOpenSignatureModal,
		handleUpdateAvatar,
		handleUpdateSignature,
		handleUpdateInstituteLogo,
		loadTherapist,
		updateTherapistInfo,
	};
};

export default useProfile;
