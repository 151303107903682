export default {
	es: {
		message: {
			SAVE_DEFAULT: 'Los datos se han guardado exitosamente',
			PROFILE_UPDATED: 'Su perfil ha sido actualizado exitosamente',
			CHANGE_PASSWORD_UPDATED: 'Su contraseña ha sido actualizada exitosamente',
			CLIENT_ADDED: 'El cliente ha sido guardado exitosamente',
			LINK_RESOURCES_SUCCESS: 'La vinculación de recursos ha sido exitosa',
			LINK_DOCUMENT_SUCCESS: 'La vinculación del document ha sido exitosa',
		},
		errorMessage: {
			DEFAULT: 'Lo sentimos, algo salió mal. Por favor, inténtalo de nuevo',
			LINK_RESOURCES_ERROR: 'Hubo un error intentando vincular los recursos',
			LINK_DOCUMENT_ERROR: 'Hubo un error intentando vincular el documento',
		},
	},
	en: {
		message: {
			SAVE_DEFAULT: 'Data has been saved successfully',
			PROFILE_UPDATED: 'Your profile has been updated successfully',
			CHANGE_PASSWORD_UPDATED: 'Your password has been updated successfully',
			CLIENT_ADDED: 'Client has been added successfully',
			LINK_RESOURCES_SUCCESS: 'Resources linked successfully',
			LINK_DOCUMENT_SUCCESS: 'Document linked successfully',
		},
		errorMessage: {
			DEFAULT: 'Sorry, something went wrong. Please try again',
			LINK_RESOURCES_ERROR: 'There was an error trying to link resources',
			LINK_DOCUMENT_ERROR: 'There was an error trying to link the document',
		},
	},
};
