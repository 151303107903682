import ChatContent from '../../components/Chat/ChatContent/ChatContent';
import { ChatUpdateContext } from '../../contexts/ChatContext';
import ContactList from '../../components/Chat/ContactList/ContactList';
import { PrivateTopBar } from '../../components';
import React from 'react';
import styles from './Chat.module.scss';
import { useTranslation } from 'react-i18next';

const Chat: React.FC = () => {
	const { t } = useTranslation();
	const { cleanChat } = React.useContext(ChatUpdateContext);

	React.useEffect(() => {
		return (): void => {
			cleanChat();
		};
	}, [cleanChat]);

	return (
		<>
			<div className={styles.chatContainer}>
				<PrivateTopBar title={t('chatView:CHAT')} />
				<div className={styles.content}>
					<ContactList />
					<ChatContent />
				</div>
			</div>
		</>
	);
};

export default Chat;

