import { Document, Page } from 'react-pdf/dist/entry.webpack';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import { PDFPreviewModal } from '..';
import React from 'react';
import { ResourceCard } from '../../interfaces/Resource';
import styles from './ResourcePreview.module.scss';
import TitleBox from '../TitleBox/TitleBox';
import useResourcePreview from './useResourcePreview';
import { useTranslation } from 'react-i18next';
import ZoomOutMapOutlinedIcon from '@material-ui/icons/ZoomOutMapOutlined';

interface ResourcePreviewProps {
	resource: ResourceCard;
	showTitle?: boolean;
}

const ResourcePreview: React.FC<ResourcePreviewProps> = ({
	resource,
	showTitle = true,
}) => {
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useResourcePreview();

	return (
		<div key={resource.id}>
			{showTitle && (
				<TitleBox type="text">
					<div className={styles.titleBox}>
						{t('libraryView:PREVIEW')}
						<span>{resource.name}</span>
					</div>
				</TitleBox>
			)}
			<div className={styles.container}>
				<Card className={styles.card} elevation={0}>
					<CardContent>
						{/* {'text' in resource ? (
							<div
								dangerouslySetInnerHTML={{
									__html: resource.text,
								}}
							></div>
						) : ( */}
						<>
							<div className={styles.pdfContainer}>
								<Document
									className={styles.pdf}
									file={resource.file}
									onLoadSuccess={(): void => {/* */}}
								>
									<Page pageNumber={1} />
								</Document>
								<Fab
									className={styles.floatButton}
									size="small"
									onClick={handleOpen}
								>
									<ZoomOutMapOutlinedIcon />
								</Fab>
							</div>
							<p className={styles.title}>{resource.name}</p>
							{resource.description && (
								<>
									<p className={styles.subtitle}>
										{t('libraryView:DESCRIPTION')}
									</p>
									<p className={styles.text}>
										{resource.description}
									</p>
								</>
							)}
							{resource.instructions && (
								<>
									<p className={styles.subtitle}>
										{t('libraryView:INSTRUCTION')}
									</p>
									<p className={styles.text}>
										{resource.instructions}
									</p>
								</>
							)}
							{resource.references && (
								<>
									<p className={styles.subtitle}>
										{t('libraryView:REFERENCES')}
									</p>
									<p className={styles.text}>
										{resource.references}
									</p>
								</>
							)}
						</>
						{/* )} */}
					</CardContent>
				</Card>
				<PDFPreviewModal
					file={(resource as ResourceCard).file?.url || ''}
					open={open}
					onClose={handleClose}
				/>
			</div>
		</div>
	);
};

export default ResourcePreview;
