import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { LoadingSpinner, StepTitle } from '../../../../../../../components';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';
import styles from './Confirmation.module.scss';
import useConfirmation from './useConfirmation';

const Confirmation = (): JSX.Element => {
	const {
		cards,
		isLoading,
		onDragEnd,
		handleRemoveItem,
	} = useConfirmation();

	return (
		<div className={styles.confirmation}>
			{isLoading
				? <LoadingSpinner />
				:
				<Box>
					<StepTitle title="patientsView:REPORTS_CONFIRMATION_TITLE" subtitle="patientsView:REPORTS_CONFIRMATION_SUBTITLE" />
					<Card className={styles.card} elevation={0}>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="list">
								{(provided: DroppableProvided): JSX.Element => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										{cards.map((card, index) => (
											<Draggable key={card.id} draggableId={card.id} index={index}>
												{(provided: DraggableProvided): JSX.Element => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={styles.alertContainer}
													>
														<Alert
															classes={{ root: styles.alertItemRoot, action: styles.alertItemAction }}
															icon={<InsertDriveFileOutlinedIcon fontSize="inherit" className={styles.alertIcon} />}
															onClose={(): void => handleRemoveItem(card.id)}
														>
															{card.content}
														</Alert>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</Card>
				</Box>
			}
		</div>
	);
};

export default Confirmation;
