import { Button as ButtonMaterial } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import styles from './Pagination.module.scss';
import { useTranslation } from 'react-i18next';

interface PaginationProps {
	from: number;
	to: number;
	total: number;
	navigate: () => void;
}

const Pagination: React.FC<PaginationProps> = ({ from, to, total, navigate }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.pagination}>
			<div className={styles.text}>{t('pagination:VIEWING')} <span>{from}-{to}</span> {t('pagination:OF')} <span>{total}</span></div>
			<ButtonMaterial
				classes={{ root: styles.button, startIcon: styles.buttonIcon, outlinedPrimary: styles.buttonPrimary }}
				variant="outlined"
				color="primary"
				startIcon={<NavigateBeforeIcon />}
				onClick={navigate}
			/>
			<ButtonMaterial classes={{ root: styles.button, startIcon: styles.buttonIcon, outlinedPrimary: styles.buttonPrimary }}
				variant="outlined"
				color="primary"
				startIcon={<NavigateNextIcon />}
				onClick={navigate}
			/>
		</div>
	);
};

export default Pagination;
