import { Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import React from 'react';
import styles from './InstituteLogo.module.scss';
import { useTranslation } from 'react-i18next';

interface InstituteLogoCardProps {
	image?: string;
	isLoading?: boolean;
	onSave?: (e: any) => void;
}

const InstituteLogoCard: React.FC<InstituteLogoCardProps> = ({ image, isLoading = false, onSave }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.instituteLogoPicture}>
			<Card className={styles.cardImage}>
				{isLoading &&
					<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
						<CircularProgress />
					</div>
				}
				{!isLoading ?
					<CardActionArea className={styles.cardActionArea}>
						<Avatar className={styles.cardMedia} alt="profile-img" src={image} />
						<div>
							<input type="file" onChange={onSave} id="logo-file" accept="image/*" multiple style={{display: 'none'}} />
							<label htmlFor="logo-file">
								<div className={styles.cardActionAreaOverlay}></div>
								<div className={styles.cardActionAreaDetails}>
									<PhotoCameraOutlinedIcon className={styles.cardActionAreaDetailsIcon} />
									<span >{t('profileView:CHANGE_INSTITUTE_LOGO_PICTURE')}</span>
								</div>
							</label>
						</div>
					</CardActionArea> :
					!isLoading && <Avatar className={styles.cardMedia} alt="profile-img" src={image} />
				}
			</Card>
		</div>
	);
};

export default InstituteLogoCard;
