import { Badge, Reminder } from '../interfaces/Task';
import { DocumentShared, DocumentSharedBody } from '../interfaces/Document';
import { Answer } from '../interfaces/DocumentForm';
import { AssistantInfo } from '../interfaces/Generic';
import axios from 'axios';
import { BasicInfo } from '../interfaces/User';
import { OptionSelect } from '../interfaces/Common';
import { Patient } from '../interfaces/Patient';
import { Report } from '../interfaces/Report';
import { SessionAvailability } from '../interfaces/SessionAvailability';
import { Therapist } from '../interfaces/Therapist';

const apiUrl = process.env.REACT_APP_API_URL;

export const findTherapist = (therapistId: number | string): Promise<Therapist> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const addNewPatient = (therapistId: number | string, data: BasicInfo): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/invitations`, {
			givenName: data.name,
			familyName: data.lastName,
			mail: data.email,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const inviteNewAssistant = (therapistId: number | string, data: BasicInfo): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/assistants`, {
			givenName: data.name,
			familyName: data.lastName,
			mail: data.email,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findAllPatientsByTherapist = (therapistId: number | string): Promise<Array<Patient>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/patients`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findTherapistPatient = (therapistId: number | string, patientId: number): Promise<Patient> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/patients/${patientId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findTreatedPatientsOptions = (): Promise<OptionSelect[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/life-stages`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findClinicalSituationsOptions = (): Promise<OptionSelect[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/medical-conditions`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findTasks = (therapistId: number | string): Promise<Reminder[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/reminders`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findInstitutionsRequests = (therapistId: number | string): Promise<Reminder[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/reminders/institutions`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getBadge = (therapistId: number | string): Promise<Badge> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/badge`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updatePatientInfo = (therapistId: number | string, patient: Patient): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/therapists/${therapistId}/patients/${patient.id}`, patient);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateTherapistInfo = (therapistId: number | string, therapist: Therapist): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/therapists/${therapistId}`, therapist);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateSignature = async (therapistId: number | string, data: FormData): Promise<void> => {
	if (apiUrl) {
		const signatureFileId = await axios.post(`${apiUrl}/files`, data);

		return axios.patch(`${apiUrl}/therapists/${therapistId}`, { signatureFile: {id: signatureFileId} });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateAvatar = async (therapistId: number | string, data: FormData): Promise<void> => {
	if (apiUrl) {
		const avatarFileId = await axios.post(`${apiUrl}/files`, data);

		return axios.patch(`${apiUrl}/therapists/${therapistId}`, { avatarFile: {id: avatarFileId} });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateInstituteLogo = async (therapistId: number | string, data: FormData): Promise<void> => {
	if (apiUrl) {
		const instituteLogoFileId = await axios.post(`${apiUrl}/files`, data);

		return axios.patch(`${apiUrl}/therapists/${therapistId}`, { logoFile: {id: instituteLogoFileId} });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findSessionAvailability = (therapistId: number | string): Promise<SessionAvailability> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/availability`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateSessionAvailability = (therapistId: number | string, sessionAvailability: SessionAvailability): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/therapists/${therapistId}/availability`, sessionAvailability);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findLinkedPatientDocuments = (therapistId: number | string, patientId: number): Promise<Array<DocumentShared>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/documents`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const linkDocumentToPatientRepo = (therapistId: number | string, documentId: number, patientId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/document/${documentId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const unlinkDocumentationFromPatientRepo = (therapistId: number | string, sharedDocumentId: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/therapists/${therapistId}/patients/${sharedDocumentId}/documents`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const shareDocumentationToPatientRepo = (therapistId: number | string, sharedDocumentId: number): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/therapists/${therapistId}/patients/${sharedDocumentId}/documents`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const answerDocument = (therapistId: number | string, sharedDocumentId: number, answers: Answer[]): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/patients/${sharedDocumentId}/complete`,
			{
				answer: answers,
			});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getDocumentReportsBodies = (therapistId: number | string, sharedDocumentIds: number[]): Promise<DocumentSharedBody[]> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/patients/reports`,
			{
				sharedDocumentIds: sharedDocumentIds,
			});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getPreview = (therapistId: number | string, sharedDocumentId: number): Promise<Blob> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/patients/shared-document/${sharedDocumentId}/report`, {
			responseType: 'blob',
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getReportPreview = (therapistId: number | string, reportId: number): Promise<Blob> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/patients/report/${reportId}`, {
			responseType: 'blob',
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getReportsByPatient = (therapistId: number | string, patientId: number): Promise<Report[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/report`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const saveReport = (therapistId: number | string, patientId: number, reportContent: string, title: string): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/save-report`,
			{
				reportContent: reportContent,
				title: title,
			});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const removeAssistant = (therapistId: number | string): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/therapists/${therapistId}/assistants`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const acceptInstitution = (therapistId: number | string, institutionId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/institutions/${institutionId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const rejectInstitution = (therapistId: number | string, institutionId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/institutions/${institutionId}/reject`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const assistantInvitationEmail = (therapistId: number | string): Promise<AssistantInfo> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/assistants/invitation`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const deleteAssistantInvitation = (therapistId: number | string): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/assistants/invitation/cancel`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const removeInstitution = (therapistId: number | string, institutionId: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/therapists/${therapistId}/institutions/${institutionId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const reInvitePatient = (therapistId: number | string, patientId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/patients/${patientId}/re-invite`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
