export default {
	es: {
		home: {
			PROMINENT_MAIN: 'Una plataforma para profesionales de la Salud Mental',
			PROMINENT_DESCRIPTION: 'Optimize la gestión de su práctica clínica y agilize los procesos de elaboración de reportes en la nube',
			CARD_AGILE_HIGHLIGHT: 'Práctica más ágil',
			CARD_AGILE_TITLE: 'Optimice su tiempo con un sistema de recordatorios de tareas y un calendario que lo mantiene organizado',
			CARD_AGILE_DESCRIPTION: 'El portal de pacientes hace que la admisión de nuevos pacientes y la comunicación continua durante todo el tratamiento sean muy fáciles y eficientes.',
			CARD_REPORTS_HIGHLIGHT: 'Informes simplificados',
			CARD_REPORTS_TITLE: 'Simplifique la creación de informes con el asistente para la elaboración de historias clínicas, notas de progreso o reportes',
			CARD_REPORTS_DESCRIPTION: 'Nuestra integración con los libros de planes de tratamiento desarrollado por nuestro equipo hace que sea mucho más fácil completar reportes sólidos y consistentes.',
			FUNCTIONALITY_MAIN: 'Funcionalidades',
			FUNCTIONALITY_AGENDA_TITLE: 'Agenda y lista de tareas',
			FUNCTIONALITY_AGENDA_DESCRIPTION: 'Dedique menos tiempo al papeleo y más tiempo a los pacientes, utilizando el sistema de agenda totalmente integrado.',
			FUNCTIONALITY_PORTAL_TITLE: 'Portal de pacientes',
			FUNCTIONALITY_PORTAL_DESCRIPTION: 'Permita que sus pacientes soliciten citas, completen formularios y reciban recursos psicoeducativos desde nuestra biblioteca.',
			FUNCTIONALITY_TELEHEALTH_TITLE: 'Telehealth y chat',
			FUNCTIONALITY_TELEHEALTH_DESCRIPTION: 'Comuníquese directamente con sus pacientes para reprogramar citas o recordarles que traigan material a una sesión.',
			FUNCTIONALITY_DOCUMENTATION_TITLE: 'Documentación',
			FUNCTIONALITY_DOCUMENTATION_DESCRIPTION: 'Acceda a sus registros en cualquier momento y lugar. Nuestras plantillas están diseñadas específicamente para cada profesional.',
			FUNCTIONALITY_RESOURCES_TITLE: 'Biblioteca de recursos',
			FUNCTIONALITY_RESOURCES_DESCRIPTION: 'Nuestra biblioteca integral de recursos está diseñada para profesionales de la salud mental en el formato ideal para sus pacientes.',
			FUNCTIONALITY_SECURITY_TITLE: 'Seguridad',
			FUNCTIONALITY_SECURITY_DESCRIPTION: 'La seguridad es nuestra principal prioridad, y trabajamos árduamente para mantener sus registros seguros y protegidos.',
			FUNCTIONALITY_READ_MORE: 'Leer más acerca de las funcionalidades',
		},
	},
	en: {
		home: {
			PROMINENT_MAIN: 'A platform for Mental Health professionals',
			PROMINENT_DESCRIPTION: 'Optimize your clinical practice management and streamline reporting processes in the cloud',
			CARD_AGILE_HIGHLIGHT: 'More agile practice',
			CARD_AGILE_TITLE: 'Optimize your time with a task reminder system and calendar to keep you organized',
			CARD_AGILE_DESCRIPTION: 'The patient portal makes admitting new patients and continuous communication throughout treatment very easy and efficient.',
			CARD_REPORTS_HIGHLIGHT: 'Simplified reports',
			CARD_REPORTS_TITLE: 'Simplify reporting with the wizard for creating medical records, progress notes or reports',
			CARD_REPORTS_DESCRIPTION: 'Our integration with the treatment plan books developed by our team makes it much easier to complete robust and consistent reports.',
			FUNCTIONALITY_MAIN: 'Functionalities',
			FUNCTIONALITY_AGENDA_TITLE: 'Agenda and to-do list',
			FUNCTIONALITY_AGENDA_DESCRIPTION: 'Spend less time on paperwork and more time on patients, using the fully integrated scheduling system.',
			FUNCTIONALITY_PORTAL_TITLE: 'Patient Portal',
			FUNCTIONALITY_PORTAL_DESCRIPTION: 'Allow your patients to request appointments, fill out forms, and receive psychoeducational resources from our library.',
			FUNCTIONALITY_TELEHEALTH_TITLE: 'Telehealth and chat',
			FUNCTIONALITY_TELEHEALTH_DESCRIPTION: 'Contact your patients directly to reschedule appointments or remind them to bring materials to a session.',
			FUNCTIONALITY_DOCUMENTATION_TITLE: 'Documentation',
			FUNCTIONALITY_DOCUMENTATION_DESCRIPTION: 'Access your records anytime, anywhere. Our templates are specifically designed for each professional.',
			FUNCTIONALITY_RESOURCES_TITLE: 'Resource Library',
			FUNCTIONALITY_RESOURCES_DESCRIPTION: 'Our comprehensive library of resources is designed for mental health professionals in the ideal format for their patients.',
			FUNCTIONALITY_SECURITY_TITLE: 'Security',
			FUNCTIONALITY_SECURITY_DESCRIPTION: 'Security is our top priority, and we work hard to keep your records safe and secure.',
			FUNCTIONALITY_READ_MORE: 'Read more about the functionalities',
		},
	},
};
