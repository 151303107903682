import { BasicInfo, PersonalInfo } from '../../../interfaces/User';
import { isEmail, isRequired } from '../../../helpers/FormFieldsValidator';
import React, { Reducer } from 'react';
import { addPatient } from '../../../services/TherapistService';
import { AlertContext } from '../../../contexts/AlertContext';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface UpdatePatientModalStore {
	formData: FormData;
	formIsValid: boolean;
	isLoading: boolean;
	errorMessageBuilder: (errorMessages: string[]) => JSX.Element | undefined;
	handleChangeTextField: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleClose: () => void;
	handleConfirm: () => void;
}

interface UpdatePatientModalProps {
	data?: PersonalInfo;
	actionType?: 'ADD' | 'UPDATE';
	fetchData?: () => Promise<void>;
	onChange: (refreshData: boolean) => void;
}

interface Action {
	type: string;
	value?: string | Date;
	name?: string;
	isUpdated?: boolean;
	error?: boolean;
	errorMessages?: string[];
	data?: FormData;
}

interface FormValidation {
	message: string;
	validation: (value: string) => boolean;
}

interface FormData {
	[key: string]: {
		value: string;
		isUpdated: boolean;
		error?: boolean;
		errorMessages: string[];
		validations?: FormValidation[];
	};
}

const initialFormData: FormData = {
	name: {
		value: '',
		isUpdated: false,
		error: false,
		errorMessages: [],
		validations: [
			{
				message: 'forms:FIELD_REQUIRED',
				validation: isRequired,
			},
		],
	},
	lastName: {
		value: '',
		isUpdated: false,
		error: false,
		errorMessages: [],
		validations: [
			{
				message: 'forms:FIELD_REQUIRED',
				validation: isRequired,
			},
		],
	},
	email: {
		value: '',
		isUpdated: false,
		error: false,
		errorMessages: [],
		validations: [
			{
				message: 'forms:FIELD_REQUIRED',
				validation: isRequired,
			},
			{
				message: 'forms:INVALID_EMAIL_ADDRESS',
				validation: isEmail,
			},
		],
	},
};

const reducer = (state: FormData, action: Action): FormData => {
	switch (action.type) {
		case 'update_all':
			return action.data as FormData;
		case 'update': {
			const name = action.name as string;
			const value = action.value as string;
			const isUpdated = action.isUpdated as boolean;
			const error = action.error as boolean;
			const errorMessages = action.errorMessages as string[];

			return { ...state, [name]: { ...state[name], value, isUpdated, error, errorMessages: errorMessages } };
		}

		default:
			return state;
	}
};

const useUpdatePatientModal = (props: UpdatePatientModalProps): UpdatePatientModalStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [formData, setFormData] = React.useReducer<Reducer<FormData, Action>>(reducer, initialFormData);
	const [formIsValid, setFormIsValid] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { t } = useTranslation();
	const { getTherapistIdParam } = useTherapistSelected();

	React.useEffect(() => {
		const keys = props.data && Object.keys(props.data);

		if (keys && keys.length) {

			const data = keys.reduce((acc, key) => {
				const value = {
					name: key,
					value: (props.data as PersonalInfo)[key],
					isUpdated: false,
					error: false,
				};

				return ({ ...acc, [key]: value });
			}, {});

			setFormData({ type: 'update_all', data });
		}
	}, [props.data]);

	React.useEffect(() => {
		const isUpdated = Object.keys(formData).some((key) => formData[key].isUpdated);

		const passValidations = Object.keys(formData).every((key) => {
			return !formData[key].error && !(formData[key].errorMessages.length > 0);
		});

		setFormIsValid(isUpdated && passValidations);
	}, [formData]);

	const handleClose = (): void => {
		props.onChange(false);
	};

	const cleanValidationFormField = (name: string): void => {
		formData[name].error = false;
		formData[name].errorMessages = [];
	};

	const validateField = (field: string): string[] => {
		cleanValidationFormField(field);
		const errorMessages: string[] = [];

		formData[field].validations?.forEach(val => {
			const passValidations = val.validation(formData[field].value);

			if (!passValidations) {
				errorMessages.push(val.message);
			}
		});

		return errorMessages;
	};

	const validateForm = (): boolean => {
		let isValidForm = true;
		const data = Object.keys(formData).reduce((acc, key) => {
			const errorMessages = validateField(key);

			if (errorMessages.length) {
				isValidForm = false;
			}

			const value = {
				name: key,
				value: formData[key].value,
				isUpdated: false,
				error: errorMessages.length > 0,
				errorMessages: errorMessages,
				validations: formData[key].validations,
			};

			return ({ ...acc, [key]: value });
		}, {});

		setFormData({ type: 'update_all', data });

		return isValidForm;
	};

	const handleConfirm = async (): Promise<void> => {
		setIsLoading(true);

		try {
			const isFormValid = validateForm();

			if (!isFormValid) {
				setIsLoading(false);

				return;
			}

			const data: BasicInfo = {
				name: formData.name.value,
				lastName: formData.lastName.value,
				email: formData.email.value,
			};
			const therapistIdParam = getTherapistIdParam();

			await addPatient(therapistIdParam, data);

			if (props.fetchData) {
				await props.fetchData();
			}

			setFormData({ type: 'update_all', data: initialFormData });
			props.onChange(true);

		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		const isUpdated = props.actionType === 'UPDATE'
			? value !== (props.data as PersonalInfo)[name]
			: true;
		const errorMessages: string[] = [];

		if (value) {
			formData[name].validations?.forEach(val => {
				const passValidations = val.validation(value);

				if (!passValidations) {
					errorMessages.push(val.message);
				}
			});
		}

		setFormData({ type: 'update', name, value, isUpdated, error: errorMessages.length > 0, errorMessages });
	};

	const errorMessageBuilder = (errorMessages: string[]): JSX.Element | undefined => {
		if (errorMessages?.length) {
			return (
				<>
					{
						errorMessages.map((element: string) => (
							<>
								<span>{t(element)} </span>
								< br />
							</>))
					}
				</>
			);
		}

		return undefined;
	};

	return {
		formData,
		formIsValid,
		isLoading,
		errorMessageBuilder,
		handleChangeTextField,
		handleClose,
		handleConfirm,
	};
};

export default useUpdatePatientModal;
