import { BooleanPreview, CheckboxPreview, DatePreview, NumberDecimalPreview, NumberIntegerPreview, PreviewWrapper, RadioPreview, SelectPreview, TextPreview } from './Questions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Form } from '../../interfaces/DocumentForm';
import React from 'react';
import styles from './FormPreview.module.scss';

const componentsPreview = {
	BooleanQuestion: BooleanPreview,
	CheckboxQuestion: CheckboxPreview,
	DateQuestion: DatePreview,
	NumberDecimalQuestion: NumberDecimalPreview,
	NumberIntegerQuestion: NumberIntegerPreview,
	TextQuestion: TextPreview,
	RadioQuestion: RadioPreview,
	SelectQuestion: SelectPreview,
};

export interface FormPreviewProps {
	form: Form;
	sectionToShow: string;
}

const FormPreview: React.FC<FormPreviewProps> = ({ form, sectionToShow }) => {
	const renderQuestion = (questionIndex: number, sectionIndex: number): JSX.Element => {
		const { title, description, required, type } = (form as Form).sections[sectionIndex].questions[questionIndex];

		const commonProps = {
			questionIndex: questionIndex,
			sectionIndex: sectionIndex,
		};

		const Component = componentsPreview[type];

		return (
			<PreviewWrapper title={title} description={description} required={required}>
				<Component {...commonProps} />
			</PreviewWrapper>
		);
	};

	return (
		<div className={styles.container}>
			<Card className={styles.card} elevation={0}>
				<CardContent>
					{form && form.title ? (
						<div className={styles.form}>
							<div className={styles.formTitle}>
								{form.title}
							</div>
							{form.description && (
								<div className={styles.formDescription}>
									{form.description}
								</div>
							)}
							{form.sections &&
								form.sections.map(
									(section, sectionIndex) => {
										if (section.id === sectionToShow) {
											return (
												<div
													key={section.id}
													className={styles.section}
												>
													<div
														className={styles.sectionHeader}
													>
														<div
															className={
																styles.sectionTitle
															}
														>
															{section.title}
														</div>
														{section.description && (
															<div
																className={
																	styles.sectionDecription
																}
															>
																{section.description}
															</div>
														)}
													</div>
													{section.questions.map(
														(question, questionIndex) => (
															<div key={question.id}>
																{renderQuestion(
																	questionIndex,
																	sectionIndex
																)}
															</div>
														)
													)}
												</div>
											);
										}
									}
								)}
						</div>
					) : (
						<div>Loading</div>
					)}
				</CardContent>
			</Card>
		</div>
	);
};

export default FormPreview;
