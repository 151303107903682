import { Button as ActionButton, Checkbox as DefaultCheckbox, Divider, GoogleAuthButton, TextField } from '../../components/';
import { Link, Redirect } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import logo from '../../assets/images/pragma-logo-signin.svg';
import React from 'react';
import styles from './SignIn.module.scss';
import { useRoutes } from '../../helpers/useRoutes';
import { useSignIn } from './useSignIn';
import { useSignInValidation } from './useSignInValidation';
import { useTranslation } from 'react-i18next';

const SignIn: React.FC = () => {
	const { t } = useTranslation();
	const { routes: ROUTES } = useRoutes();
	const { rememberMe, setRememberMe, isAuth, handleSignInClick, signInError } = useSignIn();
	const { validateEmail, validatePassword, email, emailError, emailErrorMessage, password, passwordError, passwordErrorMessage } = useSignInValidation();

	if (isAuth) {
		return (
			<Redirect to="/" />
		);
	}

	return (
		<div className={styles.signin}>
			<div className={styles.signinContainer}>
				<div className={styles.signinLogoContainer}>
					<img src={logo} alt={'logo'} className={styles.signinLogo} />
				</div>
				<span className={styles.signinTitle}>{t('view:SIGNIN_MESSAGE')}</span>
				<div className={styles.signinFormContainer}>
					<GoogleAuthButton
						onClick={(): void => { console.log('google auth coming soon...'); }}
						showIcon={true}
						text={t('view:SIGNIN_BUTTON_GOOGLE')}
					/>
					<Divider className={styles.signinDivider} text={t('view:SIGNIN_SPAN_OR')} />
					<div className={styles.signinForm}>
						<div className={styles.formField}>
							<TextField
								error={emailError}
								errorMessage={emailErrorMessage}
								placeholder={t('view:SIGNIN_LABEL_EMAIL')}
								onChange={validateEmail}
								required={true}
								type="email"
								value={email || ''}
							/>
						</div>
						<div className={styles.formField}>
							<TextField
								error={passwordError}
								errorMessage={passwordErrorMessage}
								placeholder={t('view:SIGNIN_LABEL_PASSWORD')}
								onChange={validatePassword}
								required={true}
								type="password"
								value={password || ''}
							/>
						</div>
						{signInError && (
							<div className={styles.signinError}>
								<ErrorIcon style={{ marginRight: 5 }} />
								{signInError}
							</div>
						)}
						<div className={styles.signinFooter}>
							<DefaultCheckbox
								checked={rememberMe}
								onChange={(): void => { setRememberMe(!rememberMe); }}
								label={t('view:SIGNIN_REMEMBER_ME')}
							/>
							<Link className={styles.signinLink} to={ROUTES.FORGOT_PASSWORD}>{t('view:SIGNIN_FORGOT_PASSWORD')}</Link>
						</div>
						<ActionButton onClick={(): void => handleSignInClick(email, password)} title={t('view:SIGNIN_BUTTON')} isDisabled={emailError || passwordError || !email || !password} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
