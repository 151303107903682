import { Availability, SessionAvailability } from '../../../interfaces/SessionAvailability';
import { Button, ModalTab } from '../../index';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { DurationSelector } from '../../Selectors/DurationSelector/DurationSelector';
import Modal from '../../Modal/Modal';
import NewPlaceModal from '../NewPlaceModal/NewPlaceModal';
import { Office } from '../../../interfaces/Session';
import { Option } from '../../../interfaces/Common';
import React from 'react';
import ReactLoading from 'react-loading';
import styles from './AvailabilityModalTherapist.module.scss';
import { TherapistModalContext } from '../../../contexts/TherapistModalContext';
import { TimeSelector } from '../../Selectors/TimeSelector/TimeSelector';
import useAvailabilityModalTherapist from './useAvailabilityModalTherapist';
import { useTranslation } from 'react-i18next';

interface AvailabilityModalTherapistProps {
	places: Office[];
	therapistAvailability: SessionAvailability;
	fetchData: () => Promise<void>;
}

const AvailabilityModalTherapist: React.FC<AvailabilityModalTherapistProps> = (props: AvailabilityModalTherapistProps) => {
	const { t } = useTranslation();
	const [isOpenNewPlaceModal, setIsOpenNewPlaceModal] = React.useState<boolean>(false);
	const { places, therapistAvailability, fetchData } = props;
	const {
		sessionDurationOptions,
		formValues,
		isLoading,
		isValidForm,
		time,
		handleCloseAvailabilityModal,
		handleConfirmNewSession,
		handleIncrement,
		handleDecrement,
		handleInputChange,
		handleCheckChange,
		handleTimeSelectorInput,
		handleDurationSelectorInput,
		handlePlaceChange,
	} = useAvailabilityModalTherapist({ places, therapistAvailability, fetchData });
	const { isOpen } = React.useContext(TherapistModalContext);

	return (
		<Modal
			open={isOpen}
			size="md"
			title={t('availabilityView:TITLE')}
			handleOnClose={handleCloseAvailabilityModal}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirmNewSession,
				isDisabled: !isValidForm,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CANCEL'),
				onClick: handleCloseAvailabilityModal,
			}}
			isTertiaryButtonVisible={false}
			scroll="body"
		>
			{!isLoading ?
				<div className={styles.availabilityModalTherapist}>
					<div className={styles.topContainer}>
						<span className={styles.text}>{t('availabilityView:TOP_LABEL')}</span>
					</div>
					<hr style={{ borderTop: '1px' }} />
					<DurationSelector
						title={t('availabilityView:SESSIONS').toUpperCase()}
						mainLabel={t('availabilityView:SESSION_DURATION')}
						sideLabel={t('availabilityView:PERSONALIZE')}
						cancelLabel={t('patientHomeView:MODAL_BUTTON_CANCEL')}
						name={'sessionDuration'}
						handleInputChange={handleInputChange}
						handleCheckChange={handleCheckChange}
						values={sessionDurationOptions}
						fields={[formValues['sessionDuration']]}
						defaultValuesToDisplay={t('availabilityView:DURATION_PERIODS').split(',')}
					/>

					<hr style={{ borderTop: '1px' }} />

					{
						formValues.availabilities.map((availability: Availability, idx: number) => (
							<div key={idx}>
								<TimeSelector
									title={t('availabilityView:WORK_HOURS').toUpperCase()}
									hours={time.hours}
									days={time.days}
									daysLabel={t('availabilityView:REPEAT_DAYS')}
									startLabel={t('availabilityView:START_TIME')}
									endLabel={t('availabilityView:END_TIME')}
									handleInputChange={(e): void => handleTimeSelectorInput(e, idx)}
									startTime={availability.startTime}
									endTime={availability.endTime}
									startName={'startTime'}
									endName={'endTime'}
								/>
								<DurationSelector
									mainLabel={t('availabilityView:REPEAT_DAYS')}
									handleInputChange={(): null => null}
									handleCheckChange={(e, value): void => handleDurationSelectorInput(e, value, idx)}
									values={time.days}
									defaultValuesToDisplay={t('availabilityView:DAYS').split(',')}
									multipleValues
									name={'repeatingDays'}
									fields={availability.repeatingDays}
								/>
								<div style={{ margin: 15 }}>
									<ModalTab
										tabName={t('agendaView:PLACE_TAB')}
										name={'place'}
										placeholder={t('agendaView:PLACE_TAB')}
										selectOptions={places.map(
											(place: Office): Option => {
												return {
													key: place.id,
													value: place.address,
												};
											}
										)}
										buttonTitle={t('agendaView:ADD_PLACE_BUTTON')}
										tertiaryButton={{
											show: true,
											onClick: (): void => setIsOpenNewPlaceModal(!isOpenNewPlaceModal),
										}}
										handleInputChange={(e): void => handlePlaceChange(e, idx)}
										optionSelected={availability.therapistOffice.id as number}
									/>
								</div>
								{
									formValues.availabilities.length > 1 &&
									< Button
										type="TERTIARY"
										startIcon={<DeleteIcon />}
										title={t('patientHomeView:MODAL_BUTTON_CANCEL')}
										variant="text"
										onClick={(): void => handleDecrement(idx)}
									/>
								}
							</div>
						))
					}
					<Button
						type="TERTIARY"
						startIcon={<AddIcon />}
						title={t('availabilityView:ADD_DIFFERENT_HOURS')}
						variant="text"
						onClick={handleIncrement}
					/>

					<hr style={{ borderTop: '1px' }} />
					<DurationSelector
						title={t('availabilityView:CANCEL_SESSIONS').toUpperCase()}
						mainLabel={t('availabilityView:CANCEL_SENTENCE')}
						sideLabel={t('availabilityView:PERSONALIZE')}
						cancelLabel={t('patientHomeView:MODAL_BUTTON_CANCEL')}
						name={'cancellationNoticePeriod'}
						handleInputChange={handleInputChange}
						handleCheckChange={handleCheckChange}
						values={time.cancelDays}
						fields={[formValues['cancellationNoticePeriod']]}
						defaultValuesToDisplay={t('availabilityView:CANCEL_PERIODS').split(',')}
						bigLabel
					/>
				</div>
				:
				<ReactLoading type="spin" color="#4ebed7" height={'20%'} width={'20%'} />
			}
			<NewPlaceModal
				open={isOpenNewPlaceModal}
				onChange={(): void => setIsOpenNewPlaceModal((value: boolean) => !value)}
				fetchData={fetchData}
			/>
		</Modal>
	);
};

export default AvailabilityModalTherapist;
