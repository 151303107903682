import { useHistory, useLocation } from 'react-router-dom';
import { AlertContext } from '../../../contexts/AlertContext';
import { ColDef } from '../../../components/CardsTable/CardsTable';
import { getPatientsByTherapist } from '../../../services/TherapistService';
import { Patient } from '../../../interfaces/Patient';
import React from 'react';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface PatientsStore {
	filteredPatients: Patient[];
	isLoading: boolean;
	columns: ColDef[];
	openPatientModal: boolean;
	searchValue: string;
	handleOnClickPatient: (id: number) => void;
	handleOpenPatientModal: () => void;
	handleClosePatientModal: () => void;
	handleOnConfirmPatientModal: () => Promise<void>;
	handleChangeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const columnsDef: ColDef[] = [
	{ field: 'familyName', headerName: 'patientsView:PATIENTS_TABLE_PATIENT_FAMILY_NAME', flex: 1 },
	{ field: 'givenName', headerName: 'patientsView:PATIENTS_TABLE_PATIENT_GIVEN_NAME', flex: 1 },
	{ field: 'lastSession', headerName: 'patientsView:PATIENTS_TABLE_LAST_SESSION', flex: 1 },
	{ field: 'nextSession', headerName: 'patientsView:PATIENTS_TABLE_NEXT_SESSION', flex: 1 },
];

const usePatients = (): PatientsStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const history = useHistory();
	const [patients, setPatients] = React.useState<Array<Patient>>([]);
	const [filteredPatients, setFilteredPatients] = React.useState<Array<Patient>>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [columns, setColumns] = React.useState<Array<ColDef>>([]);
	const [openPatientModal, setOpenPatientModal] = React.useState<boolean>(false);
	const [searchValue, setSearchValue] = React.useState<string>('');
	const { getTherapistIdParam } = useTherapistSelected();
	const location = useLocation();

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const data = await getPatientsByTherapist(therapistIdParam);

			setPatients(data);
			setFilteredPatients(data);
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	React.useEffect(() => {
		loadData();
		setColumns(columnsDef);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (searchValue) {
			const processedData = patients
				.filter(patient => (patient.givenName + patient.familyName).toUpperCase().includes(searchValue.toUpperCase()));

			setFilteredPatients(processedData);
		} else {
			setFilteredPatients(patients);
		}
	}, [searchValue]);

	const handleOnClickPatient = (id: number): void => {
		history.push(`${location.pathname}/${id}`);
	};

	const handleOpenPatientModal = (): void => {
		setOpenPatientModal(prevState => !prevState);
	};

	const handleClosePatientModal = (): void => {
		handleOpenPatientModal();
	};

	const handleOnConfirmPatientModal = async (): Promise<void> => {
		handleClosePatientModal();
		await loadData();
	};

	const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(event.target.value);
	};

	return ({
		filteredPatients,
		isLoading,
		columns,
		openPatientModal,
		searchValue,
		handleOnClickPatient,
		handleOpenPatientModal,
		handleClosePatientModal,
		handleOnConfirmPatientModal,
		handleChangeSearchValue,
	});
};

export default usePatients;
