import { addPlace, getPlacesByTherapist } from '../../../services/SessionService';
import { AlertContext } from '../../../contexts/AlertContext';
import { Option } from '../../../interfaces/Common';
import React from 'react';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface Error {
	hasError: boolean;
	text: string;
}

interface NewPlaceStore {
	error: Error;
	formIsValid: boolean;
	isLoading: boolean;
	place: string;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleClose: () => void;
	handleConfirm: () => void;
}

interface NewPlaceModalProps {
	fetchData: () => Promise<void>;
	onChange: (refreshData: boolean) => void;
}

const useNewPlaceModal = (props: NewPlaceModalProps): NewPlaceStore => {
	const { t } = useTranslation();
	const { showDefaultError } = React.useContext(AlertContext);
	const [place, setPlace] = React.useState<string>('');
	const [places, setPlaces] = React.useState<Array<Option>>([]);
	const [error, setError] = React.useState<Error>({ hasError: false, text: '' });
	const [formIsValid, setFormIsValid] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { getTherapistIdParam } = useTherapistSelected();

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const dataPlaces = await getPlacesByTherapist(therapistIdParam);

			setPlaces(dataPlaces.map(place => {
				return {
					key: place.id,
					value: place.address,
				};
			}));
		} catch (error) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;

		setPlace(value);
		setFormIsValid(value !== '');
		setError({
			hasError: false,
			text: '',
		});
	};

	const handleClose = (): void => {
		setPlace('');
		props.onChange(false);
	};

	const handleConfirm = async (): Promise<void> => {
		try {
			setIsLoading(true);

			const isValidPlace = !places.some(item => item.value.toLowerCase() == place.toLowerCase());

			if (isValidPlace) {
				const therapistIdParam = getTherapistIdParam();

				await addPlace(therapistIdParam, place);
				await props.fetchData();
				setPlace('');
				props.onChange(true);
				loadData();
			} else {
				setError({
					hasError: true,
					text: t('agendaView:PLACE_ALREADY_EXIST'),
				});
			}
		} catch (error) {
			showDefaultError();
		}
		finally {
			setIsLoading(false);
		}
	};

	return {
		error,
		formIsValid,
		isLoading,
		place,
		handleChange,
		handleConfirm,
		handleClose,
	};
};

export default useNewPlaceModal;
