import { Modal, PrivateTopBar } from '../../../components';
import { ConfirmationTypes } from '../../../interfaces/ConfirmationModels';
import React from 'react';
import styles from './Invitations.module.scss';
import TherapistRequestProfileCard from '../../../components/Cards/TherapistRequestProfileCard/TherapistRequestProfileCard';
import { useInvitations } from './useInvitations';
import { useTranslation } from 'react-i18next';

const Invitations = (): JSX.Element => {
	const { therapistRequests, openConfirmationModal, confirmationModalState, handleAcceptTherapist, handleConfirmationModal } = useInvitations();
	const { t } = useTranslation();

	return (
		<div className={styles.invitationContent}>
			<PrivateTopBar title={t('patientInvitationsView:TITLE_INVITATIONS')} />
			<div className={styles.content}>
				{
					therapistRequests && therapistRequests.length > 0 &&
					therapistRequests.map((therapistRequest) => {
						return (
							<>
								<TherapistRequestProfileCard
									fullName={`${therapistRequest.familyName} ${therapistRequest.givenName}`}
									image={therapistRequest.avatarFile?.url}
									key={`key-${therapistRequest.familyName} ${therapistRequest.givenName}`}
									onAccept={async (): Promise<void> => handleAcceptTherapist(therapistRequest.id) }
									onReject={(): void => handleConfirmationModal(ConfirmationTypes.cancelTherapistInvitation, therapistRequest.id)}
								/>
							</>
						);
					})
				}
				{
					therapistRequests && therapistRequests.length === 0 &&
					<div className={styles.noInvitations}>
						{t('patientInvitationsView:MESSAGE_NO_INVITATIONS')}
					</div>
				}
			</div>
			{
				openConfirmationModal &&
				<Modal
					open={openConfirmationModal}
					title={t(`${confirmationModalState.title}`)}
					handleOnClose={confirmationModalState.onClose}
					isPrimaryButtonVisible
					primaryButtonProps={{
						title: t('profileView:MODAL_CONFIRM_BUTTON'),
						onClick: confirmationModalState.onConfirm,
						isDisabled: false,
					}}
					isSecondaryButtonVisible
					secondaryButtonProps={{
						title: t('profileView:MODAL_CANCEL_BUTTON'),
						onClick: confirmationModalState.onClose,
					}}
					showDividers={false}
				>
					{t(`${confirmationModalState.content}`)}
				</Modal>
			}
		</div>
	);
};

export default Invitations;
