import { ConfirmationModalInterface, ConfirmationTypes } from '../../../interfaces/ConfirmationModels';
import { getProfile, removeTherapist } from '../../../services/InstitutionService';
import { AssistantTherapist } from '../../../interfaces/Assistant';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Options } from '../../../components/Cards/TherapistProfileCard/TherapistProfileCard';
import React from 'react';

interface UseHomeStore {
	menuOptions: Options[];
	therapists: AssistantTherapist[];
	openConfirmationModal: boolean;
	confirmationModalState: ConfirmationModalInterface;
}

export const useHome = (): UseHomeStore => {
	const [therapists, setTherapists] = React.useState<AssistantTherapist[]>([]);
	const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);
	const [confirmationModalState, setConfirmationModalState] = React.useState<ConfirmationModalInterface>({} as ConfirmationModalInterface);

	const loadData = async(): Promise<void> => {
		const institution = await getProfile();
		let therapistList: AssistantTherapist[] = [];

		if (institution && institution?.therapists?.length) {
			therapistList = therapistList.concat(institution.therapists);
		}

		setTherapists(therapistList);
	};

	const handleCloseConfirmationModal = (): void => {
		setOpenConfirmationModal(false);
	};

	const deleteTherapist = async(therapistId: number): Promise<void> => {
		await removeTherapist(therapistId);
		handleCloseConfirmationModal();
		await loadData();
	};

	const handleConfirmationModal = (confirmationType: ConfirmationTypes, therapistId: number): void => {
		switch (confirmationType) {
			case ConfirmationTypes.deleteInstitution:
				setConfirmationModalState({
					content: 'institution:DELETE_THERAPIST_CONFIRMATION_MESSAGE',
					title: 'institution:WARNING_MEESSAGE',
					onClose: handleCloseConfirmationModal,
					onConfirm: async(): Promise<void> => await deleteTherapist(therapistId),
				});
				setOpenConfirmationModal(true);

				break;
			default:
				break;
		}
	};

	const menuOptions: Options[] = [
		{
			onClick: (therapistId): void => handleConfirmationModal(ConfirmationTypes.deleteInstitution, therapistId),
			title: 'institution:DELETE_THERAPIST',
			icon: <DeleteOutlineIcon />,
			secondaryColor: true,
		},
	];

	React.useEffect(() => {
		loadData();
	}, []);

	return {
		menuOptions,
		therapists,
		openConfirmationModal,
		confirmationModalState,
	};
};
