import { Button, Carrousel, CombinedCard, SmallCard } from '../../components';
import agenda from '../../assets/images/icon-agenda-tareas.png';
import agile from '../../assets/images/home-screen-agile.png';
import agileMobile from '../../assets/images/home-screen-agile-mobile.png';
import { AuthContext } from '../../contexts/AuthContext';
import { CarrouselData } from '../../components/Carrousel/Carrousel';
import documentation from '../../assets/images/icon-documentacion.png';
import mainImage from '../../assets/images/home-screen-main-image.png';
import mainImageMobile from '../../assets/images/home-screen-main-image-mobile.png';
import portal from '../../assets/images/icon-portal-pacientes.png';
import React from 'react';
import reports from '../../assets/images/home-screen-reports.png';
import reportsMobile from '../../assets/images/home-screen-reports-mobile.png';
import resources from '../../assets/images/icon-biblioteca.png';
import security from '../../assets/images/icon-security.png';
import styles from './Home.module.scss';
import telehealth from '../../assets/images/icon-telehealth.png';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../helpers/useRoutes';
import { UserRole } from '../../interfaces/User';
import { useTranslation } from 'react-i18next';

const Home = (): JSX.Element => {
	const { t } = useTranslation();
	const history = useHistory();
	const { routes } = useRoutes();
	const { user } = React.useContext(AuthContext);
	const { assistantRoutes, institutionRoutes, therapistRoutes, patientRoutes } = useRoutes();

	React.useEffect(() => {
		// Temporary until we have a way to open the therapist or patient home from the landing page
		if (user?.roles.includes(UserRole.THERAPIST)) {
			history.push(`/${therapistRoutes.THERAPIST}/${therapistRoutes.AGENDA}`);
		} else if (user?.roles.includes(UserRole.PATIENT)) {
			history.push(`/${patientRoutes.PATIENT}/${patientRoutes.HOME}`);
		} else if (user?.roles.includes(UserRole.ASSISTANT)) {
			history.push(`/${assistantRoutes.ASSISTANT}/${assistantRoutes.HOME}`);
		} else if (user?.roles.includes(UserRole.INSTITUTION)) {
			history.push(`/${institutionRoutes.INSTITUTION}/${institutionRoutes.HOME}`);
		}
	}, [assistantRoutes.ASSISTANT, assistantRoutes.HOME, history, institutionRoutes.HOME, institutionRoutes.INSTITUTION, patientRoutes.HOME, patientRoutes.PATIENT, therapistRoutes.AGENDA, therapistRoutes.THERAPIST, user]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const prominentContent = [
		mainImage,
		mainImageMobile,
		t('home:PROMINENT_MAIN'),
		t('home:PROMINENT_DESCRIPTION'),
	];

	const combinedCards: [string, string, string, string, string][] = [
		[
			agile,
			t('home:CARD_AGILE_HIGHLIGHT'),
			t('home:CARD_AGILE_TITLE'),
			t('home:CARD_AGILE_DESCRIPTION'),
			agileMobile,
		],
		[
			reports,
			t('home:CARD_REPORTS_HIGHLIGHT'),
			t('home:CARD_REPORTS_TITLE'),
			t('home:CARD_REPORTS_DESCRIPTION'),
			reportsMobile,
		],
	];

	const FunctionalityData: CarrouselData[] = [
		{ src: agenda, title: t('home:FUNCTIONALITY_AGENDA_TITLE'), description: t('home:FUNCTIONALITY_AGENDA_DESCRIPTION') },
		{ src: portal, title: t('home:FUNCTIONALITY_PORTAL_TITLE'), description: t('home:FUNCTIONALITY_PORTAL_DESCRIPTION') },
		{ src: telehealth, title: t('home:FUNCTIONALITY_TELEHEALTH_TITLE'), description: t('home:FUNCTIONALITY_TELEHEALTH_DESCRIPTION') },
		{ src: documentation, title: t('home:FUNCTIONALITY_DOCUMENTATION_TITLE'), description: t('home:FUNCTIONALITY_DOCUMENTATION_DESCRIPTION') },
		{ src: resources, title: t('home:FUNCTIONALITY_RESOURCES_TITLE'), description: t('home:FUNCTIONALITY_RESOURCES_DESCRIPTION') },
		{ src: security, title: t('home:FUNCTIONALITY_SECURITY_TITLE'), description: t('home:FUNCTIONALITY_SECURITY_DESCRIPTION') },
	];

	return (
		<>
			<div className={styles.prominent}>
				<div className={styles.mainContent}>
					<div className={styles.homeImageContainer}>
						<img src={prominentContent[0]} alt="" className={styles.homeImage} />
						<img src={prominentContent[1]} alt="" className={styles.imageMobile} />
					</div>
					<div className={styles.content}>
						<h4 className={styles.contentTitle}>{prominentContent[2]}</h4>
						<p className={styles.contentDescription}>{prominentContent[3]}</p>
						{/* <div className={styles.bottomComponent}>
							<Button onClick={console.log} title={t('buttons:TRY_NOW_FOR_FREE')} type={'PRIMARY'} classes={styles.buttonStyle} />
						</div> */}
					</div>
				</div>
			</div>
			<div className={`${styles.info} ${styles.infoDesktop}`}>
				<div className={styles.infoCard}>
					<CombinedCard
						key={combinedCards[0][1]}
						image={combinedCards[0][0]}
						highlight={combinedCards[0][1]}
						title={combinedCards[0][2]}
						description={combinedCards[0][3]}
					/>
				</div>
				<div className={styles.infoCard}>
					<CombinedCard
						key={combinedCards[1][1]}
						image={combinedCards[1][0]}
						highlight={combinedCards[1][1]}
						title={combinedCards[1][2]}
						description={combinedCards[1][3]}
						imagePosition={'RIGHT'}
					// bottomComponent={
					// 	<Button onClick={console.log} title={t('buttons:TRY_NOW_FOR_FREE')} type={'PRIMARY'} />
					// }
					/>
				</div>
			</div>
			<div className={`${styles.info} ${styles.infoMobile}`}>
				<div className={styles.infoCard}>
					<CombinedCard
						key={combinedCards[0][1]}
						image={combinedCards[0][4]}
						highlight={combinedCards[0][1]}
						title={combinedCards[0][2]}
						description={combinedCards[0][3]}
					/>
				</div>
				<div className={styles.infoCard}>
					<CombinedCard
						key={combinedCards[1][1]}
						image={combinedCards[1][4]}
						highlight={combinedCards[1][1]}
						title={combinedCards[1][2]}
						description={combinedCards[1][3]}
						imagePosition={'RIGHT'}
					// bottomComponent={
					// 	<Button onClick={console.log} title={t('buttons:TRY_NOW_FOR_FREE')} type={'PRIMARY'} />
					// }
					/>
				</div>
			</div>
			<div className={styles.functionalitySection}>
				<h2 className={styles.functionalityTitle}>{t('home:FUNCTIONALITY_MAIN')}</h2>
				<Carrousel data={FunctionalityData} />
				<div className={styles.functionalityCardsContainer}>
					{
						FunctionalityData.map((child) => <SmallCard key={child.title} image={child.src} title={child.title} text={child.description} />)
					}
				</div>
				<div className={styles.button}>
					<Button onClick={(): void => history.push(`/${routes.FUNCTIONALITIES}`)} title={t('home:FUNCTIONALITY_READ_MORE')} type={'PRIMARY'} size="large" />
				</div>
			</div>
		</>
	);
};

export default Home;
