export default {
	es: {
		plan: {
			START: '¡Empezar prueba!',
			SELECTPLAN: 'Plan elegido',
			SELECT: 'Seleccionar',
			TYPE_BASIC: 'Basico',
			TYPE_PREMIUM: 'Premium',
			TYPE_INSTITUTIONAL: 'Institucional',
			CURRENCY: '$',
			MONTH: 'mes',
			PLAN_CARD_ITEM_UNLIMIT: 'Pacientes ilimitados.',
			PLAN_CARD_ITEM_PORTAL: 'Portal de pacientes.',
			PLAN_CARD_ITEM_NOTE: 'Registro de notas.',
			PLAN_CARD_ITEM_ONLINE: 'Online Booking',
			PLAN_CARD_ITEM_REMINDER: 'Recordatorios de sesiones',
			PLAN_CARD_ITEM_FORMS: 'Librería de formularios simples.',
			PLAN_CARD_ITEM_ASSISTANT: 'Asistente en la elaboración de informes.',
			PLAN_CARD_ITEM_SECURE: 'Canal de mensajería segura',
			PLAN_CARD_ITEM_HIPAA: 'HIPAA-Compliant (cifrado en todos los datos).',
			PLAN_CARD_ITEM_FUNCTIONALITY: 'Todas las funcionalidades del ',
			PLAN_CARD_ITEM_MORE: '+',
			PLAN_CARD_ITEM_READING: 'Biblioteca de material de lectura',
			PLAN_CARD_ITEM_LIBRARY_FORMS: 'Biblioteca de formularios simples y complejos',
			PLAN_CARD_ITEM_SECRETARY: 'Secretaria/o (registro de datos y agenda)',
			PLAN_CARD_ITEM_TELEHEALTH: 'Telehealth',
		},
	},
	en: {
		plan: {
			START: 'Start test',
			SELECTPLAN: 'Chosen plan',
			SELECT: 'Select',
			TYPE_BASIC: 'Basic',
			TYPE_PREMIUM: 'Premium',
			TYPE_INSTITUTIONAL: 'Institutional',
			CURRENCY: '$USD',
			MONTH: 'month',
			PLAN_CARD_ITEM_UNLIMIT: 'Unlimited patients.',
			PLAN_CARD_ITEM_PORTAL: 'Patient Portal.',
			PLAN_CARD_ITEM_NOTE: 'Record of notes.',
			PLAN_CARD_ITEM_ONLINE: 'Online Booking',
			PLAN_CARD_ITEM_REMINDER: 'Session reminders',
			PLAN_CARD_ITEM_FORMS: 'Simple forms library.',
			PLAN_CARD_ITEM_ASSISTANT: 'Assistant in the preparation of reports.',
			PLAN_CARD_ITEM_SECURE: 'Secure messaging channel',
			PLAN_CARD_ITEM_HIPAA: 'HIPAA-Compliant (encryption on all data).',
			PLAN_CARD_ITEM_FUNCTIONALITY: 'All the functionalities of the ',
			PLAN_CARD_ITEM_MORE: '+',
			PLAN_CARD_ITEM_READING: 'Reading Material Library',
			PLAN_CARD_ITEM_LIBRARY_FORMS: 'Simple and complex forms library',
			PLAN_CARD_ITEM_SECRETARY: 'Secretary (data record and agenda)',
			PLAN_CARD_ITEM_TELEHEALTH: 'Telehealth',
		},
	},
};
