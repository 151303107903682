import { CardsTable, PDFPreviewModal } from '../../../../../components';
import useReports, { ReportsProps } from './useReports';
import React from 'react';
import styles from './Reports.module.scss';

const Reports = (props: ReportsProps): JSX.Element => {
	const { reports, columns, isLoading, selectedReport, handleOnCloseReport } = useReports(props);

	return (
		<div className={styles.reports}>
			<CardsTable data={reports} columnsDef={columns} isLoading={isLoading} />
			{
				!!selectedReport && (
					<PDFPreviewModal
						file={selectedReport?.file || ''}
						open={!!selectedReport}
						onClose={handleOnCloseReport}
					/>
				)
			}
		</div>
	);
};

export default Reports;
