import {
	getPatientsByTherapist,
	linkDocumentationToPatient
} from '../../../services/TherapistService';
import { AlertContext } from '../../../contexts/AlertContext';
import { linkResourceToPatient } from '../../../services/ResourceLibraryService';
import { ListPatientsModalProps } from './ListPatientsModal';
import { Patient } from '../../../interfaces/Patient';
import React from 'react';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface PatientCard extends Patient {
	selected: boolean;
}

interface ListPatientsModalStore {
	isLinked: boolean | undefined;
	patientsCards: Array<PatientCard>;
	handleChangeSelected: (id: number | undefined) => void;
	handleClose: () => void;
	handleCloseSnackbar: () => void;
	handleConfirm: () => void;
	renderMessage: () => string;
}

const useListPatientsModal = (props: ListPatientsModalProps): ListPatientsModalStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [patientsCards, setPatientsCards] = React.useState<Array<PatientCard>>([]);
	const [isLinked, setIsLinked] = React.useState<boolean | undefined>(undefined);
	const { getTherapistIdParam } = useTherapistSelected();

	const { t } = useTranslation();

	const loadData = async (): Promise<void> => {
		try {
			const therapistIdParam = getTherapistIdParam();
			const data = await getPatientsByTherapist(therapistIdParam);
			const parsedData = data.map((patient: Patient): PatientCard => ({
				...patient,
				selected: false,
			}));

			setPatientsCards(parsedData);
		} catch (error) {
			showDefaultError();
		}
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);

	const handleChangeSelected = (id: number | undefined): void => {
		const data = patientsCards.map((card: PatientCard): PatientCard => {
			return card.id === id
				? { ...card, selected: !card.selected }
				: card;
		});

		setPatientsCards(data);
	};

	const handleClose = (): void => {
		props.onChange();
	};

	const handleConfirm = async (): Promise<void> => {
		try {
			patientsCards.forEach(async (patient) => {
				if (patient.id && props.objectId) {
					const therapistIdParam = getTherapistIdParam();

					if (props.type == 'resource') {

						await linkResourceToPatient(therapistIdParam, props.objectId, patient.id);
					} else if (props.type == 'document') {
						await linkDocumentationToPatient(
							therapistIdParam,
							props.objectId,
							patient.id
						);
					}

					setIsLinked(true);
				}
			});
		} catch (error) {
			showDefaultError();
			setIsLinked(false);
		}

		props.onChange();
	};

	const handleCloseSnackbar = (): void => setIsLinked(undefined);

	const renderMessage = (): string => {
		if (isLinked !== undefined) {
			switch (props.type) {
				case 'resource':
					return isLinked ? t('message:LINK_RESOURCES_SUCCESS') :t('errorMessage:LINK_RESOURCES_ERROR');
				case 'document':
					return isLinked
						? t('message:LINK_DOCUMENT_SUCCESS')
						: t('errorMessage:LINK_DOCUMENT_ERROR');
			}
		}

		return '';
	};

	return {
		isLinked,
		patientsCards,
		handleChangeSelected,
		handleClose,
		handleCloseSnackbar,
		handleConfirm,
		renderMessage,
	};
};

export default useListPatientsModal;
