export default {
	es: {
		treatedPatientAge: {
			CHILDREN: 'Niños',
			ADULTS: 'Adultos',
			OLDERS: 'Mayores',
		},
	},
	en: {
		treatedPatientAge: {
			CHILDREN: 'Children',
			ADULTS: 'Adults',
			OLDERS: 'Olders',
		},
	},
};
