import ContactSection from './ContactSection';
import { Footer } from '../../components';
import React from 'react';
import TopBar from '../../components/TopBar/public/TopBar';
import { useLayout } from './useLayout';

const PublicLayout: React.FC = ({ children }) => {
	const { isTransparentTopBar } = useLayout();

	return (
		<>
			<TopBar transparent={isTransparentTopBar} />
			{
				children
			}
			<ContactSection />
			<Footer />
		</>
	);
};

export default PublicLayout;
