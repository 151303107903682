import { Answer, CheckboxQuestion, Form, RadioQuestion, SelectQuestion } from '../interfaces/DocumentForm';
import { Document } from '../interfaces/Document';
import { DocumentsByTag } from '../interfaces/Tag';
import {
	findAllMyDocuments
} from '../api/DocumentRepository';

interface DocumentByTagMap {
	[key: number]: {
		documents: Array<Document>;
		name: string;
	};
}

const mapDocumentToDocumentByTag = (documentInfo: Document[]): DocumentsByTag[] => {
	const documentsByTagMap: DocumentByTagMap = {};

	documentInfo.forEach(doc => {
		if (doc.tags && doc.tags.length) {
			doc.tags.forEach(tag => {
				if (documentsByTagMap[tag.id]) {
					documentsByTagMap[tag.id].documents.push(doc);
				} else {
					documentsByTagMap[tag.id] = {
						name: tag.name,
						documents: [doc],
					};
				}
			});
		} else {
			if (documentsByTagMap[-1]) {
				documentsByTagMap[-1].documents.push(doc);
			} else {
				documentsByTagMap[-1] = {
					name: 'libraryView:GENERIC',
					documents: [doc],
				};
			}
		}
	});

	const documentsByTag: DocumentsByTag[] = [];

	Object.keys(documentsByTagMap).forEach((tagId) => {
		documentsByTag.push({
			id: +tagId,
			name: documentsByTagMap[+tagId].name,
			documents: documentsByTagMap[+tagId].documents,
		});
	});

	return documentsByTag;
};

export const getTagsAndDocumentsByTherapist = async (therapistId: number | string): Promise<Array<DocumentsByTag>> => {
	const documents = await findAllMyDocuments(therapistId);

	return mapDocumentToDocumentByTag(documents);
};

export const createAnswerJson = (formModel: Form): Answer[] => {
	const result: Answer[] = formModel.sections.map(
		section => section.questions.map(
			question => {
				let value;

				if (question.type === 'CheckboxQuestion') {
					const valueKeys: string[] = Object.keys((question as CheckboxQuestion).value);

					value = (question as CheckboxQuestion).options.filter(x => valueKeys.includes(x.order.toString())).map(o => o.title);
				} else if (question.type === 'RadioQuestion' || question.type === 'SelectQuestion') {
					value = (question as SelectQuestion | RadioQuestion).options.find(x => x.id === question.value)?.title || '';
				} else {
					value = question.value as string | string[];
				}

				return {
					id: question.id,
					value: value,
				};
			})).flat();

	return result;
};
