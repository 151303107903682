import { getTherapist, getTherapistPatient } from '../../../../../../../services/TherapistService';
import { GlobalReport, ReportContext, ReportUpdateContext } from '../../../../../../../contexts/ReportContext';
import { PersonalInfo, TherapistInfo } from '../../../../../../../interfaces/User';
import { AlertContext } from '../../../../../../../contexts/AlertContext';
import { PatientProfile } from '../../../../../../../interfaces/Patient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTherapistSelected } from '../../../../../../../hooks/useTherapistSelected';

interface InformationStore {
	report: GlobalReport;
	patientFields: Array<{field: string; label: string; col: number }>;
	therapistFields: Array<{field: string; label: string; col: number }>;
	patient: PersonalInfo;
	therapist: TherapistInfo;
	isLoading: boolean;
	selectAllPatientInfo: boolean;
	selectAllTherapistInfo: boolean;
	handlePatientSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleTherapistSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const patientFields = [
	{
		field: 'name',
		label: 'NAME',
		col: 0,
	},
	{
		field: 'lastName',
		label: 'LAST_NAME',
		col: 0,
	},
	{
		field: 'birthdate',
		label: 'BIRTHDATE',
		col: 0,
	},
	{
		field: 'country',
		label: 'COUNTRY',
		col: 0,
	},
	{
		field: 'city',
		label: 'CITY',
		col: 0,
	},
	{
		field: 'postalCode',
		label: 'POSTAL_CODE',
		col: 0,
	},
	{
		field: 'phone',
		label: 'PHONE',
		col: 1,
	},
	{
		field: 'email',
		label: 'EMAIL',
		col: 1,
	},
	{
		field: 'gender',
		label: 'GENDER',
		col: 1,
	},
	{
		field: 'maritalStatus',
		label: 'MARITAL_STATUS',
		col: 1,
	},
	{
		field: 'job',
		label: 'JOB',
		col: 1,
	},
	{
		field: 'tutor',
		label: 'TUTOR',
		col: 1,
	},
];

const therapistFields = [
	{
		field: 'enrollment',
		label: 'ENROLLMENT',
		col: 0,
	},
	{
		field: 'job',
		label: 'JOB',
		col: 0,
	},
	{
		field: 'email',
		label: 'EMAIL',
		col: 0,
	},
	{
		field: 'phone',
		label: 'PHONE',
		col: 0,
	},
	{
		field: 'signature',
		label: 'SIGNATURE',
		col: 1,
	},
];

const useInformation = (): InformationStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const { report, patientInfo, therapistInfo } = React.useContext(ReportContext);
	const { handleChangePatientInfo, handleChangeTherapistInfo, addPatientInformation, addTherapistInformation } = React.useContext(ReportUpdateContext);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectAllPatientInfo, setSelectAllPatientInfo] = React.useState<boolean>(false);
	const [selectAllTherapistInfo, setSelectAllTherapistInfo] = React.useState<boolean>(false);
	const { id } = useParams<{ id: string }>();
	const { getTherapistIdParam } = useTherapistSelected();

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const patientData = await getTherapistPatient(therapistIdParam, +id);
			const therapistData = await getTherapist(therapistIdParam);

			handleChangePatientInfo(patientData as PatientProfile);
			handleChangeTherapistInfo(therapistData as TherapistInfo);
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	React.useEffect(() => {
		loadData();

		setSelectAllPatientInfo(Object.keys(report.patient).length === patientFields.length);
		setSelectAllTherapistInfo(Object.keys(report.therapist).length === therapistFields.length);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePatientSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if(event.target.name.includes('selectAll')) {
			setSelectAllPatientInfo(prevState => !prevState);

			patientFields.forEach(item => {
				addPatientInformation(item.field, event.target.checked);
			});

		} else {
			addPatientInformation(event.target.name, event.target.checked);
		}

		setSelectAllPatientInfo(Object.keys(report.patient).length === patientFields.length);
	};

	const handleTherapistSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if(event.target.name.includes('selectAll')) {
			setSelectAllTherapistInfo(prevState => !prevState);

			therapistFields.forEach(item => {
				addTherapistInformation(item.field, event.target.checked);
			});
		} else {
			addTherapistInformation(event.target.name, event.target.checked);
		}

		setSelectAllTherapistInfo(Object.keys(report.therapist).length === therapistFields.length);
	};

	return ({
		report,
		patientFields,
		therapistFields,
		patient: patientInfo,
		therapist: therapistInfo,
		isLoading,
		selectAllPatientInfo,
		selectAllTherapistInfo,
		handlePatientSelect,
		handleTherapistSelect,
	});
};

export default useInformation;
