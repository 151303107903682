import { Button, CircularProgress } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Form } from '../../../interfaces/FormData';
import { FormSelectField } from './Fields';
import InputText from '../../Inputs/InputText/InputText';
import Modal from '../../Modal/Modal';
import React from 'react';
import { TherapistInfo } from '../../../interfaces/User';
import usePersonalInfoModal from './usePersonalInfoModal';
import useTherapistPersonalInfoModal from './useTherapistPersonalInfoModal';
import { useTranslation } from 'react-i18next';

export interface PersonalInfoModalProps {
	data: TherapistInfo;
	open: boolean;
	getUser: () => Promise<void>;
	updateUser: (formData: Form) => Promise<void>;
	onChange: () => void;
}

const TherapistProfessionalInfoModal: React.FC<PersonalInfoModalProps> = ({ data, open, getUser, updateUser, onChange }) => {
	const { t } = useTranslation();
	const {
		formData,
		formIsUpdated,
		handleChangeSelect,
		handleClose,
		handleConfirm,
		setFormData,
	} = usePersonalInfoModal({ data, getUser, updateUser, onChange });

	const {
		treatedPatientsOptions,
		clinicalSituationsOptions,
		therapistLocations,
		addLocation,
		handleLocationChange,
		removeLocation,
	} = useTherapistPersonalInfoModal({ data, setFormData });

	return (
		<Modal
			open={open}
			title={t('profileView:PROFESSIONAL_INFO')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: !formIsUpdated || therapistLocations.some(location => location.address === ''),
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
			scroll="body"
		>
			<div>
				{
					formData && Object.keys(formData).length > 0
						?
						<>
							<FormSelectField formKey={'treatedPatients'} formValue={formData['treatedPatients']} options={treatedPatientsOptions} handleChangeSelect={handleChangeSelect} multiple />
							<FormSelectField formKey={'clinicalSituations'} formValue={formData['clinicalSituations']} options={clinicalSituationsOptions} handleChangeSelect={handleChangeSelect} multiple />
							{
								therapistLocations.map((location, index) => {
									return (
										<div key={index} style={{ display: 'flex', alignItems: 'flex-end' }}>
											<InputText
												label={t('profileView:LOCATIONS')}
												name={'locations'}
												value={location.address}
												onChange={(event): void => handleLocationChange(event, index)}
												fullWidth
											/>
											{ therapistLocations.length > 1 &&
												<DeleteOutlineOutlinedIcon
													style={{ cursor: 'pointer' }}
													onClick={(): void => removeLocation(index)}
												/>
											}
										</div>
									);
								})
							}
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button
									color="primary"
									onClick={addLocation} >
									{t('profileView:ADD_LOCATION')}
								</Button>
							</div>
						</>
						:
						<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
							<CircularProgress />
						</div>
				}
			</div>
		</Modal>
	);
};

export default TherapistProfessionalInfoModal;
