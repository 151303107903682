export default {
	es: {
		country: {
			ARG: 'Argentina',
			CHL: 'Chile',
			URY: 'Uruguay',
			PRY: 'Paraguay',
			BOL: 'Bolivia',
			PER: 'Peru',
			ECU: 'Ecuador',
			COL: 'Colombia',
			MEX: 'Mexico',
			ESP: 'España',
		},
	},
	en: {
		country: {
			ARG: 'Argentina',
			CHL: 'Chile',
			URY: 'Uruguay',
			PRY: 'Paraguay',
			BOL: 'Bolivia',
			PER: 'Peru',
			ECU: 'Ecuador',
			COL: 'Colombia',
			MEX: 'Mexico',
			ESP: 'Spain',
		},
	},
};
