import { ReportContext, ReportUpdateContext } from '../../../../../../../contexts/ReportContext';
import { DropResult } from 'react-beautiful-dnd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmationStore {
	cards: Array<CardItem>;
	isLoading: boolean;
	onDragEnd: (result: DropResult ) => void;
	handleRemoveItem: (id: string) => void;
}

export interface CardItem {
	id: string;
	content: string;
}

const reorder = (list: Array<CardItem>, startIndex: number, endIndex: number): Array<CardItem> => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);

	result.splice(endIndex, 0, removed);

	return result;
};

const useConfirmation = (): ConfirmationStore => {
	const { t } = useTranslation();
	const { report } = React.useContext(ReportContext);
	const { handleResourcesOrder, setReport } = React.useContext(ReportUpdateContext);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const loadData = React.useCallback((): void => {
		setIsLoading(true);

		const resourcesUpdated: CardItem[] = [];

		report.forms.forEach(form => {
			const item: CardItem = {
				id: (form.id as number).toString(),
				content: form.name,
			};

			resourcesUpdated.push(item);
		});

		if(report.notes.some(note => note.checked)) {
			const item: CardItem = {
				id: 'notes',
				content: t('patientsView:REPORTS_NOTES_TITLE'),
			};

			resourcesUpdated.push(item);
		}

		handleResourcesOrder(resourcesUpdated);

		setIsLoading(false);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report.forms, report.notes]);

	React.useEffect(() => {
		loadData();
	}, [loadData]);

	const onDragEnd = (result: DropResult): void => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const resourcesItems = reorder(
			report.resourcesOrder,
			result.source.index,
			result.destination.index
		);

		handleResourcesOrder(resourcesItems);
	};

	const handleRemoveItem = (id: string): void => {
		if(id === 'notes') {
			const notesUpdated = report.notes.map(note => ({ ...note, checked: false }));

			setReport({ ...report, notes: notesUpdated});
		} else {
			const formsUpdated = report.forms;
			const index = formsUpdated.findIndex(item => item.id === +id);

			formsUpdated.splice(index, 1);

			setReport({ ...report, forms: formsUpdated});
		}
	};

	return {
		cards: report.resourcesOrder,
		isLoading,
		onDragEnd,
		handleRemoveItem,
	};
};

export default useConfirmation;
