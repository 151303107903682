import { acceptInstitution, addNewPatient, answerDocument, assistantInvitationEmail, deleteAssistantInvitation, findAllPatientsByTherapist, findClinicalSituationsOptions, findInstitutionsRequests, findLinkedPatientDocuments, findSessionAvailability, findTasks, findTherapist, findTherapistPatient, findTreatedPatientsOptions, getBadge, getDocumentReportsBodies, getPreview, getReportPreview, getReportsByPatient, inviteNewAssistant, linkDocumentToPatientRepo, reInvitePatient, rejectInstitution, removeAssistant, removeInstitution, saveReport, shareDocumentationToPatientRepo, unlinkDocumentationFromPatientRepo, updateAvatar, updateInstituteLogo, updatePatientInfo, updateSessionAvailability, updateSignature, updateTherapistInfo } from '../api/TherapistRepository';
import { AssistantInfo, ClinicalSituation, Location, TreatedPatientAge } from '../interfaces/Generic';
import { BasicInfo, TherapistInfo } from '../interfaces/User';
import { convertBase64ToFormData, convertFileToFormData, getCountries, getCountry, getJob, getJobs, getSex, getSexObject } from './GenericService';
import { DocumentShared, DocumentSharedBody } from '../interfaces/Document';
import { mapFormDataToPatient, mapPatientToPersonalInfo } from './PatientService';
import { Patient, PatientProfile } from '../interfaces/Patient';
import { Reminder, Task, TaskType } from '../interfaces/Task';
import { Answer } from '../interfaces/DocumentForm';
import { Form } from '../interfaces/FormData';
import { formatDate } from '../helpers/Fomatting';
import moment from 'moment';
import { OptionSelect } from '../interfaces/Common';
import { Report } from '../interfaces/Report';
import { SessionAvailability } from '../interfaces/SessionAvailability';
import { Therapist } from '../interfaces/Therapist';
import { TherapistCard } from '../interfaces/TherapistCard';
import { UserRowCardProps } from '../components/Cards/UserRowCard/UserRowCard';

export const mapTherapistToTherapistInfo = (therapist: Therapist): TherapistInfo => {
	return {
		assistant: therapist?.assistant,
		avatar: therapist.avatarFile?.url || '',
		institutions: therapist.institutions?.length ? therapist.institutions?.map(i => ({userId: i.id, title: i.name, avatar: i?.avatarFile?.url})): [],
		name: therapist.givenName,
		lastName: therapist.familyName,
		birthdate: therapist.birthday ? formatDate(therapist.birthday) : '',
		country: getCountry(therapist.country?.name || ''),
		city: therapist.city || '',
		postalCode: therapist.zipCode || '',
		phone: therapist.phoneNumber || '',
		gender: therapist.sex !== undefined ? getSex(therapist.sex) : '',
		email: therapist.email || '',
		job: getJob(therapist.job?.name.toUpperCase() || ''),
		enrollment: therapist.enrollment || '',
		signature: therapist.signatureFile?.url || '',
		treatedPatients: therapist.lifeStages?.length ? therapist.lifeStages?.map(item => `treatedPatientAge:${item.name.toUpperCase()}`) : [''],
		clinicalSituations: therapist.medicalConditions?.length ? therapist.medicalConditions?.map(item => `clinicalSituation:${item.name.toUpperCase()}`) : [''],
		locations: therapist.offices?.length ? therapist.offices?.map(office => ({ id: office.id, address: office.address })) : [],
		instituteLogo: therapist.logoFile?.url || '',
	};
};

const getClinicalSituationObject = async (clinicalSituationFormValue: string): Promise<ClinicalSituation[]> => {
	const clinicalSituationOptions = await findClinicalSituationsOptions();
	const clinicalSituations = clinicalSituationFormValue.split(',');
	const clinicalSituationsObject: ClinicalSituation[] = [];

	clinicalSituations.forEach(clinicalSituation => {
		const result = clinicalSituationOptions.find(item => item.name.toUpperCase() === clinicalSituation.split('clinicalSituation:')[1]);

		if (result) {
			clinicalSituationsObject.push(result);
		}
	});

	return clinicalSituationsObject;
};

const treatedPatientsAges = async (treatedPatientsFormValue: string): Promise<TreatedPatientAge[]> => {
	const treatedPatientsOptions = await findTreatedPatientsOptions();
	const treatedPatients = treatedPatientsFormValue.split(',');
	const treatedPatientsObject: TreatedPatientAge[] = [];

	treatedPatients.forEach(treatedPatient => {
		const result = treatedPatientsOptions.find(item => item.name.toUpperCase() === treatedPatient.split('treatedPatientAge:')[1]);

		if (result) {
			treatedPatientsObject.push(result);
		}
	});

	return treatedPatientsObject;
};

export const mapTherapistInfoToTherapist = async (form: Form): Promise<Therapist> => {
	const sexObject = getSexObject().find(sex => sex.name === form.gender.value.toString());
	const countryObject = await getCountries().then(countries => countries.find(country => country.name === form.country.value.toString().split('country:')[1]));
	const jobObject = await getJobs().then(jobs => jobs.find(job => job.name.toUpperCase() === form.job.value.toString().split('job:')[1]));
	const clinicalSituationObject = await getClinicalSituationObject(form.clinicalSituations.value.toString());
	const treatedPatientsObject = await treatedPatientsAges(form.treatedPatients.value.toString());

	return {
		givenName: form.name.value.toString(),
		familyName: form.lastName.value.toString(),
		birthday: form.birthdate.value.toString() !== '' ? form.birthdate.value.toString() : undefined,
		country: countryObject,
		city: form.city.value.toString(),
		zipCode: form.postalCode.value.toString(),
		phoneNumber: form.phone.value.toString(),
		email: form.email.value.toString(),
		sex: sexObject ? sexObject.id : 0,
		job: jobObject,
		enrollment: form.enrollment.value.toString(),
		medicalConditions: clinicalSituationObject,
		lifeStages: treatedPatientsObject,
		offices: form.locations.value as Location[],
	};
};

export const mapTherapistToTherapistCard = (therapist: Therapist): TherapistCard => {
	return {
		avatar: therapist.avatarFile?.url || '',
		id: therapist.id || 0,
		enabled: therapist.patient?.enabled ?? false,
		name: therapist.givenName,
		lastName: therapist.familyName,
		job: getJob(therapist.job?.name || ''),
	};
};

const getTaskTitle: Map<TaskType, string> = new Map<TaskType, string>([
	[TaskType.ConfirmSession, 'reminders:CONFIRM_SESSION'],
	[TaskType.FillMyData, 'reminders:FILL_MY_OWN_DATA'],
	[TaskType.FillPatientData, 'reminders:FILL_PATIENT_DATA'],
	[TaskType.AssignResource, 'reminders:ASSIGN_RESOURCE'],
	[TaskType.FillSessionData, 'reminders:FILL_SESSION_DATA'],
	[TaskType.RequestToJoinInstitution, 'reminders:REQUEST_TO_JOIN_INSTITUTION'],
]);

const mapReminderToTask = (reminder: Reminder): Task => {
	return {
		date: new Date(reminder.createdAt).toDateString(),
		id: reminder.id,
		isRead: reminder.isRead,
		isStarred: reminder.isStarred,
		title: getTaskTitle.get(reminder.type) || '',
		type: reminder.type,
		patientName: `${reminder?.patient?.givenName} ${reminder?.patient?.familyName}`,
		patientId: reminder.patient.id,
	};
};

const mapReminderToUserRow = (reminder: Reminder): UserRowCardProps => {
	// TODO get avatar file
	return {
		userId: reminder.institution.id,
		title: reminder.institution.name,
	};
};

const mapDocumentShared = (docInfo: DocumentShared): DocumentShared => {
	return { ...docInfo, createdAt: moment(docInfo.createdAt).format('YYYY-MM-DD'), id: docInfo.sharedDocumentId, shared: !!docInfo.sharedAt, status: docInfo?.userRole ? 'COMPLETED' : 'UNCOMPLETED' };
};

export const getTherapistPatient = async (therapistId: number | string, patientId: number): Promise<PatientProfile> => {
	return mapPatientToPersonalInfo(await findTherapistPatient(therapistId, patientId));
};

export const getPatientsByTherapist = async (therapistId: number | string): Promise<Array<Patient>> => {
	return await findAllPatientsByTherapist(therapistId);
};

export const getTherapist = async (therapistId: number | string): Promise<TherapistInfo> => {
	return mapTherapistToTherapistInfo(await findTherapist(therapistId));
};

export const inviteAssistant = async (therapistId: number | string, email: string, lastName: string, name: string): Promise<void> => {
	const data: BasicInfo = { name, lastName, email };

	return await inviteNewAssistant(therapistId, data);
};

export const addPatient = async (therapistId: number | string, data: BasicInfo): Promise<void> => {
	return await addNewPatient(therapistId, data);
};

export const updateTherapist = async (therapistId: number | string, data: Form): Promise<void> => {
	return await updateTherapistInfo(therapistId, await mapTherapistInfoToTherapist(data));
};

export const updateTherapistPatient = async (therapistId: number | string, data: Form): Promise<void> => {
	return await updatePatientInfo(therapistId, await mapFormDataToPatient(data));
};

export const updateTherapistSignature = async (therapistId: number | string, url: string): Promise<void> => {
	const data = await convertBase64ToFormData(url);

	return await updateSignature(therapistId, data);
};

export const updateTherapistAvatar = async (therapistId: number | string, files: File[]): Promise<void> => {
	const data = await convertFileToFormData(files[0]);

	return await updateAvatar(therapistId, data);
};

export const updateTherapistInstituteLogo = async (therapistId: number | string, files: File[]): Promise<void> => {
	const data = await convertFileToFormData(files[0]);

	return await updateInstituteLogo(therapistId, data);
};

export const getTherapistAvailability = async (therapistId: number | string): Promise<SessionAvailability> => {
	const availability = await findSessionAvailability(therapistId);

	return {
		availabilities: availability.availabilities.map(availability => {
			return {
				...availability,
				repeatingDays: availability.repeatingDays.map(day => day.toUpperCase()),
			};
		}),
		sessionDuration: availability.sessionDuration,
		cancellationNoticePeriod: availability.cancellationNoticePeriod,
	};
};

export const updateTherapistAvailability = async (therapistId: number | string, sessionAvailability: SessionAvailability): Promise<void> => {
	await updateSessionAvailability(therapistId, sessionAvailability);
};

export const getTreatedPatientsOptions = async (): Promise<OptionSelect[]> => {
	return await findTreatedPatientsOptions();
};

export const getClinicalSituationOptions = async (): Promise<OptionSelect[]> => {
	return await findClinicalSituationsOptions();
};

export const getLinkedPatientDocuments = async (therapistId: number | string, patiendId: number): Promise<Array<DocumentShared>> => {
	const sharedDocuments = await findLinkedPatientDocuments(therapistId, patiendId);

	return sharedDocuments.map(sharedDoc => mapDocumentShared(sharedDoc));
};

export const linkDocumentationToPatient = async (therapistId: number | string, documentationId: number, patientId: number): Promise<void> => {
	return await linkDocumentToPatientRepo(therapistId, documentationId, patientId);
};

export const unlinkDocumentationFromPatient = async (therapistId: number | string, sharedDocumentId: number): Promise<void> => {
	return await unlinkDocumentationFromPatientRepo(therapistId, sharedDocumentId);
};

export const shareDocumentationToPatient = async (therapistId: number | string, sharedDocumentId: number): Promise<void> => {
	return await shareDocumentationToPatientRepo(therapistId, sharedDocumentId);
};

export const answerDocumentByTherapist = async (therapistId: number | string, sharedDocumentId: number, answers: Answer[]): Promise<void> => {
	return await answerDocument(therapistId, sharedDocumentId, answers);
};

export const documentReportsBodies = async (therapistId: number | string, sharedDocumentIds: number[]): Promise<DocumentSharedBody[]> => {
	return await getDocumentReportsBodies(therapistId, sharedDocumentIds);
};

export const getPreviewDocument = async (therapistId: number | string, sharedDocumentId: number): Promise<Blob> => {
	return await getPreview(therapistId, sharedDocumentId);
};

export const getPatientReportsByPatient = async (therapistId: number | string, patientId: number): Promise<Report[]> => {
	return await getReportsByPatient(therapistId, patientId).catch(() => []);
};

export const getReportPreviewPdf = async (therapistId: number | string, reportId: number): Promise<Blob> => {
	return await getReportPreview(therapistId, reportId);
};

export const savePatientReport = async (therapistId: number | string, patientId: number, reportContent: string, title: string): Promise<void> => {
	return await saveReport(therapistId, patientId, reportContent, title);
};

export const removeAssistantFromTherapist = async (therapistId: number | string): Promise<void> => {
	return await removeAssistant(therapistId);
};

export const isBadge = async (therapistId: number | string): Promise<boolean> => {
	const badge = await getBadge(therapistId);

	return badge.badge;
};

export const getTasks = async (therapistId: number | string): Promise<Task[]> => {
	const reminders = await findTasks(therapistId);

	return reminders.map(reminder => mapReminderToTask(reminder));
};

export const getInstitutionsRequests = async (therapistId: number | string): Promise<UserRowCardProps[]> => {
	const reminders = await findInstitutionsRequests(therapistId);

	return reminders.map(reminder => mapReminderToUserRow(reminder));
};

export const acceptInstitutionRequest = async (therapistId: number | string, institutionId: number): Promise<void> => {
	return await acceptInstitution(therapistId, institutionId);
};

export const rejectInstitutionRequest = async (therapistId: number | string, institutionId: number): Promise<void> => {
	return await rejectInstitution(therapistId, institutionId);
};

export const getAssistantInvitationEmail = async (therapistId: number | string): Promise<AssistantInfo> => {
	return await assistantInvitationEmail(therapistId);
};

export const cancelAssistantInvitation = async (therapistId: number | string): Promise<void> => {
	return await deleteAssistantInvitation(therapistId);
};

export const deleteInstitution = async (therapistId: number | string, institutionId: number): Promise<void> => {
	return await removeInstitution(therapistId, institutionId);
};

export const reSendInvitationToPatient = async (therapistId: number | string, patientId: number): Promise<void> => {
	return await reInvitePatient(therapistId, patientId);
};
