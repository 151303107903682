import { ChatContact, ChatEvents, ChatMessage } from '../interfaces/Chat';
import { AuthContext } from './AuthContext';
import { ChatUpdateContext } from './ChatContext';
import React from 'react';
import { Socket } from 'socket.io-client';
import { useSocket } from '../hooks/useSocket';

const apiUrl = process.env.REACT_APP_API_URL;

interface SocketProviderStore {
    socket: Socket | null;
}

interface SocketUpdateProviderStore {
		handleEmitMessage: (message: string, fromId: number, toId: number) => void;
		handleReadMessages: (fromId: number, toId: number) => void;
}

export const SocketContext = React.createContext({} as SocketProviderStore);
export const SocketUpdateContext = React.createContext({} as SocketUpdateProviderStore);

export const SocketProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
	const { loadContacts, newMessage } = React.useContext(ChatUpdateContext);
	const { socket, connectSocket, disconnectSocket } = useSocket(apiUrl || '');
	const { isAuth } = React.useContext(AuthContext);

	React.useEffect(() => {
		if (isAuth) {
			connectSocket();
		}

	}, [connectSocket, isAuth]);

	React.useEffect(() => {
		if (!isAuth) {
			disconnectSocket();
		}

	}, [disconnectSocket, isAuth]);

	React.useEffect(() => {
		socket?.on(ChatEvents.contactList, (contacts: ChatContact[]) => {
			loadContacts(contacts);
		});

	}, [loadContacts, socket]);

	React.useEffect(() => {
		socket?.on(ChatEvents.personalMessage, (message: ChatMessage) => {
			newMessage(message);
		});

	}, [newMessage, socket]);

	const handleEmitMessage = (message: string, fromId: number, toId: number): void => {
		socket?.emit(ChatEvents.personalMessage, {
			from: fromId,
			to: toId,
			message: message,
		});
	};

	const handleReadMessages = (fromId: number, toId: number): void => {
		socket?.emit(ChatEvents.readMessages, {
			from: fromId,
			to: toId,
		});
	};

	return (
		<SocketContext.Provider value={{ socket }}>
			<SocketUpdateContext.Provider value={{ handleEmitMessage, handleReadMessages }}>
				{ children }
			</SocketUpdateContext.Provider>
		</SocketContext.Provider>
	);
};
