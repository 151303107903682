import { Box, Grid, Typography } from '@material-ui/core';
import { LoadingSpinner, StepTitle } from '../../../../../../../components';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlined';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import React from 'react';
import styles from './Information.module.scss';
import useInformation from './useInformation';
import { useTranslation } from 'react-i18next';

const Information = (): JSX.Element => {
	const { t } = useTranslation();
	const { report, patient, therapist, isLoading, selectAllPatientInfo, selectAllTherapistInfo, handlePatientSelect, handleTherapistSelect, patientFields, therapistFields } =
		useInformation();

	const checkbox = (label: string, name: string, value: string, checked: boolean, group: string ): JSX.Element => (
		<div key={name} className={styles.row}>
			<div className={styles.content}>
				<div className={`${styles.label} ${name.includes('selectAll') && styles.selectAll}`}>
					<Checkbox
						classes={{ root: styles.checkbox, checked: styles.checkboxChecked }}
						checked={checked}
						onChange={group === 'patient' ? handlePatientSelect : handleTherapistSelect}
						name={name}
						color="primary"
						checkedIcon={<CheckBoxIcon />}
						size="small"
					/>
					<Typography>{label}</Typography>
				</div>
				<Typography>{value}</Typography>
			</div>
			<Divider />
		</div>
	);

	return (
		<div className={styles.information}>
			{isLoading
				? <LoadingSpinner />
				: <>
					<Box>
						<StepTitle title="patientsView:REPORTS_INFO_PATIENT_CARD" subtitle="patientsView:REPORTS_INFO_CARD_SUBTITLE" />
						<Card className={styles.card}>
							<FormControl component="fieldset" fullWidth>
								<FormGroup>
									<Grid container>
										<Grid container item xs={12} spacing={8}>
											<Grid item xs={12} lg={8}>
												{checkbox(t('patientsView:REPORTS_SELECT_ALL'), 'selectAllPatientInfo', '', selectAllPatientInfo, 'patient')}
											</Grid>
										</Grid>
										<Grid container item xs={12} spacing={8}>
											<Grid item xs={12} md={6} lg={4}>
												{patientFields.filter(item => item.col === 0).map((item) => (
													checkbox(
														t(`profileView:${item.label}`),
														item.field,
														patient[item.field as string] as string,
														// eslint-disable-next-line no-prototype-builtins
														report.patient.hasOwnProperty(item.field),
														'patient'
													)
												))}
											</Grid>
											<Grid item xs={12} md={6} lg={4}>
												{patientFields.filter(item => item.col === 1).map((item) => (
													checkbox(
														t(`profileView:${item.label}`),
														item.field,
														patient[item.field as string] as string,
														// eslint-disable-next-line no-prototype-builtins
														report.patient.hasOwnProperty(item.field),
														'patient'
													)
												))}
											</Grid>
										</Grid>
									</Grid>
								</FormGroup>
							</FormControl>
						</Card>
					</Box>
					<Box mt={5}>
						<StepTitle title="patientsView:REPORTS_INFO_PROFESSIONAL_CARD" subtitle="patientsView:REPORTS_INFO_CARD_SUBTITLE" />
						<Card className={styles.card}>
							<FormControl component="fieldset" fullWidth>
								<FormGroup>
									<Grid container>
										<Grid container item xs={12} spacing={8}>
											<Grid item xs={12} lg={8}>
												{checkbox(t('patientsView:REPORTS_SELECT_ALL'), 'selectAllTherapistInfo', '', selectAllTherapistInfo, 'therapist')}
											</Grid>
										</Grid>
										<Grid container item xs={12} spacing={8}>
											<Grid item xs={12} md={6} lg={4}>
												{therapistFields.filter(item => item.col === 0).map((item) => (
													checkbox(
														t(`profileView:${item.label}`),
														item.field,
														therapist[item.field as string] as string,
														// eslint-disable-next-line no-prototype-builtins
														report.therapist.hasOwnProperty(item.field),
														'therapist'
													)
												))}
											</Grid>
											<Grid item xs={12} md={6} lg={4}>
												{checkbox(
													t(`profileView:${therapistFields[4].label}`),
													therapistFields[4].field,
													therapist[therapistFields[4].field as string] as string,
													// eslint-disable-next-line no-prototype-builtins
													report.therapist.hasOwnProperty(therapistFields[4].field),
													'therapist'
												)}
											</Grid>
										</Grid>
									</Grid>
								</FormGroup>
							</FormControl>
						</Card>
					</Box>
				</>
			}
		</div>
	);
};

export default Information;
