import axios, { AxiosResponse } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const contactMail = (name: string, mail: string, phone: string, message: string): Promise<AxiosResponse> => {
	if (apiUrl) {
		const subject = 'Contacto - Web Pública';

		return axios.post(`${apiUrl}/contact`, { name, mail, phone, message, subject });
	}

	return Promise.reject('API url not defined');
};
