import { TextField as MaterialTextField } from '@material-ui/core';
import React from 'react';
import style from './TextField.module.scss';

interface TextFieldProps {
	placeholder?: string;
	value: string;
	onChange: (value: string) => void;
	multiline?: boolean;
	error?: boolean;
	errorMessage?: string;
	required?: boolean;
	type?: 'email' | 'password';
}

const TextField: React.FC<TextFieldProps> = ({ placeholder, value, onChange, multiline, error, errorMessage, required, type }) => {

	const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
		onChange(event.target.value);
	};

	return (
		<MaterialTextField
			variant="outlined"
			placeholder={placeholder}
			multiline={multiline}
			className={`${style.regularTextField} ${multiline ? style.multilineTextField : null}`}
			onChange={onChangeValue}
			value={value}
			error={error}
			helperText={errorMessage}
			required={required}
			type={type}
		/>
	);
};

export default TextField;
