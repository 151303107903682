import { PrivateTopBar, ProfileCard, ProfilePictureCard } from '../../../components';
import Grid from '@material-ui/core/Grid';
import InstitutionPersonalInfoModal from '../../../components/Modals/PersonalInfoModal/InstitutionPersonalInfoModal';
import { keyValue } from '../../../interfaces/Common';
import React from 'react';
import styles from './Profile.module.scss';
import useProfile from './useProfile';
import { useTranslation } from 'react-i18next';

const Profile = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		isLoading,
		institutionInfo,
		openPersonalInfoModal,
		handleOpenPersonalInfoModal,
		handleUpdateAvatar,
		loadInstitution,
		updateInstitutionInfo,
	} = useProfile();

	const personalInfoFields = ['name'];

	return (
		<div className={styles.profile}>
			<PrivateTopBar
				title={t('profileView:TITLE_PROFILE')}
			/>
			<div className={styles.container}>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={4}>
						<ProfilePictureCard fullName={`${institutionInfo.name}`} image={institutionInfo?.avatarFile?.url} onSave={handleUpdateAvatar} isLoading={isLoading} isAllowedToEdit/>
					</Grid>
				</Grid>
				<Grid container spacing={1} style={{ marginTop: 10 }}>
					<Grid item xs={12} sm={4}>
						<ProfileCard title={t('profileView:PERSONAL_INFO')} showEditBtn onClickBtn={handleOpenPersonalInfoModal} data={institutionInfo as unknown as keyValue} fieldsToDisplay={personalInfoFields}/>
					</Grid>
				</Grid>
			</div>
			{
				openPersonalInfoModal &&
				<InstitutionPersonalInfoModal data={institutionInfo} open={openPersonalInfoModal} onChange={handleOpenPersonalInfoModal} getUser={loadInstitution} updateUser={updateInstitutionInfo} />
			}
		</div >
	);
};

export default Profile;
