import { Day, Hour } from './../interfaces/Session';
import {
	findCancelDaysByTherapist,
	findDaysByTherapist,
	findDurationsByTherapist,
	findHoursByTherapist
} from './../api/AvailabilityRepository';

export const getHoursByTherapist = async (): Promise<Hour[]> => {
	return await findHoursByTherapist();
};

export const getDaysByTherapist = async (): Promise<Day[]> => {
	return await findDaysByTherapist();
};

export const getDurationsByTherapist = async (): Promise<Hour[]> => {
	return await findDurationsByTherapist();
};

export const getCancelDaysByTherapist = async (): Promise<Day[]> => {
	return await findCancelDaysByTherapist();
};
