import { useTranslation } from 'react-i18next';

export type RoutesType = { [key: string]: string };
// type UserRolesType = { [key: string]: Array<string> };

interface UseRoutesStore {
	assistantRoutes: RoutesType;
	institutionRoutes: RoutesType;
	patientRoutes: RoutesType;
	routes: RoutesType;
	therapistRoutes: RoutesType;
}

export enum UserTypes {
	admin = 0,
	patient = 1,
	therapist = 2,
}

export const useRoutes: () => UseRoutesStore = () => {
	const { t } = useTranslation();

	const ASSISTANT_ROUTES: RoutesType = {
		ASSISTANT: t('assistantRoutes:ASSISTANT'),
		AGENDA: t('assistantRoutes:AGENDA'),
		DOCUMENTATION: t('assistantRoutes:DOCUMENTATION'),
		HOME: t('assistantRoutes:HOME'),
		PATIENTS: t('assistantRoutes:PATIENTS'),
		PROFILE: t('assistantRoutes:PROFILE'),
		RESOURCE_LIBRARY: t('assistantRoutes:RESOURCE_LIBRARY'),
		TASKS: t('assistantRoutes:TASKS'),
		REPORTS: t('assistantRoutes:REPORTS'),
		NEW: t('assistantRoutes:NEW'),
	};

	const INSTITUTION_ROUTES: RoutesType = {
		INSTITUTION: t('institutionRoutes:INSTITUTION'),
		HOME: t('institutionRoutes:HOME'),
		PROFILE: t('institutionRoutes:PROFILE'),
	};

	const PATIENT_ROUTES: RoutesType = {
		CHAT: t('patientRoute:CHAT'),
		HOME: t('patientRoute:HOME'),
		INVITATIONS: t('patientRoute:INVITATIONS'),
		LIBRARY: t('patientRoute:LIBRARY'),
		MESSAGES: t('patientRoute:MESSAGES'),
		PATIENT: t('patientRoute:PATIENT'),
		PROFILE: t('patientRoute:PROFILE'),
	};

	const ROUTES: RoutesType = {
		ABOUT: t('route:ABOUT'),
		BLOG: t('route:BLOG'),
		FAQ: t('route:FAQ'),
		FORGOT_PASSWORD: t('route:FORGOT_PASSWORD'),
		FUNCTIONALITIES: t('route:FUNCTIONALITIES'),
		HOME: t('route:HOME'),
		SIGNIN: t('route:SIGNIN'),
		SIGNUP: t('route:SIGNUP'),
		SIGNOUT: t('route:SIGNOUT'),
		PLANS: t('route:PLANS'),
		POST: t('route:POST'),
		CONTACT: t('route:CONTACT'),
	};

	const THERAPIST_ROUTES: RoutesType = {
		AGENDA: t('therapistRoute:AGENDA'),
		CHAT: t('therapistRoute:CHAT'),
		DOCUMENTATION: t('therapistRoute:DOCUMENTATION'),
		NEW: t('therapistRoute:NEW'),
		PATIENTS: t('therapistRoute:PATIENTS'),
		PROFILE: t('therapistRoute:PROFILE'),
		REPORTS: t('therapistRoute:REPORTS'),
		RESOURCE_LIBRARY: t('therapistRoute:RESOURCE_LIBRARY'),
		THERAPIST: t('therapistRoute:THERAPIST'),
		TASKS: t('therapistRoute:TASKS'),
	};

	return {
		assistantRoutes: ASSISTANT_ROUTES,
		institutionRoutes: INSTITUTION_ROUTES,
		patientRoutes: PATIENT_ROUTES,
		routes: ROUTES,
		therapistRoutes: THERAPIST_ROUTES,
	};
};
