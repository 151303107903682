import InputText from '../../Inputs/InputText/InputText';
import Modal from '../../Modal/Modal';
import React from 'react';
import ReactLoading from 'react-loading';
import styles from './NewPlaceModal.module.scss';
import useNewPlaceModal from './useNewPlaceModal';
import { useTranslation } from 'react-i18next';

interface NewPlaceModalProps {
	open: boolean;
	fetchData: () => Promise<void>;
	onChange: (refreshData: boolean) => void;
}

const NewPlaceModal: React.FC<NewPlaceModalProps> = ({ open, fetchData, onChange }) => {
	const { t } = useTranslation();
	const {
		error,
		place,
		handleChange,
		formIsValid,
		handleClose,
		handleConfirm,
		isLoading,
	} = useNewPlaceModal({ fetchData, onChange });

	return (
		<Modal
			open={open}
			title={t('agendaView:MODAL_NEW_PLACE')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientsView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: !formIsValid,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientsView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
			showDividers={false}
		>
			{
				!isLoading ?
					<div className={styles.content} >
						<InputText
							value={place}
							name="place" label={t('agendaView:PLACE_TAB')}
							fullWidth
							onChange={handleChange}
							helperText={error.text} />
					</div>
					:
					<ReactLoading type="spin" color="#4ebed7" height={'20%'} width={'20%'} />
			}
		</Modal>
	);
};

export default NewPlaceModal;
