import { Frequency, Session } from '../../../interfaces/Session';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSessionModal from '../DeleteSessionModal/DeleteSessionModal';
import Modal from '../../Modal/Modal';
import moment from 'moment';
import React from 'react';
import styles from './SessionPreviewModal.module.scss';
import useSessionPreviewModal from './useSessionPreviewModal';
import { useTranslation } from 'react-i18next';

interface SessionPreviewModalProps {
	open: boolean;
	session: Session;
	onEditSession: () => void;
	onClose: () => void;
	onRefreshData: () => void;
}

const SessionPreviewModal: React.FC<SessionPreviewModalProps> = ({ open, session, onEditSession, onClose, onRefreshData }) => {
	const [isOpenDeleteSessionModal, setIsOpenDeleteSessionModal] = React.useState(false);
	const {
		handleCreateNote,
		handleConfirmSession,
	} = useSessionPreviewModal({ onClose, onRefreshData });
	const { t } = useTranslation();
	const isConfirmed = session.status === 1;

	return (
		<Modal
			open={open}
			handleOnClose={onClose}
			isPrimaryButtonVisible={false}
			isSecondaryButtonVisible={false}
			showDividers={false}
		>
			<div className={styles.content} >
				<div>
					<span className={styles.patientName}>{`${session.patient.givenName} ${session.patient.familyName}`}</span>
					{
						isConfirmed &&
								<div>
									<Button
										className={styles.iconButton}
										startIcon={<DeleteIcon />}
										onClick={(): void => setIsOpenDeleteSessionModal(true)}
										style={{ float: 'right' }}
									>
										{t('agendaView:DELETE_SESSION_BUTTON_PREVIEW_MODAL')}
									</Button>
									<Button
										className={styles.iconButton}
										startIcon={<CreateIcon />}
										onClick={onEditSession}
										style={{ float: 'right' }}
									>
										{t('agendaView:EDIT_SESSION_BUTTON_PREVIEW_MODAL')}
									</Button>
								</div>
					}
				</div>
				<span style={{ marginTop: 10 }}>
					{`${moment(session.startDate).format('dddd D MMMM')} • ${session.startTime} - ${session.endTime}`}
				</span>
				<hr className={styles.divider} />
				{ !session.telehealth
					? <div className={styles.labelsContainer}>
						<span className={styles.leftLabel}>{t('agendaView:PLACE_TAB')}</span>
						<span style={{ paddingLeft: 22 }}>{session.therapistOffice.address}</span>
					</div>
					: <div className={styles.labelsContainer}>
						<span className={styles.leftLabel}>{t('agendaView:TELEHEALTH')}</span>
					</div>
				}
				<div className={styles.labelsContainer}>
					<span className={styles.leftLabel}>{t('agendaView:SESSION_TYPE_TAB')}</span>
					<span style={{ paddingLeft: 22 }}>{session.therapistSessionType?.name}</span>
				</div>
				<div className={styles.labelsContainer}>
					<span className={styles.leftLabel}>{t('agendaView:FREQUENCY_TAB')}</span>
					<span style={{ paddingLeft: 44 }}>{t(`agendaView:${Object.keys(Frequency)[session.frequency ?? 0]}`)}</span>
				</div>
				<hr className={styles.divider} />
				{
					isConfirmed ?
						<div>
							<Button
								startIcon={<CreateIcon />}
								onClick={(): void => handleCreateNote(session.patient.id)}
								className={styles.iconButton}
							>
								{t('patientsView:INFO_CREATE_NOTE_BUTTON')}
							</Button>
						</div>
						: <span>
							<Button
								onClick={(): Promise<void> => handleConfirmSession(session.id)}
								className={styles.iconButton}
							>
								{t('patientHomeView:BUTTON_ACCEPT')}
							</Button>
							<Button
								onClick={(): void => setIsOpenDeleteSessionModal(true)}
								className={styles.iconButton}
							>
								{t('agendaView:REJECT_BUTTON_PREVIEW_MODAL')}
							</Button>
						</span>
				}
				<DeleteSessionModal
					open={isOpenDeleteSessionModal}
					onChange={(): void => setIsOpenDeleteSessionModal(false)}
					onDelete={(): void => {
						onRefreshData();
						onClose();
					}}
					sessionId={session.id}
				/>
			</div>
		</Modal>
	);
};

export default SessionPreviewModal;
