export default {
	es: {
		assistantRoutes: {
			AGENDA: 'agenda',
			ASSISTANT: 'asistente',
			DOCUMENTATION: 'documentacion',
			HOME: 'home',
			NEW: 'nuevo',
			PATIENTS: 'pacientes',
			PROFILE: 'mi-perfil',
			REPORTS: 'reportes',
			RESOURCE_LIBRARY: 'biblioteca-de-recursos',
			TASKS: 'tareas',
		},
		institutionRoutes: {
			HOME: 'home',
			INSTITUTION: 'institucion',
		},
		route: {
			ABOUT: 'acerca',
			BLOG: 'blog',
			FAQ: 'preguntas-frecuentes',
			FUNCTIONALITIES: 'funcionalidades',
			HOME: 'inicio',
			SIGNIN: 'signin',
			SIGNUP: 'signup',
			SIGNOUT: 'signout',
			PLANS: 'planes',
			FREE_TRIAL: 'probar-gratis',
			ACCESS: 'ingresar',
			POST: 'posts',
			CONTACT: 'contactanos',
		},
		patientRoute: {
			CHAT: 'chat',
			HOME: 'inicio',
			INVITATIONS: 'invitaciones',
			LIBRARY: 'biblioteca',
			MESSAGES: 'mensajes',
			PATIENT: 'paciente',
			PROFILE: 'mi-perfil',
		},
		therapistRoute: {
			AGENDA: 'agenda',
			CHAT: 'chat',
			DOCUMENTATION: 'documentacion',
			NEW: 'nuevo',
			PATIENTS: 'pacientes',
			REPORTS: 'reportes',
			RESOURCE_LIBRARY: 'biblioteca-de-recursos',
			THERAPIST: 'terapeuta',
			TASKS: 'tareas',
		},
	},
	en: {
		assistantRoutes: {
			AGENDA: 'agenda',
			ASSISTANT: 'assistant',
			DOCUMENTATION: 'documentation',
			HOME: 'home',
			NEW: 'new',
			PATIENTS: 'patients',
			PROFILE: 'my-profile',
			REPORTS: 'reports',
			RESOURCE_LIBRARY: 'resource-library',
			TASKS: 'tareas',
		},
		institutionRoutes: {
			HOME: 'home',
			INSTITUTION: 'institution',
		},
		route: {
			ABOUT: 'about',
			BLOG: 'blog',
			FAQ: 'faq',
			FUNCTIONALITIES: 'functionalities',
			HOME: 'home',
			SIGNIN: 'signin',
			SIGNUP: 'signup',
			SIGNOUT: 'signout',
			PLANS: 'plans',
			FREE_TRIAL: 'free-trial',
			ACCESS: 'sign-in',
			POST: 'posts',
			CONTACT: 'contact-us',
		},
		patientRoute: {
			CHAT: 'chat',
			HOME: 'home',
			INVITATIONS: 'invitations',
			LIBRARY: 'library',
			MESSAGES: 'messages',
			PATIENT: 'patient',
			PROFILE: 'my-profile',
		},
		therapistRoute: {
			AGENDA: 'agenda',
			CHAT: 'chat',
			DOCUMENTATION: 'documentation',
			NEW: 'new',
			PATIENTS: 'patients',
			PROFILE: 'my-profile',
			REPORTS: 'reports',
			RESOURCE_LIBRARY: 'resource-library',
			TASKS: 'tasks',
			THERAPIST: 'therapist',
		},
	},
};
