import React from 'react';
import { TherapistCard } from '../../../interfaces/TherapistCard';

interface TherapistCardsProps {
	onClick?: (therapist: TherapistCard) => void;
}

interface TherapistCardsStore {
	selected: TherapistCard;
	handleClick: (therapist: TherapistCard) => void;
}

const useTherapistCards = (props: TherapistCardsProps): TherapistCardsStore => {
	const [selected, setSelected] = React.useState<TherapistCard>({} as TherapistCard);

	const handleClick = (therapist: TherapistCard): void => {
		setSelected(therapist);

		if (props.onClick) {
			props.onClick(therapist);
		}
	};

	return {
		selected,
		handleClick,
	};
};

export default useTherapistCards;
