/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
// import { Button } from '../..';
import closeMenuLogo from '../../../assets/images/menu-close-mob.svg';
import logo from '../../../assets/images/pragma-logo.svg';
import menuLogo from '../../../assets/images/menu-icon-mobile.svg';
import { NavLink } from 'react-router-dom';
import React from 'react';
import styles from './TopBar.module.scss';
import { useRoutes } from '../../../helpers/useRoutes';
import { useTranslation } from 'react-i18next';

interface TopBarProps {
	transparent?: boolean;
}

const TopBar: React.FC<TopBarProps> = ({ transparent }) => {
	const { t } = useTranslation();
	const { routes } = useRoutes();
	const [ isMenuVisible, setIsMenuVisible ] = React.useState(false);

	const options: [string, string][] = [
		[routes.HOME, t('options:HOME')],
		// [routes.PLANS, t('options:PLANS')],
		[routes.FUNCTIONALITIES, t('options:FUNCTIONALITIES')],
		[routes.ABOUT, t('options:ABOUT')],
		[routes.FAQ, t('options:FAQ')],
		[routes.BLOG, t('options:BLOG')],
	];
	const optionsMobile: [string, string][] = [
		[routes.HOME, t('options:HOME')],
		[routes.BLOG, t('options:BLOG')],
	];

	return (
		<>
			<div className={`${styles.topbar} ${transparent && styles.topbarTransparent}`}>
				<div className={styles.container}>
					<div className={styles.logoContainer}>
						<a href="/">
							<img src={logo} className={styles.logo} />
						</a>
					</div>
					<div className={styles.topbarLinks}>
						{
							options.map(option => <NavLink key={`/${option[0]}`} to={`/${option[0]}`} className={styles.link} activeClassName={styles.selectedLink}>{option[1].toUpperCase()}</NavLink>)
						}
						<a href={`#${routes.CONTACT}`} className={styles.link}>{t('options:CONTACT').toUpperCase()}</a>
					</div>
				</div>
				{/* <div className={styles.buttonContainer}>
					<div className={styles.button}>
						<Button classes={styles.buttonTransparent} title={t('options:FREE_TRIAL')} onClick={console.log} type={'TERTIARY'} />
					</div>
					<div className={styles.button}>
						<Button title={t('options:ACCESS')} onClick={console.log} type={'TERTIARY'} />
					</div>
				</div> */}
				<div className={styles.menuLogoContainer} onClick={(): void => setIsMenuVisible(true)}>
					<img src={menuLogo} className={styles.menuLogo} />
				</div>
			</div>
			<div className={`${styles.menu} ${isMenuVisible && styles.menuDisplay}`}>
				<div className={styles.closeMenuLogoContainer} onClick={(): void => setIsMenuVisible(false)}>
					<img src={closeMenuLogo} className={styles.closeMenuLogo} />
				</div>
				<div className={styles.topbarLinks}>
					{
						optionsMobile.map(option => <NavLink key={`/${option[0]}`} to={`/${option[0]}`} className={styles.link} onClick={(): void => setIsMenuVisible(false)} activeClassName={styles.selectedLink}>{option[1].toUpperCase()}</NavLink>)
					}
					<a href={`#${routes.CONTACT}`} className={styles.link} onClick={(): void => setIsMenuVisible(false)}>{t('options:CONTACT').toUpperCase()}</a>
				</div>
				{/* <div className={styles.buttonContainer}>
					<div className={styles.button}>
						<Button classes={`${styles.fixWidth} ${styles.buttonTransparent}`} title={t('options:FREE_TRIAL')} onClick={(): void => setIsMenuVisible(false)} type={'TERTIARY'} size="large" />
					</div>
					<div className={styles.button}>
						<Button classes={styles.fixWidth} title={t('options:ACCESS')} onClick={(): void => setIsMenuVisible(false)} type={'TERTIARY'} size="large" />
					</div>
				</div> */}
			</div>
		</>
	);
};

export default TopBar;
