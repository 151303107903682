import { AlertProvider } from './contexts/AlertContext';
import { AuthProvider } from './contexts/AuthContext';
import { ChatProvider } from './contexts/ChatContext';
import DateFnsUtils from '@date-io/date-fns';
import { Layout } from './pages';
import { LoadingProvider } from './contexts/LoadingContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import muiTheme from './themes/muiTheme';
import React from 'react';
import ReportProvider from './contexts/ReportContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { SocketProvider } from './contexts/SocketContext';
import { ThemeProvider } from '@material-ui/core/styles';
import { TherapistModalProvider } from './contexts/TherapistModalContext';
import { TherapistProvider } from './contexts/TherapistContext';

const App = (): JSX.Element => {
	return (
		<Router basename="/">
			<ThemeProvider theme={muiTheme}>
				<AlertProvider>
					<AuthProvider>
						<ChatProvider>
							<SocketProvider>
								<LoadingProvider>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<TherapistProvider>
											<TherapistModalProvider>
												<ReportProvider>
													<Layout />
												</ReportProvider>
											</TherapistModalProvider>
										</TherapistProvider>
									</MuiPickersUtilsProvider>
								</LoadingProvider>
							</SocketProvider>
						</ChatProvider>
					</AuthProvider>
				</AlertProvider>
			</ThemeProvider>
		</Router>
	);
};

export default App;
