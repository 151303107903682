import { formatDate } from '../../../helpers/Fomatting';
import Modal from '../../Modal/Modal';
import { Note } from '../../../interfaces/Note';
import React from 'react';
import styles from './NoteModal.module.scss';
import TextField from '@material-ui/core/TextField';
import useNoteModal from './useNoteModal';
import { useTranslation } from 'react-i18next';

interface NoteModalProps {
	open: boolean;
	note?: Note;
	onChange: () => void;
	patientId?: number;
	noteId?: number;
	loadData: () => void;
}

const NoteModal: React.FC<NoteModalProps> = ({ open, note, onChange, patientId, noteId, loadData }) => {
	const { t } = useTranslation();
	const { date,
		noteContent,
		handleChangeNote,
		formIsValid,
		handleClose,
		handleConfirm,
	} = useNoteModal({ note, onChange, patientId, noteId, loadData });

	return (
		<Modal
			open={open}
			title={t('patientsView:MODAL_CREATE_NOTE')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientsView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: !formIsValid,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientsView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
			showDividers={false}
		>
			<div className={styles.content} >
				<div className={styles.date}>{formatDate(date)}</div>
				<TextField
					multiline
					rowsMax={13}
					rows={13}
					value={noteContent}
					onChange={handleChangeNote}
					variant="outlined"
					fullWidth
				/>
			</div>
		</Modal>
	);
};

export default NoteModal;
