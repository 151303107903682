import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Option } from '../../../../interfaces/DocumentForm';
import { QuestionPreviewProps } from '../PreviewWrapper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import useBooleanPreview from './useBooleanPreview';

const BooleanPreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		showErrors,
		errorMessage,
		handleOnChange,
	} = useBooleanPreview(props);
	const { value, options, isValid } = question;

	return (
		<>
			<RadioGroup row value={value} onChange={handleOnChange}>
				{options.map((option: Option, index) => (
					<FormControlLabel
						key={index}
						value={option.id}
						control={<Radio color="secondary" />}
						label={option.title}
						name={option.id}
					/>
				))}
			</RadioGroup>
			{showErrors && <FormHelperText error={showErrors && !isValid}>{errorMessage}</FormHelperText>}
		</>
	);
};

export default BooleanPreview;
