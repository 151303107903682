import { DateCard as DateCardInt, SiteDateCard, StatusDateCard } from '../../../interfaces/DateCard';
import Avatar from '@material-ui/core/Avatar';
import CallIcon from '@material-ui/icons/Call';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import React from 'react';
import styles from './DateCards.module.scss';
import useDateCards from './useDateCards';
import { useDates } from '../../../helpers/useDates';
import { useTranslation } from 'react-i18next';

interface DateCardProps {
	data: Array<DateCardInt>;
	noDataMessage?: string;
	showMonthYearSelector?: boolean;
	value?: string;
	onChange?: (value: string) => void;
	onDecline?: (sessionId: number) => void;
	onAccept?: (sessionId: number) => void;
	fromPatient?: boolean;
	fetchData?: (date: Date) => void;
}

const DateCard: React.FC<DateCardProps> = ({ data, noDataMessage, value, onChange, showMonthYearSelector = false, onDecline, onAccept, fromPatient, fetchData }) => {
	const { t } = useTranslation();
	const { days, months } = useDates();
	const {
		selectedMonth,
		selectedYear,
		handleClickLeftArrow,
		handleClickRightArrow,
		handleChange,
	} = useDateCards({ onChange, onDecline, onAccept, fetchData });

	return (
		<>
			{
				showMonthYearSelector &&
				<div className={styles.monthYearSelector}>
					<IconButton className={styles.button} onClick={handleClickLeftArrow}>
						<KeyboardArrowLeftIcon />
					</IconButton>
					<span>{t(months[selectedMonth])} {selectedYear}</span>
					<IconButton className={styles.button} onClick={handleClickRightArrow}>
						<KeyboardArrowRightIcon />
					</IconButton>
				</div>
			}
			{
				data.length
					? data.map(item => {
						const isNew = item.status === StatusDateCard.New;
						const isTelehealth = item.site === SiteDateCard.Telehealth;
						const cardContentStyle = isNew && showMonthYearSelector
							? styles.cardContentBackground
							: (value === item.id.toString() && !showMonthYearSelector && styles.cardContentBackgroundBorderRadius);

						return (
							<Card key={item.id} classes={{root: styles.dateCard}}>
								<CardActionArea onClick={(): void => handleChange(item.id.toString())}>
									<CardContent className={`${styles.cardContent} ${cardContentStyle}`}>
										<div className={styles.date}>{t(days[item.startTime.getDay()])}<span>{item.startTime?.getDate()}</span></div>
										<div className={styles.time}>
											{`${moment(item.startTime).format('HH:mm')} - ${moment(item.endTime).format('HH:mm')}` }
											{
												<div style={{display: 'flex', flexDirection: 'column'}}>
													<span style={{fontSize: 14}}>
														{ isTelehealth
															? t('patientHomeView:SESSION_SITE_TELEHEALTH')
															: `${t('patientHomeView:SESSION_SITE_CONSULTING_ROOM')}: ${item.therapistOffice?.address}`
														}
													</span>
													{ isNew &&
																<span>
																	{t('agendaView:PENDING_SESSION')}
																</span>
													}
												</div>
											}
										</div>
										{
											!fromPatient && isTelehealth &&
											<Avatar className={styles.avatar}>
												<CallIcon className={styles.icon} />
											</Avatar>
										}
									</CardContent>
								</CardActionArea>
							</Card>
						);
					})
					: <div className={styles.dateCardNoData}>{noDataMessage ?? t('messages:NO_DATA')}</div>
			}
		</>
	);
};

export default DateCard;
