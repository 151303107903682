import { Day, Hour } from '../../../interfaces/Session';
import Grid from '@material-ui/core/Grid';
import { MenuItem } from '@material-ui/core';
import React from 'react';
import styles from './TimeSelector.module.scss';
import TextField from '@material-ui/core/TextField';

export interface TimeSelectorProps {
	title: string;
	startTime: string;
	startName: string;
	endName: string;
	endTime: string;
	hours: Hour[];
	days: Day[];
	daysLabel: string;
	startLabel: string;
	endLabel: string;
	handleInputChange: (event: React.ChangeEvent<{ value: unknown; name: string }>) => void;
}

export const TimeSelector: React.FC<TimeSelectorProps> = ({
	title,
	startTime,
	startName,
	endTime,
	endName,
	hours,
	startLabel,
	endLabel,
	handleInputChange }): JSX.Element => {

	const TimeComponent = (label: string, timeValue: string, timeName: string): JSX.Element => {
		return (
			<Grid container item spacing={1} className={styles.timeSelector}>
				<Grid item xs={12} sm={6} md={3}>
					<span className={styles.text}>{label}</span>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<TextField
						select
						variant="outlined"
						onChange={handleInputChange}
						value={timeValue}
						size="small"
						className={styles.inputSelect}
						name={timeName}
					>
						{
							hours.map((value) => (
								<MenuItem key={value.key} value={value.value} className={styles.inputSelect}>
									{value.value}
								</MenuItem>
							))
						}
					</TextField>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<span className={styles.title}>{title}</span>
			<div className={styles.medContainer}>
				{TimeComponent(startLabel, startTime, startName)}
				{TimeComponent(endLabel, endTime, endName)}
			</div>
		</>
	);
};
