import { getPatientDocumentsByTherapist, getResourcesByTherapist } from '../../../services/PatientService';
import { DateCard } from '../../../interfaces/DateCard';
import { DocumentCard } from '../../../interfaces/Document';
import { getSessionEventsByPatient } from '../../../services/SessionService';
import React from 'react';
import { ResourceCard } from '../../../interfaces/Resource';
import { TherapistContext } from '../../../contexts/TherapistContext';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../helpers/useRoutes';

interface HomeStore {
	documents: DocumentCard[];
	open: boolean;
	openUpdateTherapist: boolean;
	resources: ResourceCard[];
	sessions: DateCard[];
	therapistSelectedId: number;
	fetchData: (date: Date) => Promise<void>;
	handleAcceptSession: (sessionId: number) => void;
	handleClickDocument: (resourceId: number) => void;
	handleClickResource: (resourceId: number) => void;
	handleDeclineSession: (sessionId: number) => void;
	handleOpenNewSession: () => void;
	handleOpenUpdateTherapist: () => void;
}

const useHome = (): HomeStore => {
	const history = useHistory();
	const { patientRoutes } = useRoutes();
	const [sessions, setSessions] = React.useState<Array<DateCard>>([]);
	const [resources, setResources] = React.useState<Array<ResourceCard>>([]);
	const [documents, setDocuments] = React.useState<Array<DocumentCard>>([]);
	const [open, setOpen] = React.useState<boolean>(false);
	const [openUpdateTherapist, setOpenUpdateTherapist] = React.useState<boolean>(false);
	const { therapistSelected } = React.useContext(TherapistContext);

	const fetchData = React.useCallback(async (date: Date): Promise<void> => {
		if (therapistSelected.id) {
			const sessions = await getSessionEventsByPatient(therapistSelected.id, date);
			const resources = await getResourcesByTherapist(therapistSelected.id);
			const documents = await getPatientDocumentsByTherapist(therapistSelected.id);

			setSessions(sessions);
			setResources(resources);
			setDocuments(documents);
		}
	}, [therapistSelected.id]);

	React.useEffect(() => {
		fetchData(new Date());
	}, [fetchData]);

	const handleOpenNewSession = (): void => {
		setOpen(prevState => !prevState);
	};

	const handleDeclineSession = (sessionId: number): void => {
		// eslint-disable-next-line no-console
		console.log(`decline ${sessionId}`);
	};

	const handleAcceptSession = (sessionId: number): void => {
		// eslint-disable-next-line no-console
		console.log(`accept ${sessionId}`);
	};

	const handleClickResource = (id: number): void => {
		history.push(`/${patientRoutes.PATIENT}/${patientRoutes.LIBRARY}`, { id, cardType: 'resources' });
	};

	const handleClickDocument = (id: number): void => {
		history.push(`/${patientRoutes.PATIENT}/${patientRoutes.LIBRARY}`, { id, cardType: 'documents' });
	};

	const handleOpenUpdateTherapist = (): void => {
		setOpenUpdateTherapist(prevState => !prevState);
	};

	return {
		documents,
		open,
		openUpdateTherapist,
		resources,
		sessions,
		therapistSelectedId: therapistSelected.id,
		fetchData,
		handleAcceptSession,
		handleClickDocument,
		handleClickResource,
		handleDeclineSession,
		handleOpenNewSession,
		handleOpenUpdateTherapist,
	};
};

export default useHome;
