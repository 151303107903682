import { Modal, PrivateTopBar, ProfileCard, ProfilePictureCard, SignatureCard, TherapistPersonalInfoModal } from '../../../components';
import Grid from '@material-ui/core/Grid';
import InstituteLogoCard from '../../../components/InstituteLogo/InstituteLogo';
import { InviteAssistantCard } from '../../../components/Cards/InviteAssistantCard/InviteAssistantCard';
import { keyValue } from '../../../interfaces/Common';
import React from 'react';
import SignatureModal from '../../../components/Modals/SignatureModal/SignatureModal';
import styles from './Profile.module.scss';
import TherapistProfessionalInfoModal from '../../../components/Modals/PersonalInfoModal/TherapistProfessionalInfoModal';
import useProfile from './useProfile';
import { UserFilterListCard } from '../../../components/Cards/UserFilterListCard/UserFilterListCard';
import { useTranslation } from 'react-i18next';

const Profile = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		assistantInvitationEmail,
		institutionRequests,
		isLoading,
		isLogoLoading,
		therapistInfo,
		openPersonalInfoModal,
		openPersonalInfoExtensionModal,
		openSignatureModal,
		openConfirmationModal,
		confirmationModalState,
		handleOpenPersonalInfoModal,
		handleOpenPersonalInfoExtensionModal,
		handleOpenSignatureModal,
		handleUpdateAvatar,
		handleUpdateSignature,
		handleUpdateInstituteLogo,
		loadTherapist,
		updateTherapistInfo,
	} = useProfile();

	const therapistProfessionalInfoKeyValue = {
		treatedPatients: therapistInfo.treatedPatients?.map(treatedPatient => t(treatedPatient)).join(', '),
		clinicalSituations: therapistInfo.clinicalSituations?.map(clinicalSituation => t(clinicalSituation)).join(', '),
		locations: therapistInfo.locations?.map(location => location.address).join(', '),
	};

	const personalInfoFields = ['birthdate', 'city', 'country', 'email', 'enrollment', 'gender', 'job', 'lastName', 'name', 'phone', 'postalCode'];

	return (
		<div className={styles.profile}>
			<PrivateTopBar
				title={t('profileView:TITLE_PROFILE')}
			/>
			<div className={styles.container}>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={3}>
						<ProfilePictureCard fullName={`${therapistInfo.name} ${therapistInfo.lastName}`} image={therapistInfo.avatar} onSave={handleUpdateAvatar} isLoading={isLoading} isAllowedToEdit/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<ProfileCard title={t('profileView:PROFESSIONAL_INFO')} data={therapistProfessionalInfoKeyValue as keyValue} onClickBtn={handleOpenPersonalInfoExtensionModal} showEditBtn/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<InstituteLogoCard image={therapistInfo.instituteLogo} onSave={handleUpdateInstituteLogo} isLoading={isLogoLoading}/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<SignatureCard onOpenModal={handleOpenSignatureModal} therapistInfo={therapistInfo} />
					</Grid>
				</Grid>
				<Grid container spacing={1} style={{ marginTop: 10 }}>
					<Grid item xs={12} sm={3}>
						<ProfileCard title={t('profileView:PERSONAL_INFO')} data={therapistInfo as keyValue} showEditBtn onClickBtn={handleOpenPersonalInfoModal} fieldsToDisplay={personalInfoFields}/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<InviteAssistantCard data={therapistInfo.assistant} reloadProfile={loadTherapist} assistantInvitationEmail={assistantInvitationEmail} />
					</Grid>
					<Grid item xs={12} sm={3}>
						<UserFilterListCard className={styles.userListCard} userSelector={{users: therapistInfo.institutions?.concat(institutionRequests), height:{height: '450px'}}} title={t('institution:TITLE_INSTITUTIONS')} />
					</Grid>
				</Grid>
			</div>
			<TherapistPersonalInfoModal data={therapistInfo} open={openPersonalInfoModal} onChange={handleOpenPersonalInfoModal} getUser={loadTherapist} updateUser={updateTherapistInfo} />
			<TherapistProfessionalInfoModal data={therapistInfo} open={openPersonalInfoExtensionModal} onChange={handleOpenPersonalInfoExtensionModal} getUser={loadTherapist} updateUser={updateTherapistInfo}/>
			<SignatureModal open={openSignatureModal} getUser={loadTherapist} onChange={handleOpenSignatureModal} onSave={handleUpdateSignature} />
			{
				openConfirmationModal &&
				<Modal
					open={openConfirmationModal}
					title={t(`${confirmationModalState.title}`)}
					handleOnClose={confirmationModalState.onClose}
					isPrimaryButtonVisible
					primaryButtonProps={{
						title: t('profileView:MODAL_CONFIRM_BUTTON'),
						onClick: confirmationModalState.onConfirm,
						isDisabled: false,
					}}
					isSecondaryButtonVisible
					secondaryButtonProps={{
						title: t('profileView:MODAL_CANCEL_BUTTON'),
						onClick: confirmationModalState.onClose,
					}}
					showDividers={false}
				>
					{t(`${confirmationModalState.content}`)}
				</Modal>
			}
		</div >
	);
};

export default Profile;
