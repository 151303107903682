import { Tag } from './Tag';

export enum StatusResource {
	Linked = 0,
	Shared = 1,
}

export interface Resource {
	id: number;
	name: string;
	file?: {
		id: number;
		url?: string;
	};
	description?: string;
	instructions?: string;
	references?: string;
	updatedAt?: string;
	tags?: Array<Tag>;
	sharedAt?: string | null;
	linkedAt?: string;
}

export interface ResourceCard extends Resource {
	selected?: boolean;
	sharedAvatar?: JSX.Element;
}
