import React from 'react';
import styles from './CombinedCard.module.scss';
import { Tag } from '..';

interface CombinedCardProps {
	image: string;
	highlight: string;
	title: string;
	description: string;
	imagePosition?: 'LEFT' | 'RIGHT';
	bottomComponent?: JSX.Element;
}

const CombinedCard: React.FC<CombinedCardProps> = ({ image, highlight, title, description, imagePosition, bottomComponent }) => {
	const imageClassName = `${styles.imageContainer} ${imagePosition === 'RIGHT' && styles['imageContainer--right']}`;
	const contentClassName = `${styles.content} ${imagePosition === 'RIGHT' ? styles['content--right'] : styles['content--left']}`;

	return (
		<div className={styles.container}>
			<div className={imageClassName}>
				<img src={image} alt="" className={image} />
			</div>
			<div className={contentClassName}>
				<Tag text={highlight} />
				<h4 className={styles.contentTitle}>{title}</h4>
				<p className={styles.contentDescription}>{description}</p>
				{
					bottomComponent && (
						<div className={styles.bottomComponent}>
							{bottomComponent}
						</div>)
				}
			</div>
		</div>
	);
};

export default CombinedCard;

