import { getPatientReportsByPatient, getReportPreviewPdf } from '../../../../../services/TherapistService';
import { AlertContext } from '../../../../../contexts/AlertContext';
import { ColDef } from '../../../../../components/CardsTable/CardsTable';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';
import { Report } from '../../../../../interfaces/Report';
import styles from './Reports.module.scss';
import useDownload from '../../../../../hooks/useDonwload';
import { useParams } from 'react-router-dom';
import { useTherapistSelected } from '../../../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';

interface ReportsStore {
	reports: Report[];
	isLoading: boolean;
	columns: ColDef[];
	selectedReport: Partial<Report> | undefined;
	handleOnCloseReport: () => void;
}

export interface ReportsProps {
	searchValue: string;
	selectedTab: number;
}

const useReports = (props: ReportsProps): ReportsStore => {
	const { t } = useTranslation();
	const { showDefaultError } = React.useContext(AlertContext);
	const [reports, setReports] = React.useState<Array<Report>>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [columns, setColumns] = React.useState<Array<ColDef>>([]);
	const [selectedReport, setSelectedReport] = React.useState<Partial<Report> | undefined>(undefined);
	const [reportToDonwloadId, setReportToDownloadId] = React.useState<number>();
	const { id: patientId } = useParams<{ id: string }>();
	const { download } = useDownload();
	const { getTherapistIdParam } = useTherapistSelected();

	const setFileToReport = React.useCallback(async(reportId: number): Promise<Report | undefined> => {
		let report = reports .find(r => r.id === reportId);

		if (!report?.file) {
			const therapistIdParam = getTherapistIdParam();
			const previewPDF = await getReportPreviewPdf(therapistIdParam, reportId);

			setReports(reports => reports.map(reportItem => {
				if (reportItem.id === reportId) {
					report = {...reportItem, file: previewPDF };

					return report;
				}

				return reportItem;
			}));
		}

		return report;
	}, [reports]);

	const handleActivateReport = React.useCallback(async(id: number): Promise<void> => {
		const activeReport = await setFileToReport(id);

		setSelectedReport(activeReport);
	}, [setFileToReport]);

	React.useEffect(() => {
		if (selectedReport?.id) {
			handleActivateReport(selectedReport?.id);
		}
	}, [handleActivateReport, selectedReport]);

	const handlePreview = (id: number): void => {
		setSelectedReport({id: id});
	};

	const downloadReport = React.useCallback(async(id: number): Promise<void> => {
		const report = await setFileToReport(id);

		if (report?.file) {
			download(report.file, report.name);
		}

		setReportToDownloadId(undefined);
	}, [download, setFileToReport]);

	React.useEffect(() => {
		if (reportToDonwloadId) {
			downloadReport(reportToDonwloadId);
		}
	}, [downloadReport, reportToDonwloadId]);

	const handleDownload = (id: number): void => {
		setReportToDownloadId(id);
	};

	const columnsDef: ColDef[] = [
		{ field: 'iconFile', headerName: '', sortable: false, flex: 1 },
		{ field: 'name', headerName: t('patientsView:REPORTS_PREVIOUS'), sortable: false, flex: 8 },
		{ field: 'date', headerName: t('patientsView:REPORTS_DATE'), flex: 6 },
		{
			field: 'actions',
			headerName: 'patientsView:DOCUMENTATION_ACTIONS',
			sortable: false,
			flex: 4,
			map: false,
			buttons: [
				{
					key: 'fileCopy',
					icon: <FileCopyOutlinedIcon />,
					style: styles.iconFile,
					onClick: handlePreview,
				},
				{
					key: 'downloadIcon',
					icon: <GetAppIcon />,
					onClick: handleDownload,
					style: styles.iconFile,
				},
			],
		},
	];

	const preProcessing = (data: Report[]): void => {
		const processedData = data
			.filter(report => report.name.toUpperCase().includes(props.searchValue.toUpperCase()))
			.map(doc => {
				const iconFile = <InsertDriveFileOutlinedIcon fontSize="small" className={styles.iconFile} />;

				return ({ ...doc, iconFile });
			});

		setReports(processedData);
	};

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);

			const therapistIdParam = getTherapistIdParam();
			const data = await getPatientReportsByPatient(therapistIdParam, +patientId);

			preProcessing(data);
			setColumns(columnsDef);
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.searchValue]);

	const handleOnCloseReport = (): void => {
		setSelectedReport(undefined);
	};

	return ({
		reports,
		isLoading,
		columns,
		selectedReport,
		handleOnCloseReport,
	});
};

export default useReports;
