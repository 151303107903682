import { Document, DocumentCard } from '../../interfaces/Document';
import { DocumentsByTag, ResourcesByTag } from '../../interfaces/Tag';
import { Resource, ResourceCard } from '../../interfaces/Resource';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { LoadingSpinner } from '..';
import { Patient } from '../../interfaces/Patient';
import React from 'react';
import { Report } from '../../interfaces/Report';
import SectionTitle from '../SectionTitle/SectionTitle';
import styles from './CardsTable.module.scss';
import { Task } from '../../interfaces/Task';
import useCardsTable from './useCardsTable';
import { useTranslation } from 'react-i18next';

type DataType = Task | Patient | Document | Report | ResourceCard | DocumentCard | DocumentsByTag | Resource | ResourcesByTag;

interface TasksTableProps {
	data: Array<DataType>;
	columnsDef: ColDef[];
	onClick?: (id: number) => void;
	isLoading?: boolean;
	stylizeSelected?: boolean;
}

interface ActionButton {
	key: string;
	icon: JSX.Element;
	style?: string | (<T>(item: T) => string);
	size?: 'small' | 'medium';
	onClick?: (id: number) => void;
	hide?: <T>(item: T) => boolean;
}

export enum SortOrder {
	desc = 'desc',
	asc = 'asc',
}

export interface ColDef {
	field: string;
	headerName: string;
	flex: number;
	justify?: 'center' | 'left' | 'right';
	map?: boolean;
	type?: 'actions';
	buttons?: ActionButton[];
	sortable?: boolean;
}

const CardsTable: React.FC<TasksTableProps> = ({ data, columnsDef, onClick, isLoading, stylizeSelected }) => {
	const { t } = useTranslation();
	const {
		columns,
		sortedData,
		getSelectedCardStyle,
		handleChange,
		handleClickSort,
	} = useCardsTable({ onClick, stylizeSelected, data, columnsDef });

	return (
		isLoading
			? <LoadingSpinner />
			:
			<div className={styles.table}>
				<div className={styles.header}>
					{columns.map(column => (
						<div key={column.field} style={{ flex: column.flex, justifyContent: column.justify }} className={styles.headerContent}>
							<SectionTitle title={column.headerName} showDivider={false} />
							{
								column.sortable ??
								(
									column.sort.order === SortOrder.desc//column.sortOrder
										?
										<span className={styles.arrowIcon} onClick={(): void => handleClickSort(column.field)}>
											<IconButton disableRipple color={column.sort.selected ? 'secondary' : 'default'} size="small">
												<ArrowDownwardOutlinedIcon fontSize="inherit" />
											</IconButton>
										</span>
										: <span className={styles.arrowIcon} onClick={(): void => handleClickSort(column.field)}>
											<IconButton disableRipple color={column.sort.selected ? 'secondary' : 'default'} size="small">
												<ArrowUpwardIcon fontSize="inherit" />
											</IconButton>
										</span>
								)
							}
						</div>
					))}
				</div>
				{sortedData.length
					? sortedData.map(item => (
						<Card key={item.id} className={getSelectedCardStyle(item.id as number)} >
							<CardActionArea onClick={(): void => handleChange(item.id as number)} className={styles.rowActionArea} disableRipple>
								<CardContent className={styles.content}>
									{columnsDef.map(column => (
										<div key={column.field} style={{ flex: column.flex, justifyContent: column.justify }}>
											{column.buttons
												? column.buttons.map(button =>
													(!button.hide || Boolean(button.hide(item))) &&
													<IconButton
														key={button.key}
														aria-label={button.key}
														className={ (typeof button.style === 'function') ? button.style(item) : button.style }
														size={button.size}
														onClick={(): void => button.onClick ? button.onClick(item.id as number) : undefined}
													>
														{button.icon}
													</IconButton>)
												: item[column.field as keyof DataType]
											}
										</div>
									))}
								</CardContent>
							</CardActionArea>
						</Card>
					))
					: <div className={styles.noData}>{t('messages:NO_DATA')}</div>}
			</div>
	);
};

export default CardsTable;
