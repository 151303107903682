import {
	getResourcesLinkedToPatient,
	shareResourceToPatient,
	unlinkResourceFromPatient
} from '../../../../../services/ResourceLibraryService';
import { AlertContext } from '../../../../../contexts/AlertContext';
import Avatar from '@material-ui/core/Avatar';
import { ColDef } from '../../../../../components/CardsTable/CardsTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import { GridSize } from '@material-ui/core/Grid';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';
import { ResourceCard } from '../../../../../interfaces/Resource';
import styles from './Resources.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from 'react-router-dom';
import { useTherapistSelected } from '../../../../../hooks/useTherapistSelected';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface DocumentationStore {
	resources: ResourceCard[];
	isLoading: boolean;
	columns: ColDef[];
	openUnlinkResourceModal: boolean;
	openShareResourceModal: boolean;
	selectedResource: ResourceCard | undefined;
	gridWidth: GridSize;
	handleCloseUnlinkResourceModal: () => void;
	handleConfirmUnlinkResource: () => void;
	handleConfirmShareResource: () => void;
	handleClosePreviewCard: () => void;
	handleCloseShareModal: () => void;
}

export interface ResourcesProps {
	searchValue: string;
	selectedTab: number;
	isPatientEnabled: boolean;
}

interface ResourcesParams {
	id: string;
}

const useDocumentation = (props: ResourcesProps): DocumentationStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [resources, setResources] = React.useState<Array<ResourceCard>>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectedResource, setSelectedResource] = React.useState<ResourceCard | undefined>();
	const [openUnlinkResourceModal, setOpenUnlinkResourceModal] = React.useState<boolean>(false);
	const [openShareResourceModal, setOpenShareResourceModal] = React.useState<boolean>(false);
	const [refreshData, setRefreshData] = React.useState<boolean>(false);
	const [selectedResourceId, setSelectedResourceId] = React.useState<number | undefined>();
	const [gridWidth, setGridWidth] = React.useState<GridSize>(12);
	const { id: patientIdString } = useParams<ResourcesParams>();
	const { t } = useTranslation();
	const { getTherapistIdParam } = useTherapistSelected();
	const patientId = +patientIdString;

	const handleClickShareButton = (id: number): void => {
		setSelectedResourceId(id);

		setOpenShareResourceModal(prevState => !prevState);
	};

	const handleClickUnlinkButton = (id: number): void => {
		setSelectedResourceId(id);
		setOpenUnlinkResourceModal(prevState => !prevState);
	};

	const handleClickPreviewButton = (id: number): void => {
		setSelectedResourceId(id);
		setGridWidth(6);
	};

	const columnsDef: ColDef[] = [
		{ field: 'iconFile', headerName: '', sortable: false, flex: 1 },
		{ field: 'name', headerName: 'patientsView:RESOURCES_LINKED_RESOURCE', sortable: false, flex: 6 },
		{ field: 'linkedAt', headerName: 'patientsView:DOCUMENTATION_DATE', flex: 4 },
		{ field: 'sharedAvatar', headerName: 'patientsView:SHARED', sortable: false, flex: 4 },
		{
			field: 'actions',
			headerName: 'patientsView:DOCUMENTATION_ACTIONS',
			sortable: false,
			flex: 4,
			map: false,
			buttons: [
				{
					key: 'delete',
					icon: (
						<Tooltip title={t('patientsView:DELETE_TOOLTIP') as string} aria-label={t('patientsView:DELETE_TOOLTIP')}>
							<DeleteOutlineIcon />
						</Tooltip>),
					style: styles.iconActions,
					onClick: handleClickUnlinkButton,
					hide: (): boolean => {
						return props.isPatientEnabled;
					},
				},
				{
					key: 'preview',
					icon: (
						<Tooltip title={t('patientsView:PREVIEW_TOOLTIP') as string} aria-label={t('patientsView:PREVIEW_TOOLTIP')}>
							<VisibilityIcon />
						</Tooltip>),
					style: styles.iconActions,
					onClick: handleClickPreviewButton,
				},
				{
					key: 'forward',
					icon: (
						<Tooltip title={t('patientsView:SHARE_TOOLTIP') as string} aria-label={t('patientsView:SHARE_TOOLTIP')}>
							<ForwardOutlinedIcon />
						</Tooltip>),
					style: styles.iconActions,
					onClick: handleClickShareButton,
					hide: (item): boolean => {
						const castedItem = item as unknown as ResourceCard;

						return castedItem.sharedAt === null && props.isPatientEnabled;
					},
				},
			],
		},
	];

	const preProcessing = React.useCallback((data: ResourceCard[]): void => {
		const processedData = data
			.filter(resource => resource.name.toUpperCase().includes(props.searchValue.toUpperCase()))
			.map(resource => {
				const iconFile = <InsertDriveFileOutlinedIcon fontSize="small" className={styles.iconFile} />;

				let avatar = (<Avatar className={styles.avatar}>{t('patientsView:NO_AVATAR_LABEL')}</Avatar>);

				if (resource.sharedAt) {
					avatar = (<Avatar className={styles.avatar}>{t('patientsView:YES_AVATAR_LABEL')}</Avatar>);
				}

				return ({ ...resource, sharedAvatar: avatar, iconFile });
			});

		setResources(processedData);
	}, [props.searchValue, t]);

	const loadData = React.useCallback(async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const data = await getResourcesLinkedToPatient(therapistIdParam, patientId);

			preProcessing(data);
			// setColumns(columnsDef);
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	}, [getTherapistIdParam, patientId, preProcessing, showDefaultError]);

	React.useEffect(() => {
		loadData();
	}, [loadData, props.searchValue, refreshData]);

	React.useEffect(() => {
		if (selectedResourceId !== undefined) {
			const resource = resources.find(resource => resource.id === selectedResourceId);

			setSelectedResource(resource);
		}
	}, [resources, selectedResourceId]);

	const handleCloseUnlinkResourceModal = (): void => {
		setOpenUnlinkResourceModal(prevState => !prevState);
	};

	const handleCloseShareModal = (): void => {
		setOpenShareResourceModal(prevState => !prevState);
	};

	const handleConfirmUnlinkResource = async (): Promise<void> => {
		try {
			if (selectedResource) {
				const therapistIdParam = getTherapistIdParam();

				await unlinkResourceFromPatient(therapistIdParam, selectedResource.id, patientId);
				setRefreshData(prevState => !prevState);
				setGridWidth(12);
			}

			setOpenUnlinkResourceModal(prevState => !prevState);
		} catch (error) {
			showDefaultError();
		}
	};

	const handleConfirmShareResource = async (): Promise<void> => {
		try {
			if (selectedResource) {
				const therapistIdParam = getTherapistIdParam();

				await shareResourceToPatient(therapistIdParam, selectedResource.id, patientId);
				setRefreshData(prevState => !prevState);
			}

			setOpenShareResourceModal(prevState => !prevState);
		} catch (error) {
			showDefaultError();
		}
	};

	const handleClosePreviewCard = (): void => {
		setGridWidth(12);
		setSelectedResource(undefined);
		setSelectedResourceId(undefined);
	};

	return ({
		resources,
		isLoading,
		columns: columnsDef,
		openUnlinkResourceModal,
		openShareResourceModal,
		selectedResource,
		gridWidth,
		handleCloseUnlinkResourceModal,
		handleConfirmUnlinkResource,
		handleConfirmShareResource,
		handleClosePreviewCard,
		handleCloseShareModal,
	});
};

export default useDocumentation;
