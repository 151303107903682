import Card from './Card';
import React from 'react';
import styles from './ImageOverflowCard.module.scss';

interface ImageOverflowCardProps {
	image: string;
	title: string;
	subtitle: string;
	description: string;
	className?: string;
}

const ImageOverflowCard: React.FC<ImageOverflowCardProps> = ({ image, title, subtitle, description, className }) => {
	const classnames = `${styles.cardContainer} ${className || ''}`;

	return (
		<Card className={classnames}>
			<div className={styles.imageContainer}>
				<img src={image} alt={title} className={styles.image} />
			</div>
			<h4 className={styles.cardTitle}>{title}</h4>
			<h6 className={styles.cardSubtitle}>{subtitle}</h6>
			<p className={styles.cardDescription}>{description}</p>
		</Card>
	);
};

export default ImageOverflowCard;
