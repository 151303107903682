import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FormDataValue } from '../../../interfaces/FormData';
import InputText from '../../Inputs/InputText/InputText';
import { OptionSelect } from '../../../interfaces/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FormTextFieldProps {
	formValue: FormDataValue;
	handleChangeTextField: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({ formValue, handleChangeTextField }) => {
	const { name, value, label } = formValue;
	const { t } = useTranslation();

	return (
		<InputText
			label={t(`profileView:${label}`)}
			name={name}
			value={value as string}
			onChange={handleChangeTextField}
			fullWidth
		/>
	);
};

interface FormDateFieldProps {
	formKey: string;
	formValue: FormDataValue;
	handleChangeDate: (data: Date, key: string) => void;
}

export const FormDateField: React.FC<FormDateFieldProps> = ({ formKey, formValue, handleChangeDate }) => {
	const { value, label } = formValue;
	const { t } = useTranslation();

	return (
		<DatePicker
			autoOk
			variant="inline"
			openTo="date"
			label={t(`profileView:${label}`)}
			value={value || new Date()}
			format="MM/dd/yyyy"
			onChange={(date): void => handleChangeDate(date as Date, formKey)}
			fullWidth
			InputLabelProps={{ shrink: false, style: { fontFamily: 'Source Sans Pro' } }}
			inputProps={{ style: { textAlign: 'end', fontFamily: 'Source Sans Pro', fontWeight: 400 }}}
		/>
	);
};

interface FormSelectFieldProps {
	formKey: string;
	formValue: FormDataValue;
	options: Array<OptionSelect>;
	multiple?: boolean;
	handleChangeSelect: (value: string, name: string) => void;
}

export const FormSelectField: React.FC<FormSelectFieldProps> = ({ formKey, formValue, options, multiple, handleChangeSelect }) => {
	const { value, label} = formValue;
	const { t } = useTranslation();

	return (
		<FormControl fullWidth>
			<InputLabel id={label} shrink={false} style={{ fontFamily: 'Source Sans Pro' }}>{t(`profileView:${label}`)}</InputLabel>
			<Select
				labelId={label}
				id={`${label}-id`}
				value={value}
				onChange={(e): void => handleChangeSelect(e.target.value as string, formKey)}
				style={{ textAlign: 'end', fontFamily: 'Source Sans Pro', fontWeight: 400 }}
				multiple={multiple}
			>
				{options.map((item) => (
					<MenuItem key={item.id} value={item.name}>{t(item.name)}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
