import { ColDef, SortOrder } from './CardsTable';
import { Document, DocumentCard } from '../../interfaces/Document';
import { DocumentsByTag, ResourcesByTag } from '../../interfaces/Tag';
import { Resource, ResourceCard } from '../../interfaces/Resource';
import _orderBy from 'lodash/orderBy';
import { Patient } from '../../interfaces/Patient';
import React from 'react';
import { Report } from '../../interfaces/Report';
import styles from './CardsTable.module.scss';
import { Task } from '../../interfaces/Task';

type DataType = Task | Patient | Document | Report | ResourceCard | DocumentCard | DocumentsByTag | Resource | ResourcesByTag;

type SortType = 'asc' | 'desc';

interface SortCol {
	selected: boolean;
	order: SortOrder;
}

interface ColumnsCard extends ColDef {
	sort: SortCol;
}
interface CardsTableProps {
	onClick?: (id: number) => void;
	data: Array<DataType>;
	stylizeSelected?: boolean;
	columnsDef: ColDef[];
}

interface CardsTableStore {
	columns: ColumnsCard[];
	sortedData: DataType[];
	getSelectedCardStyle: (id: number) => string;
	handleChange: (id: number) => void | undefined;
	handleClickSort: (field: string) => void;
}

const useCardsTable = (props: CardsTableProps): CardsTableStore => {
	const [selectedCard, setSelectedCard] = React.useState<number | undefined>(undefined);
	const [sortedData, setSortedData] = React.useState<Array<DataType>>([]);
	const [columns, setColumns] = React.useState<Array<ColumnsCard>>([]);
	const [selecetdSortHeader, setSelecetdSortHeader] = React.useState<string>('');

	React.useEffect(() => {
		setSelectedCard(undefined);
		setSortedData(props.data);
	}, [props.data]);

	React.useEffect(() => {
		const columnsParsed = props.columnsDef.map((column: ColDef): ColumnsCard => ({ ...column, sort: { selected: false, order: SortOrder.desc } }));

		setColumns(columnsParsed);
	}, [props.columnsDef]);

	const sortData = (): void => {
		const sortColumnSelected = columns.find(column => column.field === selecetdSortHeader);

		const data = _orderBy(props.data, [selecetdSortHeader], [sortColumnSelected?.sort.order as unknown as SortType]);

		setSortedData(data);
	};

	React.useEffect(() => {
		sortData();
	}, [columns]);

	const handleChange = (id: number): void | undefined => {
		if (props.onClick) {
			props.onClick(id as number);

			if (props.stylizeSelected) {
				setSelectedCard(id);
			}
		}
	};

	const getSelectedCardStyle = (id: number): string => {
		if (id == selectedCard && props.stylizeSelected) {
			return `${styles.row} ${styles.selectedRow}`;
		}

		return styles.row;
	};

	const handleClickSort = (field: string): void => {
		const data = columns.map(column => {
			return column.field === field
				? {
					...column,
					sort: {
						order: column.sort.order === SortOrder.asc ? SortOrder.desc : SortOrder.asc,
						selected: true,
					},
				}
				: {
					...column,
					sort: {
						order: SortOrder.desc,
						selected: false,
					},
				};
		});

		setSelecetdSortHeader(field);
		setColumns(data);
	};

	return ({
		columns,
		sortedData,
		getSelectedCardStyle,
		handleChange,
		handleClickSort,
	});
};

export default useCardsTable;
