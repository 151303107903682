import { CardsTable, FormPreviewModal } from '../../../../../components';
import useDocumentation, { DocumentationProps } from './useDocumentation';
import { FormProvider } from '../../../../../contexts/FormContext';
import Modal from '../../../../../components/Modal/Modal';
import React from 'react';
import styles from './Documentation.module.scss';
import { useTranslation } from 'react-i18next';

// interface DocumentationProps {
// 	searchValue: string;
// 	selectedTab: number;
// 	isPatientEnabled: boolean;
// }

const Documentation = (props: DocumentationProps): JSX.Element => {
	const {
		documents,
		columns,
		isLoading,
		openUnlinkDocumentModal,
		openShareDocumentModal,
		openFormPreviewModal,
		selectedPatientDocumentation,
		handleCloseUnlinkDocumentModal,
		handleConfirmUnlinkDocument,
		handleConfirmShareDocument,
		handleConfirmFormPreview,
		handleCloseShareModal,
		handleCloseFormPreview,
	} = useDocumentation(props);
	const { t } = useTranslation();

	return (
		<div className={styles.documentation}>
			<CardsTable
				data={documents}
				columnsDef={columns}
				isLoading={isLoading}
			/>
			{
				openFormPreviewModal && selectedPatientDocumentation &&
				<FormProvider>
					<FormPreviewModal
						open={openFormPreviewModal}
						handleValidate={handleConfirmFormPreview}
						handleClose={handleCloseFormPreview}
						form={{
							id: selectedPatientDocumentation?.documentId,
							title: selectedPatientDocumentation?.name,
							description: selectedPatientDocumentation?.description,
							sections: selectedPatientDocumentation?.sections,
							showErrors: false,
						}}
					/>
				</FormProvider>
			}
			<Modal
				open={openUnlinkDocumentModal}
				title={t('patientsView:UNLINK_DOCUMENTATION')}
				handleOnClose={handleCloseUnlinkDocumentModal}
				isPrimaryButtonVisible
				primaryButtonProps={{
					title: t('patientsView:REPORTS_CONFIRM_BUTTON'),
					onClick: handleConfirmUnlinkDocument,
					isDisabled: false,
				}}
				isSecondaryButtonVisible
				secondaryButtonProps={{
					title: t('patientsView:MODAL_BUTTON_CANCEL'),
					onClick: handleCloseUnlinkDocumentModal,
				}}
				showDividers={false}
			>
				{t('patientsView:UNLINK_DOCUMENTATION_MODAL_CONTENT')}
			</Modal>
			<Modal
				open={openShareDocumentModal}
				title={t('patientsView:SHARE_DOCUMENTATION')}
				handleOnClose={handleCloseShareModal}
				isPrimaryButtonVisible
				primaryButtonProps={{
					title: t('patientsView:REPORTS_CONFIRM_BUTTON'),
					onClick: handleConfirmShareDocument,
					isDisabled: false,
				}}
				isSecondaryButtonVisible
				secondaryButtonProps={{
					title: t('patientsView:MODAL_BUTTON_CANCEL'),
					onClick: handleCloseShareModal,
				}}
				showDividers={false}
			>
				{t('patientsView:SHARE_DOCUMENTATION_MODAL_CONTENT')}
			</Modal>
		</div>
	);
};

export default Documentation;
