import { Button, Card, Input } from '@material-ui/core';
import { InviteAssistantCardProps, useInviteAssistantCard } from './useInviteAssistantCard';
import { default as AxiomaButton } from '../../Button/Button';
import { ConfirmationTypes } from '../../../interfaces/ConfirmationModels';
import EditOutlined from '@material-ui/icons/EditOutlined';
import InputText from '../../Inputs/InputText/InputText';
import Modal from '../../Modal/Modal';
import React from 'react';
import styles from './InviteAssistantCard.module.scss';
import { useTranslation } from 'react-i18next';

const inputClasses = { root: styles.inputRoot, input: styles.input, underline: styles.inputUnderline };

export const InviteAssistantCard: React.FC<InviteAssistantCardProps> = (props) => {
	const { t } = useTranslation();

	const {
		formData,
		openConfirmationModal,
		confirmationModalState,
		handleChangeTextField,
		handleSave,
		handleConfirmationModal,
	} = useInviteAssistantCard(props);

	return (
		<div className={styles.inviteCard}>
			<Card className={`${styles.cardForm} ${props.fullWidth && styles.maxWidth}`}>
				<div className={`${styles.content} ${props.fullWidth && styles.maxWidth}`}>
					<div className={styles.header}>
						<p className={styles.headerTitle}>{t('invitations:TITLE_INVITATE_ASSISTANT')}</p>
						{
							!props.assistantInvitationEmail && props.data?.email &&
							<Button
								className={styles.headerEditButton}
								startIcon={<EditOutlined />}
								onClick={(): void => handleConfirmationModal(ConfirmationTypes.deleteAssistant)}
							>
								{t('invitations:BUTTON_DELETE_ASSISTANT')}
							</Button>
						}
						{
							props.assistantInvitationEmail &&
							<Button
								className={styles.headerEditButton}
								startIcon={<EditOutlined />}
								onClick={(): void => handleConfirmationModal(ConfirmationTypes.cancelAssistantInvitation)}
							>
								{t('invitations:BUTTON_CANCEL_INVITATION')}
							</Button>
						}
					</div>
					<div className={styles.body}>
						{
							!props.assistantInvitationEmail && props.data && !props.data?.email &&
							<>
								<InputText
									value={formData['name'].value}
									name="name"
									label={t('invitations:LABEL_NAME_INVITATION')}
									fullWidth
									onChange={handleChangeTextField}
									error={formData['name'].error}
									helperText={formData['name']?.showError && t(`invitations:${formData['name']?.errorMessage}`)}
									variant='outlined'
								/>
								<InputText
									value={formData['lastName'].value}
									name="lastName"
									label={t('invitations:LABEL_LAST_NAME_INVITATION')}
									fullWidth
									onChange={handleChangeTextField}
									error={formData['lastName'].error}
									helperText={formData['lastName']?.showError && t(`invitations:${formData['lastName']?.errorMessage}`)}
									variant='outlined'
								/>
								<InputText
									value={formData['email'].value}
									name="email"
									label={t('invitations:LABEL_EMAIL_INVITATION')}
									fullWidth
									onChange={handleChangeTextField}
									error={formData['email']?.error}
									helperText={formData['email']?.showError && t(`invitations:${formData['email']?.errorMessage}`)}
									variant='outlined'
								/>
								<AxiomaButton
									title={t('invitations:BUTTON_SEND_INVITATION')}
									onClick={handleSave}
									fullWidth
									isDisabled={ !(formData['name']?.value?.length > 0 && formData['lastName']?.value?.length > 0 && formData['email']?.value?.length > 0 )}
								/>
							</>
						}
						{
							!props.assistantInvitationEmail && props.data && props.data?.email &&
							<>
								<Input
									key={'key-name'}
									classes={inputClasses}
									placeholder=""
									fullWidth
									readOnly
									startAdornment={
										<span className={styles.label}>{t('invitations:LABEL_NAME_INVITATION')}</span>
									}
									value={props.data?.givenName}
									disabled
								/>
								<Input
									key={'key-lastName'}
									classes={inputClasses}
									placeholder=""
									fullWidth
									readOnly
									startAdornment={
										<span className={styles.label}>{t('invitations:LABEL_LAST_NAME_INVITATION')}</span>
									}
									value={props.data?.familyName}
									disabled
								/>
								<Input
									key={'key-email'}
									classes={inputClasses}
									placeholder=""
									fullWidth
									readOnly
									startAdornment={
										<span className={styles.label}>{t('invitations:LABEL_EMAIL_INVITATION')}</span>
									}
									value={props.data?.email}
									disabled
								/>
							</>
						}
						{
							props.assistantInvitationEmail && (
								<div className={styles.invitationStatus}>
									<p className={styles.invitationStatusTitle}>{t('invitations:INVITATION_SENT', { email: props.assistantInvitationEmail })}</p>
								</div>
							)
						}
					</div>
				</div>
			</Card>
			{
				openConfirmationModal &&
				<Modal
					open={openConfirmationModal}
					title={t(`${confirmationModalState.title}`)}
					handleOnClose={confirmationModalState.onClose}
					isPrimaryButtonVisible
					primaryButtonProps={{
						title: t('invitations:MODAL_CONFIRM_BUTTON'),
						onClick: confirmationModalState.onConfirm,
						isDisabled: false,
					}}
					isSecondaryButtonVisible
					secondaryButtonProps={{
						title: t('invitations:MODAL_CANCEL_BUTTON'),
						onClick: confirmationModalState.onClose,
					}}
					showDividers={false}
				>
					{t(`${confirmationModalState.content}`)}
				</Modal>
			}
		</div>
	);
};
