export default {
	es: {
		personalInfo: {
			GUARDIAN_YES: 'Si',
			GUARDIAN_NO: 'No',
			SEX_FEMALE: 'Femenino',
			SEX_MALE: 'Masculino',
			CIVIL_STATUS_DIVORCED: 'Divorciado/a',
			CIVIL_STATUS_MARRIED: 'Casado/a',
			CIVIL_STATUS_WIDOWED: 'Viudo/a',
			CIVIL_STATUS_SINGLE: 'Soltero/a',
		},
	},
	en: {
		personalInfo: {
			GUARDIAN_YES: 'Yes',
			GUARDIAN_NO: 'No',
			SEX_FEMALE: 'Female',
			SEX_MALE: 'Male',
			CIVIL_STATUS_DIVORCED: 'Divorced',
			CIVIL_STATUS_MARRIED: 'Married',
			CIVIL_STATUS_WIDOWED: 'Widowed',
			CIVIL_STATUS_SINGLE: 'Single',
		},
	},
};
