import { getClinicalSituationOptions, getTreatedPatientsOptions } from '../../../services/TherapistService';
import { AlertContext } from '../../../contexts/AlertContext';
import { getJobs } from '../../../services/GenericService';
import { Location } from '../../../interfaces/Generic';
import { OptionSelect } from '../../../interfaces/Common';
import React from 'react';
import { TherapistInfo } from '../../../interfaces/User';

interface TherapistInfoModalStore {
	clinicalSituationsOptions: Array<OptionSelect>;
	jobs: Array<OptionSelect>;
	therapistLocations: Location[];
	treatedPatientsOptions: Array<OptionSelect>;
	addLocation: () => void;
	handleLocationChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
	removeLocation: (index: number) => void;
}

interface TherapistInfoModalProps {
	data: TherapistInfo;
	setFormData: (value: any) => void;
}

const useTherapistInfoModal = (props?: TherapistInfoModalProps): TherapistInfoModalStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [jobs, setJobs] = React.useState<Array<OptionSelect>>([]);
	const [treatedPatientsOptions, setTreatedPatientsOptions] = React.useState<Array<OptionSelect>>([]);
	const [clinicalSituationsOptions, setClinicalSituationsOptions] = React.useState<Array<OptionSelect>>([]);
	const [therapistLocations, setTherapistLocations] = React.useState<Location[]>([]);

	const loadData = async (): Promise<void> => {
		try {
			const jobs = await getJobs();
			const treatedPatients = await getTreatedPatientsOptions();
			const clinicalSituations = await getClinicalSituationOptions();

			setJobs(jobs.map(job => ({ ...job, name: `job:${job.name.toUpperCase()}` })));
			setTreatedPatientsOptions(treatedPatients.map(treatedPatient => ({...treatedPatient, name: `treatedPatientAge:${treatedPatient.name.toUpperCase()}`})));
			setClinicalSituationsOptions(clinicalSituations.map(clinicalSituation => ({...clinicalSituation, name: `clinicalSituation:${clinicalSituation.name.toUpperCase()}`})));
			setTherapistLocations(props?.data.locations ?? []);
		} catch (error) {
			showDefaultError();
		}
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.data.locations]);

	const addLocation = (): void => {
		setTherapistLocations([...therapistLocations, { address: '' }]);
	};

	const removeLocation = ( index: number): void => {
		const locationsArray = [...therapistLocations];

		locationsArray.splice(index, 1);
		setTherapistLocations(locationsArray);
		props?.setFormData({ type: 'update', name: 'locations', value: locationsArray, isUpdated: true });
	};

	const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
		const locationsArray = [...therapistLocations];

		locationsArray.forEach((location, i) => {
			if (i === index) {
				location.address = event.target.value;
			}
		});
		setTherapistLocations(locationsArray);
		props?.setFormData({ type: 'update', name: event.target.name, value: locationsArray, isUpdated: event.target.value !== props.data[event.target.name] });
	};

	return ({
		clinicalSituationsOptions,
		jobs,
		therapistLocations,
		treatedPatientsOptions,
		addLocation,
		handleLocationChange,
		removeLocation,
	});
};

export default useTherapistInfoModal;
