import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import styles from './DefaultInput.module.scss';

interface DefaultInputProps {
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	value?: string | number | undefined;
	isError?: boolean;
	errorMessage?: string;
	placeholder?: string;
	isMultiline?: boolean;
	fullWidth?: boolean;
}

const DefaultInput: React.FC<DefaultInputProps> = ({ onChange, onBlur, value, isError, errorMessage, placeholder, isMultiline, fullWidth }) => {
	const ErrorAdornment = (
		<InputAdornment
			position="end"
		>
			<IconButton
				className={styles.adornment}
				aria-label="error state"
			>
				<ErrorIcon />
			</IconButton>
		</InputAdornment>
	);

	return (
		<div className={styles.container}>
			<TextField
				className={styles.textInput}
				variant="outlined"
				color="secondary"
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				placeholder={placeholder}
				InputProps={{
					className: styles.textInput,
					endAdornment: isError ? ErrorAdornment : undefined,
				}}
				error={isError}
				helperText={errorMessage}
				multiline={isMultiline}
				fullWidth={fullWidth}
			/>
		</div>
	);
};

export default DefaultInput;
