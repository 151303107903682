import { DocumentCard, StatusDocumentCard } from '../../../interfaces/Document';
import { DocumentCards, FormPreviewWizard, PDFPreviewModal, PrivateTopBar, ResourceCards, ResourcePreview, TherapistChangeCard, UpdateTherapistModal } from '../../../components';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { FormProvider } from '../../../contexts/FormContext';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Report } from '../../../interfaces/Report';
import ReportPreview from '../../../components/ReportPreview/ReportPreview';
import { ResourceCard } from '../../../interfaces/Resource';
import styles from './Library.module.scss';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useLibrary from './useLibrary';
import { useTranslation } from 'react-i18next';
import { usePatient } from '../hooks/usePatient';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

interface RenderCards { cards: JSX.Element; preview?: JSX.Element }

const RESOURCES = 0;
const FORMS = 1;
const REPORTS = 2;

const Library = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		activeItem,
		data,
		openPDFPreview,
		openUpdateTherapist,
		selectedTab,
		therapistSelectedId,
		handleChangeTabs,
		handleClickActiveCard,
		handleClickActiveReportCard,
		handleConfirmFormPreview,
		handleOpenPDFPreview,
		handleOpenUpdateTherapist,
	} = useLibrary();

	const { updateTherapistStatus } = usePatient();

	const TabPanel = (props: TabPanelProps): JSX.Element => {
		const { value, index, ...other } = props;

		const renderCards = (idx: number): RenderCards => {
			switch (idx) {
				case RESOURCES:
					return {
						cards: (<ResourceCards
							data={data as Array<ResourceCard>}
							activeCard={activeItem?.id}
							onClick={handleClickActiveCard}
						/>),
						preview: activeItem ? <ResourcePreview resource={activeItem as ResourceCard} /> : <></>,
					};
				case FORMS:
					return {
						cards: (
							<DocumentCards
								data={data as Array<DocumentCard>}
								activeCard={activeItem?.id}
								onClick={handleClickActiveCard}
							/>
						),
						preview:
							activeItem ?
								((activeItem as DocumentCard).hasBeenRead !== StatusDocumentCard.IsReady ? (
									<FormProvider>
										<FormPreviewWizard
											handleValidate={handleConfirmFormPreview}
											form={{
												id: (activeItem as DocumentCard).id,
												title: (activeItem as DocumentCard)
													.name,
												description: (
												activeItem as DocumentCard
												).description,
												sections: (
												activeItem as DocumentCard
												).sections,
												showErrors: false,
											}}
										/>
									</FormProvider>
								) : (
									<ReportPreview
										reportPreview={(activeItem as DocumentCard)?.reportDocument || null}
									/>
								))
								:
								undefined,
					};
				case REPORTS:
					return {
						cards: (<>
							<ResourceCards
								data={data as Array<Report>}
								activeCard={activeItem?.id}
								onClick={handleClickActiveReportCard}
							/>
							<PDFPreviewModal
								file={(activeItem as Report)?.file || ''}
								open={openPDFPreview}
								onClose={handleOpenPDFPreview}
							/>
						</>),
					};
				default: return {
					cards: <></>,
				};
			}
		};

		const { cards, preview } = renderCards(index);
		const noReportTab = index !== REPORTS;

		return (
			<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
				{value === index && (
					<Box py={2}>
						<Grid container spacing={4}>
							<Grid item xs={12} md={noReportTab && 6} lg={noReportTab && 4}>
								{cards}
							</Grid>
							{noReportTab &&
								<Grid item xs={12} lg={8}>
									{activeItem && preview}
								</Grid>}
						</Grid>
					</Box>
				)}
			</div>
		);
	};

	return (
		<div className={styles.library}>
			<PrivateTopBar title={t('libraryView:TITLE_LIBRARY')} />
			<div className={styles.container}>
				<Grid container spacing={3}>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={12} sm={6} lg={4}>
							<TherapistChangeCard onChange={handleOpenUpdateTherapist} updateStatus={updateTherapistStatus} />
						</Grid>
					</Grid>
					{
						therapistSelectedId &&
						<Grid item xs={12}>
							<AppBar className={styles.appBar} position="static" variant="outlined">
								<Tabs
									classes={{ root: styles.tabs, indicator: styles.tabsIndicator }}
									value={selectedTab}
									onChange={handleChangeTabs}
									aria-label="simple tabs example"
									TabIndicatorProps={{ children: <span /> }}
								>
									<Tab label={t('libraryView:TAB_RESOURCES')} className={styles.tab} id="simple-tab-0" aria-controls="simple-tabpanel-0" />
									<Tab label={t('libraryView:TAB_FORMS')} className={styles.tab} id="simple-tab-1" aria-controls="simple-tabpanel-1" />
									<Tab label={t('libraryView:TAB_REPORTS')} className={styles.tab} id="simple-tab-2" aria-controls="simple-tabpanel-2" />
								</Tabs>
							</AppBar>
							<TabPanel value={selectedTab} index={RESOURCES} />
							<TabPanel value={selectedTab} index={FORMS} />
							<TabPanel value={selectedTab} index={REPORTS} />
						</Grid>
					}
				</Grid>
			</div>
			<UpdateTherapistModal
				open={openUpdateTherapist}
				onChange={handleOpenUpdateTherapist}
			/>
		</div>
	);
};

export default Library;
