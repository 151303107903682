import { Card, CardContent } from '@material-ui/core';
import Button from '../../Button/Button';
import { Form } from '../../../interfaces/DocumentForm';
import FormPreview from '../FormPreview';
import React from 'react';
import styles from './FormPreviewWizard.module.scss';
import useFormPreview from '../useFormPreview';
import { useTranslation } from 'react-i18next';

interface PreviewModalProps {
	form: Form;
	handleClose?: () => void;
	handleValidate?: () => Promise<void>;
}

const PreviewModal: React.FC<PreviewModalProps> = (props) => {
	const {
		formModel,
		sectionsToShow,
		isFirstStep,
		isLastStep,
		handleBack,
		handleNextButton,
	} = useFormPreview({form: props.form, onConfirm: props.handleValidate, onClose: props.handleClose});
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<Card className={styles.card} elevation={0}>
				<CardContent className={styles.cardContent}>
					<FormPreview
						sectionToShow={sectionsToShow}
						form={formModel}
					/>
				</CardContent>
				<div className={styles.buttons}>
					{
						!isFirstStep() &&
						<Button
							type="SECONDARY"
							title={t('documentationView:MODAL_PREVIEW_BUTTON_BACK')}
							onClick={handleBack}
						/>
					}
					<Button
						type="PRIMARY"
						title={isLastStep() ? t('documentationView:MODAL_PREVIEW_BUTTON_VALIDATE') : t('documentationView:MODAL_PREVIEW_BUTTON_NEXT')}
						onClick={handleNextButton}
					/>
				</div>
			</Card>
		</div>
	);
};

export default PreviewModal;
