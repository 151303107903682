import { ImageOverflowCard } from '../../components';
import React from 'react';
import styles from './TeamSection.module.scss';
import TEAM_IMAGE_1 from '../../assets/images/team_image_1.jpeg';
import TEAM_IMAGE_2 from '../../assets/images/team_image_2.jpeg';
import TEAM_IMAGE_3 from '../../assets/images/team_image_3.jpeg';
import TEAM_IMAGE_4 from '../../assets/images/team_image_4.png';
import { useTranslation } from 'react-i18next';

const TeamSection: React.FC = () => {
	const { t } = useTranslation();

	const teamCards: [string, string, string, string][] = [
		[TEAM_IMAGE_1, t('about:TEAM_NAME_1'), t('about:TEAM_TITLE_1'), t('about:TEAM_DESCRIPTION_1')],
		[TEAM_IMAGE_2, t('about:TEAM_NAME_2'), t('about:TEAM_TITLE_2'), t('about:TEAM_DESCRIPTION_2')],
		[TEAM_IMAGE_3, t('about:TEAM_NAME_3'), t('about:TEAM_TITLE_3'), t('about:TEAM_DESCRIPTION_3')],
		[TEAM_IMAGE_4, t('about:TEAM_NAME_4'), t('about:TEAM_TITLE_4'), t('about:TEAM_DESCRIPTION_4')],
	];

	return (
		<div className={styles.teamBehindSection}>
			<h2 className={styles.teamBehindTitle}>{t('about:MAIN_TEAM_TITLE')}</h2>
			<div className={styles.teamBehindCardsContainer}>
				{
					teamCards.map(([src, title, subtitle, desc]) =>
						(
							<div key={title} className={styles.teamBehindCard}>
								<ImageOverflowCard image={src} title={title} subtitle={subtitle} description={desc} />
							</div>
						))
				}
			</div>
		</div>
	);
};

export default TeamSection;
