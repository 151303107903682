import React from 'react';
import styles from './Card.module.scss';

interface CardProps {
	className?: string;
}
const Card: React.FC<CardProps> = ({ children, className }) => {
	// eslint-disable-next-line @typescript-eslint/no-inferrable-types
	const classnames: string = `${styles.cardContainer} ${className || ''}`;

	return (
		<div className={classnames}>
			{children}
		</div>
	);
};

export default Card;
