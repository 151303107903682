import { Button, LoadingSpinner, NoteModal } from '../..';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NoteDetail from './NoteDetail/NoteDetail';
import React from 'react';
import Steppers from './Steppers/Steppers';
import styles from './NotesCard.module.scss';
import useNotesCard from './useNotesCard';
import { useTranslation } from 'react-i18next';

interface NotesCardProps {
	patientId?: number;
	isPatientEnabled: boolean;
}

const NotesCard: React.FC<NotesCardProps> = ({ patientId, isPatientEnabled }) => {
	const { t } = useTranslation();
	const {
		activeStep,
		currentNote,
		isAssistant,
		isCreateNoteButtonEnabled,
		isLoading,
		noteId,
		notes,
		openNoteModal,
		handleOpenNoteModal,
		handleStep,
		loadData,
		removeNoteById,
	} = useNotesCard(patientId);

	const isNoteCreationEnabled = isCreateNoteButtonEnabled && !isAssistant && isPatientEnabled;

	return (
		isLoading
			? <LoadingSpinner />
			:
			<div className={styles.notes}>
				<Card className={styles.card} variant="outlined">
					<CardContent className={styles.content}>
						<div className={styles.header}>
							<span>{t('patientsView:INFO_TITLE_NOTES_CARD')}</span>
							<Button
								type="PRIMARY"
								startIcon={<EditOutlinedIcon />}
								title={t('patientsView:INFO_CREATE_NOTE_BUTTON')}
								onClick={(): void => handleOpenNoteModal(true)}
								size="large"
								isDisabled={!isNoteCreationEnabled}
							/>
						</div>
						<div className={styles.body}>
							{
								notes.length
									? <>
										<div className={styles.steppers}>
											<Steppers notes={notes} activeStep={activeStep} onClick={handleStep} onClickRemove={removeNoteById} />
										</div>
										<div className={styles.noteDetail}>
											<NoteDetail note={notes[activeStep]} onEditNote={(): void => handleOpenNoteModal(false)} />
										</div>
									</>
									:
									<div className={styles.noData}>{t('messages:NO_DATA')}</div>
							}
						</div>
					</CardContent>
				</Card>
				<NoteModal loadData={loadData} noteId={noteId} patientId={patientId} open={openNoteModal} note={currentNote} onChange={(): void => handleOpenNoteModal(false)} />
			</div>
	);
};

export default NotesCard;
