import { Pagination, PrivateTopBar, Select, TherapistButtons, TitleBox } from '../../../components';
import AddIcon from '@material-ui/icons/Add';
import { Button as ButtonMaterial } from '@material-ui/core';
import CardsTable from '../../../components/CardsTable/CardsTable';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styles from './Tasks.module.scss';
import UpdatePatientModal from '../../../components/Modals/UpdatePatientModal/UpdatePatientModal';
import useTasks from './useTasks';
import { useTranslation } from 'react-i18next';

const Tasks = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		isOpenNewPatientModal,
		isLoading,
		columns,
		patientOptions,
		pendingTask,
		tasks,
		taskTypeOptions,
		handleOpenNewPatientModal,
		handlePatientFilter,
		handleTaskTypeFilter,
	} = useTasks();

	return (
		<div className={styles.tasks}>
			<PrivateTopBar title={t('tasksView:TITLE')} buttons={<TherapistButtons showSetReminders showSearchInput />} />
			<div className={styles.container}>
				<Grid container spacing={3}>
					<Grid container item xs={12} spacing={3} className={styles.header}>
						<Grid item xs={12} sm={6} md={2}>
							<TitleBox type="text">
								<div className={styles.info}>
									<span>{t('tasksView:PENDING_TASKS')}:</span><span>{pendingTask}</span>
								</div>
							</TitleBox>
						</Grid>
						<Grid item xs={12} sm={6} md={2}>
							<Select options={patientOptions} defaultValue={patientOptions[0]?.key} dense fullWidth onChange={(e): void => handlePatientFilter(e?.target?.value as unknown as number)} />
						</Grid>
						<Grid item xs={12} sm={6} md={2}>
							<Select options={taskTypeOptions} dense fullWidth onChange={(e): void => handleTaskTypeFilter(e.target.value as unknown as number)} />
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<ButtonMaterial className={styles.actionButton} color="primary" onClick={handleOpenNewPatientModal} startIcon={<AddIcon />}>
								{t('tasksView:ADD_PATIENT_BUTTON')}
							</ButtonMaterial>
						</Grid>
						<Grid item xs={12} sm={6} md={3} className={styles.pagination}>
							<Pagination from={1} to={tasks.length} total={tasks.length} navigate={(): void => { /* Do nothing */ }} />
						</Grid>
					</Grid>
					<Grid container item xs={12} className={styles.table}>
						<CardsTable data={tasks} columnsDef={columns} isLoading={isLoading} />
					</Grid>
				</Grid>
			</div>
			<UpdatePatientModal
				open={isOpenNewPatientModal}
				onChange={handleOpenNewPatientModal}
				actionType={'ADD'}
			/>
		</div>
	);
};

export default Tasks;
