import { FormContext, FormUpdateContext } from '../../../../contexts/FormContext';
import { Section, SelectQuestion } from '../../../../interfaces/DocumentForm';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SelectPreviewStore {
	question: SelectQuestion;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const useNumberIntegerPreview = (props: QuestionPreviewProps): SelectPreviewStore => {
	const { t } = useTranslation();
	const { formModel, formModel: { showErrors } } = React.useContext(FormContext);
	const { handleUpdateQuestion } = React.useContext(FormUpdateContext);
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = formModel.sections[props.sectionIndex].questions[props.questionIndex] as SelectQuestion;

	const isValueValid = (value: string): boolean => {
		if (!question.required) {
			return true;
		}

		return question.options.some((option) => option.id === value);
	};

	React.useEffect(() => {
		const isValid = isValueValid(question.value);

		setErrorMessage(t('libraryView:FORMS_FIELD_REQUIRED'));

		handleUpdateQuestion({ ...question, isValid }, formModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
		const value = event.target.value as string;
		const isValid: boolean = isValueValid(value);

		setErrorMessage(isValid ? '' : t('libraryView:FORMS_FIELD_REQUIRED'));
		handleUpdateQuestion({ ...question, value, isValid }, formModel.sections[props.sectionIndex] as Section);
	};

	return {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	};
};

export default useNumberIntegerPreview;
