import { PrivateTopBar } from '../../components';
import React from 'react';

const Messages = (): JSX.Element => {

	return (
		<>
			<PrivateTopBar title="Messages" />
			<h1>Messages (Patient)</h1>
		</>
	);
};

export default Messages;
