import axios from 'axios';
import { TherapistNote } from '../interfaces/Note';

const apiUrl = process.env.REACT_APP_API_URL;

export const findAllNotesByPatient = (therapistId: number | string | string, id: number): Promise<{
	rows: Array<TherapistNote>;
}> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/notes?patientId=${id}&sort=date:desc`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findNoteByPatient = (therapistId: number | string, id: number): Promise<TherapistNote> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/notes/${id}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const addNewNote = (therapistId: number | string, id: number, data: TherapistNote): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/therapists/${therapistId}/notes`, {
			...data,
			patient: {
				id,
			},
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateNote = (therapistId: number | string, id: number, data: TherapistNote): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/therapists/${therapistId}/notes/${id}`, {
			text: data.text,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const deleteNote = (therapistId: number | string | string, id: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/therapists/${therapistId}/notes/${id}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
