import { deleteSession } from '../../../services/SessionService';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface DeleteSessionModalStore {
	handleDelete: (sessionId: number | undefined) => void;
	handleClose: () => void;
}

interface DeleteSessionModalModalProps {
	onChange: () => void;
	onDelete: () => void;
}

const useDeleteSessionModalModal = (props: DeleteSessionModalModalProps): DeleteSessionModalStore => {
	const { getTherapistIdParam } = useTherapistSelected();

	const handleClose = (): void => {
		props.onChange();
	};

	const handleDelete = async (sessionId: number | undefined): Promise<void> => {
		if (sessionId) {
			const therapistIdParam = getTherapistIdParam();

			await deleteSession(therapistIdParam, sessionId);
		}

		props.onDelete();
		handleClose();
	};

	return {
		handleDelete,
		handleClose,
	};
};

export default useDeleteSessionModalModal;
