export enum CivilStatus {
	Single = 0,
	Married = 1,
	Divorced = 2,
	Widowed = 3,
}

export enum Sex {
	Female = 0,
	Male = 1,
}

export enum DaysWeek {
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
	SUNDAY = 7,
}

export interface AssistantInfo {
	email?: string;
	familyName?: string;
	givenName?: string;
}

export interface Country {
	id: number;
	name: string;
}

export interface Job {
	id: number;
	name: string;
}

export interface InstituteLogo {
	id: number;
	url: string;
}

export interface Signature {
	id: number;
	url: string;
}

export interface Avatar {
	id: number;
	url: string;
}

export interface ClinicalSituation {
	id: number;
	name: string;
}

export interface TreatedPatientAge {
	id: number;
	name: string;
}

export interface Location {
	id?: number;
	address: string;
}
