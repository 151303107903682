import { acceptTherapistRequest, getProfile, getTherapistRequests, rejectTherapistRequest, removeTherapist } from '../../../services/AssistantService';
import { AssistantTherapist, AssistantTherapistRequest } from '../../../interfaces/Assistant';
import { ConfirmationModalInterface, ConfirmationTypes } from '../../../interfaces/ConfirmationModels';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Options } from '../../../components/Cards/TherapistProfileCard/TherapistProfileCard';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../helpers/useRoutes';
import { useSessionStorage } from '../../../hooks/useSessionStorage';

interface UseHomeStore {
	menuOptions: Options[];
	therapists: AssistantTherapist[];
	therapistRequests: AssistantTherapistRequest[];
	openConfirmationModal: boolean;
	confirmationModalState: ConfirmationModalInterface;
	handleAcceptTherapist: (therapistId: number) => Promise<void>;
	handleRedirect: (therapistId: number) => void;
	handleConfirmationModal: (confirmationType: ConfirmationTypes, therapistId: number) => void;
}

export const useHome = (): UseHomeStore => {
	const history = useHistory();
	const { assistantRoutes, therapistRoutes } = useRoutes();
	const { setItem } = useSessionStorage('selected_therapist_id_by_assistant');
	const [therapists, setTherapists] = React.useState<AssistantTherapist[]>([]);
	const [therapistRequests, setTherapistRequests] = React.useState<AssistantTherapistRequest[]>([]);
	const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);
	const [confirmationModalState, setConfirmationModalState] = React.useState<ConfirmationModalInterface>({} as ConfirmationModalInterface);

	const handleRedirect = (therapistId: number): void => {
		setItem(therapistId.toString());
		history.push(`/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistId}/${assistantRoutes.AGENDA}`);
	};

	const loadData = async(): Promise<void> => {
		const assistant = await getProfile();
		let therapistList: AssistantTherapist[] = [];

		if (assistant && assistant?.therapists?.length) {
			therapistList = therapistList.concat(assistant.therapists);
		}

		setTherapists(therapistList);

		const therapistRequests = await getTherapistRequests();

		setTherapistRequests(therapistRequests);
	};

	const handleCloseConfirmationModal = (): void => {
		setOpenConfirmationModal(false);
	};

	const handleAcceptTherapist = async(therapistId: number): Promise<void> => {
		await acceptTherapistRequest(therapistId);
		await loadData();
	};

	const handleRejectTherapist = async(therapistId: number): Promise<void> => {
		await rejectTherapistRequest(therapistId);
		handleCloseConfirmationModal();
		await loadData();
	};

	const handleDeleteTherapist = async(therapistId: number): Promise<void> => {
		await removeTherapist(therapistId);
		handleCloseConfirmationModal();
		await loadData();
	};

	const handleConfirmationModal = (confirmationType: ConfirmationTypes, therapistId: number): void => {
		switch (confirmationType) {
			case ConfirmationTypes.cancelTherapistInvitation:
				setConfirmationModalState({
					content: 'assistant:CANCEL_THERAPIST_CONFIRMATION_INVITATION',
					title: 'assistant:WARNING_MEESSAGE',
					onClose: handleCloseConfirmationModal,
					onConfirm: async(): Promise<void> => await handleRejectTherapist(therapistId),
				});
				setOpenConfirmationModal(true);

				break;
			case ConfirmationTypes.deleteTherapist:
				setConfirmationModalState({
					content: 'assistant:DELETE_THERAPIST_CONFIRMATION_MESSAGE',
					title: 'assistant:WARNING_MEESSAGE',
					onClose: handleCloseConfirmationModal,
					onConfirm: async(): Promise<void> => await handleDeleteTherapist(therapistId),
				});
				setOpenConfirmationModal(true);
				break;
			default:
				break;
		}
	};

	const menuOptions: Options[] = [
		{
			// onClick: async(therapistId): Promise<void> => await handleDeleteTherapist(therapistId),
			onClick: (therapistId): void => handleConfirmationModal(ConfirmationTypes.deleteTherapist, therapistId),
			title: 'assistant:DELETE_THERAPIST',
			icon: <DeleteOutlineIcon />,
			secondaryColor: true,
		},
	];

	React.useEffect(() => {
		loadData();
	}, []);

	return {
		menuOptions,
		therapists,
		therapistRequests,
		openConfirmationModal,
		confirmationModalState,
		handleAcceptTherapist,
		handleRedirect,
		handleConfirmationModal,
	};
};
