import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import React from 'react';
import styles from './TherapistChangeCard.module.scss';
import { TherapistContext } from '../../../contexts/TherapistContext';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface TherapistChangeCardProps {
	onChange: () => void;
	updateStatus: (therapistId: number) => Promise<void>;
}

const TherapistChangeCard: React.FC<TherapistChangeCardProps> = ({ onChange, updateStatus }): JSX.Element => {
	const { therapistSelected } = React.useContext(TherapistContext);
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<Card className={styles.card} variant="outlined">
				<CardContent className={styles.cardContent}>
					<div className={styles.cardContentInfo}>
						<Avatar className={styles.cardContentInfoAvatar} alt="profile" src={therapistSelected.avatar} /><span>{therapistSelected.id ? `${therapistSelected.name} ${therapistSelected.lastName}` : t('patientHomeView:TITLE_NO_THERAPIST_SELECTED')}</span>
					</div>
					<div className={styles.cardContentButton}>
						<Button
							size='small'
							endIcon={therapistSelected.enabled ? <VisibilityOffIcon/> : <VisibilityIcon/>}
							onClick={(): Promise<void> => updateStatus(therapistSelected.id)} >
							{therapistSelected.enabled ? t('patientHomeView:BUTTON_DISABLED') : t('patientHomeView:BUTTON_ENABLED')}
						</Button>
						<Button size="small" endIcon={<LoopOutlinedIcon />} onClick={onChange}>
							{t('patientHomeView:BUTTON_CHANGE')}
						</Button>
					</div>
				</CardContent>
			</Card>
		</div>
	);
};

export default TherapistChangeCard;
