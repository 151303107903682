
export default {
	es: {
		signup: {
			SIGNUP_BUTTON: 'Registrarse',
			SIGNUP_LABEL_GIVEN_NAME: 'Nombre',
			SIGNUP_LABEL_FAMILY_NAME: 'Apellido',
			SIGNUP_MESSAGE: 'Registrarse para acceder al Dashboard',
		},
	},
	en: {
		signup: {
			SIGNUP_BUTTON: 'Sign up',
			SIGNUP_LABEL_GIVEN_NAME: 'Given name',
			SIGNUP_LABEL_FAMILY_NAME: 'Family name',
			SIGNUP_MESSAGE: 'Sign up to access the Dashboard',
		},
	},
};
