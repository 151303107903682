import React, { useEffect } from 'react';
import { TherapistContext, UpdateTherapistContext } from '../../contexts/TherapistContext';
import { AuthContext } from '../../contexts/AuthContext';
import { isBadge } from '../../services/TherapistService';
import { SideBar } from '../../components';
import { UserRole } from '../../interfaces/User';
import { useTherapistSelected } from '../../hooks/useTherapistSelected';

const PrivateLayout: React.FC = ({ children }) => {
	const { user } = React.useContext(AuthContext);
	const { badge } = React.useContext(TherapistContext);
	const { setBadge } = React.useContext(UpdateTherapistContext);
	const { getTherapistIdParam } = useTherapistSelected();

	const getBadge = React.useCallback(async (): Promise<void> => {
		if (user?.roles[0] === UserRole.THERAPIST || user?.roles[0] === UserRole.ASSISTANT) {
			const therapistIdParam = getTherapistIdParam();
			const newBadge = await isBadge(therapistIdParam);

			if (newBadge !== badge) {
				setBadge(newBadge);
			}
		}
	}, [badge, getTherapistIdParam, setBadge, user]);

	useEffect(() => {
		getBadge();
	}, [getBadge]);

	return (
		<SideBar>
			{ children }
		</SideBar>
	);
};

export default PrivateLayout;
