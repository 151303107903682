import axios from 'axios';
import { Document } from '../interfaces/Document';
import { Tag } from '../interfaces/Tag';

const apiUrl = process.env.REACT_APP_API_URL;

export const findAllMyDocuments = (therapistId: number | string): Promise<Array<Document>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/therapists/${therapistId}/documents`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findAllDocumentsTags = (): Promise<Array<Tag>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/documents/tags`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

