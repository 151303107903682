import Avatar from '@material-ui/core/Avatar';
import CallIcon from '@material-ui/icons/Call';
import React from 'react';
import styles from './ModalBottomBar.module.scss';
import Switch from '@material-ui/core/Switch';

export interface ModalBottomBarProps {
	iconName: string;
	isChecked: boolean;
	name: string;
	primaryOption: string;
	secondaryOption: string;
	handleInputChange: (event: React.ChangeEvent<{ value: unknown; name: string }>) => void;
}

const ModalBottomBar: React.FC<ModalBottomBarProps> = ({ iconName, isChecked, name, primaryOption, secondaryOption, handleInputChange }) => (
	<div className={styles.ModalBottomBar}>
		<Avatar className={styles.avatar}>
			<CallIcon className={styles.icon} />
		</Avatar>
		<span className={styles.text}>{iconName}</span>
		<span className={styles.text}>{primaryOption}</span>
		<Switch checked={isChecked} name={name} onChange={handleInputChange}/>
		<span className={styles.text}>{secondaryOption}</span>
	</div>
);

export default ModalBottomBar;
