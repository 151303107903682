import { FormContext, FormUpdateContext } from '../../../../contexts/FormContext';
import { NumberIntegerQuestion, Section } from '../../../../interfaces/DocumentForm';
import { isIntegerNumber } from '../../../../helpers/Validations';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NumberIntegerPreviewStore {
	question: NumberIntegerQuestion;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useNumberIntegerPreview = (props: QuestionPreviewProps): NumberIntegerPreviewStore => {
	const { t } = useTranslation();
	const { formModel, formModel: { showErrors } } = React.useContext(FormContext);
	const { handleUpdateQuestion } = React.useContext(FormUpdateContext);
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = formModel.sections[props.sectionIndex].questions[props.questionIndex] as NumberIntegerQuestion;

	const isValueValid = (val: string): boolean => {
		if (val.length) {
			const value = +val;

			if (typeof value === 'number' && !Number.isNaN(value)) {
				const { min, max } = question;
				const belongsRange = +value >= min && +value <= max;

				setErrorMessage(belongsRange ? '' : `${t('formsView:MODAL_PREVIEW_NUMERIC_VALIDATION')}: ${min} - ${max}`);

				return belongsRange;
			}
		}

		setErrorMessage(question.required ? t('formsView:MODAL_PREVIEW_FIELD_REQUIRED') : '' );

		return false;
	};

	React.useEffect(() => {
		const isValid = isValueValid(question.value.toString());

		setErrorMessage(t('libraryView:FORMS_FIELD_REQUIRED'));

		handleUpdateQuestion({ ...question, isValid }, formModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const val = event.target.value;

		if (!isIntegerNumber(val)) {
			return;
		}

		const isValid = isValueValid(val);

		handleUpdateQuestion({ ...question, value: val, isValid: isValid }, formModel.sections[props.sectionIndex] as Section);
	};

	return {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	};
};

export default useNumberIntegerPreview;
