import { ReportContext, ReportUpdateContext } from '../../../../../../../contexts/ReportContext';
import { AlertContext } from '../../../../../../../contexts/AlertContext';
import { getNotesByPatient } from '../../../../../../../services/NoteService';
import { Note } from '../../../../../../../interfaces/Note';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTherapistSelected } from '../../../../../../../hooks/useTherapistSelected';

interface NotesStore {
	notes: Note[];
	activeStep: number;
	handleStep: (value: number) => void;
	isLoading: boolean;
	handleAddNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	selectAll: boolean;
}

const useNotes = (): NotesStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const { report, notesInfo } = React.useContext(ReportContext);
	const { handleChangeNotesInfo, addNote, setReport } = React.useContext(ReportUpdateContext);
	const [activeStep, setActiveStep] = React.useState<number>(0);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectAll, setSelectAll] = React.useState<boolean>(false);
	const { id: patientId } = useParams<{ id: string }>();
	const { getTherapistIdParam } = useTherapistSelected();

	React.useEffect(() => {
		setSelectAll(report.notes.length === notesInfo.length && report.notes.every(item => item.checked));
	}, [notesInfo.length, report.notes]);

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const data = await getNotesByPatient(therapistIdParam, +patientId);
			const notes = data;

			handleChangeNotesInfo(data);

			if(data !== report.notes) {
				notes.forEach(item => item.checked = false);
				setReport({ ...report, notes });
			}
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	React.useEffect(() => {
		loadData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStep = (value: number): void => {
		setActiveStep(value);
	};

	const handleAddNote = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, checked } = event.target;

		if(name.includes('selectAll')) {
			setSelectAll(prevState => !prevState);

			notesInfo.forEach(item => {
				addNote((item.id as number).toString(), checked);
			});
		} else {
			addNote(name, checked);
			setSelectAll(report.notes.length === notesInfo.length && report.notes.every(item => item.checked));
		}
	};

	return {
		notes: notesInfo,
		activeStep,
		handleStep,
		isLoading,
		handleAddNote,
		selectAll,
	};
};

export default useNotes;
