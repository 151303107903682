import { Button, PrivateTopBar, ReportConfirmationModal } from '../../../../../../components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button as MaterialButton } from '@material-ui/core';
import React from 'react';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import styles from './CreateReport.module.scss';
import Typography from '@material-ui/core/Typography';
import useCreateReport from './useCreateReport';
import { useTranslation } from 'react-i18next';

const CreateReport = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		steps,
		activeStep,
		completed,
		isConfirmButtonEnabled,
		openConfirmationModal,
		isLastStep,
		allStepsCompleted,
		handleNext,
		handleBack,
		back,
		handleComplete,
		handleReset,
		backToReportsList,
		handleReportConfirmationModal,
	} = useCreateReport();

	const Component = steps[activeStep].component;

	return (
		<div className={styles.createReport}>
			<PrivateTopBar title={t('patientsView:REPORTS_CREATE_BUTTON')} />
			<div className={styles.container}>
				<MaterialButton
					className={styles.actionButton}
					startIcon={<ArrowBackIcon />}
					color="primary"
					onClick={backToReportsList}
				>
					{t('patientsView:REPORTS_BACK_TO_PROFILE_BUTTON')}
				</MaterialButton>
				<Stepper
					className={styles.stepper}
					nonLinear
					activeStep={activeStep}
				>
					{Object.values(steps).map((step) => (
						<Step key={step.index} className={styles.step} disabled>
							<StepButton
								className={styles.stepButton}
								completed={completed[step.index]}
							>
								<span className={styles.stepButtonTitle}>
									{t(step.title)}
								</span>
							</StepButton>
						</Step>
					))}
				</Stepper>
				<div className={styles.content}>
					{allStepsCompleted() ? (
						<div>
							<Typography>
								All steps completed - you&apos;re finished
							</Typography>
							<MaterialButton onClick={handleReset}>
								Reset
							</MaterialButton>
						</div>
					) : (
						<>
							{<Component />}
							<div className={styles.footer}>
								{activeStep !== 0 && (
									<Button
										title={t(
											'patientsView:REPORTS_BACK_BUTTON'
										)}
										type="SECONDARY"
										onClick={handleBack}
									/>
								)}
								{!isLastStep() && (
									<Button
										title={t(
											'patientsView:REPORTS_NEXT_BUTTON'
										)}
										variant="contained"
										onClick={handleNext}
									/>
								)}
								{isLastStep() && (
									<Button
										title={t(
											'patientsView:REPORTS_CONFIRM_BUTTON'
										)}
										variant="contained"
										onClick={handleComplete}
										isDisabled={!isConfirmButtonEnabled()}
									/>
								)}
							</div>
						</>
					)}
				</div>
			</div>
			<ReportConfirmationModal
				open={openConfirmationModal}
				onChange={handleReportConfirmationModal}
				onConfirm={back}
			/>
		</div>
	);
};

export default CreateReport;
