import { InviteTherapistModalProps, useInviteTherapistModal } from './useInviteTherapistModal';
import InputText from '../../Inputs/InputText/InputText';
import Modal from '../../Modal/Modal';
import React from 'react';
import styles from './inviteTherapistModal.module.scss';
import { UserFilterListCard } from '../../Cards/UserFilterListCard/UserFilterListCard';
import { useTranslation } from 'react-i18next';

const InviteTherapistModal: React.FC<InviteTherapistModalProps> = (props) => {
	const { t } = useTranslation();
	const {
		formData,
		therapists,
		handleChangeTextField,
		handleSave,
	} = useInviteTherapistModal(props);

	return (
		<Modal
			open={props.open}
			title={t('institution:MODAL_INVITE_THERAPIST_TITLE')}
			handleOnClose={props.onClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('institution:MODAL_BUTTON_CONFIRM'),
				onClick: handleSave,
				isDisabled: !(formData['name']?.value?.length > 0 && formData['lastName']?.value?.length > 0 && formData['email']?.value?.length > 0 ),
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('institution:MODAL_BUTTON_CANCEL'),
				onClick: props.onClose,
			}}
			size={'md'}
			scroll='paper'
		>
			<div className={styles.content}>
				<div className={styles.therapistList}>
					<UserFilterListCard userSelector={{users: therapists || [], height: {height: '240px'}}} fullWidth />
				</div>
				<div className={styles.footer}>
					<div className={styles.footerText}>
						{t('institution:MODAL_FOOTER')}
					</div>
					<InputText
						value={formData['name'].value}
						name="name"
						label={t('invitations:LABEL_NAME_INVITATION')}
						fullWidth
						onChange={handleChangeTextField}
						error={formData['name'].error}
						helperText={formData['name']?.showError && t(`invitations:${formData['name']?.errorMessage}`)}
						variant='outlined'
					/>
					<InputText
						value={formData['lastName'].value}
						name="lastName"
						label={t('invitations:LABEL_LAST_NAME_INVITATION')}
						fullWidth
						onChange={handleChangeTextField}
						error={formData['lastName'].error}
						helperText={formData['lastName']?.showError && t(`invitations:${formData['lastName']?.errorMessage}`)}
						variant='outlined'
					/>
					<InputText
						value={formData['email'].value}
						name="email"
						label={t('invitations:LABEL_EMAIL_INVITATION')}
						fullWidth
						onChange={handleChangeTextField}
						error={formData['email']?.error}
						helperText={formData['email']?.showError && t(`invitations:${formData['email']?.errorMessage}`)}
						variant='outlined'
					/>
				</div>
			</div>
		</Modal>
	);
};

export default InviteTherapistModal;
