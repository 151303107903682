export default {
	es: {
		agendaView: {
			TITLE: 'Agenda',
			MODAL_BUTTON_AVAILABILITY: 'Consultar disponibilidad',
			ADD_PATIENT_TAB: 'Agregar paciente',
			SELECT_PATIENT_TAB: 'Seleccionar paciente',
			ADD_PATIENT_BUTTON: 'Agregar nuevo paciente',
			SESSION_TYPE_TAB: 'Tipo de sesión',
			SELECT_SESSION_TAB: 'Seleccionar tipo sesión',
			ADD_SESSION_TYPE_BUTTON: 'Agregar tipo de sesión',
			PLACE_TAB: 'Lugar',
			SELECT_PLACE_TAB: 'Seleccionar lugar',
			ADD_PLACE_BUTTON: 'Agregar lugar',
			MODAL_NEW_PLACE: 'Nuevo Lugar',
			PLACE_ALREADY_EXIST: 'El lugar ya existe',
			DATE_TAB: 'Fecha',
			FREQUENCY_TAB: 'Frecuencia',
			SELECT_FREQUENCY_TAB: 'Seleccionar Frecuencia',
			TIME_ZONE_TAB: 'Zona horaria',
			TELEHEALTH: 'Telesalud',
			CONNECTOR_TAB: 'a',
			SESSIONS_THIS_WEEK_BOX: 'Sesiones esta semana',
			TYPES_OF_SESSIONS: 'Tipos de sesiones',
			ADD_SESSION_TYPE: 'Agregar tipo de sesión',
			TODAY_BUTTON: 'Hoy',
			MODAL_NEW_SESSION_TYPE: 'Nuevo Tipo de Sesión',
			SESSION_TYPE_ALREADY_EXIST: 'El tipo de sesión ya existe',
			TITLE_DELETE_SESSION_MODAL: 'Eliminar sesión',
			CONTENT_DELETE_SESSION_MODAL: '¿Desea eliminar la sesión seleccionada?',
			DELETE_ALL_BUTTON_DELETE_SESSION_MODAL: 'Eliminar todas',
			DELETE_ONE_BUTTON_DELETE_SESSION_MODAL: 'Eliminar actual',
			CONFIRM_BUTTON_PREVIEW_MODAL: 'Confirmar',
			REJECT_BUTTON_PREVIEW_MODAL: 'Rechazar',
			EDIT_SESSION_BUTTON_PREVIEW_MODAL: 'Editar sesión',
			DELETE_SESSION_BUTTON_PREVIEW_MODAL: 'Eliminar sesión',
			SESSION_ENDS: 'La session finaliza a las',
			ONE_TIME: 'Unica vez',
			DAILY: 'Diariamente',
			WEEKLY: 'Semanalmente',
			MONTHLY: 'Mensualmente',
			ADD_END_DATE: 'Agregar fecha límite',
			SELECT_END_DATE: 'Seleccionar fecha límite',
			TIME_SELECTED: 'Horario seleccionado',
			PENDING_SESSION: 'Sesión pendiente de aprobación',
		},
		availabilityView: {
			TITLE: 'Disponibilidad',
			TOP_LABEL:
				'Los pacientes solo podrán pedir sesiones durante los tiempos definidos acontinuación.',
			WORK_HOURS: 'Horarios de trabajo',
			START_TIME: 'Horario de inicio',
			END_TIME: 'Horario de finalización',
			REPEAT_DAYS: 'Repetir los días',
			ADD_DIFFERENT_HOURS: 'Agregar horarios diferentes para otros días',
			SESSIONS: 'Sesiones',
			CANCEL_SESSIONS: 'Cancelación de sesiones',
			SESSION_DURATION: 'Duración de la sesión',
			TIME_BETWEEN_SESSIONS: 'Tiempo entre sesiones',
			PERSONALIZE: 'Personalizado',
			CANCEL_SENTENCE:
				'El paciente puede cancelar una cita con una anticipación de',
			CANCEL_PERIODS: '1 Día,2 Días,3 Días',
			DURATION_PERIODS: '15 min,30 min,45 min,60 min',
			DAYS: 'Lunes,Martes,Miércoles,Jueves,Viernes,Sábado,Domingo',
			DAY_LABEL: 'Día',
			DAYS_LABEL: 'Días',
			MINUTES: 'min',
			MONDAY: 'Lunes',
			THURSDAY: 'Martes',
			WEDNESDAY: 'Miércoles',
			TUESDAY: 'Jueves',
			FRIDAY: 'Viernes',
			SATURDAY: 'Sábado',
			SUNDAY: 'Domingo',
		},
		invitations: {
			BUTTON_CANCEL_INVITATION: 'Cancelar invitación',
			BUTTON_DELETE_ASSISTANT: 'Eliminar asistente',
			BUTTON_SEND_INVITATION: 'Enviar invitación',
			EMAIL_INVALID_FORMAT: 'Formato incorrecto',
			FIELD_REQUIRED: 'Campo obligatorio',
			INVITATION_SENT: 'Invitación enviada a: {{email}}',
			LABEL_EMAIL_INVITATION: 'Email',
			LABEL_LAST_NAME_INVITATION: 'Apellido',
			LABEL_NAME_INVITATION: 'Nombre',
			TITLE_ASSISTANT_INFORMATION: 'INFORMACIÓN DE ASISTENTE',
			TITLE_INVITATE_ASSISTANT: 'INVITAR ASISTENTE',
			WARNING_MEESSAGE: 'Advertencia',
			DELETE_ASSISTANT_CONFIRMATION_MESSAGE: '¿Desea eliminar el asistente?',
			CANCEL_ASSISTANT_INVITATION: '¿Desea cancelar la invitación?',
			MODAL_CONFIRM_BUTTON: 'Confirmar',
			MODAL_CANCEL_BUTTON: 'Cancelar',
		},
		tasksView: {
			TITLE: 'Tareas',
			PENDING_TASKS: 'Tareas pendientes',
			ADD_PATIENT_BUTTON: 'Agregar paciente',
			UPDATE_PATIENT_BUTTON: 'Editar paciente',
		},
		reminders: {
			CONFIRM_SESSION: 'Hay una sesión sin confirmar para el paciente: {{patientName}}',
			FILL_MY_OWN_DATA: 'Hay datos sin cargar',
			FILL_PATIENT_DATA: 'Hay datos sin cargar para el paciente: {{patientName}} ',
			ASSIGN_RESOURCE: 'Puedes asignar un recurso a un paciente',
			FILL_SESSION_DATA: 'Hay datos de session sin llenar para el paciente: {{patientName}}',
			REQUEST_TO_JOIN_INSTITUTION: 'Hay una invitacion de la institucion: {{institutionName}}',
		},
		reminderType: {
			CONFIRM_SESSION: 'Confirmar sesión',
			FILL_MY_OWN_DATA: 'Completar mis datos',
			FILL_PATIENT_DATA: 'Completar datos del paciente',
			ASSIGN_RESOURCE: 'Asignar recurso',
			FILL_SESSION_DATA: 'Completar datos de sesión',
			REQUEST_TO_JOIN_INSTITUTION: 'Aceptar/declinar institución',
		},
		patientsView: {
			TITLE: 'Pacientes',
			PATIENT_TAB_INFO: 'Datos del paciente',
			PATIENT_TAB_DOCUMENTATION: 'Documentación',
			PATIENT_TAB_RESOURCES: 'Recursos',
			PATIENT_TAB_REPORTS: 'Reportes',
			PATIENT_INFO: 'Información del Paciente',
			INFO_SEND_MESSAGE: 'Enviar mensaje',
			INFO_CREATE_NOTE_BUTTON: 'Crear nota',
			INFO_TITLE_NOTES_CARD: 'Ruta de sesiones y notas',
			INVITATION_RESENT_SUCCESSFULLY: 'Invitación reenviada con éxito',
			DOCUMENTATION_LINKED_DOCUMENTATION: 'Documentación vinculada',
			DOCUMENTATION_DATE: 'Fecha',
			DOCUMENTATION_STATUS: 'Estado',
			DOCUMENTATION_COMPLETED_BY: 'Completado por',
			DOCUMENTATION_ACTIONS: 'Acciones',
			MODAL_EDIT_PATIENT: 'Editar Paciente',
			MODAL_ADD_PATIENT: 'Agregar Paciente',
			MODAL_BUTTON_CONFIRM: 'Guardar',
			MODAL_BUTTON_CANCEL: 'Cancelar',
			MODAL_NAME_FIELD: 'Nombre',
			MODAL_LASTNAME_FIELD: 'Apellido',
			MODAL_EMAIL_FIELD: 'E-mail',
			MODAL_FOOTER:
				'Al guardar se enviará un mail al paciente para que termine de completar su perfil y la invitación a formar parte del portal.',
			MODAL_CREATE_NOTE: 'Crear nota',
			REPORTS_DATE: 'Fecha',
			REPORTS_ACTIONS: 'Acciones',
			REPORTS_PREVIOUS: 'Reportes anteriores',
			REPORTS_CREATE_BUTTON: 'Crear reporte',
			REPORTS_BACK_BUTTON: 'Volver',
			REPORTS_BACK_TO_PROFILE_BUTTON: 'Volver al perfil del paciente',
			REPORTS_NEXT_BUTTON: 'Siguiente',
			REPORTS_CONFIRM_BUTTON: 'Confirmar',
			REPORTS_INFO_STEP: 'información del paciente',
			REPORTS_DOCUMENTATION_STEP: 'documentación',
			REPORTS_NOTES_STEP: 'notas',
			REPORTS_CONFIRM_ORDER_STEP: 'confirmar orden',
			REPORTS_PREVIEW_STEP: 'vista previa',
			REPORTS_INFO_PATIENT_CARD: 'Información del Paciente',
			REPORTS_INFO_PROFESSIONAL_CARD: 'Información del Profesional',
			REPORTS_INFO_CARD_SUBTITLE: 'Seleccionar la información que desea agregar al reporte',
			REPORTS_DOCUMENTATION_TITLE: 'Documentación',
			REPORTS_DOCUMENTATION_SUBTITLE: 'Seleccionar los formularios que desea agregar al reporte',
			REPORTS_NOTES_TITLE: 'Notas',
			REPORTS_NOTES_SUBTITLE: 'Seleccionar todas las notas que desea agregar al reporte',
			REPORTS_NOTES_SELECT_BY_PERIOD: 'Seleccionar por período',
			REPORTS_NOTES_FILTER_FROM: 'Desde',
			REPORTS_NOTES_FILTER_TO: 'hasta',
			REPORTS_SELECT_ALL: 'Seleccionar todos',
			REPORTS_APPLY_BUTTON: 'Aplicar',
			REPORTS_CONFIRMATION_TITLE: 'Confirmar orden',
			REPORTS_CONFIRMATION_SUBTITLE: 'Ordena y confirma el orden de aparición de los documentos y notas seleccionados previamente',
			REPORTS_CONFIRMATION_MODAL_TITLE: '¿Está seguro que desea volver atrás?',
			REPORTS_CONFIRMATION_MODAL_SUBTITLE: 'La información editada en esta instancia se perderá si vuelve atrás.',
			REPORTS_CONFIRMATION_MODAL_CONFIRM_BUTTON: 'Sí, volver atrás',
			REPORTS_CONFIRMATION_MODAL_CANCEL_BUTTON: 'No, continuar editando',
			REPORTS_PREVIEW_TITLE: 'Vista previa',
			REPORTS_PREVIEW_SUBTITLE: 'Revisa y confirma tu reporte final',
			REPORTS_PREVIEW_WARNING: 'La información editada en esta instancia se perderá si vuelve a los pasos anteriores.',
			REPORTS_PREVIEW_TEXT_PLACEHOLDER: 'Título del informe',
			REPORTS_PREVIEW_REPORT_DATE: 'fecha del reporte',
			REPORTS_PREVIEW_PATIENT_INFO: 'Información del paciente',
			REPORTS_PREVIEW_PROFESSIONAL_INFO: 'Información del profesional',
			RESOURCES_LINKED_RESOURCE: 'Recurso vinculado',
			SHARED: 'Compartido',
			YES_AVATAR_LABEL: 'SI',
			NO_AVATAR_LABEL: 'NO',
			DELETE_TOOLTIP: 'Eliminar',
			SHARE_TOOLTIP: 'Compartir',
			PREVIEW_TOOLTIP: 'Vista Previa',
			UNLINK_RESOURCE: 'Desvincular recurso',
			UNLINK_RESOURCE_MODAL_CONTENT: '¿Desea desvincular el recurso del paciente?',
			CLOSE_BUTTON: 'Cerrar',
			SHARE_RESOURCE: 'Compartir recurso',
			SHARE_RESOURCE_MODAL_CONTENT: '¿Está seguro que desea compartir el recurso? No podra volver atras luego de confirmar',
			UNLINK_DOCUMENTATION: 'Desvincular documentación',
			UNLINK_DOCUMENTATION_MODAL_CONTENT: '¿Desea desvincular la documentación del paciente?',
			SHARE_DOCUMENTATION: 'Compartir documentación',
			SHARE_DOCUMENTATION_MODAL_CONTENT: '¿Está seguro que desea compartir la documentación? No podra volver atras luego de confirmar',
			PATIENTS_TABLE_PATIENT_FAMILY_NAME: 'Apellido',
			PATIENTS_TABLE_PATIENT_GIVEN_NAME: 'Nombre',
			PATIENTS_TABLE_LAST_SESSION: 'Última Sesión',
			PATIENTS_TABLE_NEXT_SESSION: 'Próxima Sesión',
			PATIENTS_TABLE_ACTIONS: 'Acciones',
			RESEND_TOOLTIP: 'Reenviar invitación',
			PREVIOUS: 'Anterior',
			COMPLETED: 'Completado',
			UNCOMPLETED: 'Incompleto',
			FORM_PREVIEW_TITLE: 'Vista del Formulario',
		},
		resourceLibraryView: {
			TITLE: 'Biblioteca de recursos',
			PROBLEMATIC_CARD_HEADER: 'Problemática',
			RESOURCE_CARD_HEADER: 'Nombre de recurso',
			PREVIEW_CARD_HEADER: 'Vista previa',
			BUTTON_LINK: 'Vincular',
			MODAL_TITLE_LINK_PATIENTS: 'Vincular pacientes',
			MODAL_BUTTON_CONFIRM_LINK_PATIENTS: 'Vincular',
			MODAL_BUTTON_CANCEL_LINK_PATIENTS: 'Cancelar',
		},
		documentationView: {
			TITLE: 'Documentación',
			PROBLEMATIC_CARD_HEADER: 'Problemática',
			DOCUMENTS_CARD_HEADER: 'Documentos',
			BUTTON_LINK: 'Vincular',
			FORM_PREVIEW_TITLE: 'Vista del Formulario',
			MODAL_PREVIEW_BUTTON_VALIDATE: 'Guardar',
			MODAL_PREVIEW_BUTTON_NEXT: 'Siguiente',
			MODAL_PREVIEW_BUTTON_BACK: 'Volver',
		},
	},
	en: {
		agendaView: {
			TITLE: 'Agenda',
			MODAL_BUTTON_AVAILABILITY: 'Check availability',
			ADD_PATIENT_TAB: 'Add patient',
			SELECT_PATIENT_TAB: 'Select patient',
			ADD_PATIENT_BUTTON: 'Add new patient',
			SESSION_TYPE_TAB: 'Session type',
			SELECT_SESSION_TAB: 'Select session type',
			ADD_SESSION_TYPE_BUTTON: 'Add session type',
			PLACE_TAB: 'Place',
			SELECT_PLACE_TAB: 'Select place',
			ADD_PLACE_BUTTON: 'Add place',
			MODAL_NEW_PLACE: 'New Place',
			PLACE_ALREADY_EXIST: 'The place already exists',
			DATE_TAB: 'Date',
			FREQUENCY_TAB: 'Frequency',
			SELECT_FREQUENCY_TAB: 'Select Frequency',
			TIME_ZONE_TAB: 'Time zone',
			TELEHEALTH: 'Telehealth',
			CONNECTOR_TAB: 'to',
			SESSIONS_THIS_WEEK_BOX: 'Sessions this week',
			TYPES_OF_SESSIONS: 'Types of sessions',
			ADD_SESSION_TYPE: 'Add session type',
			TODAY_BUTTON: 'Today',
			MODAL_NEW_SESSION_TYPE: 'New Type Of Session',
			SESSION_TYPE_ALREADY_EXIST: 'Session type already exists',
			TITLE_DELETE_SESSION_MODAL: 'Delete session',
			CONTENT_DELETE_SESSION_MODAL: 'Do you want to delete the session selected?',
			DELETE_ALL_BUTTON_DELETE_SESSION_MODAL: 'Delete all',
			DELETE_ONE_BUTTON_DELETE_SESSION_MODAL: 'Delete current',
			CONFIRM_BUTTON_PREVIEW_MODAL: 'Confirm',
			REJECT_BUTTON_PREVIEW_MODAL: 'Reject',
			EDIT_SESSION_BUTTON_PREVIEW_MODAL: 'Edit session',
			DELETE_SESSION_BUTTON_PREVIEW_MODAL: 'Delete session',
			SESSION_ENDS: 'Session ends at',
			ONE_TIME: 'One time',
			DAILY: 'Daily',
			WEEKLY: 'Weekly',
			MONTHLY: 'Monthly',
			ADD_END_DATE: 'Add limit date',
			SELECT_END_DATE: 'Select limit date',
			TIME_SELECTED: 'Time selected',
			PENDING_SESSION: 'Session pending approval',
		},
		availabilityView: {
			TITLE: 'Availability',
			TOP_LABEL:
				'Patients can only request sessions during the times defined below',
			WORK_HOURS: 'Work schedules',
			START_TIME: 'Start time',
			END_TIME: 'End Time',
			REPEAT_DAYS: 'Repeat days',
			ADD_DIFFERENT_HOURS: 'Add different hours for other days',
			SESSIONS: 'Sessions',
			CANCEL_SESSIONS: 'Session cancellation',
			SESSION_DURATION: 'Session duration',
			TIME_BETWEEN_SESSIONS: 'Time between sessions',
			PERSONALIZE: 'Personalized',
			CANCEL_SENTENCE:
				'Patient can cancel a meeting with an anticipation of',
			CANCEL_PERIODS: '1 Day,2 Days,3 Days',
			DURATION_PERIODS: '15 min,30 min,45 min,60 min',
			DAYS: 'Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday',
			DAY_LABEL: 'Day',
			DAYS_LABEL: 'Days',
			MINUTES: 'min',
			MONDAY: 'Monday',
			THURSDAY: 'Thursday',
			WEDNESDAY: 'Wednesday',
			TUESDAY: 'Tuesday',
			FRIDAY: 'Friday',
			SATURDAY: 'Saturday',
			SUNDAY: 'Sunday',
		},
		invitations: {
			BUTTON_CANCEL_INVITATION: 'Cancel invitation',
			BUTTON_DELETE_ASSISTANT: 'Delete assistant',
			BUTTON_SEND_INVITATION: 'Send invitation',
			EMAIL_INVALID_FORMAT: 'Invalid format',
			FIELD_REQUIRED: 'Field required',
			INVITATION_SENT: 'Invitation sent to: {{email}}',
			LABEL_LAST_NAME_INVITATION: 'Last name',
			LABEL_NAME_INVITATION: 'Name',
			LABEL_EMAIL_INVITATION: 'Email',
			TITLE_INVITATE_ASSISTANT: 'INVITE ASSISTANT',
			TITLE_ASSISTANT_INFORMATION: 'ASSISTANT INFORMATION',
			WARNING_MEESSAGE: 'Warning',
			DELETE_ASSISTANT_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this assistant?',
			CANCEL_ASSISTANT_INVITATION: 'Are you sure you want to delete this invitation?',
			MODAL_CONFIRM_BUTTON: 'Confirm',
			MODAL_CANCEL_BUTTON: 'Cancel',
		},
		tasksView: {
			TITLE: 'Tasks',
			PENDING_TASKS: 'Pending tasks',
			UPDATE_PATIENT_BUTTON: 'Update patient',
		},
		reminders: {
			CONFIRM_SESSION: 'There is a unconfirmed session for the patient: {{patientName}}',
			FILL_MY_OWN_DATA: 'There are unfilled personal data',
			FILL_PATIENT_DATA: 'There is unfilled data for the patient: {{patientName}} ',
			ASSIGN_RESOURCE: 'You can assing a resource to a patient',
			FILL_SESSION_DATA: 'There is unfilled session data for the patient: {{patientName}}',
			REQUEST_TO_JOIN_INSTITUTION: 'There is an invitation from institution: {{institutionName}}',
		},
		reminderType: {
			CONFIRM_SESSION: 'Confirm session',
			FILL_MY_OWN_DATA: 'Fill my own data',
			FILL_PATIENT_DATA: 'Fill patient data',
			ASSIGN_RESOURCE: 'Assing resource',
			FILL_SESSION_DATA: 'Fill session data',
			REQUEST_TO_JOIN_INSTITUTION: 'Accept/decline institution',
		},
		patientsView: {
			TITLE: 'Patients',
			PATIENT_TAB_INFO: 'Patient data',
			PATIENT_TAB_DOCUMENTATION: 'Documentation',
			PATIENT_TAB_RESOURCES: 'Resources',
			PATIENT_TAB_REPORTS: 'Reports',
			PATIENT_INFO: 'Patient Information',
			INFO_SEND_MESSAGE: 'Send message',
			INFO_CREATE_NOTE_BUTTON: 'Create note',
			INFO_TITLE_NOTES_CARD: 'Route of sessions and notes',
			INVITATION_RESENT_SUCCESSFULLY: 'Invitation resent successfully',
			DOCUMENTATION_LINKED_DOCUMENTATION: 'Linked documentation',
			DOCUMENTATION_DATE: 'Date',
			DOCUMENTATION_STATUS: 'Status',
			DOCUMENTATION_COMPLETED_BY: 'Completed by',
			DOCUMENTATION_ACTIONS: 'Actions',
			MODAL_EDIT_PATIENT: 'Edit Patient',
			MODAL_ADD_PATIENT: 'Add Patient',
			MODAL_BUTTON_CONFIRM: 'Save',
			MODAL_BUTTON_CANCEL: 'Cancel',
			MODAL_NAME_FIELD: 'Name',
			MODAL_LASTNAME_FIELD: 'Last Name',
			MODAL_EMAIL_FIELD: 'E-mail',
			MODAL_FOOTER:
				'When saving, an email will be sent to the patient to finish completing their profile and the invitation to be part of the portal.',
			MODAL_CREATE_NOTE: 'Create note',
			REPORTS_DATE: 'Date',
			REPORTS_ACTIONS: 'Actions',
			REPORTS_PREVIOUS: 'Previous reports',
			REPORTS_CREATE_BUTTON: 'Create report',
			REPORTS_BACK_BUTTON: 'Back',
			REPORTS_BACK_TO_PROFILE_BUTTON: 'Back to profile patient',
			REPORTS_NEXT_BUTTON: 'Next',
			REPORTS_CONFIRM_BUTTON: 'Confirm',
			REPORTS_INFO_STEP: 'patient information',
			REPORTS_DOCUMENTATION_STEP: 'documentation',
			REPORTS_NOTES_STEP: 'notes',
			REPORTS_CONFIRM_ORDER_STEP: 'confirm order',
			REPORTS_PREVIEW_STEP: 'preview',
			REPORTS_INFO_PATIENT_CARD: 'Patient Information',
			REPORTS_INFO_CARD_SUBTITLE: 'Select the information you want to add to the report',
			REPORTS_INFO_PROFESSIONAL_CARD: 'Professional Information',
			REPORTS_DOCUMENTATION_TITLE: 'Documentation',
			REPORTS_DOCUMENTATION_SUBTITLE: 'Select the forms you want to add to the report',
			REPORTS_NOTES_TITLE: 'Notes',
			REPORTS_NOTES_SUBTITLE: 'Select the notes you want to add to the report',
			REPORTS_NOTES_SELECT_BY_PERIOD: 'Select by period',
			REPORTS_NOTES_FILTER_FROM: 'From',
			REPORTS_NOTES_FILTER_TO: 'to',
			REPORTS_SELECT_ALL: 'Select all',
			REPORTS_APPLY_BUTTON: 'Apply',
			REPORTS_CONFIRMATION_TITLE: 'Confirm order',
			REPORTS_CONFIRMATION_SUBTITLE: 'Sort and confirm the order of appearance of previously selected documents and notes',
			REPORTS_CONFIRMATION_MODAL_TITLE: 'Are you sure you want to go back?',
			REPORTS_CONFIRMATION_MODAL_SUBTITLE: 'Information edited in this instance will be lost if you go back.',
			REPORTS_CONFIRMATION_MODAL_CONFIRM_BUTTON: 'Yes, go back',
			REPORTS_CONFIRMATION_MODAL_CANCEL_BUTTON: 'No, continue editing',
			REPORTS_PREVIEW_TITLE: 'Preview',
			REPORTS_PREVIEW_SUBTITLE: 'Review and confirm your final report',
			REPORTS_PREVIEW_WARNING: 'Information edited in this instance will be lost if you go back to previous steps.',
			REPORTS_PREVIEW_TEXT_PLACEHOLDER: 'Report title',
			REPORTS_PREVIEW_REPORT_DATE: 'Report date',
			REPORTS_PREVIEW_PATIENT_INFO: 'Patient information',
			REPORTS_PREVIEW_PROFESSIONAL_INFO: 'Professional information',
			RESOURCES_LINKED_RESOURCE: 'Linked resource',
			SHARED: 'Shared',
			YES_AVATAR_LABEL: 'YES',
			NO_AVATAR_LABEL: 'NO',
			DELETE_TOOLTIP: 'Delete',
			SHARE_TOOLTIP: 'Share',
			PREVIEW_TOOLTIP: 'Preview',
			UNLINK_RESOURCE: 'Unlink resource',
			UNLINK_RESOURCE_MODAL_CONTENT: 'Do you want to unlink the resource from the patient?',
			CLOSE_BUTTON: 'Close',
			SHARE_RESOURCE: 'Share resource',
			SHARE_RESOURCE_MODAL_CONTENT: 'Are you sure you want to share the resource? You will not be able to go back after confirming',
			UNLINK_DOCUMENTATION: 'Unlink documentación',
			UNLINK_DOCUMENTATION_MODAL_CONTENT: 'Do you want to unlink the documentation from the patient?',
			SHARE_DOCUMENTATION: 'Share documentation',
			SHARE_DOCUMENTATION_MODAL_CONTENT: 'Are you sure you want to share the documentation? You will not be able to go back after confirming',
			PATIENTS_TABLE_PATIENT_NAME: 'Patient Name',
			PATIENTS_TABLE_PATIENT_FAMILY_NAME: 'Last Name',
			PATIENTS_TABLE_PATIENT_GIVEN_NAME: 'Name',
			PATIENTS_TABLE_ACTIONS: 'Actions',
			PREVIOUS: 'Previous',
			RESEND_TOOLTIP: 'Resend invitation',
			COMPLETED: 'Completed',
			UNCOMPLETED: 'Uncompleted',
			FORM_PREVIEW_TITLE: 'Form Preview',

		},
		resourceLibraryView: {
			TITLE: 'Resource library',
			PROBLEMATIC_CARD_HEADER: 'Problematic',
			RESOURCE_CARD_HEADER: 'Resource name',
			PREVIEW_CARD_HEADER: 'Preview',
			BUTTON_LINK: 'Link',
			MODAL_TITLE_LINK_PATIENTS: 'Link patients',
			MODAL_BUTTON_CONFIRM_LINK_PATIENTS: 'Link',
			MODAL_BUTTON_CANCEL_LINK_PATIENTS: 'Cancel',
		},
		documentationView: {
			TITLE: 'Documentation',
			PROBLEMATIC_CARD_HEADER: 'Problematic',
			DOCUMENTS_CARD_HEADER: 'Documents',
			BUTTON_LINK: 'Link',
			FORM_PREVIEW_TITLE: 'Form Preview',
			MODAL_PREVIEW_BUTTON_VALIDATE: 'Save',
			MODAL_PREVIEW_BUTTON_NEXT: 'Next',
			MODAL_PREVIEW_BUTTON_BACK: 'Back',
		},
	},
};
