import React from 'react';
import styles from './Tag.module.scss';

interface TagProps {
	text: string;
	classes?: string;
}

const Tag: React.FC<TagProps> = ({ text, classes }) => {
	return <div className={`${styles.tagContainer} ${classes}`}><span className={styles.tagText}>{text}</span></div>;
};

export default Tag;
