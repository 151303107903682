export default {
	es: {
		document: {
			STATUS_READY: '¡Listo!',
			STATUS_TO_COMPLETE: 'Completar',
		},
	},
	en: {
		document: {
			STATUS_READY: 'Ready!',
			STATUS_TO_COMPLETE: 'To complete',
		},
	},
};
