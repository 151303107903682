import axios, { AxiosResponse } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const getPost = (id: number): Promise<AxiosResponse> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/posts/${id}`);
	}

	return Promise.reject('API url not defined');
};

export const getPosts = (tags: number[]): Promise<AxiosResponse> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/posts`, {
			params: {
				tags,
			},
		});
	}

	return Promise.reject('API url not defined');
};

export const getTags = (): Promise<AxiosResponse> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/posts/tags`);
	}

	return Promise.reject('API url not defined');
};
