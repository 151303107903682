import { useLocation } from 'react-router-dom';
import { useRoutes } from '../../helpers/useRoutes';

interface UseLayoutStore {
	isLoginPage: boolean;
	isTransparentTopBar: boolean;
}

export const useLayout: () => UseLayoutStore = () => {
	const { routes } = useRoutes();
	const location = useLocation();
	const isLoginPage = location.pathname === `/${routes.SIGNIN}`;
	const isTransparentTopBar = location.pathname === `/${routes.HOME}` || location.pathname === `/${routes.FUNCTIONALITIES}`;

	return {
		isLoginPage,
		isTransparentTopBar,
	};
};
