import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import styles from './TherapistCards.module.scss';
import { TherapistCard } from '../../../interfaces/TherapistCard';
import { TherapistContext } from '../../../contexts/TherapistContext';
import useTherapistCards from './useTherapistCards';
import { useTranslation } from 'react-i18next';

interface TherapistCardsProps {
	onClick?: (therapist: TherapistCard) => void;
}

const TherapistCards: React.FC<TherapistCardsProps> = ({ onClick }) => {
	const { t } = useTranslation();
	const { selected, handleClick } = useTherapistCards({ onClick });
	const { therapistsByCurrentPatient, therapistSelected } = React.useContext(TherapistContext);

	return <>
		{
			therapistsByCurrentPatient.length
				? therapistsByCurrentPatient.map(therapist => {
					let selectedStyle = {};

					if (selected.id) {
						selectedStyle = selected.id === therapist.id ? styles.cardContentSelected : styles.cardContentSimple;
					} else {
						selectedStyle = therapistSelected?.id === therapist.id ? styles.cardContentSelected : styles.cardContentSimple;
					}

					return (
						<Card key={therapist.id} classes={{ root: styles.therapistCard }}>
							<CardActionArea onClick={(): void => handleClick(therapist)} >
								<CardContent className={`${styles.cardContent} ${selectedStyle}`}>
									<Avatar className={styles.cardContentInfoAvatar} alt="profile" src={therapist.avatar} />
									<div className={styles.title}>
										{`${therapist.name} ${therapist.lastName}`}
										<span>{t(therapist.job)}</span>
									</div>
								</CardContent>
							</CardActionArea>
						</Card>);
				})
				: <div className={styles.therapistCardNoData}>{t('messages:NO_DATA')}</div>
		}
	</>;
};

export default TherapistCards;
