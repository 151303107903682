import { Office } from './Session';

export enum StatusDateCard {
	New = 0,
	Acepted = 1,
	Declined = 2,
	Unset = 3,
}

export enum SiteDateCard {
	Telehealth = 0,
	ConsultingRoom = 1,
}

export interface DateCard {
	id: number;
	status?: StatusDateCard;
	site?: SiteDateCard;
	therapistOffice?: Office;
	startTime: Date;
	endTime: Date;
}
