import Card from './Card';
import { Link } from 'react-router-dom';
import React from 'react';
import styles from './BlogCard.module.scss';
import Tag from '../Tag/Tag';
import { useRoutes } from '../../helpers/useRoutes';
import { useTranslation } from 'react-i18next';

interface BlogCardProps {
	id: number;
	image: string;
	title: string;
	summary: string;
	tags?: string[];
}

const BlogCard: React.FC<BlogCardProps> = ({ id, image, title, summary, tags }) => {
	const { t } = useTranslation();
	const { routes } = useRoutes();

	return (
		<div className={styles.blogCardContainer}>
			<img src={image} className={styles.image} alt="blog-image" />
			<Card className={styles.blogPostContent}>
				{
					tags && (
						<div className={styles.tagContainer}>
							{
								tags.map(title => <div key={title} className={styles.tag}><Tag text={title} /></div>)
							}
						</div>
					)
				}
				<h3 className={styles.title}>{title}</h3>
				<p className={styles.summary} dangerouslySetInnerHTML={{ __html: summary }}></p>
				<div className={styles.moreContainer}>
					<Link to={`/${routes.POST}/${id}`} className={styles.more}>{t('blogContent:MORE')}</Link>
				</div>
			</Card>
		</div>
	);
};

export default BlogCard;
