import { markTaskAsDeleted, markTaskAsStarred, markTasksAsRead } from '../api/TaskRepository';

export const softDeleteTask = async (taskId: number): Promise<void> => {
	return await markTaskAsDeleted(taskId);
};

export const readTasks = async (taskIds: number[]): Promise<void> => {
	return await markTasksAsRead(taskIds);
};

export const updateStarredStatus = async (taskId: number): Promise<void> => {
	return await markTaskAsStarred(taskId);
};
