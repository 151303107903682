export interface ChatContact {
	avatar?: string;
	id: number;
	name: string;
	unreadMessages: number;
}

export interface ChatMessage {
		createdAt: Date;
		fromId: number;
		id: number;
		messages: string;
		toId: number;
}

export enum ChatEvents {
	connectError = 'connect-error',
	contactList = 'contact-list',
	personalMessage = 'personal-message',
	readMessages = 'read-messages',
}

