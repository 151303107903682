import { ReportContext, ReportUpdateContext } from '../../../../../../contexts/ReportContext';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import Confirmation from './Confirmation/Confirmation';
import Documents from './Documents/Documents';
import Information from './Information/Information';
import Notes from './Notes/Notes';
import Preview from './Preview/Preview';
import React from 'react';
import { savePatientReport } from '../../../../../../services/TherapistService';
import { useRoutes } from '../../../../../../helpers/useRoutes';
import { UserRole } from '../../../../../../interfaces/User';
import { useSessionStorage } from '../../../../../../hooks/useSessionStorage';
import { useTherapistSelected } from '../../../../../../hooks/useTherapistSelected';

interface CreateReportStore {
	steps: Step;
	activeStep: number;
	completed: { [k: number]: boolean };
	openConfirmationModal: boolean;
	isLastStep: () => boolean;
	isConfirmButtonEnabled: () => boolean;
	allStepsCompleted: () => boolean;
	handleNext: () => void;
	handleBack: () => void;
	back: () => void;
	handleComplete: () => void;
	handleReset: () => void;
	backToReportsList: () => void;
	handleReportConfirmationModal: () => void;
}

interface Step {
	[key: number]: {
		index: number;
		title: string;
		component: React.FC;
	};
}

const STEPS = {
	INFO: 0,
	DOCUMENTS: 1,
	NOTES: 2,
	CONFIRM: 3,
	PREVIEW: 4,
};

const steps: Step = {
	[STEPS.INFO]: {
		index: STEPS.INFO,
		title: 'patientsView:REPORTS_INFO_STEP',
		component: Information,
	},
	[STEPS.DOCUMENTS]: {
		index: STEPS.DOCUMENTS,
		title: 'patientsView:REPORTS_DOCUMENTATION_STEP',
		component: Documents,
	},
	[STEPS.NOTES]: {
		index: STEPS.NOTES,
		title: 'patientsView:REPORTS_NOTES_STEP',
		component: Notes,
	},
	[STEPS.CONFIRM]: {
		index: STEPS.CONFIRM,
		title: 'patientsView:REPORTS_CONFIRM_ORDER_STEP',
		component: Confirmation,
	},
	[STEPS.PREVIEW]: {
		index: STEPS.PREVIEW,
		title: 'patientsView:REPORTS_PREVIEW_STEP',
		component: Preview,
	},
};

const useCreateReport = (): CreateReportStore => {
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const { user } = React.useContext(AuthContext);
	const { getItem } = useSessionStorage('selected_therapist_id_by_assistant');
	const { assistantRoutes, therapistRoutes } = useRoutes();
	const { report } = React.useContext(ReportContext);
	const { cleanReport, setReport } = React.useContext(ReportUpdateContext);
	const [activeStep, setActiveStep] = React.useState<number>(0);
	const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
	const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);
	const { getTherapistIdParam } = useTherapistSelected();

	const isConfirmButtonEnabled = (): boolean => {
		return (Boolean(report.title));
	};

	const totalSteps = (): number => {
		return Object.keys(steps).length;
	};

	const completedSteps = (): number => {
		return Object.keys(completed).length;
	};

	const isLastStep = (): boolean => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = (): boolean => {
		return completedSteps() === totalSteps();
	};

	const handleNext = (): void => {
		const newActiveStep = isLastStep() && !allStepsCompleted()
			? Object.keys(steps).findIndex((step, i) => !(i in completed))
			: activeStep + 1;

		setActiveStep(newActiveStep);
		setCompleted({ ...completed, [newActiveStep - 1]: true });
	};

	const back = (): void => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		const update = Object.values(completed);

		update.pop();

		setCompleted(Object.values(update));

		if(activeStep === STEPS.PREVIEW) {
			setReport({...report, title: '' });
		}
	};

	const handleBack = (backToPrevious = false): void => {
		if (activeStep === STEPS.PREVIEW && backToPrevious) {
			setOpenConfirmationModal(true);

			return ;
		}

		back();
	};

	const handleReset = (): void => {
		setActiveStep(0);
		setCompleted({});
	};

	const backToReportsList = (): void => {
		cleanReport();

		const therapistSelectedId = getItem();
		const assistantPatientRoute = `/${assistantRoutes.ASSISTANT}/${therapistRoutes.THERAPIST}/${therapistSelectedId}/${assistantRoutes.PATIENTS}/${id}`;
		const therapistPatientRoute = `/${therapistRoutes.THERAPIST}/${therapistRoutes.PATIENTS}/${id}`;

		let patientRoute = '';

		if (user?.roles[0] === UserRole.ASSISTANT) {
			patientRoute = assistantPatientRoute;
		} else if (user?.roles[0] === UserRole.THERAPIST) {
			patientRoute = therapistPatientRoute;
		}

		if (patientRoute) {
			history.push(patientRoute);
		}
	};

	const handleComplete = async(): Promise<void> => {
		const newCompleted = completed;
		const therapistIdParam = getTherapistIdParam();

		newCompleted[activeStep] = true;
		setCompleted(newCompleted);

		await savePatientReport(therapistIdParam, +id, report.previewValue, report.title);
		backToReportsList();
	};

	const handleReportConfirmationModal = (): void => {
		setOpenConfirmationModal(prevState => !prevState);
	};

	return ({
		steps,
		activeStep,
		completed,
		isConfirmButtonEnabled,
		openConfirmationModal,
		isLastStep,
		allStepsCompleted,
		handleNext,
		handleBack,
		back,
		handleComplete,
		handleReset,
		backToReportsList,
		handleReportConfirmationModal,
	});
};

export default useCreateReport;
