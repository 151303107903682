import { Assistant, AssistantTherapistRequest } from '../interfaces/Assistant';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const deleteTherapist = (therapistId: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/assistants/me/therapists/${therapistId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findAssistant = (): Promise<Assistant> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/assistants/me`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const findTherapistRequests = (): Promise<AssistantTherapistRequest[]> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/assistants/me/therapists/requests`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateAvatar = async (data: FormData): Promise<void> => {
	if (apiUrl) {
		const avatarFileId = await axios.post(`${apiUrl}/files`, data);

		return axios.patch(`${apiUrl}/assistants/me/`, { avatarFile: {id: avatarFileId} });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateAssistantInfo = (assistant: Assistant): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/assistants/me/`, assistant);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const acceptTherapist = (therapistId: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/assistants/me/therapists/invitation/${therapistId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const rejectTherapist = (therapistId: number): Promise<void> => {
	if (apiUrl) {
		return axios.delete(`${apiUrl}/assistants/me/therapists/invitation/${therapistId}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
