import { Button, NotesCard, PatientPersonalInfoModal, ProfileCard, ProfilePictureCard } from '../../../../../components';

import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { keyValue } from '../../../../../interfaces/Common';
import { PatientProfile } from '../../../../../interfaces/Patient';
import React from 'react';
import styles from './Info.module.scss';
import useInfo from './useInfo';
import { useTranslation } from 'react-i18next';

interface InfoProps {
	user: PatientProfile;
	getTherapistPatientInfo: () => Promise<void>;
}

const Info: React.FC<InfoProps> = ({ user, getTherapistPatientInfo }) => {
	const { t } = useTranslation();
	const {
		isAssistant,
		open,
		handleOpen,
		handleRedirectToChat,
		updateTherapistPatientInfo,
	} = useInfo();

	const isPatientEnabled = user.enabled === true;

	return (
		<div className={styles.info}>
			<div className={styles.infoContainer}>
				<ProfilePictureCard fullName={`${user.name} ${user.lastName}`} image={user.avatar}>
					<div className={styles.sendMessageBtn}>
						<Button
							type="SECONDARY"
							title={t('patientsView:INFO_SEND_MESSAGE')}
							startIcon={<CommentOutlinedIcon />}
							size="large"
							onClick={(): void => handleRedirectToChat(user?.id)}
							isDisabled={isAssistant || !isPatientEnabled}
						/>
					</div>
				</ProfilePictureCard>
				<ProfileCard title={t('patientsView:PATIENT_INFO')} data={user as keyValue} showEditBtn={isPatientEnabled} onClickBtn={handleOpen} />
			</div>
			<div className={styles.notesContainer}>
				{
					user.id &&
					<NotesCard patientId={user.id} isPatientEnabled />
				}
			</div>
			{ open &&
				<PatientPersonalInfoModal data={user as PatientProfile} open={open} onChange={handleOpen} getUser={getTherapistPatientInfo} updateUser={updateTherapistPatientInfo} />
			}
		</div>
	);
};

export default Info;
