import moment from 'moment';
import React from 'react';
import styles from './OutgoingMessage.module.scss';

interface Props {
  text: string;
	date: Date;
}
const OutgoingMessage: React.FC<Props> = ({ date, text }) => {

	return (
		<div className={styles.outgoingMessage}>
			<div className={styles.message}>
				<div className={styles.messageBubble}>{text}</div>
				<div className={styles.messageTime}>{moment(date).format('HH:MM')}</div>

			</div>
		</div>
	);
};

export default OutgoingMessage;
