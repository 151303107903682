import React from 'react';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
	const { t } = useTranslation();

	return (
		<footer className={styles.footer}>
			<div className={styles.logo} ></div>
			<p className={styles.footerText}>{t('footer:COPYRIGHT_MESSAGE')}</p>
		</footer>
	);
};

export default Footer;
