import { cancelAssistantInvitation, inviteAssistant, removeAssistantFromTherapist } from '../../../services/TherapistService';
import { ConfirmationModalInterface, ConfirmationTypes } from '../../../interfaces/ConfirmationModels';
import { isEmail, isRequired } from '../../../helpers/FormFieldsValidator';
import { AssistantInfo } from '../../../interfaces/Generic';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

export interface InviteAssistantCardProps {
	assistantInvitationEmail: string | null;
	data?: AssistantInfo;
	fullWidth?: boolean;
	reloadProfile: () => Promise<void>;
}

interface FormData {
	[key: string]: {
		error: boolean;
		errorMessage: string;
		value: string;
		showError: boolean;
		validation: (value: string) => boolean;
	};
}

interface InviteAssistantCardInterface {
	formData: FormData;
	showDeleteButton: boolean;
	openConfirmationModal: boolean;
	confirmationModalState: ConfirmationModalInterface;
	handleChangeTextField: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleSave: () => void;
	handleConfirmationModal: (confirmationType: ConfirmationTypes) => void;
}

const initialFormData: FormData = {
	name: {
		error: false,
		errorMessage: 'invitations:FIELD_REQUIRED',
		value: '',
		showError: false,
		validation: isRequired, //check trim and length
	},
	lastName: {
		error: false,
		errorMessage: 'invitations:FIELD_REQUIRED',
		value: '',
		showError: false,
		validation: isRequired, //check trim and length
	},
	email: {
		error: false,
		errorMessage: 'EMAIL_INVALID_FORMAT',
		value: '',
		showError: false,
		validation: isEmail,
	},
};

export const useInviteAssistantCard = (props: InviteAssistantCardProps): InviteAssistantCardInterface => {
	const [formData, setFormData] = React.useState<FormData>(initialFormData);
	const [showDeleteButton] = React.useState<boolean>(false);
	const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);
	const [confirmationModalState, setConfirmationModalState] = React.useState<ConfirmationModalInterface>({} as ConfirmationModalInterface);
	const { getTherapistIdParam } = useTherapistSelected();

	const handleCloseConfirmationModal = (): void => {
		setOpenConfirmationModal(false);
	};

	const handleChangeTextField = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { name, value } = event.target;

		const error = !formData[name].validation(value.trim());

		setFormData({
			...formData,
			[name]: { ...formData[name], error: error, value: value },
		});
	};

	const handleRemoveAssistant = async (): Promise<void> => {
		const therapistIdParam = getTherapistIdParam();

		await removeAssistantFromTherapist(therapistIdParam);
		handleCloseConfirmationModal();
		setFormData(initialFormData);
		await props.reloadProfile();
	};

	const handleCancelAssistantInvitation = async (): Promise<void> => {
		const therapistIdParam = getTherapistIdParam();

		await cancelAssistantInvitation(therapistIdParam);
		handleCloseConfirmationModal();
		await props.reloadProfile();
	};

	const handleSave = async(): Promise<void> => {
		const isFormValid = !Object.keys(formData).some(
			(key) => formData[key].error === true
		);

		if (isFormValid) {
			const therapistIdParam = getTherapistIdParam();

			await inviteAssistant(therapistIdParam, formData.email.value, formData.lastName.value, formData.name.value);
			await props.reloadProfile();
		} else {
			const formDataClone = cloneDeep(formData);

			Object.keys(formDataClone).forEach((item) => {
				formDataClone[item].showError = formDataClone[item].error;
			});

			setFormData(formDataClone);
		}
	};

	const handleConfirmationModal = (confirmationType: ConfirmationTypes): void => {
		switch (confirmationType) {
			case ConfirmationTypes.cancelAssistantInvitation:
				setConfirmationModalState({
					content: 'invitations:CANCEL_ASSISTANT_INVITATION',
					title: 'invitations:WARNING_MEESSAGE',
					onClose: handleCloseConfirmationModal,
					onConfirm: async(): Promise<void> => await handleCancelAssistantInvitation(),
				});
				setOpenConfirmationModal(true);

				break;
			case ConfirmationTypes.deleteAssistant:
				setConfirmationModalState({
					content: 'invitations:DELETE_ASSISTANT_CONFIRMATION_MESSAGE',
					title: 'invitations:WARNING_MEESSAGE',
					onClose: handleCloseConfirmationModal,
					onConfirm: async(): Promise<void> => await handleRemoveAssistant(),
				});
				setOpenConfirmationModal(true);
				break;
			default:
				break;
		}
	};

	return {
		formData,
		showDeleteButton,
		openConfirmationModal,
		confirmationModalState,
		handleChangeTextField,
		handleSave,
		handleConfirmationModal,
	};
};
