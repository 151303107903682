import { getNotesByPatient, removeNote } from '../../../services/NoteService';
import { AlertContext } from '../../../contexts/AlertContext';
import { AuthContext } from '../../../contexts/AuthContext';
import moment from 'moment';
import { Note } from '../../../interfaces/Note';
import React from 'react';
import { UserRole } from '../../../interfaces/User';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface NotesCardStore {
	activeStep: number;
	currentNote: Note;
	isAssistant: boolean;
	isCreateNoteButtonEnabled: boolean;
	isLoading: boolean;
	noteId?: number;
	notes: Note[];
	openNoteModal: boolean;
	handleOpenNoteModal: (isNewNote: boolean) => void;
	handleStep: (value: number) => void;
	loadData: () => void;
	removeNoteById: (noteId?: number) => void;
}

const useNotesCard = (patientId?: number): NotesCardStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [notes, setNotes] = React.useState<Note[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [noteId, setNoteId] = React.useState<number | undefined>(undefined);
	const [activeStep, setActiveStep] = React.useState<number>(0);
	const [openNoteModal, setOpenNoteModal] = React.useState<boolean>(false);
	const [isCreateNoteButtonEnabled, setIsCreateNoteButtonDisabled] = React.useState<boolean>(true);
	const [currentNote, setCurrentNote] = React.useState<Note>({} as Note);
	const { getTherapistIdParam } = useTherapistSelected();
	const { user } = React.useContext(AuthContext);

	const loadData = async (): Promise<void> => {
		try {
			setIsLoading(true);

			if (patientId) {
				const therapistIdParam = getTherapistIdParam();
				const data = await getNotesByPatient(therapistIdParam, patientId);

				setNotes(data);
				setCurrentNote(data[activeStep]);
			}
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const isSameDate = notes.some((note: Note): boolean => moment(note.title).isSame(moment().format('MM/DD/YYYY')));

		if (isSameDate) {
			setIsCreateNoteButtonDisabled(true);
		} else {
			setIsCreateNoteButtonDisabled(false);
		}
	}, [notes]);

	const handleStep = (value: number): void => {
		setActiveStep(value);
		setCurrentNote(notes[value]);
	};

	const handleOpenNoteModal = (isNewNote: boolean): void => {
		if (isNewNote) {
			setCurrentNote({} as Note);
		} else {
			setCurrentNote(notes[activeStep]);
		}

		setNoteId(isNewNote ? undefined : notes[activeStep]?.id);
		setOpenNoteModal(prevState => !prevState);
	};

	const removeNoteById = async (noteId?: number): Promise<void> => {
		try {
			setIsLoading(true);

			if (noteId) {
				const therapistIdParam = getTherapistIdParam();

				await removeNote(therapistIdParam, noteId);
				setActiveStep(0);
				setCurrentNote({} as Note);
				await loadData();
			}
		} catch (error) {
			showDefaultError();
		}

		setIsLoading(false);
	};

	return ({
		activeStep,
		currentNote,
		isAssistant: user?.roles?.includes?.(UserRole.ASSISTANT) ?? false,
		isCreateNoteButtonEnabled,
		isLoading,
		noteId,
		notes,
		openNoteModal,
		handleOpenNoteModal,
		handleStep,
		loadData,
		removeNoteById,
	});
};

export default useNotesCard;
