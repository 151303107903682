import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { SignatureType } from '../../../interfaces/User';

interface SignatureModalProps {
	getUser: () => void;
	onChange: () => void;
	onSave: (signatureImage: string) => void;
}

interface SignatureModalStore {
	signatureValue: string;
	selectedTab: number;
	isInvalidSignature: boolean;
	sigCanvas: React.MutableRefObject<SignatureCanvas>;
	handleClear: () => void;
	handleClose: () => void;
	handleConfirm: () => void;
	handleChangeTab: (event: unknown, newValue: number) => void;
	handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleOnEndCanvas: () => void;
}

const useSignatureModal = (props: SignatureModalProps): SignatureModalStore => {
	const [selectedTab, setSelectedTab] = React.useState(0);
	const [signatureValue, setSignatureValue] = React.useState('');
	const [isInvalidSignature, setIsInvalidSignature] = React.useState<boolean>(true);
	const sigCanvas = React.useRef() as React.MutableRefObject<SignatureCanvas>;

	const valitadateSignature = (): void => {
		if (selectedTab === SignatureType.Write && signatureValue) {
			setIsInvalidSignature(signatureValue.length > 30);
		} else if (selectedTab === SignatureType.Draw && sigCanvas.current && !sigCanvas.current.isEmpty()) {
			setIsInvalidSignature(false);
		}
	};

	React.useEffect(() => {
		valitadateSignature();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signatureValue]);

	const handleClear = (): void => {
		setSignatureValue('');
		setIsInvalidSignature(true);

		if (sigCanvas.current) {
			sigCanvas.current.clear();
		}

		const canvas = document.getElementById('canvasSignatureWritter') as HTMLCanvasElement;

		if (canvas) {
			const ctx = canvas.getContext('2d');

			if (ctx) {
				ctx.clearRect(0, 0, canvas.width, canvas.height);
			}
		}
	};

	const handleClose = (): void => {
		handleClear();
		props.onChange();
	};

	const handleChangeTab = (event: unknown, newValue: number): void => {
		handleClear();
		setSelectedTab(newValue);
	};

	const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;

		setSignatureValue(value);

		const canvas = document.getElementById('canvasSignatureWritter') as HTMLCanvasElement;
		const ctx = canvas.getContext('2d');

		if (ctx) {
			canvas.width = ctx.measureText(value).width + 5;
			ctx.font = '40px Cookie';
			ctx.fillText(value, 5, 60);
		}
	};

	const handleConfirm = async (): Promise<void> => {
		let signatureImage = '';

		if (selectedTab === SignatureType.Write) {
			const canvas = document.getElementById('canvasSignatureWritter') as HTMLCanvasElement;

			signatureImage = canvas.toDataURL('image/png');
		} else {
			signatureImage = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
		}

		await props.onSave(signatureImage);
		await props.getUser();
		handleClear();
		props.onChange();
	};

	const handleOnEndCanvas = (): void => {
		valitadateSignature();
	};

	return ({
		signatureValue,
		selectedTab,
		sigCanvas,
		isInvalidSignature,
		handleClear,
		handleClose,
		handleConfirm,
		handleChangeTab,
		handleChangeInput,
		handleOnEndCanvas,
	});
};

export default useSignatureModal;
