import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { Option } from '../../../../interfaces/DocumentForm';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import Select from '@material-ui/core/Select';
import useSelectPreview from './useSelectPreview';

const SelectPreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	} = useSelectPreview(props);
	const { value, options, isValid } = question;

	return (
		<FormControl
			style={{ width: 250 }}
			variant="outlined"
			error={showErrors && !isValid}
		>
			<Select
				value={value}
				onChange={handleOnChange}
				color="secondary"
			>
				{
					options.map((option: Option) => {
						return (
							<MenuItem	key={option.id}	value={option.id}>
								{option.title}
							</MenuItem>
						);
					})
				}
			</Select>
			<FormHelperText>
				{showErrors && !isValid && `${errorMessage}`}
			</FormHelperText>
		</FormControl>
	);
};

export default SelectPreview;
