import { DatePicker } from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import useDatePreview from './useDatePreview';

const DatePreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	} = useDatePreview(props);
	const { min, max, value, required, isValid } = question;

	return (
		<>
			<DatePicker
				style={{ width: 150, color: '#686868' }}
				color="secondary"
				inputVariant="outlined"
				placeholder="MM/dd/yyyy"
				value={value}
				onChange={(newDate): void => handleOnChange(newDate)}
				autoOk
				format="MM/dd/yyyy"
				variant="inline"
				minDate={min}
				maxDate={max}
				required={required}
			/>
			{showErrors && !isValid && <FormHelperText error={showErrors && !isValid}>{showErrors ? errorMessage : ''}</FormHelperText>}
		</>
	);
};

export default DatePreview;
