import { Document, Page } from 'react-pdf/dist/entry.webpack';
import React from 'react';
import useReportPreview from './useReportPreview';

interface ReportPreviewProps {
	reportPreview: Blob | null;
}

const ReportPreview: React.FC<ReportPreviewProps> = (props) => {
	const { options, numPages, handleLoadSuccess } = useReportPreview();

	return (
		<div>
			<Document
				file={props.reportPreview}
				onLoadSuccess={handleLoadSuccess}
				options={options}
			>
				{Array.from(new Array(numPages), (el, index) => (
					<Page
						key={`page_${index + 1}`}
						pageNumber={index + 1}
						scale={1.2}
					/>
				))}
			</Document>
		</div>
	);
};

export default ReportPreview;
