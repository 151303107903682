export default {
	es: {
		profileView: {
			ADD_LOCATION: '+ Agregar Oficina',
			BIRTHDATE: 'Fecha de nacimiento',
			CHANGE_INSTITUTE_LOGO_PICTURE: 'Cambiar logo de instituto',
			CHANGE_PROFILE_PICTURE: 'Cambiar imagen de perfil',
			CHANGE_THERAPIST: 'Cambiar terapeuta',
			CITY: 'Ciudad',
			CLINICAL_SITUATIONS: 'Situaciones clínicas más frecuentes',
			COUNTRY: 'País',
			DRAW_TAB_OPTION: 'Escribir',
			EDIT_BUTTON: 'Editar',
			EMAIL: 'Email',
			ENROLLMENT: 'Matrícula',
			FAMILY_NAME: 'Apellido',
			FULL_NAME_LABEL: 'Nombre completo',
			GENDER: 'Sexo',
			GIVEN_NAME: 'Nombre',
			HELPER_TEXT: 'Máximo 30 caracteres',
			JOB: 'Profesión',
			LAST_NAME: 'Apellido',
			LOCATIONS: 'Consultorios',
			LOGO: 'Logo del instituto',
			MARITAL_STATUS: 'Estado civil',
			MENU_LOGOUT: 'Cerrar sesión',
			MENU_MY_ACCOUNT: 'Mi perfil',
			MODAL_BUTTON_CLEAR: 'Limpiar',
			NAME: 'Nombre',
			PERSONAL_INFO: 'Información Personal',
			PHONE: 'Teléfono',
			POSTAL_CODE: 'Código Postal',
			PROFESSIONAL_INFO: 'Información Profesional',
			SIGNATURE: 'Firma',
			TITLE_PROFILE: 'Perfil',
			TREATED_PATIENTS: 'Edad de pacientes tratados',
			TUTOR: 'Tutor o responsable',
			UPDATE_SIGNATURE: 'Actualizar firma',
			WRITE_TAB_OPTION: 'Dibujar',
			WARNING_MEESSAGE: 'Advertencia',
			DELETE_ASSISTANT_CONFIRMATION_MESSAGE: '¿Estás seguro que deseas eliminar este asistente?',
			DELETE_INSTITTUION_CONFIRMATION_MESSAGE: '¿Estás seguro que deseas eliminar esta institución?',
			DELETE_ASSISTANT_INVITATION: '¿Estás seguro que deseas eliminar esta invitación?',
			MODAL_CONFIRM_BUTTON: 'Confirmar',
			MODAL_CANCEL_BUTTON: 'Cancelar',
		},
		changePasswordView: {
			HEADER_TITLE: 'Ingresa tu nueva contraseña',
			HEADER_DESCRIPTION: 'Sugerencia: la contraseña debe tener entre 8 y 32 caracteres de longitud. Para hacerla más segura, use letras mayúsculas y minúsculas, y números.',
			NEW_PASSWORD_LABEL: 'Contraseña nueva',
			OLD_PASSWORD_LABEL: 'Contraseña anterior',
			CONFIRM_PASSWORD_LABEL: 'Confirmar contraseña nueva',
			CHANGE_PASSWORD_PRIMARY_BUTTON: 'Guardar',
		},
		forgotPassword: {
			HEADER_TITLE: 'Ingresa tu dirección de mail',
			HEADER_DESCRIPTION: 'Un mail se enviará a tu correo para restablecer la contraseña',
			EMAIL_LABEL: 'Email',
			RECOVER_PASSWORD_PRIMARY_BUTTON: 'Recuperar',
		},
	},
	en: {
		profileView: {
			ADD_LOCATION: '+ Add Location',
			BIRTHDATE: 'Birthdate',
			CHANGE_INSTITUTE_LOGO_PICTURE: 'Change institute logo picture',
			CHANGE_PROFILE_PICTURE: 'Change profile picture',
			CHANGE_THERAPIST: 'Change therapist',
			CITY: 'City',
			CLINICAL_SITUATIONS: 'Most frequent clinical situations',
			COUNTRY: 'Country',
			DRAW_TAB_OPTION: 'Draw',
			EDIT_BUTTON: 'Edit',
			EMAIL: 'Email',
			ENROLLMENT: 'Enrollment',
			FAMILY_NAME: 'Last Name',
			FULL_NAME_LABEL: 'Full name',
			GENDER: 'Gender',
			GIVEN_NAME: 'Name',
			HELPER_TEXT: 'Max 30 characters',
			JOB: 'Job',
			LAST_NAME: 'Last name',
			LOCATIONS: 'Consulting rooms',
			LOGO: 'Institute logo',
			MARITAL_STATUS: 'Marital status',
			MENU_LOGOUT: 'Logout',
			MENU_MY_ACCOUNT: 'My account',
			MODAL_BUTTON_CLEAR: 'Clear',
			NAME: 'Name',
			PERSONAL_INFO: 'Personal Information',
			PHONE: 'Phone',
			POSTAL_CODE: 'Postal code',
			PROFESSIONAL_INFO: 'Professional Information',
			SIGNATURE: 'Signature',
			TITLE_PROFILE: 'Profile',
			TREATED_PATIENTS: 'Age of patients treated',
			TUTOR: 'Tutor or manager',
			UPDATE_SIGNATURE: 'Update signature',
			WRITE_TAB_OPTION: 'Write',
			WARNING_MEESSAGE: 'Warning',
			DELETE_INSTITTUION_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this institution?',
			CANCEL_INSTITTUION_INVITATION: 'Are you sure you want to delete this invitation?',
			MODAL_CONFIRM_BUTTON: 'Confirm',
			MODAL_CANCEL_BUTTON: 'Cancel',
		},
		changePasswordView: {
			HEADER_TITLE: 'Enter new password',
			HEADER_DESCRIPTION: 'Tip: The password must be between 8 and 32 characters long. To make it stronger, use upper and lower case letters, and numbers.',
			NEW_PASSWORD_LABEL: 'New password',
			OLD_PASSWORD_LABEL: 'Old password',
			CONFIRM_PASSWORD_LABEL: 'Confirm new password',
			CHANGE_PASSWORD_PRIMARY_BUTTON: 'Save',
		},
		forgotPassword: {
			HEADER_TITLE: 'Enter email',
			HEADER_DESCRIPTION: 'We will send you an email to recover your password.',
			EMAIL_LABEL: 'Email',
			RECOVER_PASSWORD_PRIMARY_BUTTON: 'Recover',
		},
	},
};
