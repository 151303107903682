import React, { ReactNode } from 'react';
import { CardItem } from '../pages/therapist/Patients/patient/Reports/CreateReport/Confirmation/useConfirmation';
import { cloneDeep } from 'lodash';
import { DocumentShared } from '../interfaces/Document';
import { Note } from '../interfaces/Note';
import { PatientProfile } from '../interfaces/Patient';
import { TherapistInfo } from '../interfaces/User';

export interface GlobalReport {
	title: string;
	patient: {
		[key: string]: string;
	};
	therapist: {
		[key: string]: string;
	};
	forms: DocumentShared[];
	notes: Note[];
	resourcesOrder: CardItem[];
	previewValue: string;
}

interface ReportStore {
	report: GlobalReport;
	patientInfo: PatientProfile;
	therapistInfo: TherapistInfo;
	formsInfo: DocumentShared[];
	notesInfo: Note[];
}

interface ReportUpdateStore {
	setReport: (value: GlobalReport) => void;
	handleChangePatientInfo: (patient: PatientProfile) => void;
	handleChangeTherapistInfo: (patient: TherapistInfo) => void;
	handleChangeFormsInfo: (forms: DocumentShared[]) => void;
	handleChangeNotesInfo: (forms: Note[]) => void;
	addPatientInformation: (name: string, checked: boolean) => void;
	addTherapistInformation: (name: string, checked: boolean) => void;
	addForm: (name: string, checked: boolean) => void;
	addNote: (name: string, checked: boolean) => void;
	handleResourcesOrder: (resources: CardItem[]) => void;
	cleanReport: () => void;
}

const initialReport: ReportStore = {
	report: {
		title: '',
		patient: {},
		therapist: {},
		forms: [],
		notes: [],
		resourcesOrder: [],
		previewValue: '',
	} as GlobalReport,
	patientInfo: {} as PatientProfile,
	therapistInfo: {} as TherapistInfo,
	formsInfo: [] as DocumentShared[],
	notesInfo: [] as Note[],
};

export const ReportContext = React.createContext(initialReport);
export const ReportUpdateContext = React.createContext({} as ReportUpdateStore);

const ReportProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [report, setReport] = React.useState<GlobalReport>(cloneDeep(initialReport.report));
	const [patientInfo, setPatientInfo] = React.useState<PatientProfile>(initialReport.patientInfo);
	const [therapistInfo, setTherapistInfo] = React.useState<TherapistInfo>(initialReport.therapistInfo);
	const [formsInfo, setFormInfo] = React.useState<Array<DocumentShared>>(initialReport.formsInfo);
	const [notesInfo, setNotesInfo] = React.useState<Array<Note>>(initialReport.notesInfo);

	const handleChangePatientInfo = (patient: PatientProfile): void => {
		setPatientInfo(patient);
	};

	const handleChangeTherapistInfo = (therapist: TherapistInfo): void => {
		setTherapistInfo(therapist);
	};

	const handleChangeFormsInfo = (forms: DocumentShared[]): void => {
		setFormInfo(forms);
	};

	const handleChangeNotesInfo = (notes: Note[]): void => {
		setNotesInfo(notes);
	};

	const addPatientInformation = (name: string, checked: boolean): void => {
		const patientUpdated = report.patient;

		if(checked) {
			patientUpdated[name] = patientInfo[name] as string;
		} else {
			delete patientUpdated[name];
		}

		setReport({...report, patient: patientUpdated });
	};

	const addTherapistInformation = (name: string, checked: boolean): void => {
		const therapistUpdated = report.therapist;

		if(checked) {
			therapistUpdated[name] = therapistInfo[name] as string;
		} else {
			delete therapistUpdated[name];
		}

		setReport({...report, therapist: therapistUpdated });
	};

	const addForm = (name: string, checked: boolean): void => {
		const formsUpdated = report.forms;

		if(checked) {
			const form = formsInfo.find(item => (item.id as number).toString() === name) as DocumentShared;

			if(!formsUpdated.find(item => item.id === form.id)) {
				formsUpdated.push(form as DocumentShared);
			}
		} else {
			const index = formsUpdated.findIndex(item => (item.id as number).toString() === name);

			formsUpdated.splice(index, 1);
		}

		setReport({...report, forms: formsUpdated });
	};

	const addNote = (name: string, checked: boolean): void => {
		const updatedNotes = report.notes;
		const index = updatedNotes.findIndex(item => (item.id as number).toString() === name);

		if(index > -1) {
			updatedNotes[index].checked = checked;
		} else {
			const note = notesInfo.find(item => (item.id as number).toString() === name) as Note;

			note.checked = checked;
			updatedNotes.push(note);
		}

		setReport({...report, notes: updatedNotes });
	};

	const handleResourcesOrder = (resources: CardItem[]): void => {
		setReport({...report, resourcesOrder: resources });
	};

	const cleanReport = (): void => {
		setReport(initialReport.report);
	};

	const store: ReportStore = {
		report,
		patientInfo,
		therapistInfo,
		formsInfo,
		notesInfo,
	};

	const storeUpdate: ReportUpdateStore = {
		setReport,
		handleChangePatientInfo,
		handleChangeTherapistInfo,
		handleChangeFormsInfo,
		handleChangeNotesInfo,
		addPatientInformation,
		addTherapistInformation,
		addForm,
		addNote,
		handleResourcesOrder,
		cleanReport,
	};

	return (
		<ReportContext.Provider value={store}>
			<ReportUpdateContext.Provider value={storeUpdate}>
				{children}
			</ReportUpdateContext.Provider>
		</ReportContext.Provider>
	);
};

export default ReportProvider;
