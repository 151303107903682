import AppBar from '@material-ui/core/AppBar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AuthContext } from '../../../contexts/AuthContext';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import NotificationsOutlined from '@material-ui/icons/NotificationsOutlined';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react';
import styles from './TopBar.module.scss';
import { TherapistContext } from '../../../contexts/TherapistContext';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { UserRole } from '../../../interfaces/User';
import useTopBar from './useTopBar';
import { useTranslation } from 'react-i18next';

interface TopBarProps {
	title: string;
	buttons?: JSX.Element;
	lockActions?: boolean;
}

const TopBar: React.FC<TopBarProps> = (props) => {
	const { badge } = React.useContext(TherapistContext);
	const { user } = React.useContext(AuthContext);
	const { t } = useTranslation();

	const {
		avatarUrl,
		anchorRef,
		open,
		handleChangeTherapist,
		handleClickNotifactionIcon,
		handleClose,
		handleLogout,
		handleOpenProfile,
		handleToggle,
	} = useTopBar();

	return (
		<div className={styles.topbar}>
			<AppBar position="static" color="transparent" variant="outlined" className={styles.appbar}>
				<Toolbar className={styles.toolbar}>
					<Typography className={styles.title}>
						{props.title}
					</Typography>
					{props.buttons}
					<div className={styles.grow} />
					{
						!props.lockActions &&
						<IconButton size="small" onClick={handleClickNotifactionIcon} >
							<Badge overlap="circular" variant="dot" invisible={!badge} classes={{ badge: styles.notificationBadge }}>
								<NotificationsOutlined className={styles.notificationIcon} />
							</Badge>
						</IconButton>
					}
					<Divider orientation="vertical" className={styles.divider} />
					<Avatar alt="profile" src={avatarUrl} />
					<Button
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={handleToggle}
						className={styles.profileLabel}
						disableRipple
					>
						<span className={styles.profileLabelName}>{user?.name}</span>
						<span className={styles.profileLabelArrow}><ArrowDropDownIcon /></span>
					</Button>
					<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={styles.popper}>
						{({ TransitionProps, placement }): JSX.Element => (
							<Grow
								{...TransitionProps}
								style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList autoFocusItem={open} id="menu-list-grow">
											{
												!props.lockActions &&
												<MenuItem onClick={handleOpenProfile}>{t('profileView:MENU_MY_ACCOUNT')}</MenuItem>
											}
											{
												!props.lockActions && user?.roles.includes(UserRole.ASSISTANT) &&
												<MenuItem onClick={handleChangeTherapist}>{t('profileView:CHANGE_THERAPIST')}</MenuItem>
											}
											<MenuItem onClick={handleLogout}>{t('profileView:MENU_LOGOUT')}</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default TopBar;
