export default {
	es: {
		faq: {
			FAQ_TITLE: 'Preguntas Frecuentes',
			WHAT_IS_PRAGMA_QUESTION: '¿Qué es Pragma?',
			WHAT_IS_PRAGMA_ANSWER: 'Es una plataforma web específica para los profesionales de la salud mental: psicoterapeutas, psiquiatras, psicopedagogos y logopedas, para que pueden realizar su práctica de manera más eficiente y ordenada. Propone una solución integrada de gestión para que los profesionales puedan centrarse más en los pacientes y menos en el papeleo que conlleva esta práctica. Sus objetivos principales podrían sintetizarse en 3 puntos: 1 - Simplificar el trabajo administrativo: recordatorios de tareas, registro de datos de cada paciente, agenda con recordatorios automáticos; 2 - Agilizar, optimizar y mejorar la experiencia profesional en la elaboración de informes, planes de tratamiento, notas de progreso, historias clínicas; 3 - Conectar al profesional con sus pacientes de una manera efectiva, a través del portal de terapia: enviar material de lectura (de nuestra base de datos basada en bibliografía específica desarrollada por nuestro equipo); cuestionarios y formularios; canal de mensajería segura; Teleterapia.',
			WHO_CAN_USE_PRAGMA_QUESTION: '¿Qué especialistas pueden usar Pragma?',
			WHO_CAN_USE_PRAGMA_ANSWER: 'Pragma fue diseñado para profesionales de la salud mental que trabajan en el ámbito privado y público. Nuestra base de datos de clientes incluye psicólogos, neuropsicólogos, psiquiatras, psicopedagogos, terapistas de lenguaje, psicomotricistas y terapistas ocupacionales.',
			HOW_TO_REGISTER_QUESTION: '¿Cómo me registro?',
			HOW_TO_REGISTER_ANSWER: 'Para registrarte y acceder a todas las funcionalidades de la plataforma necesitas una tarjeta de crédito. De esta forma accedes a 30 días de prueba gratuita para determinar si lo que ofrece Pragma se ajusta a tus necesidades profesionales. Al final de los 30 días, puedes actualizar a uno de nuestros planes pagos o simplemente permitir que tu cuenta caduque.',
			FOR_WHICH_DEVICES_QUESTION: '¿En qué dispositivos funciona?',
			FOR_WHICH_DEVICES_ANSWER: 'Pragma es una plataforma de trabajo que funciona desde cualquier dispositivo que pueda conectarse a un explorador web, con internet. Se recomienda su utilización desde el ordenador para acceder a todas sus funcionalidades. Desde las aplicaciones móviles puedes acceder a diversas funcionalidades, exceptuando las vinculadas a la elaboración de reportes.',
			COSTS_QUESTION: '¿Cuáles son los costos para contratar el servicio?',
			COSTS_ANSWER: 'Tenemos diversos planes, puedes acceder al detalle desde la secciones "Planes"',
			TEAMS_PLANS_QUESTION: '¿Hay planes para equipos o instituciones?',
			TEAMS_PLANS_ANSWER: 'Efectivamente, la plataforma se adapta a las necesidades de equipos o grupos de trabajo interdisciplinario. Contamos con un plan específico para ello.',
			HOW_LONG_TO_SETUP_QUESTION: '¿Cuánto tiempo se tarda en configurar mi cuenta en Pragma?',
			HOW_LONG_TO_SETUP_ANSWER: 'Puede estar en funcionamiento en minutos. Hemos simplificado el inicio con la mínima información requerida para que ingrese. A medida que utiliza las funciones de Pragma, puede personalizarlas.',
			WHAT_SUPPORT_QUESTION: '¿Qué tipo de soporte se ofrece?',
			WHAT_SUPPORT_ANSWER: 'Además del soporte en línea (E-mail y canal de chat), también ofrecemos la siguiente forma de conectarse con nuestros especialistas: Sesiones de entrenamiento individuales de 30 minutos con un especialista en incorporación; Videollamadas que se programan ha pedido en un momento que sea conveniente para usted; Canal de youtube con videos del manejo general de la plataforma y específico de cada funcionalidad.',
			CAN_I_SUGGEST_QUESTION: '¿Se puede enviar algún comentario o recomendación?',
			CAN_I_SUGGEST_ANSWER: 'Claro que sí. Siempre buscamos mejorar la experiencia. Hay una pestaña de Comentarios en la configuración de su cuenta donde puede enviar cualquier pensamiento e idea que pueda tener. Gracias por adelantado.',
			HOW_CAN_I_CANCEL_QUESTION: '¿Cómo cancelo mi suscripción?',
			HOW_CAN_I_CANCEL_ANSWER: 'Dentro de la configuración de su cuenta, en su perfil profesional, hay un enlace que puede usar para cancelar su cuenta.',
			WHERE_IS_MY_DATA_QUESTION: '¿Dónde se guardan mis datos y los de mis pacientes?',
			WHERE_IS_MY_DATA_ANSWER: 'Pragma tiene conformidad y cumple con HIPAA. Todos los datos se cifran y almacenan de forma segura mediante una infraestructura de servidores que está certificada, garantiza la máxima seguridad física y además un tiempo de actividad del 99,9%.',
		},
	},
	en: {
		faq: {
			FAQ_TITLE: 'Frequently Asked Questions',
			WHAT_IS_PRAGMA_QUESTION: 'What is Pragma?',
			WHAT_IS_PRAGMA_ANSWER: 'It is a specific web platform for mental health professionals: psychotherapists, psychiatrists, psychopedagogues and speech therapists, so that they can carry out their practice in a more efficient and orderly way. It proposes an integrated management solution so that professionals can focus more on patients and less on the paperwork involved in this practice. Its main objectives could be synthesized in 3 points: 1 - Simplify administrative work: task reminders, data record of each patient, agenda with automatic reminders; 2 - Streamline, optimize and improve professional experience in the preparation of reports, treatment plans, progress notes, medical records; 3 - Connect the professional with their patients in an effective way, through the therapy portal: send reading material (from our database based on specific bibliography developed by our team); questionnaires and forms; secure messaging channel; Teletherapy.',
			WHO_CAN_USE_PRAGMA_QUESTION: 'What specialists can use Pragma?',
			WHO_CAN_USE_PRAGMA_ANSWER: 'Pragma was designed for mental health professionals working in the private and public spheres. Our client database includes psychologists, neuropsychologists, psychiatrists, educational psychologists, speech therapists, psychomotorists, and occupational therapists.',
			HOW_TO_REGISTER_QUESTION: 'How do I register?',
			HOW_TO_REGISTER_ANSWER: 'To register and access all the functionalities of the platform you need a credit card. In this way, you have access to a 30-day free trial to determine if what Pragma offers meets your professional needs. At the end of 30 days, you can upgrade to one of our paid plans or simply allow your account to expire.',
			FOR_WHICH_DEVICES_QUESTION: 'What devices does it work on?',
			FOR_WHICH_DEVICES_ANSWER: 'Pragma is a work platform that works from any device that can connect to a web browser, with the internet. It is recommended to use it from the computer to access all its functionalities. From the mobile applications you can access various functionalities, except those related to the preparation of reports.',
			COSTS_QUESTION: 'What are the costs to hire the service?',
			COSTS_ANSWER: 'We have different plans, you can access the details from the "Plans" section',
			TEAMS_PLANS_QUESTION: 'Are there plans for teams or institutions?',
			TEAMS_PLANS_ANSWER: 'Indeed, the platform adapts to the needs of teams or interdisciplinary work groups. We have a specific plan for this.',
			HOW_LONG_TO_SETUP_QUESTION: 'How long does it take to set up my Pragma account?',
			HOW_LONG_TO_SETUP_ANSWER: 'It can be up and running in minutes. We have simplified getting started with the minimum information required for you to enter. As you use Pragma features, you can customize them.',
			WHAT_SUPPORT_QUESTION: 'What kind of support is offered?',
			WHAT_SUPPORT_ANSWER: 'In addition to online support (E-mail and chat channel), we also offer the following way to connect with our specialists: 30-minute one-on-one training sessions with an onboarding specialist; Video calls that are scheduled have been requested at a time that is convenient for you; YouTube channel with videos of the general management of the platform and specific to each functionality.',
			CAN_I_SUGGEST_QUESTION: 'Can you send any comments or recommendations?',
			CAN_I_SUGGEST_ANSWER: 'Of course. We are always looking to improve the experience. There is a Comments tab in your account settings where you can submit any thoughts and ideas you may have. Thanks in advance.',
			HOW_CAN_I_CANCEL_QUESTION: 'How do I cancel my subscription?',
			HOW_CAN_I_CANCEL_ANSWER: 'Within your account settings, in your professional profile, there is a link that you can use to cancel your account.',
			WHERE_IS_MY_DATA_QUESTION: 'Where are my data and those of my patients stored?',
			WHERE_IS_MY_DATA_ANSWER: 'Pragma is HIPAA compliant and compliant. All data is encrypted and stored securely through a server infrastructure that is certified, guarantees maximum physical security and also has an uptime of 99.9%.',
		},
	},
};
