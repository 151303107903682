import ChatUserCard from '../ChatUserCard/ChatUserCard';
import NotificationBadge from '../../NotificationBadge/NotificationBadge';
import React from 'react';
import styles from './ContactList.module.scss';
import { useContactList } from './useContactList';
import { useTranslation } from 'react-i18next';

const ContactList: React.FC = () => {
	const { activeContactId, searchTerm, handleSearch, filteredContacts, onSelectContact } = useContactList();
	const { t } = useTranslation();

	return (
		<aside className={styles.contactsSideBar}>
			<input
				className={styles.searchInput}
				type="text"
				placeholder={t('chatView:SEARCH_CONTACTS')}
				value={searchTerm}
				onChange={handleSearch}
			/>
			<ul className={styles.ulList}>
				{filteredContacts.map((contact) => (
					<ChatUserCard
						actions={contact.unreadMessages ? <NotificationBadge count={contact.unreadMessages} /> : <></>}
						active={contact.id === activeContactId}
						avatar={contact.avatar}
						key={contact.id}
						onClick={onSelectContact}
						title={contact.name}
						userId={contact.id}
					/>
				))}
			</ul>
		</aside>
	);
};

export default ContactList;
