import _debounce from 'lodash/debounce';
import { AlertContext } from '../../../contexts/AlertContext';
import { ColDef } from '../../../components/CardsTable/CardsTable';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import { getTagsAndResourcesByTherapist } from '../../../services/ResourceLibraryService';
import { GridSize } from '@material-ui/core/Grid';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';
import { ResourceCard } from '../../../interfaces/Resource';
import { ResourcesByTag } from '../../../interfaces/Tag';
import styles from './ResourceLibrary.module.scss';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface ResourceLibraryStore {
	tagsColumns: ColDef[];
	resourcesColumns: ColDef[];
	filteredTags: ResourcesByTag[];
	resources: ResourceCard[];
	gridWidth: GridSize;
	selectedTagId: number | undefined;
	selectedResource: ResourceCard | undefined;
	searchValue: string;
	openListPatientsModal: boolean;
	handleClickTagCard: (id: number) => void;
	handleClickResourceCard: (id: number) => void;
	handleChangeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleClickLink: () => void;
}

const useResourceLibrary = (): ResourceLibraryStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [searchValue, setSearchValue] = React.useState<string>('');
	const [tagsColumns, setTagsColumns] = React.useState<Array<ColDef>>([]);
	const [tags, setTags] = React.useState<Array<ResourcesByTag>>([]);
	const [filteredTags, setFilteredTags] = React.useState<Array<ResourcesByTag>>([]);
	const [selectedTagId, setselectedTagIdId] = React.useState<number | undefined>(undefined);
	const [resourcesColumns, setResourcesColumns] = React.useState<Array<ColDef>>([]);
	const [resources, setResources] = React.useState<Array<ResourceCard>>([]);
	const [selectedResource, setSelectedResource] = React.useState<ResourceCard | undefined>(undefined);
	const [gridWidth, setGridWidth] = React.useState<GridSize>(12);
	const [openListPatientsModal, setOpenListPatientsModal] = React.useState<boolean>(false);
	const { getTherapistIdParam } = useTherapistSelected();

	const tagColumnsDef: ColDef[] = [
		{
			field: 'folder', headerName: '', sortable: false, flex: 1,
			buttons: [
				{
					key: 'favourite',
					icon: <FolderOutlinedIcon fontSize="inherit" />,
					size: 'small',
				},
			],
		},
		{ field: 'name', headerName: '', sortable: false, flex: 8, justify: 'left' },
	];

	const resourceColumnsDef: ColDef[] = [
		{
			field: 'folder', headerName: '', sortable: false, flex: 1,
			buttons: [
				{
					key: 'folder',
					icon: <InsertDriveFileOutlinedIcon fontSize="inherit" />,
					style: styles.iconFolder,
					size: 'small',
				},
			],
		},
		{
			field: 'name',
			headerName: '',
			sortable: false,
			flex: 8,
			justify: 'left',
		},
		{
			field: 'actions',
			headerName: '',
			sortable: false,
			flex: 2,
			map: false,
		},
	];

	const getResourcesByTag = (id: number | undefined): ResourceCard[] => {
		const tagResources = tags.find(tag => tag.id === id)?.resources;

		return tagResources || [];
	};

	const loadData = async (): Promise<void> => {
		try {
			const therapistIdParam = getTherapistIdParam();

			const data = await getTagsAndResourcesByTherapist(therapistIdParam);

			setTagsColumns(tagColumnsDef);
			setResourcesColumns(resourceColumnsDef);
			setTags(data);
			setFilteredTags(data);
		} catch (error) {
			showDefaultError();
		}
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (selectedTagId) {
			setResources(getResourcesByTag(selectedTagId));
			setGridWidth(6);
			setSelectedResource(undefined);
		}
	}, [selectedTagId]);

	const handleClickTagCard = (id: number): void => {
		setselectedTagIdId(id);
		setGridWidth(6);
	};

	const handleClickResourceCard = (id: number): void => {
		const resource = resources.find(resource => resource.id === id);

		setSelectedResource(resource);
		setGridWidth(4);
	};

	const handleDebounce = (value: string, tags: ResourcesByTag[]): void => {
		if (value) {
			const data = tags.filter(tag => tag.name.toUpperCase().includes(value.toUpperCase()));

			setFilteredTags(data);
		} else {
			setFilteredTags(tags);
		}

		setselectedTagIdId(undefined);
		setSelectedResource(undefined);
		setGridWidth(12);
	};

	const debounceHandleChangeSearchValue = React.useCallback(_debounce(handleDebounce, 500), []);

	const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSearchValue(event.target.value);
		debounceHandleChangeSearchValue(event.target.value, tags);
	};

	const handleClickLink = (): void => {
		setOpenListPatientsModal(prevState => !prevState);
	};

	return ({
		tagsColumns,
		resourcesColumns,
		filteredTags,
		resources,
		selectedTagId,
		selectedResource,
		gridWidth,
		searchValue,
		openListPatientsModal,
		handleClickTagCard,
		handleClickResourceCard,
		handleChangeSearchValue,
		handleClickLink,
	});
};

export default useResourceLibrary;
