import { FormDateField, FormSelectField, FormTextField } from './Fields';
import { Form } from '../../../interfaces/FormData';
import Modal from '../../Modal/Modal';
import React from 'react';
import { TherapistInfo } from '../../../interfaces/User';
import usePersonalInfoModal from './usePersonalInfoModal';
import useTherapistPersonalInfoModal from './useTherapistPersonalInfoModal';
import { useTranslation } from 'react-i18next';

export interface PersonalInfoModalProps {
	data: TherapistInfo;
	open: boolean;
	getUser: () => Promise<void>;
	updateUser: (formData: Form) => Promise<void>;
	onChange: () => void;
}

const TherapistPersonalInfoModal: React.FC<PersonalInfoModalProps> = ({ data, open, getUser, updateUser, onChange }) => {
	const { t } = useTranslation();
	const {
		formData,
		formIsUpdated,
		countries,
		genders,
		handleChangeTextField,
		handleChangeSelect,
		handleChangeDate,
		handleClose,
		handleConfirm,
	} = usePersonalInfoModal({ data, getUser, updateUser, onChange });

	const {
		jobs,
	} = useTherapistPersonalInfoModal();

	return (
		<Modal
			open={open}
			title={t('profileView:PERSONAL_INFO')}
			handleOnClose={handleClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CONFIRM'),
				onClick: handleConfirm,
				isDisabled: !formIsUpdated,
			}}
			isSecondaryButtonVisible
			secondaryButtonProps={{
				title: t('patientHomeView:MODAL_BUTTON_CANCEL'),
				onClick: handleClose,
			}}
			scroll="body"
		>
			<div>
				{
					formData && Object.keys(formData).length > 0
						?
						<>
							<FormTextField formValue={formData['name']} handleChangeTextField={handleChangeTextField} />
							<FormTextField formValue={formData['lastName']} handleChangeTextField={handleChangeTextField} />
							<FormDateField formKey={'birthdate'} formValue={formData['birthdate']} handleChangeDate={handleChangeDate} />
							<FormSelectField formKey={'country'} formValue={formData['country']} options={countries} handleChangeSelect={handleChangeSelect} />
							<FormTextField formValue={formData['city']} handleChangeTextField={handleChangeTextField} />
							<FormTextField formValue={formData['postalCode']} handleChangeTextField={handleChangeTextField} />
							<FormTextField formValue={formData['phone']} handleChangeTextField={handleChangeTextField} />
							<FormSelectField formKey={'gender'} formValue={formData['gender']} options={genders} handleChangeSelect={handleChangeSelect} />
							<FormTextField formValue={formData['email']} handleChangeTextField={handleChangeTextField} />
							<FormSelectField formKey={'job'} formValue={formData['job']} options={jobs} handleChangeSelect={handleChangeSelect} />
							<FormTextField formValue={formData['enrollment']} handleChangeTextField={handleChangeTextField} />
						</>
						: <div>Loading</div>
				}
			</div>
		</Modal>
	);
};

export default TherapistPersonalInfoModal;
