import illustration from '../../assets/images/illustration-grow.png';
// import MoreSection from './MoreSection';
import React from 'react';
import styles from './About.module.scss';
import TeamSection from './TeamSection';
import { useTranslation } from 'react-i18next';

const About = (): JSX.Element => {
	const { t } = useTranslation();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// const planCards: [string, string][] = [
	// 	[t('about:TREATMENT_ADULT_TITLE'), t('about:TREATMENT_ADULT_DESCRIPTION')],
	// 	[t('about:TREATMENT_TEEN_TITLE'), t('about:TREATMENT_TEEN_DESCRIPTION')],
	// 	[t('about:TREATMENT_CHILDREN_TITLE'), t('about:TREATMENT_CHILDREN_DESCRIPTION')],
	// 	[t('about:TREATMENT_OLDER_TITLE'), t('about:TREATMENT_OLDER_DESCRIPTION')],
	// 	[t('about:TREATMENT_LANGUAGE_TITLE'), t('about:TREATMENT_LANGUAGE_DESCRIPTION')],
	// 	[t('about:TREATMENT_PSYCHIATRY_TITLE'), t('about:TREATMENT_PSYCHIATRY_DESCRIPTION')],
	// 	[t('about:TREATMENT_PSYCHOPEDAGOGICAL_TITLE'), t('about:TREATMENT_PSYCHOPEDAGOGICAL_DESCRIPTION')],
	// ];

	return (
		<>
			<div className={styles.mainContainer}>
				<div className={styles.container}>
					<h2 className={styles.mainTitle}>{t('about:MAIN_TITLE')}</h2>
					<p className={styles.mainDescription}>{t('about:MAIN_DESCRIPTION')}</p>
				</div>
				<img src={illustration} alt="" className={styles.mainImage} />
			</div>
			<TeamSection />
			{/* <h2 className={styles.planTitle}>{t('about:TREATMENT_TITLE')}</h2>
			<div className={styles.treatmentContainer}>
				{
					planCards.map(([title, description]) =>
						(
							<div key={title} className={styles.teamBehindCard}>
								<div className={styles.card}>
									<div className={styles.rectangle} />
									<div className={styles.cardDescription}>
										<h5 className={styles.treatment_card_title}>{title}</h5>
										<p className={styles.treatment_card_description}>{description}</p>
									</div>
								</div>
							</div>
						))
				}
			</div>
			<MoreSection /> */}
		</>
	);
};

export default About;
