import { keyValue } from '../interfaces/Common';
import { useTranslation } from 'react-i18next';

interface DateStore {
	days: keyValue;
	months: keyValue;
}

export const useDates = (): DateStore => {
	const { t } = useTranslation();
	const days: {[key: number]: string} = {
		0: t('days:SUN'),
		1: t('days:MON'),
		2: t('days:TUE'),
		3: t('days:WED'),
		4: t('days:THU'),
		5: t('days:FRI'),
		6: t('days:SAT'),
	};

	const months: {[key: number]: string} = {
		0: t('months:JAN'),
		1: t('months:FEB'),
		2: t('months:MAR'),
		3: t('months:APR'),
		4: t('months:MAY'),
		5: t('months:JUN'),
		6: t('months:JUL'),
		7: t('months:AUG'),
		8: t('months:SEP'),
		9: t('months:OCT'),
		10: t('months:NOV'),
		11: t('months:DEC'),
	};

	return {
		days,
		months,
	};
};
