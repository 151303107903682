import { AlertContext } from '../../../contexts/AlertContext';
import { getSessionEventsByTherapist } from '../../../services/SessionService';
import React from 'react';
import { SessionEvent } from '../../../interfaces/Session';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface SessionsAvailabilityModalStore {
	isLoading: boolean;
	selectedDate: Date | null;
	sessions: SessionEvent[];
	handleChangeDate: (date: Date | null) => void;
	handleClose: () => void;
}

interface SessionsAvailabilityModalProps {
	onChange: () => void;
}

const useSessionsAvailabilityModal = (props: SessionsAvailabilityModalProps): SessionsAvailabilityModalStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
	const [sessions, setSessions] = React.useState<SessionEvent[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { getTherapistIdParam } = useTherapistSelected();

	const getSessions = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const dataSessions = await getSessionEventsByTherapist(therapistIdParam, selectedDate);

			setSessions(dataSessions);
		} catch (error) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		getSessions();
	}, [selectedDate]);

	const handleChangeDate = (date: Date | null = new Date()): void => {
		setSelectedDate(date);
	};

	const handleClose = (): void => {
		props.onChange();
	};

	return {
		isLoading,
		selectedDate,
		sessions,
		handleChangeDate,
		handleClose,
	};
};

export default useSessionsAvailabilityModal;
