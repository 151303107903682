export default {
	es: {
		blogContent: {
			TITLE: 'Blog',
			FILTER: 'Filtrar por categorías',
			MORE: 'Leer más',
			FILTER_RESPONSIVE: 'Filtrar',
			NO_POST: 'No se encontró ningún Post.',
			NO_FILTER: 'No se encontró ningún filtro.',
		},
	},
	en: {
		blogContent: {
			TITLE: 'Blog',
			FILTER: 'Filter by category',
			MORE: 'See more',
			FILTER_RESPONSIVE: 'Filter',
			NO_POST: 'No Post found.',
			NO_FILTER: 'No filter found.',
		},
	},
};
