import { Card as div, TextField} from '@material-ui/core';
import { UserFilterListCardProps, useUserFilterListCard } from './useUserFilterListCard';
import React from 'react';
import styles from './UserFilterListCard.module.scss';
import UserSelector from '../../UserSelector/UserSelector';

export const UserFilterListCard: React.FC<UserFilterListCardProps> = (props) => {
	const {
		data,
		debouncedResults,
	} = useUserFilterListCard(props);

	return (
		<div className={`${styles.cardForm} ${props.fullWidth && styles.maxWidth} ${props.className}`}>
			<div className={`${styles.content} ${props.fullWidth && styles.maxWidth}`}>
				{
					props.title &&
						<div className={styles.header}>
							<p className={styles.headerTitle}>{props.title}</p>
						</div>
				}
				<div className={styles.body}>
					{
						props.filter &&
							<TextField
								fullWidth
								onChange={(e): void => debouncedResults(e.target.value)}
								variant='outlined'
							/>
					}
					<UserSelector users={data} height={props.userSelector.height} />
				</div>
			</div>
		</div>
	);
};
