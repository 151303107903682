interface UseDonwloadStore {
	download: (file: Blob, fileName: string) => void;
}

const useDownload = (): UseDonwloadStore => {
	const download = (file: Blob, fileName: string): void => {

		const url = window.URL.createObjectURL(file);
		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', `${fileName}`);
		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	};

	return {
		download,
	};

};

export default useDownload;
