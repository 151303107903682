import { acceptTherapist, deleteTherapist, findAssistant, findTherapistRequests, rejectTherapist, updateAssistantInfo, updateAvatar } from '../api/AssistantRepository';
import { Assistant, AssistantTherapistRequest } from '../interfaces/Assistant';
import { convertFileToFormData } from './GenericService';
import { Form } from '../interfaces/FormData';

const mapFormToAssistant = (form: Form): Assistant => {
	return {
		givenName: form.givenName.value.toString(),
		familyName: form.familyName.value.toString(),
	} as Assistant;
};

export const getTherapistRequests = async (): Promise<AssistantTherapistRequest[]> => {
	return await findTherapistRequests();
};

export const getProfile = async (): Promise<Assistant> => {
	return await findAssistant();
};

export const updateAssistantAvatar = async ( files: File[]): Promise<void> => {
	const data = await convertFileToFormData(files[0]);

	return await updateAvatar(data);
};

export const removeTherapist = async (therapistId: number): Promise<void> => {
	return await deleteTherapist(therapistId);
};

export const updateAssistant = async (assistant: Form): Promise<void> => {
	return await updateAssistantInfo(mapFormToAssistant(assistant));
};

export const acceptTherapistRequest = async (therapistId: number): Promise<void> => {
	return await acceptTherapist(therapistId);
};

export const rejectTherapistRequest = async (therapistId: number): Promise<void> => {
	return await rejectTherapist(therapistId);
};
