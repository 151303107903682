import { LoadingSpinner, Tag } from '../../components';
import React from 'react';
import styles from './Post.module.scss';
import usePost from './usePost';

const Post: React.FC = () => {
	const { isLoadingPost, post } = usePost();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={styles.postContainer}>
			{
				isLoadingPost ? (
					<LoadingSpinner />
				) : (
					<>
						{
							post ? (
								<>
									<div
										className={styles.postHeader}
										style={{
											backgroundImage: `url(${post.imageUrl})`,
											backgroundSize: 'cover',
											boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
										}}
									>
										<div className={styles.tags}>
											{
												post.tags.map(tag => (
													<div key={tag.id} className={styles.tagContainer}><Tag text={tag.name} classes={styles.tag} /></div>
												))
											}
										</div>
										<div className={styles.title}>
											{post.title}
										</div>
									</div>
									<div className={styles.postContent} dangerouslySetInnerHTML={{ __html: post.content }}></div>
								</>
							) : (
								null
							)
						}
					</>
				)
			}
		</div>
	);
};

export default Post;
