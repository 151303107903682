import { Button } from '../../..';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import styles from './TherapistButtons.module.scss';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

interface TherapistButtonsProps {
	button?: {
		text: string;
		icon: JSX.Element;
		onClick: () => void;
	};
	showSetReminders?: boolean;
	showSearchInput?: boolean;
	showChangeWorkHours?: {
		show: boolean;
		onClick: () => void;
	};
	searchValue?: string;
	onChangeSearchInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TherapistButtons: React.FC<TherapistButtonsProps> = (props) => {
	const { t } = useTranslation();

	return (
		<div className={styles.therapistButtons}>
			{props.button &&
				<div className={styles.primaryButton}>
					<Button
						type="PRIMARY"
						startIcon={props.button.icon}
						title={props.button.text}
						onClick={props.button.onClick}
						size="large"
					/>
				</div>}
			{props.showSetReminders && <div className={styles.secondaryButton}>
				<Button
					type="TERTIARY"
					startIcon={<SettingsOutlinedIcon />}
					title={t('therapistButtons:SET_REMINDER_BUTTON')}
					variant="text"
					onClick={(): void => { /* Do nothing */ }}
				/>
			</div>}
			{props.showSearchInput && <div className={styles.search}>
				<Divider orientation="vertical" className={styles.divider} />
				<TextField
					placeholder={t('therapistButtons:SEARCH_INPUT')}
					value={props.searchValue}
					onChange={props.onChangeSearchInput}
					InputProps={{
						startAdornment: <SearchOutlinedIcon color="disabled" className={styles.icon} />,
						disableUnderline: true,
					}}
				/>
			</div>}
			{props.showChangeWorkHours && <div className={styles.secondaryButton}>
				<Button
					type="TERTIARY"
					startIcon={<SettingsOutlinedIcon />}
					title={t('therapistButtons:CHANGE_WORK_HOURS')}
					variant="text"
					onClick={props.showChangeWorkHours.onClick}
				/>
			</div>}
		</div>
	);
};

export default TherapistButtons;
