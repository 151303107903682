import moment from 'moment';
import React from 'react';
import styles from './IncomingMessage.module.scss';

interface Props {
  text: string;
	date: Date;
}

const IncomingMessage: React.FC<Props> = ({ date, text }) => {
	return (
		<div className={styles.incomingMessage}>
			<div className={styles.message}>
				<div className={styles.messageBubble}>{text}</div>
				<div className={styles.messageTime}>{moment(date).format('HH:MM')}</div>
			</div>
		</div>
	);
};

export default IncomingMessage;
