import { Modal, PrivateTopBar, TherapistProfileCard } from '../../../components';
import { ConfirmationTypes } from '../../../interfaces/ConfirmationModels';
import React from 'react';
import styles from './Home.module.scss';
import TherapistRequestProfileCard from '../../../components/Cards/TherapistRequestProfileCard/TherapistRequestProfileCard';
import { useHome } from './useHome';
import { useTranslation } from 'react-i18next';

const Home = (): JSX.Element => {
	const {
		menuOptions,
		therapists,
		therapistRequests,
		openConfirmationModal,
		confirmationModalState,
		handleAcceptTherapist,
		handleRedirect,
		handleConfirmationModal,

	} = useHome();
	const { t } = useTranslation();

	return (
		<div className={styles.assistantHome}>
			<PrivateTopBar title={t('assistant:CHOOSE_THERAPIST')} lockActions />
			<div className={styles.content}>
				{
					therapists && therapists.length > 0 &&
					therapists.map((therapist) => {
						return (
							<>
								<TherapistProfileCard
									fullName={`${therapist.familyName} ${therapist.givenName}`}
									image={therapist.avatarFile?.url}
									key={`key-${therapist.familyName} ${therapist.givenName}`}
									options={menuOptions}
									onClick={(): void => handleRedirect(therapist.id)}
									therapistId={therapist.id}
								/>
							</>
						);
					})
				}
				{
					therapistRequests && therapistRequests.length > 0 &&
					therapistRequests.map((therapistRequest) => {
						return (
							<>
								<TherapistRequestProfileCard
									fullName={`${therapistRequest.familyName} ${therapistRequest.givenName}`}
									image={therapistRequest.avatarFile?.url}
									key={`key-${therapistRequest.familyName} ${therapistRequest.givenName}`}
									onAccept={async (): Promise<void> => handleAcceptTherapist(therapistRequest.id) }
									onReject={(): void => handleConfirmationModal(ConfirmationTypes.cancelTherapistInvitation, therapistRequest.id)}
								/>
							</>
						);
					})
				}
			</div>
			{
				openConfirmationModal &&
				<Modal
					open={openConfirmationModal}
					title={t(`${confirmationModalState.title}`)}
					handleOnClose={confirmationModalState.onClose}
					isPrimaryButtonVisible
					primaryButtonProps={{
						title: t('profileView:MODAL_CONFIRM_BUTTON'),
						onClick: confirmationModalState.onConfirm,
						isDisabled: false,
					}}
					isSecondaryButtonVisible
					secondaryButtonProps={{
						title: t('profileView:MODAL_CANCEL_BUTTON'),
						onClick: confirmationModalState.onClose,
					}}
					showDividers={false}
				>
					{t(`${confirmationModalState.content}`)}
				</Modal>
			}
		</div>
	);
};

export default Home;
