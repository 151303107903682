import { useHistory, useLocation } from 'react-router-dom';
import { updateSessionStatus } from './../../../services/SessionService';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

interface SessionPreviewModalStore {
	handleCreateNote: (patientId: number | undefined) => void;
	handleConfirmSession: (sessionId: number | undefined) => Promise<void>;
}

interface SessionPreviewModalProps {
	onClose: () => void;
	onRefreshData: () => void;
}

const useSessionPreviewModal = (props: SessionPreviewModalProps): SessionPreviewModalStore => {
	const history = useHistory();
	const location = useLocation();
	const { getTherapistIdParam } = useTherapistSelected();

	const handleCreateNote = async (patientId: number | undefined): Promise<void> => {
		if (patientId) {
			history.push(`${location.pathname}/${patientId}`);

		}
	};

	const handleConfirmSession = async (sessionId: number | undefined): Promise<void> => {
		if (sessionId) {
			const confirmStatus = 1;
			const therapistIdParam = getTherapistIdParam();

			await updateSessionStatus(therapistIdParam, sessionId, confirmStatus);
			await props.onRefreshData();
		}

		props.onClose();
	};

	return {
		handleCreateNote,
		handleConfirmSession,
	};
};

export default useSessionPreviewModal;
