export default {
	es: {
		days: {
			SUN: 'dom',
			MON: 'lun',
			TUE: 'mar',
			WED: 'mie',
			THU: 'jue',
			FRI: 'vie',
			SAT: 'sab',
		},
		months: {
			JAN: 'enero',
			FEB: 'febrero',
			MAR: 'marzo',
			APR: 'abril',
			MAY: 'mayo',
			JUN: 'junio',
			JUL: 'julio',
			AUG: 'agosto',
			SEP: 'septiembre',
			OCT: 'octubre',
			NOV: 'noviembre',
			DEC: 'diciembre',
		},
		messages: {
			NO_DATA: 'Sin datos',
		},
		pagination: {
			VIEWING: 'Viendo',
			OF: 'de',
		},
		forms: {
			FIELD_REQUIRED: 'Este campo es requerido',
			INVALID_EMAIL_ADDRESS: 'Invalid email address',
		},
	},
	en: {
		days: {
			SUN: 'sun',
			MON: 'mon',
			TUE: 'tue',
			WED: 'wed',
			THU: 'thu',
			FRI: 'fri',
			SAT: 'sat',
		},
		months: {
			JAN: 'january',
			FEB: 'february',
			MAR: 'march',
			APR: 'april',
			MAY: 'may',
			JUN: 'june',
			JUL: 'july',
			AUG: 'august',
			SEP: 'september',
			OCT: 'october',
			NOV: 'november',
			DEC: 'december',
		},
		messages: {
			NO_DATA: 'No data',
		},
		pagination: {
			VIEWING: 'Viewing',
			OF: 'of',
		},
		forms: {
			FIELD_REQUIRED: 'This field is required',
			INVALID_EMAIL_ADDRESS: 'Invalid email address',
		},
	},
};
