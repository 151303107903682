import Carousel from 'react-material-ui-carousel';
import React from 'react';
import { SmallCard } from '../../components';
import styles from './Carrousel.module.scss';

export interface CarrouselData {
	src: string;
	title: string;
	description: string;
}

interface CarrouselProps {
	data: CarrouselData[];
}

const Carrousel: React.FC<CarrouselProps> = ({ data }) => {

	return (
		<div className={styles.container}>
			<Carousel className={styles.carrousel} >
				{
					data.map((child) => <SmallCard key={child.title} image={child.src} title={child.title} text={child.description} />)
				}
			</Carousel>
		</div>
	);
};

export default Carrousel;
