import { getPatientsByTherapist, getTherapistAvailability } from '../../../services/TherapistService';
import { getPlacesByTherapist, getSessionById, getSessionEventsByTherapist, getSessionsTypesByTherapist } from '../../../services/SessionService';
import { Office, Session, SessionEvent, SessionType, SessionTypeFilter } from '../../../interfaces/Session';
import { Patient, PatientFilter } from '../../../interfaces/Patient';
import { TherapistModalContext, UpdateTherapistModalContext } from '../../../contexts/TherapistModalContext';
import { AlertContext } from '../../../contexts/AlertContext';
import moment from 'moment';
import React from 'react';
import { SessionAvailability } from '../../../interfaces/SessionAvailability';
import { useTherapistSelected } from '../../../hooks/useTherapistSelected';

type ActionType = 'Add' | 'Update';

type FilterType = 'Patient' | 'SessionType';

interface AgendaStore {
	openNewSessionModal: boolean;
	openSessionPreviewModal: boolean;
	selectedDate: Date | null;
	sessions: SessionEvent[];
	refreshCalendarData: boolean;
	sessionModalTherapistAction: ActionType;
	selectedSession: Session | undefined;
	isLoading: boolean;
	places: Office[];
	patients: Patient[];
	therapistAvailability: SessionAvailability;
	sessionsTypes: SessionType[];
	handleOpenNewSessionModal: () => void;
	handleCloseNewSessionModal: () => void;
	handleOnConfirmNewSessionModal: () => void;
	handleOnConfirmAvailabilityModal: () => void;
	handleChangeSelectedDate: (date: Date | null) => void;
	handleClickCalendarSessionCard?: (sessionInfo: SessionEvent) => void;
	handleClickSideMenuFilter: (filterType: FilterType, value: string) => void;
	handleOnEditSession: () => void;
	handleOnCloseSessionPreviewModal: () => void;
	handleRefreshSessions: () => void;
	fetchData: () => Promise<void>;
}

const useAgenda = (): AgendaStore => {
	const { showDefaultError } = React.useContext(AlertContext);
	const { setIsOpen } = React.useContext(UpdateTherapistModalContext);
	const { isOpen } = React.useContext(TherapistModalContext);
	const { getTherapistIdParam } = useTherapistSelected();
	const [openNewSessionModal, setOpenNewSessionModal] = React.useState<boolean>(false);
	const [refreshCalendarData, setRefreshCalendarData] = React.useState<boolean>(false);
	const [openSessionPreviewModal, setOpenSessionPreviewModal] = React.useState<boolean>(false);
	const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
	const [sessions, setSessions] = React.useState<SessionEvent[]>([]);
	const [baseSessions, setBaseSessions] = React.useState<SessionEvent[]>([]);
	const [sessionsTypesFilter, setSessionsTypesFilter] = React.useState<SessionTypeFilter[]>([]);
	const [sessionsPatients, setSessionsPatients] = React.useState<PatientFilter[]>([]);
	const [sessionModalTherapistAction, setSessionModalTherapistAction] = React.useState<ActionType>('Add');
	const [selectedSession, setSelectedSession] = React.useState<Session | undefined>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [patients, setPatients] = React.useState<Patient[]>([]);
	const [places, setPlaces] = React.useState<Array<Office>>([]);
	const [therapistAvailability, setTherapistAvailability] = React.useState<SessionAvailability>({} as SessionAvailability);
	const [sessionsTypes, setSessionsTypes] = React.useState<SessionType[]>([]);

	const fetchData = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const therapistIdParam = getTherapistIdParam();
			const dataPatients = await getPatientsByTherapist(therapistIdParam);
			const dataSessionTypes = await getSessionsTypesByTherapist(therapistIdParam);
			const dataPlaces = await getPlacesByTherapist(therapistIdParam);
			const sessionAvailability = await getTherapistAvailability(therapistIdParam);

			setPatients(dataPatients);
			setSessionsTypes(dataSessionTypes);
			setPlaces(dataPlaces);
			setTherapistAvailability(sessionAvailability);
		} catch (error) {
			showDefaultError();
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSessions = async (date: Date | null): Promise<void> => {
		try {
			const therapistIdParam = getTherapistIdParam();
			const weeklySessions = await getSessionEventsByTherapist(therapistIdParam, date);

			setSessions(weeklySessions);
			setBaseSessions(weeklySessions);
		} catch (error) {
			showDefaultError();
		}
	};

	React.useEffect(() => {
		getSessions(selectedDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, refreshCalendarData]);

	React.useEffect(() => {
		const someSessionPatientSelected = sessionsPatients.some(sessionPatient => sessionPatient.selected);
		const someSessionTypesSelected = sessionsTypesFilter.some(sessionType => sessionType.selected);

		if ((someSessionPatientSelected || someSessionTypesSelected) && sessionsTypes.length && sessionsPatients.length) {

			const data = baseSessions.filter(session => {
				const isSessionPatientSelected = sessionsPatients.some(sessionPatient => sessionPatient.selected && `${session.patient.givenName} ${session.patient.familyName}` === sessionPatient.name);

				const isSessionTypeSelected = sessionsTypesFilter.some(sessionType => sessionType.selected && session.type.name === sessionType.name);

				return isSessionPatientSelected || isSessionTypeSelected;
			});

			setSessions(data);
		} else {
			setSessions(baseSessions);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionsPatients, baseSessions, sessionsTypesFilter]);

	const handleOpenNewSessionModal = (): void => {
		setSessionModalTherapistAction('Add');
		setOpenNewSessionModal(prevState => !prevState);
	};

	const handleCloseNewSessionModal = (): void => {
		setOpenNewSessionModal(prevState => !prevState);
		setSelectedSession(undefined);
	};

	const handleOnConfirmNewSessionModal = (): void => {
		setRefreshCalendarData(prevState => !prevState);
		handleCloseNewSessionModal();
	};

	const handleOnConfirmAvailabilityModal = (): void => {
		setIsOpen(!isOpen);
	};

	const handleChangeSelectedDate = (date: Date | null = new Date()): void => {
		const areDatesOfDifferentWeeks = moment(date).isSame(selectedDate, 'week');

		if (!areDatesOfDifferentWeeks) {
			getSessions(date);
		}

		setSelectedDate(date);
	};

	const handleClickCalendarSessionCard = async (session: SessionEvent): Promise<void> => {
		setSessionModalTherapistAction('Update');

		if (session?.id) {
			const therapistIdParam = getTherapistIdParam();
			const selectedSession = await getSessionById(therapistIdParam, session.id);

			setSelectedSession(selectedSession);
		}

		setOpenSessionPreviewModal(prevState => !prevState);
	};

	const getSideMenuFilters = (data: (PatientFilter | SessionTypeFilter)[], value: string): (PatientFilter | SessionTypeFilter)[] => {
		return data.map(session => {
			return session.name === value
				? { ...session, selected: !session.selected }
				: session;
		});
	};

	const handleClickSideMenuFilter = (filterType: FilterType, value: string): void => {
		if (filterType === 'Patient') {
			const data = getSideMenuFilters(sessionsPatients, value);

			setSessionsPatients(data);
		} else {
			const data = getSideMenuFilters(sessionsTypesFilter, value);

			setSessionsTypesFilter(data);
		}
	};

	const handleOnCloseSessionPreviewModal = (): void => {
		setOpenSessionPreviewModal(prevState => !prevState);
	};

	const handleOnEditSession = (): void => {
		handleOnCloseSessionPreviewModal();
		setOpenNewSessionModal(prevState => !prevState);
	};

	const handleRefreshSessions = (): void => {
		setRefreshCalendarData(prevState => !prevState);
	};

	return ({
		openNewSessionModal,
		openSessionPreviewModal,
		selectedDate,
		sessions,
		refreshCalendarData,
		sessionModalTherapistAction,
		selectedSession,
		isLoading,
		patients,
		therapistAvailability,
		sessionsTypes,
		places,
		handleOpenNewSessionModal,
		handleCloseNewSessionModal,
		handleOnConfirmNewSessionModal,
		handleOnConfirmAvailabilityModal,
		handleChangeSelectedDate,
		handleClickCalendarSessionCard,
		handleClickSideMenuFilter,
		handleOnEditSession,
		handleOnCloseSessionPreviewModal,
		handleRefreshSessions,
		fetchData,
	});
};

export default useAgenda;
