import about from './about';
import assistant from './assistant';
import blog from './blog';
import buttons from './buttons';
import chat from './chat';
import clinicalSituations from './clinicalSituations';
import contact from './contact';
import countries from './countries';
import documentCard from './documentCard';
import faq from './faq';
import footer from './footer';
import functions from './functions';
import helpers from './helpers';
import home from './home';
import institution from './institution';
import jobs from './jobs';
import messages from './messages';
import pages from './pages';
import patientPages from './patientPages';
import personalInfo from './personalInfo';
import planCard from './planCard';
import plans from './plans';
import routes from './routes';
import signin from './signin';
import signup from './signup';
import therapistPages from './therapistPages';
import topBar from './topBar';
import treatedPatientAges from './treatedPatientAges';

const translations = {
	es: {
		...assistant.es,
		...blog.es,
		...buttons.es,
		...chat.es,
		...contact.es,
		...countries.es,
		...documentCard.es,
		...footer.es,
		...jobs.es,
		...helpers.es,
		...home.es,
		...institution.es,
		...pages.es,
		...patientPages.es,
		...routes.es,
		...topBar.es,
		...planCard.es,
		...functions.es,
		...plans.es,
		...faq.es,
		...about.es,
		...therapistPages.es,
		...signin.es,
		...signup.es,
		...messages.es,
		...personalInfo.es,
		...clinicalSituations.es,
		...treatedPatientAges.es,
	},
	en: {
		...assistant.en,
		...blog.en,
		...buttons.en,
		...chat.en,
		...contact.en,
		...countries.en,
		...documentCard.en,
		...footer.en,
		...jobs.en,
		...helpers.en,
		...home.en,
		...institution.en,
		...pages.en,
		...patientPages.en,
		...routes.en,
		...topBar.en,
		...planCard.en,
		...functions.en,
		...plans.en,
		...faq.en,
		...about.en,
		...therapistPages.en,
		...signin.en,
		...signup.en,
		...messages.en,
		...personalInfo.en,
		...clinicalSituations.en,
		...treatedPatientAges.en,
	},
};

export default translations;
