import React from 'react';
import styles from './Divider.module.scss';

interface DividerProps {
	className?: string;
	text?: string;
}

const Divider: React.FC<DividerProps> = ({ className, text }) => {
	return (
		<div className={className}>
			<div className={styles.horizontalRuler} />
			{
				!!text && <span className={styles.rulerText}>{text}</span>
			}
			<div className={styles.horizontalRuler} />
		</div>
	);
};

export default Divider;
